const React = require('react');
const { Box, Paper, Typography, TextField, Button } = require('@mui/material');
const { useAuth } = require('./AuthContext');

// 驗證入口組件
const AuthGateway = ({ children }) => {
  const { isAuthenticated, isLoading, authenticateByIP, authenticateByPassphrase } = useAuth();
  const [passphrase, setPassphrase] = React.useState('');
  const [error, setError] = React.useState('');
  const [attemptingIpAuth, setAttemptingIpAuth] = React.useState(false);
  
  // 組件載入時嘗試 IP 驗證，但不影響UI顯示
  React.useEffect(() => {
    const checkIP = async () => {
      if (attemptingIpAuth) return;
      
      setAttemptingIpAuth(true);
      try {
        // 嘗試 IP 驗證，但不會阻止顯示密碼輸入界面
        const result = await authenticateByIP();
        console.log('IP驗證結果:', result);
      } catch (error) {
        console.error('IP驗證錯誤:', error);
      }
      setAttemptingIpAuth(false);
    };
    
    if (!isAuthenticated && !isLoading) {
      checkIP();
    }
  }, [isAuthenticated, isLoading, authenticateByIP, attemptingIpAuth]);
  
  // 處理密語表單提交
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!passphrase.trim()) {
      setError('請輸入通關密語');
      return;
    }
    
    // 嘗試通過密語驗證
    const result = await authenticateByPassphrase(passphrase);
    if (!result.success) {
      setError(result.message || '密語不正確');
    }
  };
  
  // 如果已經通過身份驗證，顯示子組件（主應用內容）
  if (isAuthenticated) {
    return children;
  }
  
  // 無論如何都顯示密語輸入表單
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      bgcolor: '#2c3e50' // 深藍灰色背景
    }}>
      <Paper 
        elevation={4} 
        sx={{ 
          width: '100%', 
          maxWidth: 400, 
          p: 4,
          borderRadius: 2,
          bgcolor: '#34495e', // 稍淺的深藍灰色
          color: '#ecf0f1' // 明亮的淺灰色文字
        }}
      >
        <Typography variant="h5" component="h1" sx={{ 
          mb: 2, 
          fontWeight: 'bold', 
          textAlign: 'center',
          color: '#3498db' // 藍色標題
        }}>
          MaikoSniper 訪問控制
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 3, 
          textAlign: 'center',
          color: '#ecf0f1' // 淺灰色文字
        }}>
          請輸入通關密語繼續操作
        </Typography>
        
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="password"
            label="通關密語"
            variant="outlined"
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{ 
              mb: 3,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#7f8c8d', // 標準邊框顏色
                },
                '&:hover fieldset': {
                  borderColor: '#3498db', // 懸停時的邊框顏色
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2ecc71', // 聚焦時的邊框顏色
                },
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              },
              '& .MuiInputLabel-root': {
                color: '#bdc3c7', // 標籤顏色
              },
              '& .MuiInputBase-input': {
                color: '#ecf0f1', // 輸入文字顏色
              },
              '& .MuiFormHelperText-root': {
                color: '#e74c3c', // 錯誤信息顏色
              }
            }}
          />
          
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            size="large" 
            sx={{ 
              py: 1.5,
              bgcolor: '#2ecc71', // 綠色按鈕
              '&:hover': {
                bgcolor: '#27ae60', // 深綠色懸停效果
              },
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem'
            }}
          >
            驗證
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

module.exports = AuthGateway;
