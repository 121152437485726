// src/index.js
const React = require('react');
const { createRoot } = require('react-dom/client');
const { ThemeProvider, createTheme, CssBaseline } = require('@mui/material');
const App = require('./App');

// Create dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

// Render app
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  React.createElement(
    React.StrictMode,
    null,
    React.createElement(
      ThemeProvider,
      { theme: darkTheme },
      [
        React.createElement(CssBaseline, null),
        React.createElement(App, null)
      ]
    )
  )
);
