const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Select,
  MenuItem,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Chip,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  CircularProgress
} = require('@mui/material');
const Web3 = require('web3');

// Network configurations
const NETWORKS = {
  56: {
    name: "BNB Smart Chain",
    blockTime: 3,
    rpc: [
      "https://bsc-dataseed.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc.publicnode.com"
    ]
  },
  1: {
    name: "Ethereum",
    blockTime: 12,
    rpc: [
      "https://eth.llamarpc.com",
      "https://rpc.ankr.com/eth",
      "https://eth.public-rpc.com"
    ]
  },
  8453: {
    name: "Base",
    blockTime: 2,
    rpc: [
      "https://mainnet.base.org",
      "https://base.llamarpc.com",
      "https://base.publicnode.com"
    ]
  },
  42161: {
    name: "Arbitrum One",
    blockTime: 0.25,
    rpc: [
      "https://arb1.arbitrum.io/rpc",
      "https://arbitrum.llamarpc.com",
      "https://arbitrum-one.publicnode.com"
    ]
  },
  20201022: {
    name: "PEGO Network",
    blockTime: 3,
    rpc: [
      "https://node1.pegorpc.com",
      "https://node2.pegorpc.com"
    ]
  },
  1116: {
    name: "Core DAO",
    blockTime: 3,
    rpc: [
      "https://rpc.coredao.org",
      "https://core.public.infstones.com"
    ]
  },
  146: {
    name: "Sonic Mainnet",
    blockTime: 0.4,
    rpc: [
      "https://rpc.soniclabs.com"
    ]
  },
  43114: {
    name: "Avalanche C-Chain",
    blockTime: 2,
    rpc: [
      "https://api.avax.network/ext/bc/C/rpc",
      "https://avalanche-c-chain.publicnode.com",
      "https://rpc.ankr.com/avalanche"
    ]
  }
};

// WebSocket状態常量
const WS_STATUS = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
};

// WebSocket連接相關常量
const WS_PING_INTERVAL = 15000; // 15秒發送一次ping
const WS_PONG_TIMEOUT = 5000;   // 5秒內沒收到pong則視為超時
const WS_RECONNECT_DELAY = 5000; // 重連間隔5秒
const WS_CONNECTION_TIMEOUT = 10000; // 連接超時10秒
const WS_MAX_RECONNECT_ATTEMPTS = 5; // 最大重連次數

const RPCPanel = () => {
  const [selectedChain, setSelectedChain] = React.useState('56');
  const [customRpc, setCustomRpc] = React.useState('');
  const [testResults, setTestResults] = React.useState({});
  const [loading, setLoading] = React.useState({});
  const [error, setError] = React.useState('');
  const [blockHeights, setBlockHeights] = React.useState({});
  const [selectedRpc, setSelectedRpc] = React.useState('');
  const [wsConnectionStatus, setWsConnectionStatus] = React.useState('未連接');

  // 引用变量
  const wsRef = React.useRef(null);
  const wsHeartbeatRef = React.useRef(null);
  const wsPongTimeoutRef = React.useRef(null);
  const wsReconnectAttemptsRef = React.useRef(0);
  const intervals = React.useRef({});
  const mountedRef = React.useRef(true);
  const wsConnectionStatusRef = React.useRef({});

  // 檢查URL類型
  const isWebSocketUrl = (url) => {
    return url && (url.startsWith('ws://') || url.startsWith('wss://'));
  };

  // 恢復已保存的錢包到Web3實例
  const restoreWallets = (web3) => {
    try {
      const savedWallets = localStorage.getItem('wallets');
      if (savedWallets) {
        const wallets = JSON.parse(savedWallets);
        wallets.forEach(wallet => {
          const account = web3.eth.accounts.privateKeyToAccount(wallet.privateKey);
          web3.eth.accounts.wallet.add(account);
        });
        window._userAddresses = wallets.map(w => w.address);
        window._userAddress = wallets[0]?.address;
      }
    } catch (err) {
      console.error('Failed to restore wallets:', err);
    }
  };

  // 創建增強版WebSocket Provider
  const createEnhancedWebSocketProvider = (url) => {
    if (!isWebSocketUrl(url)) {
      console.error('Not a WebSocket URL:', url);
      return null;
    }

    console.log('Creating enhanced WebSocket provider for:', url);

    // 創建WebSocket提供者
    const wsProvider = new Web3.providers.WebsocketProvider(url, {
      timeout: WS_CONNECTION_TIMEOUT,
      clientConfig: {
        maxReceivedFrameSize: 100000000,
        maxReceivedMessageSize: 100000000,
        keepalive: true,
        keepaliveInterval: 60000
      },
      reconnect: {
        auto: true,
        delay: WS_RECONNECT_DELAY,
        maxAttempts: WS_MAX_RECONNECT_ATTEMPTS,
        onTimeout: true
      }
    });

    // 保存連接狀態
    wsConnectionStatusRef.current[url] = 'connecting';
    setWsConnectionStatus('正在連接...');

    // 連接事件處理
    wsProvider.on('connect', () => {
      console.log('Enhanced WebSocket Connected');
      wsConnectionStatusRef.current[url] = 'connected';
      setWsConnectionStatus('已連接');
      wsReconnectAttemptsRef.current = 0;

      // 清理之前的心跳
      clearInterval(wsHeartbeatRef.current);
      clearTimeout(wsPongTimeoutRef.current);

      // 開始心跳檢測
      let waitingForPong = false;
      wsHeartbeatRef.current = setInterval(() => {
        if (!wsProvider.connection || wsProvider.connection.readyState !== WS_STATUS.OPEN) {
          console.warn('WebSocket connection not open, skipping ping');
          return;
        }

        if (waitingForPong) {
          console.warn('Still waiting for previous pong, skipping this ping');
          return;
        }

        try {
          // 移除了 console.log('Sending ping to keep connection alive');
          waitingForPong = true;
          
          // 發送心跳查詢 (使用eth_blockNumber作為ping)
          const pingMsg = JSON.stringify({
            jsonrpc: '2.0',
            id: Date.now(),
            method: 'eth_blockNumber',
            params: []
          });

          wsProvider.connection.send(pingMsg);
          
          // 設置pong超時
          wsPongTimeoutRef.current = setTimeout(() => {
            console.error('Pong timeout, connection might be dead');
            waitingForPong = false;
            wsConnectionStatusRef.current[url] = 'reconnecting';
            setWsConnectionStatus('重新連接中...');
            
            // 嘗試重新連接
            try {
              if (wsProvider.reconnect) {
                wsProvider.reconnect();
              }
            } catch (e) {
              console.error('Error during reconnection after pong timeout:', e);
            }
          }, WS_PONG_TIMEOUT);

          // 設置回調來監聽響應
          const originalOnMessage = wsProvider.connection.onmessage;
          wsProvider.connection.onmessage = (event) => {
            try {
              const data = JSON.parse(event.data);
              // 如果接收到响应，重置超时
              if (data.jsonrpc === '2.0' && data.id) {
                clearTimeout(wsPongTimeoutRef.current);
                waitingForPong = false;
              }
            } catch (e) {
              // 忽略解析错误
            }
            
            // 仍然调用原始处理程序
            if (originalOnMessage) originalOnMessage(event);
          };
        } catch (error) {
          console.error('Error sending ping:', error);
          waitingForPong = false;
        }
      }, WS_PING_INTERVAL);
    });

    // 重連事件處理
    wsProvider.on('reconnect', (attempt) => {
      console.log(`WebSocket Reconnecting: Attempt ${attempt}`);
      wsConnectionStatusRef.current[url] = 'reconnecting';
      setWsConnectionStatus(`重新連接中 (${attempt}/${WS_MAX_RECONNECT_ATTEMPTS})`);
      wsReconnectAttemptsRef.current = attempt;
    });

    // 錯誤事件處理
    wsProvider.on('error', (error) => {
      console.error('WebSocket Error:', error);
      wsConnectionStatusRef.current[url] = 'error';
      setWsConnectionStatus('連接錯誤');
      setError(`WebSocket 連接錯誤: ${error.message || '未知錯誤'}`);
    });

    // 結束事件處理
    wsProvider.on('end', () => {
      console.log('WebSocket Connection Ended');
      wsConnectionStatusRef.current[url] = 'disconnected';
      setWsConnectionStatus('已斷開');

      // 清理心跳
      clearInterval(wsHeartbeatRef.current);
      clearTimeout(wsPongTimeoutRef.current);
    });

    // 设置连接超时
    const connectionTimeout = setTimeout(() => {
      if (wsConnectionStatusRef.current[url] !== 'connected') {
        console.error('WebSocket连接超时');
        wsConnectionStatusRef.current[url] = 'timeout';
        setWsConnectionStatus('連接超時');
        setError(`WebSocket連接超時，請嘗試其他RPC或稍後重試`);
        
        try {
          wsProvider.disconnect();
        } catch (e) {
          console.error('Error disconnecting timed out WebSocket:', e);
        }
      }
    }, WS_CONNECTION_TIMEOUT);

    // 當連接成功后清除超時計時器
    wsProvider.on('connect', () => {
      clearTimeout(connectionTimeout);
    });

    return wsProvider;
  };

  // 創建Web3實例
  const createWeb3Instance = (rpcUrl) => {
    try {
      // 斷開現有連接
      if (window._currentProvider) {
        if (typeof window._currentProvider.disconnect === 'function') {
          window._currentProvider.disconnect();
        }
        window._currentProvider = null;
        window._web3Instance = null;
      }

      // 創建新的 provider
      let provider;
      
      if (isWebSocketUrl(rpcUrl)) {
        provider = createEnhancedWebSocketProvider(rpcUrl);
      } else {
        provider = new Web3.providers.HttpProvider(rpcUrl, {
          timeout: 30000,
          headers: [
            {
              name: 'Content-Type',
              value: 'application/json'
            }
          ]
        });
      }

      if (!provider) {
        throw new Error('無法創建Provider');
      }

      const web3 = new Web3(provider);

      // 恢復已保存的錢包
      restoreWallets(web3);

      window._web3Instance = web3;
      window._currentProvider = provider;
      window._rpcUrl = rpcUrl;
      window._isWebSocket = isWebSocketUrl(rpcUrl);

      return web3;
    } catch (error) {
      console.error('Failed to create Web3 instance:', error);
      throw error;
    }
  };

  // WebSocket連接處理
  const setupWebSocketConnection = (wsUrl) => {
    try {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }

      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;
      
      setWsConnectionStatus('正在連接...');
      wsConnectionStatusRef.current[wsUrl] = 'connecting';
      
      ws.onopen = () => {
        console.log('Block monitoring WebSocket connected');
        setWsConnectionStatus('已連接');
        wsConnectionStatusRef.current[wsUrl] = 'connected';
        setError('');
        
        // 訂閱新區塊
        const subscription = {
          jsonrpc: '2.0',
          id: 1,
          method: 'eth_subscribe',
          params: ['newHeads']
        };
        ws.send(JSON.stringify(subscription));
        
        // 啟動心跳檢測
        startWebSocketHeartbeat(ws, wsUrl);
      };
      
      ws.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response.params?.result?.number) {
            const blockNumber = parseInt(response.params.result.number, 16);
            setBlockHeights(prev => ({
              ...prev,
              [wsUrl]: blockNumber
            }));
          }
          
          // 重置心跳超時計時器
          resetPongTimeout(wsUrl);
        } catch (err) {
          console.error('Failed to parse WebSocket message:', err);
        }
      };
      
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError(`WebSocket 連接錯誤`);
        wsConnectionStatusRef.current[wsUrl] = 'error';
        setWsConnectionStatus('連接錯誤');
      };
      
      ws.onclose = (event) => {
        console.log('WebSocket disconnected', event.code, event.reason);
        wsConnectionStatusRef.current[wsUrl] = 'disconnected';
        setWsConnectionStatus('已斷開');
        
        // 清理心跳
        clearHeartbeat(wsUrl);
        
        // 斷線後重連邏輯
        if (selectedRpc === wsUrl && mountedRef.current) {
          if (wsReconnectAttemptsRef.current < WS_MAX_RECONNECT_ATTEMPTS) {
            wsReconnectAttemptsRef.current++;
            setWsConnectionStatus(`重新連接中 (${wsReconnectAttemptsRef.current}/${WS_MAX_RECONNECT_ATTEMPTS})`);
            setTimeout(() => setupWebSocketConnection(wsUrl), WS_RECONNECT_DELAY);
          } else {
            setError(`WebSocket重連失敗，已達最大重試次數(${WS_MAX_RECONNECT_ATTEMPTS})`);
            setWsConnectionStatus('重連失敗');
          }
        }
      };

    } catch (error) {
      console.error('WebSocket setup failed:', error);
      setError(`WebSocket 設置失敗: ${error.message}`);
      wsConnectionStatusRef.current[wsUrl] = 'setup_failed';
      setWsConnectionStatus('設置失敗');
    }
  };

  // WebSocket心跳檢測
  const startWebSocketHeartbeat = (ws, wsUrl) => {
    // 清理舊的心跳
    clearHeartbeat(wsUrl);
    
    // 設置新的心跳
    intervals.current[`${wsUrl}_heartbeat`] = setInterval(() => {
      if (ws.readyState === WS_STATUS.OPEN) {
        try {
          // 移除了 console.log('Sending WebSocket heartbeat');
          ws.send(JSON.stringify({
            jsonrpc: '2.0',
            id: Date.now(),
            method: 'eth_blockNumber',
            params: []
          }));
          
          // 設置pong超時計時器
          if (intervals.current[`${wsUrl}_pong_timeout`]) {
            clearTimeout(intervals.current[`${wsUrl}_pong_timeout`]);
          }
          
          intervals.current[`${wsUrl}_pong_timeout`] = setTimeout(() => {
            console.error('WebSocket heartbeat timeout');
            if (wsConnectionStatusRef.current[wsUrl] === 'connected') {
              wsConnectionStatusRef.current[wsUrl] = 'timeout';
              setWsConnectionStatus('心跳超時');
              
              // 嘗試關閉並重新連接
              try {
                ws.close();
              } catch (e) {
                console.error('Error closing WebSocket after heartbeat timeout:', e);
              }
            }
          }, WS_PONG_TIMEOUT);
          
        } catch (error) {
          console.error('Error sending WebSocket heartbeat:', error);
        }
      } else {
        console.warn('WebSocket not open, skipping heartbeat');
      }
    }, WS_PING_INTERVAL);
  };

  // 重置pong超時
  const resetPongTimeout = (wsUrl) => {
    if (intervals.current[`${wsUrl}_pong_timeout`]) {
      clearTimeout(intervals.current[`${wsUrl}_pong_timeout`]);
      intervals.current[`${wsUrl}_pong_timeout`] = null;
    }
  };

  // 清理心跳相關計時器
  const clearHeartbeat = (wsUrl) => {
    if (intervals.current[`${wsUrl}_heartbeat`]) {
      clearInterval(intervals.current[`${wsUrl}_heartbeat`]);
      intervals.current[`${wsUrl}_heartbeat`] = null;
    }
    
    if (intervals.current[`${wsUrl}_pong_timeout`]) {
      clearTimeout(intervals.current[`${wsUrl}_pong_timeout`]);
      intervals.current[`${wsUrl}_pong_timeout`] = null;
    }
  };

  // HTTP更新區塊高度
  const updateBlockHeight = async (rpcUrl) => {
    if (!rpcUrl || isWebSocketUrl(rpcUrl)) return;
    try {
      const blockNumber = await window._web3Instance.eth.getBlockNumber();
      setBlockHeights(prev => ({
        ...prev,
        [rpcUrl]: blockNumber
      }));
    } catch (error) {
      console.error(`Block height update failed for ${rpcUrl}:`, error);
    }
  };

  // 測試RPC連接
  const testRPC = async (rpcUrl) => {
    if (!rpcUrl) return;
    setLoading(prev => ({ ...prev, [rpcUrl]: true }));
    setError('');

    try {
      const provider = isWebSocketUrl(rpcUrl) ?
        new Web3.providers.WebsocketProvider(rpcUrl, {
          timeout: 10000,
          clientConfig: {
            maxReceivedFrameSize: 100000000,
            maxReceivedMessageSize: 100000000
          }
        }) :
        new Web3.providers.HttpProvider(rpcUrl, {
          timeout: 10000
        });
      
      const web3 = new Web3(provider);
      
      const startTime = Date.now();
      await web3.eth.getBlockNumber();
      const latency = Date.now() - startTime;

      if (provider.disconnect && typeof provider.disconnect === 'function') {
        provider.disconnect();
      }

      setTestResults(prev => ({
        ...prev,
        [rpcUrl]: {
          success: true,
          latency
        }
      }));
    } catch (err) {
      setTestResults(prev => ({
        ...prev,
        [rpcUrl]: {
          success: false,
          error: err.message
        }
      }));
      setError(`測試失敗: ${err.message}`);
    }

    setLoading(prev => ({ ...prev, [rpcUrl]: false }));
  };

  // 選擇RPC
  const handleSelectRpc = async (rpcUrl) => {
    if (!rpcUrl) return;

    // 清除現有連接
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    
    // 清理所有間隔計時器
    Object.entries(intervals.current).forEach(([key, interval]) => {
      if (interval) {
        if (key.includes('_heartbeat') || key.includes('_pong_timeout')) {
          clearInterval(interval);
        } else {
          clearInterval(interval);
        }
      }
    });
    intervals.current = {};

    try {
      console.log('Creating Web3 instance for:', rpcUrl);
      const web3 = createWeb3Instance(rpcUrl);
      await web3.eth.getChainId();
      
      setSelectedRpc(rpcUrl);
      setError('');
      
      if (isWebSocketUrl(rpcUrl)) {
        setupWebSocketConnection(rpcUrl);
      } else {
        updateBlockHeight(rpcUrl);
        intervals.current[rpcUrl] = setInterval(() => {
          updateBlockHeight(rpcUrl);
        }, 1000);
      }
    } catch (error) {
      console.error('Failed to connect to RPC:', error);
      setError(`連接失敗: ${error.message}`);
      
      // 清理全局變量
      window._web3Instance = null;
      window._currentProvider = null;
      window._rpcUrl = null;
      window._isWebSocket = false;
    }
  };

  // 組件卸載時清理
  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
      
      // 清理 WebSocket 連接
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
      
      // 清理全局心跳
      clearInterval(wsHeartbeatRef.current);
      clearTimeout(wsPongTimeoutRef.current);
      
      // 清理所有間隔計時器
      Object.entries(intervals.current).forEach(([key, interval]) => {
        if (interval) {
          if (key.includes('_heartbeat') || key.includes('_pong_timeout')) {
            clearInterval(interval);
          } else {
            clearInterval(interval);
          }
        }
      });
    };
  }, []);

  // 切換網絡時重置
  React.useEffect(() => {
    setSelectedRpc('');
    setTestResults({});
    setBlockHeights({});
    window._rpcUrl = null;
    window._web3Instance = null;
    window._currentProvider = null;
    window._isWebSocket = false;

    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    
    // 清理所有間隔計時器
    Object.entries(intervals.current).forEach(([key, interval]) => {
      if (interval) {
        if (key.includes('_heartbeat') || key.includes('_pong_timeout')) {
          clearInterval(interval);
        } else {
          clearInterval(interval);
        }
      }
    });
    intervals.current = {};
  }, [selectedChain]);

  return (
    <Card>
      <CardContent>
        {/* 網絡選擇 */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            網絡選擇
          </Typography>
          <Select
            fullWidth
            value={selectedChain}
            onChange={(e) => setSelectedChain(e.target.value)}
          >
            {Object.entries(NETWORKS).map(([chainId, network]) => (
              <MenuItem key={chainId} value={chainId}>
                {network.name}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography>
              當前區塊: {blockHeights[selectedRpc] || '未選擇RPC'}
            </Typography>
            <Typography>
              區塊時間: {NETWORKS[selectedChain].blockTime} 秒
            </Typography>
          </Box>
          {isWebSocketUrl(selectedRpc) && (
            <Typography sx={{ mt: 1 }} color={
              wsConnectionStatus === '已連接' ? 'success.main' : 
              wsConnectionStatus.includes('重新連接') ? 'warning.main' : 
              'error.main'
            }>
              WebSocket狀態: {wsConnectionStatus}
            </Typography>
          )}
        </Box>

        {/* 預設RPC列表 */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            預設 RPC
          </Typography>
          <RadioGroup
            value={selectedRpc}
            onChange={(e) => handleSelectRpc(e.target.value)}
          >
            {NETWORKS[selectedChain].rpc.map((rpc, index) => (
              <Box key={index} sx={{ 
                mb: 2, 
                p: 2, 
                border: 1, 
                borderColor: 'divider', 
                borderRadius: 1,
                bgcolor: selectedRpc === rpc ? 'action.selected' : 'transparent'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <FormControlLabel
                    value={rpc}
                    control={<Radio />}
                    label={
                      <Box>
                        {rpc}
                        {isWebSocketUrl(rpc) && (
                          <Chip 
                            size="small" 
                            label="WebSocket" 
                            color="primary" 
                            sx={{ ml: 1 }}
                          />
                        )}
                      </Box>
                    }
                  />
                  <Button 
                    variant="contained" 
                    onClick={() => testRPC(rpc)}
                    disabled={loading[rpc]}
                  >
                    {loading[rpc] ? <CircularProgress size={24} /> : '測試'}
                  </Button>
                </Box>
                {testResults[rpc] && (
                  <Box sx={{ mt: 1, ml: 4 }}>
                    {testResults[rpc].success ? (
                      <Typography color="success.main">
                        延遲: {testResults[rpc].latency}ms
                      </Typography>
                    ) : (
                      <Typography color="error">
                        {testResults[rpc].error}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </RadioGroup>
        </Box>

        {/* 自定義RPC */}
        <Box>
          <Typography variant="h6" gutterBottom>
            自定義 RPC
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              value={customRpc}
              onChange={(e) => setCustomRpc(e.target.value)}
              placeholder="輸入 RPC URL (HTTP 或 WebSocket)"
            />
            <Button 
              variant="contained" 
              onClick={() => testRPC(customRpc)}
              disabled={loading[customRpc] || !customRpc}
            >
              {loading[customRpc] ? <CircularProgress size={24} /> : '測試'}
            </Button>
          </Box>
          {testResults[customRpc] && (
            <Box sx={{ mb: 2 }}>
              {testResults[customRpc].success ? (
                <Box>
                  <Typography color="success.main">連接成功！</Typography>
                  <Typography>延遲: {testResults[customRpc].latency}ms</Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleSelectRpc(customRpc)}
                    sx={{ mt: 1 }}
                  >
                    選用此RPC
                  </Button>
                </Box>
              ) : (
                <Typography color="error">
                  {testResults[customRpc].error}
                </Typography>
              )}
            </Box>
          )}
        </Box>

        {/* 錯誤提示 */}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

module.exports = RPCPanel;
