const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Alert,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Chip,
  Divider
} = require('@mui/material');
const Web3 = require('web3');
const {
  CONSTANTS,
  NETWORK_CONFIG,
  ABIS,
  utils
} = require('./evm');

// 支持同區塊搶跑的鏈 (支援內存池監控)
const SAME_BLOCK_FRONTRUN_CHAINS = {
  '1': 'Ethereum',    // ETH
  '56': 'BSC',        // BSC
  '146': 'SONIC',     // SONIC
  '1116': 'Core',     // Core
  '20201022': 'PEGO'  // PEGO
};

// 使用區塊訂閱的鏈 (不支持內存池訪問的鏈)
const BLOCK_SUBSCRIPTION_CHAINS = {
  '8453': 'Base',     // Base
  '42161': 'Arbitrum', // Arbitrum
  '43114': 'AVAX-C'   // Avalanche C-Chain
};

// 所有支持的鏈列表
const ALL_SUPPORTED_CHAINS = {
  ...SAME_BLOCK_FRONTRUN_CHAINS,
  ...BLOCK_SUBSCRIPTION_CHAINS
};

// DEX協議列表
const DEX_PROTOCOLS = {
  'UNISWAP_V2': 'UniswapV2',
  'UNISWAP_V3': 'UniswapV3'
};
// UniswapV2 ABI
const UNISWAP_V2_ROUTER_ABI = [
  {
    "inputs": [
      { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
      { "internalType": "address[]", "name": "path", "type": "address[]" },
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "deadline", "type": "uint256" }
    ],
    "name": "swapExactETHForTokens",
    "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
      { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
      { "internalType": "address[]", "name": "path", "type": "address[]" },
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "deadline", "type": "uint256" }
    ],
    "name": "swapExactTokensForETH",
    "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
      { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
      { "internalType": "address[]", "name": "path", "type": "address[]" },
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "deadline", "type": "uint256" }
    ],
    "name": "swapExactTokensForTokens",
    "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

// UniswapV3 ABI (簡化版)
const UNISWAP_V3_ROUTER_ABI = [
  {
    "inputs": [
      {
        "components": [
          { "internalType": "address", "name": "tokenIn", "type": "address" },
          { "internalType": "address", "name": "tokenOut", "type": "address" },
          { "internalType": "uint24", "name": "fee", "type": "uint24" },
          { "internalType": "address", "name": "recipient", "type": "address" },
          { "internalType": "uint256", "name": "deadline", "type": "uint256" },
          { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
          { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" },
          { "internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160" }
        ],
        "internalType": "struct ISwapRouter.ExactInputSingleParams",
        "name": "params",
        "type": "tuple"
      }
    ],
    "name": "exactInputSingle",
    "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "bytes", "name": "path", "type": "bytes" },
          { "internalType": "address", "name": "recipient", "type": "address" },
          { "internalType": "uint256", "name": "deadline", "type": "uint256" },
          { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
          { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" }
        ],
        "internalType": "struct ISwapRouter.ExactInputParams",
        "name": "params",
        "type": "tuple"
      }
    ],
    "name": "exactInput",
    "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
    "stateMutability": "payable",
    "type": "function"
  }
];

const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
const NATIVE_TOKEN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
const DEFAULT_SLIPPAGE = '0.5'; // 默認滑點0.5%
const WEBSOCKET_HEARTBEAT_INTERVAL = 30000; // 30秒心跳檢測

// DEX路由器地址映射 (用於檢測交易到哪個DEX)
const DEX_ROUTERS = {
  // Ethereum
  '1': {
    'UNISWAP_V2': '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // Uniswap V2
    'UNISWAP_V3': '0xE592427A0AEce92De3Edee1F18E0157C05861564', // Uniswap V3
    'SUSHISWAP': '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F', // SushiSwap
  },
  // BSC
  '56': {
    'UNISWAP_V2': '0x10ED43C718714eb63d5aA57B78B54704E256024E', // PancakeSwap
    'UNISWAP_V3': '0x13f4EA83D0bd40E75C8222255bc855a974568Dd4', // PancakeSwap V3
    'SUSHISWAP': '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506', // SushiSwap
  },
  // SONIC
  '146': {
    'UNISWAP_V2': '0xA9aBC1D464a2d96D850cF7864D72Ca97AF1F303A', // SonicSwap
  },
  // Core
  '1116': {
    'UNISWAP_V2': '0x1a1EC25DC08e98e5E93F1104B5e5cdD298707d31', // CoreDEX
  },
  // Base
  '8453': {
    'UNISWAP_V3': '0x2626664c2603336E57B271c5C0b26F421741e481', // BaseSwap
  },
  // Arbitrum
  '42161': {
    'UNISWAP_V2': '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506', // SushiSwap
    'UNISWAP_V3': '0xE592427A0AEce92De3Edee1F18E0157C05861564', // Uniswap V3
  },
  // AVAX-C
  '43114': {
    'UNISWAP_V2': '0x60aE616a2155Ee3d9A68541Ba4544862310933d4', // TraderJoe
  },
  // PEGO
  '20201022': {
    'UNISWAP_V2': '0x33810A426e45a61413A8f4D938475D98D9d376C3', // PegoSwap
  }
};

// 主鏈原生代幣映射
const NATIVE_TOKENS = {
  '1': {
    symbol: 'ETH',
    decimals: 18
  },
  '56': {
    symbol: 'BNB',
    decimals: 18
  },
  '146': {
    symbol: 'SONIC',
    decimals: 18
  },
  '1116': {
    symbol: 'CORE',
    decimals: 18
  },
  '8453': {
    symbol: 'ETH',
    decimals: 18
  },
  '42161': {
    symbol: 'ETH',
    decimals: 18
  },
  '43114': {
    symbol: 'AVAX',
    decimals: 18
  },
  '20201022': {
    symbol: 'PEGO',
    decimals: 18
  }
};

// 主鏈WRAPPED代幣映射
const WRAPPED_TOKENS = {
  '1': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',     // WETH
  '56': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',    // WBNB
  '146': '0x8C05F6931942E799636a93cfAB57D57B1281AAE0',   // WSONIC
  '1116': '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f',  // WCORE
  '8453': '0x4200000000000000000000000000000000000006',   // WETH (Base)
  '42161': '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',  // WETH (Arbitrum)
  '43114': '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',  // WAVAX
  '20201022': '0x836A18072CE2F6A1502d10F2A5210BA69c88B52d' // WPEGO
};
// 查找網絡配置
const findCurrentNetwork = async (web3) => {
  try {
    const chainId = await web3.eth.getChainId();
    const chainIdStr = chainId.toString();
    const network = Object.values(NETWORK_CONFIG).find(net => 
      net.chainId.toString() === chainIdStr
    );
    return {
      ...network,
      chainId: chainIdStr
    };
  } catch (error) {
    console.error('Failed to find network:', error);
    return null;
  }
};

// 檢查交易方法ID
const getTransactionMethod = (inputData) => {
  if (!inputData || inputData === '0x') return null;
  
  try {
    return inputData.substring(0, 10);
  } catch (error) {
    console.error('Error getting transaction method:', error);
    return null;
  }
};

// 通用交易解析函數
const parseTransaction = (tx) => {
  try {
    if (!tx || !tx.input || tx.input === '0x') return null;
    
    // 檢測交易是否發送到已知DEX路由器
    const chainId = tx.chainId?.toString() || '';
    if (!chainId) return null;
    
    const dexRouters = DEX_ROUTERS[chainId] || {};
    const dexInfo = Object.entries(dexRouters).find(
      ([_, routerAddress]) => tx.to?.toLowerCase() === routerAddress.toLowerCase()
    );
    
    if (!dexInfo) return null;
    
    const [dexType, routerAddress] = dexInfo;
    const methodId = getTransactionMethod(tx.input);
    
    // 檢測Uniswap V2方法
    if (dexType === 'UNISWAP_V2' || dexType === 'SUSHISWAP') {
      const uniswapV2Methods = {
        '0x7ff36ab5': { protocol: 'UNISWAP_V2', operation: 'buy', method: 'swapExactETHForTokens' },
        '0x38ed1739': { protocol: 'UNISWAP_V2', operation: 'tokens-to-tokens', method: 'swapExactTokensForTokens' },
        '0x18cbafe5': { protocol: 'UNISWAP_V2', operation: 'sell', method: 'swapExactTokensForETH' }
      };
      
      const methodInfo = uniswapV2Methods[methodId];
      if (methodInfo) {
        return {
          ...methodInfo,
          dexType,
          routerAddress,
          parsedData: parseUniswapV2Transaction(tx)
        };
      }
    }
    // 檢測Uniswap V3方法
    else if (dexType === 'UNISWAP_V3') {
      const uniswapV3Methods = {
        '0x414bf389': { protocol: 'UNISWAP_V3', operation: 'exactInputSingle', method: 'exactInputSingle' },
        '0xc04b8d59': { protocol: 'UNISWAP_V3', operation: 'exactInput', method: 'exactInput' }
      };
      
      const methodInfo = uniswapV3Methods[methodId];
      if (methodInfo) {
        return {
          ...methodInfo,
          dexType,
          routerAddress,
          parsedData: parseUniswapV3Transaction(tx, chainId)
        };
      }
    }
    
    return null;
  } catch (error) {
    console.error('Error parsing transaction:', error);
    return null;
  }
};

// 解析UniswapV2交易
const parseUniswapV2Transaction = (tx) => {
  try {
    const methodId = getTransactionMethod(tx.input);
    const web3 = new Web3();
    
    if (methodId === '0x7ff36ab5') { // swapExactETHForTokens
      const params = web3.eth.abi.decodeParameters(
        ['uint256', 'address[]', 'address', 'uint256'],
        '0x' + tx.input.substring(10)
      );
      
      return {
        amountOutMin: params[0],
        path: params[1],
        to: params[2],
        deadline: params[3],
        value: tx.value,
        operation: 'buy'
      };
    }
    else if (methodId === '0x38ed1739') { // swapExactTokensForTokens
      const params = web3.eth.abi.decodeParameters(
        ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
        '0x' + tx.input.substring(10)
      );
      
      return {
        amountIn: params[0],
        amountOutMin: params[1],
        path: params[2],
        to: params[3],
        deadline: params[4],
        operation: 'tokens-to-tokens'
      };
    }
    else if (methodId === '0x18cbafe5') { // swapExactTokensForETH
      const params = web3.eth.abi.decodeParameters(
        ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
        '0x' + tx.input.substring(10)
      );
      
      return {
        amountIn: params[0],
        amountOutMin: params[1],
        path: params[2],
        to: params[3],
        deadline: params[4],
        operation: 'sell'
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error parsing Uniswap V2 transaction:', error);
    return null;
  }
};

// 解析UniswapV3交易
const parseUniswapV3Transaction = (tx, chainId) => {
  try {
    const methodId = getTransactionMethod(tx.input);
    const web3 = new Web3();
    const wrappedToken = WRAPPED_TOKENS[chainId];
    
    if (methodId === '0x414bf389') { // exactInputSingle
      const params = web3.eth.abi.decodeParameters([{
        type: 'tuple',
        components: [
          { type: 'address', name: 'tokenIn' },
          { type: 'address', name: 'tokenOut' },
          { type: 'uint24', name: 'fee' },
          { type: 'address', name: 'recipient' },
          { type: 'uint256', name: 'deadline' },
          { type: 'uint256', name: 'amountIn' },
          { type: 'uint256', name: 'amountOutMinimum' },
          { type: 'uint160', name: 'sqrtPriceLimitX96' }
        ]
      }], '0x' + tx.input.substring(10));
      
      // 判斷操作類型
      let operation = 'tokens-to-tokens';
      if (wrappedToken && params[0].tokenIn.toLowerCase() === wrappedToken.toLowerCase()) {
        operation = 'buy';
      } else if (wrappedToken && params[0].tokenOut.toLowerCase() === wrappedToken.toLowerCase()) {
        operation = 'sell';
      }
      
      return {
        ...params[0],
        operation,
        value: tx.value
      };
    }
    else if (methodId === '0xc04b8d59') { // exactInput
      const params = web3.eth.abi.decodeParameters([{
        type: 'tuple',
        components: [
          { type: 'bytes', name: 'path' },
          { type: 'address', name: 'recipient' },
          { type: 'uint256', name: 'deadline' },
          { type: 'uint256', name: 'amountIn' },
          { type: 'uint256', name: 'amountOutMinimum' }
        ]
      }], '0x' + tx.input.substring(10));
      
      // UniswapV3的path編碼較複雜，這裡簡化處理
      return {
        ...params[0],
        operation: 'tokens-to-tokens',
        value: tx.value
      };
    }
    
    return null;
  } catch (error) {
    console.error('Error parsing Uniswap V3 transaction:', error);
    return null;
  }
};

// 創建WebSocket提供程序並設置心跳
const createWebSocketProvider = (url) => {
  if (!url.startsWith('ws')) {
    throw new Error('URL必須是WebSocket協議 (ws:// 或 wss://)');
  }
  
  const provider = new Web3.providers.WebsocketProvider(url, {
    reconnect: {
      auto: true,
      delay: 5000,
      maxAttempts: 5,
      onTimeout: false
    }
  });
  
  // 心跳檢測
  let heartbeatInterval;
  
  provider.on('connect', () => {
    console.log('WebSocket已連接');
    
    // 設置心跳
    heartbeatInterval = setInterval(() => {
      // 發送心跳請求
      if (provider.connection.readyState === provider.connection.OPEN) {
        provider.send({
          jsonrpc: '2.0',
          id: new Date().getTime(),
          method: 'net_version',
          params: []
        });
        // 移除了心跳日誌，減少噪音
      } else {
        console.warn('WebSocket未開啟，清除心跳');
        clearInterval(heartbeatInterval);
      }
    }, WEBSOCKET_HEARTBEAT_INTERVAL);
  });
  
  provider.on('error', (err) => {
    console.error('WebSocket錯誤:', err);
    clearInterval(heartbeatInterval);
  });
  
  provider.on('end', () => {
    console.log('WebSocket連接結束');
    clearInterval(heartbeatInterval);
  });
  
  return provider;
};
const SmartMoneyTracker = () => {
  // 基礎狀態
  const [isMonitoring, setIsMonitoring] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [monitoringStats, setMonitoringStats] = React.useState({
    totalScanned: 0,
    matchedTx: 0,
    successfulTx: 0,
    failedTx: 0,
    startTime: null
  });
  // 鏈支持狀態
  const [chainSupport, setChainSupport] = React.useState({
    chainId: '',
    chainName: '',
    isFrontRunnable: false,
    isSupported: false
  });
  // 全局設置
  const [gasMultiplier, setGasMultiplier] = React.useState('1.1');
  const [targetAddress, setTargetAddress] = React.useState('');
  const [approveGasPrice, setApproveGasPrice] = React.useState('');
  const [approveGasLimit, setApproveGasLimit] = React.useState('100000');
  const [currentGasPrice, setCurrentGasPrice] = React.useState(null);
  const [slippage, setSlippage] = React.useState(DEFAULT_SLIPPAGE);
  // 監控模式
  const [monitorMode, setMonitorMode] = React.useState('buy'); // buy, sell, both
  // 買入模式
  const [buyMode, setBuyMode] = React.useState('percentage'); // percentage, fixed
  const [buyPercentage, setBuyPercentage] = React.useState('');
  const [fixedAmount, setFixedAmount] = React.useState('');
  // 賣出模式
  const [sellMode, setSellMode] = React.useState('all'); // all, percentage
  const [sellPercentage, setSellPercentage] = React.useState('');
  // 持倉和授權相關
  const [holdingTokens, setHoldingTokens] = React.useState([]);
  const [hasCheckedAllowance, setHasCheckedAllowance] = React.useState(false);
  const [allowanceChecking, setAllowanceChecking] = React.useState(false);
  // Web3 相關
  const web3Ref = React.useRef(null);
  const subscriptionRef = React.useRef(null);
  const blockSubscriptionRef = React.useRef(null);
  const pendingTxRef = React.useRef(new Set());
  const processingTxRef = React.useRef(new Set());
  const wrappedNativeTokenRef = React.useRef('');
  const nativeTokenRef = React.useRef(null);
  const websocketHeartbeatRef = React.useRef(null);
  // 交易記錄
  const [transactions, setTransactions] = React.useState([]);
  // 檢測到的DEX路由器
  const [detectedDexes, setDetectedDexes] = React.useState([]);
  
  // 初始化Web3
  const initWeb3 = async () => {
    try {
      console.log('初始化Web3開始...');
      if (!window._rpcUrl) {
        throw new Error('請先設置RPC URL');
      }
      // 根據URL判斷是否為WebSocket
      const isWebSocket = window._rpcUrl.startsWith('ws');
      console.log(`使用${isWebSocket ? 'WebSocket' : 'HTTP'} RPC: ${window._rpcUrl}`);
      
      let provider;
      if (isWebSocket) {
        // 使用帶心跳的WebSocket提供程序
        provider = createWebSocketProvider(window._rpcUrl);
      } else {
        provider = new Web3.providers.HttpProvider(window._rpcUrl);
      }
      web3Ref.current = new Web3(provider);
      // 獲取當前鏈ID
      const chainId = await web3Ref.current.eth.getChainId();
      const chainIdStr = chainId.toString();
      console.log(`連接到鏈ID: ${chainIdStr}`);
      
      // 設置Wrapped代幣地址
      wrappedNativeTokenRef.current = WRAPPED_TOKENS[chainIdStr] || '';
      nativeTokenRef.current = NATIVE_TOKENS[chainIdStr] || { symbol: 'ETH', decimals: 18 };
      console.log(`原生代幣: ${nativeTokenRef.current.symbol}, Wrapped地址: ${wrappedNativeTokenRef.current}`);
      
      console.log('Web3初始化成功!');
      return chainIdStr;
    } catch (error) {
      console.error('Initialize Web3 error:', error);
      setError('初始化Web3失敗: ' + error.message);
      return null;
    }
  };
  
  // 驗證輸入
  const validateInputs = () => {
    console.log('驗證輸入開始...');
    
    // 驗證目標地址
    if (!targetAddress || !Web3.utils.isAddress(targetAddress)) {
      setError('請輸入有效的目標錢包地址');
      console.log('目標錢包地址無效');
      return false;
    }
    
    // 驗證Gas倍數
    const gasMultiplierNum = parseFloat(gasMultiplier);
    if (gasMultiplierNum < 1.1 || gasMultiplierNum > 10) {
      setError('Gas倍數必須在1.1~10之間');
      console.log('Gas倍數無效');
      return false;
    }
    
    // 驗證滑點
    const slippageNum = parseFloat(slippage);
    if (isNaN(slippageNum) || slippageNum < 0.1 || slippageNum > 100) {
      setError('滑點必須在0.1~100%之間');
      console.log('滑點無效');
      return false;
    }
    
    // 根據模式驗證參數
    if (monitorMode === 'buy' || monitorMode === 'both') {
      if (buyMode === 'percentage') {
        if (!buyPercentage || parseFloat(buyPercentage) <= 0 || parseFloat(buyPercentage) > 1000) {
          setError('買入比例必須在0-1000%之間');
          console.log('買入比例無效');
          return false;
        }
      } else {
        if (!fixedAmount || parseFloat(fixedAmount) <= 0) {
          setError('請輸入有效的固定買入金額');
          console.log('固定買入金額無效');
          return false;
        }
      }
    }
    if (monitorMode === 'sell' || monitorMode === 'both') {
      if (sellMode === 'percentage' && (!sellPercentage || parseFloat(sellPercentage) <= 0 || parseFloat(sellPercentage) > 100)) {
        setError('賣出比例必須在0-100%之間');
        console.log('賣出比例無效');
        return false;
      }
    }
    
    console.log('輸入驗證通過');
    return true;
  };
  
  // 檢查錢包
  const checkWallets = () => {
    console.log('檢查錢包開始...');
    if (!window._userAddresses || window._userAddresses.length === 0) {
      setError('請先選擇要操作的錢包');
      console.log('未選擇操作錢包');
      return false;
    }
    console.log(`檢測到操作錢包: ${window._userAddresses[0]}`);
    return true;
  };

// 開始監控
  const startMonitoring = async () => {
    if (!validateInputs() || !checkWallets()) return;
    try {
      setLoading(true);
      console.log('開始監控流程...');
      
      // 初始化Web3
      const chainId = await initWeb3();
      if (!chainId) return;
      // 重置監控狀態
      setMonitoringStats({
        totalScanned: 0,
        matchedTx: 0,
        successfulTx: 0,
        failedTx: 0,
        startTime: Date.now()
      });
      
      // 重置交易記錄
      setTransactions([]);
      setDetectedDexes([]);
      pendingTxRef.current.clear();
      processingTxRef.current.clear();
      // 確定監控方法
      if (SAME_BLOCK_FRONTRUN_CHAINS[chainId]) {
        // 使用內存池監控
        console.log(`使用內存池訂閱模式監控 ${SAME_BLOCK_FRONTRUN_CHAINS[chainId]}...`);
        subscriptionRef.current = web3Ref.current.eth.subscribe('pendingTransactions')
          .on('data', handlePendingTransaction)
          .on('error', handleSubscriptionError);
          
        console.log(`已成功訂閱 ${SAME_BLOCK_FRONTRUN_CHAINS[chainId]} 內存池`);
      } else if (BLOCK_SUBSCRIPTION_CHAINS[chainId]) {
        // 使用區塊頭監控
        console.log(`使用區塊頭訂閱模式監控 ${BLOCK_SUBSCRIPTION_CHAINS[chainId]}...`);
        blockSubscriptionRef.current = web3Ref.current.eth.subscribe('newBlockHeaders')
          .on('data', handleNewBlock)
          .on('error', handleSubscriptionError);
          
        console.log(`已成功訂閱 ${BLOCK_SUBSCRIPTION_CHAINS[chainId]} 區塊頭`);
      } else {
        throw new Error('當前鏈不支持監控');
      }
      setIsMonitoring(true);
      setError('');
      console.log('監控成功啟動');
    } catch (err) {
      console.error('Start monitoring error:', err);
      setError('啟動監控失敗: ' + err.message);
      setIsMonitoring(false);
    } finally {
      setLoading(false);
    }
  };
  
  // 停止監控
  const stopMonitoring = () => {
    console.log('停止監控...');
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
      console.log('已取消內存池訂閱');
    }
    if (blockSubscriptionRef.current) {
      blockSubscriptionRef.current.unsubscribe();
      blockSubscriptionRef.current = null;
      console.log('已取消區塊頭訂閱');
    }
    // 清除WebSocket心跳
    if (websocketHeartbeatRef.current) {
      clearInterval(websocketHeartbeatRef.current);
      websocketHeartbeatRef.current = null;
      console.log('已清除WebSocket心跳');
    }
    setIsMonitoring(false);
    console.log('監控已完全停止');
  };
  
  // 處理新區塊 (用於不支持內存池的鏈)
  const handleNewBlock = async (blockHeader) => {
    try {
      console.log(`檢測到新區塊: ${blockHeader.number}`);
      const block = await web3Ref.current.eth.getBlock(blockHeader.number, true);
      
      if (block && block.transactions) {
        console.log(`區塊 ${blockHeader.number} 包含 ${block.transactions.length} 筆交易`);
        for (const tx of block.transactions) {
          // 處理區塊中的交易
          await processTx(tx, false);
        }
      }
    } catch (error) {
      console.error('Handle new block error:', error);
    }
  };
  
  // 處理pending交易
  const handlePendingTransaction = async (txHash) => {
    try {
      if (pendingTxRef.current.has(txHash)) return;
      pendingTxRef.current.add(txHash);
      setMonitoringStats(prev => ({
        ...prev,
        totalScanned: prev.totalScanned + 1
      }));
      
      console.log(`檢測到新交易: ${txHash}`);
      const tx = await web3Ref.current.eth.getTransaction(txHash);
      if (!tx) {
        console.log(`交易 ${txHash} 無法獲取詳情`);
        return;
      }
      
      // 處理交易
      await processTx(tx, true);
    } catch (err) {
      console.error('Handle pending transaction error:', err);
    }
  };
  
  // 處理交易
  const processTx = async (tx, isPending) => {
    try {
      // 檢查是否是目標地址的交易
      const isTargetAddress = tx.from?.toLowerCase() === targetAddress.toLowerCase();
      if (!isTargetAddress) {
        return;
      }
      
      console.log(`找到目標地址 ${targetAddress} 的交易: ${tx.hash}`);
      
      // 如果交易已經在處理中，跳過
      if (processingTxRef.current.has(tx.hash)) {
        console.log(`交易 ${tx.hash} 已在處理中，跳過`);
        return;
      }
      
      processingTxRef.current.add(tx.hash);
      setMonitoringStats(prev => ({
        ...prev,
        matchedTx: prev.matchedTx + 1
      }));
      
      // 獲取當前鏈ID
      const chainId = await web3Ref.current.eth.getChainId();
      const chainIdString = chainId.toString();
      
      // 解析交易
      const txInfo = parseTransaction(tx);
      
      if (!txInfo) {
        console.log(`未能識別的交易: ${tx.hash}`);
        processingTxRef.current.delete(tx.hash);
        return;
      }
      
      console.log(`成功解析交易 ${tx.hash}:`, JSON.stringify(txInfo));
      
      // 保存檢測到的DEX
      if (!detectedDexes.some(dex => dex.routerAddress === txInfo.routerAddress)) {
        console.log(`檢測到新的DEX: ${txInfo.dexType} (${txInfo.routerAddress})`);
        setDetectedDexes(prev => [...prev, {
          type: txInfo.dexType,
          protocol: txInfo.protocol,
          routerAddress: txInfo.routerAddress
        }]);
      }
      
      // 檢查是否符合監控模式
      let shouldCopy = false;
      
      if (monitorMode === 'both') {
        shouldCopy = true;
        console.log(`監控模式: both, 將處理所有交易`);
      } else if (monitorMode === 'buy' && 
                (txInfo.operation === 'buy' || txInfo.parsedData?.operation === 'buy')) {
        shouldCopy = true;
        console.log(`監控模式: buy, 檢測到買入交易`);
      } else if (monitorMode === 'sell' && 
                (txInfo.operation === 'sell' || txInfo.parsedData?.operation === 'sell')) {
        shouldCopy = true;
        console.log(`監控模式: sell, 檢測到賣出交易`);
      }
      
      if (!shouldCopy) {
        console.log(`交易 ${tx.hash} 不符合當前監控模式 ${monitorMode}，跳過`);
        processingTxRef.current.delete(tx.hash);
        return;
      }
      
      // 記錄交易
      addTransaction({
        hash: tx.hash,
        blockNumber: tx.blockNumber || 'pending',
        type: txInfo.parsedData?.operation || txInfo.operation,
        protocol: txInfo.protocol,
        dexType: txInfo.dexType,
        method: txInfo.method || '',
        status: 'detected',
        timestamp: Date.now(),
        routerAddress: txInfo.routerAddress
      });
      
      console.log(`交易 ${tx.hash} 已添加到記錄，準備跟單`);
      
      // 執行跟隨交易
      await followTransaction(tx, txInfo, chainIdString);
      
    } catch (err) {
      console.error('Process transaction error:', err);
    } finally {
      // 從處理中移除
      processingTxRef.current.delete(tx.hash);
    }
  };
  
  // 添加交易記錄
  const addTransaction = (tx) => {
    setTransactions(prev => [tx, ...prev].slice(0, 20)); // 只保留最近20條記錄
  };
  
  // 更新交易記錄
  const updateTransaction = (hash, updates) => {
    setTransactions(prev => prev.map(tx => 
      tx.hash === hash ? { ...tx, ...updates } : tx
    ));
  };

  // 處理訂閱錯誤
  const handleSubscriptionError = (error) => {
    console.error('Subscription error:', error);
    setError('監控訂閱錯誤: ' + error.message);
    stopMonitoring();
  };// 跟隨交易
  const followTransaction = async (tx, txInfo, chainId) => {
    try {
      console.log(`開始跟隨交易: ${tx.hash}`);
      
      if (!window._userAddresses || window._userAddresses.length === 0) {
        throw new Error('無可用錢包');
      }
      const fromAddress = window._userAddresses[0];
      const web3 = web3Ref.current;
      const routerAddress = txInfo.routerAddress;
      
      if (!routerAddress) {
        throw new Error('未找到DEX路由器地址');
      }
      
      // 更新交易狀態
      updateTransaction(tx.hash, { status: 'processing' });
      console.log(`將交易 ${tx.hash} 狀態更新為處理中`);
      
      // 獲取當前gas價格
      const gasPrice = await web3.eth.getGasPrice();
      const multipliedGasPrice = Math.floor(
        parseFloat(gasPrice) * parseFloat(gasMultiplier)
      ).toString();
      
      console.log(`當前Gas價格: ${web3.utils.fromWei(gasPrice, 'gwei')} Gwei, 倍數後: ${web3.utils.fromWei(multipliedGasPrice, 'gwei')} Gwei`);
      
      // 準備交易數據
      let txData;
      
      if (txInfo.protocol === 'UNISWAP_V2') {
        // 準備UniswapV2交易
        console.log(`準備UniswapV2交易, 操作: ${txInfo.parsedData?.operation || txInfo.operation}`);
        txData = await prepareUniswapV2Transaction(
          txInfo.parsedData, 
          txInfo, 
          routerAddress, 
          fromAddress, 
          chainId
        );
      } else if (txInfo.protocol === 'UNISWAP_V3') {
        // 準備UniswapV3交易
        console.log(`準備UniswapV3交易, 操作: ${txInfo.parsedData?.operation || txInfo.operation}`);
        txData = await prepareUniswapV3Transaction(
          txInfo.parsedData, 
          txInfo, 
          routerAddress, 
          fromAddress, 
          chainId
        );
      } else {
        throw new Error('不支持的協議');
      }
      
      // 如果沒有生成交易數據，退出
      if (!txData) {
        throw new Error('生成交易數據失敗');
      }
      
      console.log(`已生成交易數據:`, txData);
      
      // 發送交易
      console.log(`準備發送交易, 從: ${fromAddress}, 到: ${txData.to}, 值: ${txData.value}`);
      
      const result = await web3.eth.sendTransaction({
        ...txData,
        from: fromAddress,
        gasPrice: multipliedGasPrice
      });
      
      console.log(`交易發送成功! 交易哈希: ${result.transactionHash}`);
      
      // 更新交易狀態
      updateTransaction(tx.hash, { 
        status: 'success',
        followTxHash: result.transactionHash
      });
      
      setMonitoringStats(prev => ({
        ...prev,
        successfulTx: prev.successfulTx + 1
      }));
      
      return result;
    } catch (error) {
      console.error('Follow transaction error:', error);
      console.log(`跟隨交易 ${tx.hash} 失敗: ${error.message}`);
      
      // 更新交易狀態
      updateTransaction(tx.hash, { 
        status: 'failed',
        error: error.message
      });
      
      setMonitoringStats(prev => ({
        ...prev,
        failedTx: prev.failedTx + 1
      }));
      
      return null;
    }
  };
  
  // 準備UniswapV2交易
  const prepareUniswapV2Transaction = async (parsedTx, txInfo, routerAddress, fromAddress, chainId) => {
    const web3 = web3Ref.current;
    const router = new web3.eth.Contract(UNISWAP_V2_ROUTER_ABI, routerAddress);
    const slippageMultiplier = (100 - parseFloat(slippage)) / 100;
    const deadline = Math.floor(Date.now() / 1000) + 300; // 5分鐘超時
    
    try {
      console.log(`準備UniswapV2交易, 操作類型: ${parsedTx.operation}`);
      
      // 根據交易類型準備數據
      if (parsedTx.operation === 'buy') {
        // 買入交易 (swapExactETHForTokens)
        let amountToSend;
        
        if (buyMode === 'percentage') {
          // 按比例跟單
          const percentageMultiplier = parseFloat(buyPercentage) / 100;
          amountToSend = BigInt(Math.floor(
            parseInt(parsedTx.value) * percentageMultiplier
          )).toString();
          console.log(`按比例買入: ${buyPercentage}%, 原值: ${parsedTx.value}, 計算後: ${amountToSend}`);
        } else {
          // 固定金額
          amountToSend = web3.utils.toWei(fixedAmount, 'ether');
          console.log(`固定金額買入: ${fixedAmount} ${nativeTokenRef.current.symbol}, Wei: ${amountToSend}`);
        }
        
        // 計算最小輸出金額
        const amountOutMin = BigInt(Math.floor(
          parseInt(parsedTx.amountOutMin) * slippageMultiplier
        )).toString();
        
        console.log(`最小輸出: ${parsedTx.amountOutMin} * ${slippageMultiplier} = ${amountOutMin}`);
        console.log(`交易路徑: ${parsedTx.path.join(' -> ')}`);
        
        return {
          to: routerAddress,
          value: amountToSend,
          data: router.methods.swapExactETHForTokens(
            amountOutMin,
            parsedTx.path,
            fromAddress,
            deadline
          ).encodeABI(),
          gas: 400000 // 預設gas限制
        };
      } 
      else if (parsedTx.operation === 'sell') {
        // 賣出交易 (swapExactTokensForETH)
        const tokenAddress = parsedTx.path[0];
        console.log(`準備賣出代幣: ${tokenAddress}`);
        
        const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
        
        // 檢查授權
        console.log(`檢查授權狀態: ${fromAddress} -> ${routerAddress}`);
        const allowance = await tokenContract.methods.allowance(fromAddress, routerAddress).call();
        console.log(`當前授權額度: ${allowance}`);
        
        if (BigInt(allowance) < BigInt(parsedTx.amountIn)) {
          // 需要授權
          console.log(`授權額度不足，執行approve...`);
          await tokenContract.methods.approve(routerAddress, MAX_UINT256).send({
            from: fromAddress,
            gasPrice: web3.utils.toWei(approveGasPrice || '5', 'gwei'),
            gas: parseInt(approveGasLimit)
          });
          console.log(`授權成功!`);
        }
        
        let amountIn;
        
        if (sellMode === 'all') {
          // 全部賣出
          const balance = await tokenContract.methods.balanceOf(fromAddress).call();
          amountIn = balance;
          console.log(`全部賣出模式，當前餘額: ${balance}`);
        } else {
          // 按比例賣出
          const balance = await tokenContract.methods.balanceOf(fromAddress).call();
          const percentage = parseFloat(sellPercentage) / 100;
          amountIn = BigInt(Math.floor(parseInt(balance) * percentage)).toString();
          console.log(`按比例賣出: ${sellPercentage}%, 當前餘額: ${balance}, 賣出數量: ${amountIn}`);
        }
        
        // 如果餘額為0，退出
        if (BigInt(amountIn) <= 0n) {
          throw new Error('代幣餘額為0');
        }
        
        // 計算最小輸出金額
        const amountOutMin = BigInt(Math.floor(
          parseInt(parsedTx.amountOutMin) * slippageMultiplier
        )).toString();
        
        console.log(`最小輸出: ${parsedTx.amountOutMin} * ${slippageMultiplier} = ${amountOutMin}`);
        console.log(`交易路徑: ${parsedTx.path.join(' -> ')}`);
        
        return {
          to: routerAddress,
          data: router.methods.swapExactTokensForETH(
            amountIn,
            amountOutMin,
            parsedTx.path,
            fromAddress,
            deadline
          ).encodeABI(),
          gas: 400000 // 預設gas限制
        };
      }
      else if (parsedTx.operation === 'tokens-to-tokens') {
        // 代幣對代幣交易 (swapExactTokensForTokens)
        const tokenAddress = parsedTx.path[0];
        console.log(`準備代幣對代幣交換: ${tokenAddress}`);
        
        const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
        
        // 檢查授權
        console.log(`檢查授權狀態: ${fromAddress} -> ${routerAddress}`);
        const allowance = await tokenContract.methods.allowance(fromAddress, routerAddress).call();
        console.log(`當前授權額度: ${allowance}`);
        
        if (BigInt(allowance) < BigInt(parsedTx.amountIn)) {
          // 需要授權
          console.log(`授權額度不足，執行approve...`);
          await tokenContract.methods.approve(routerAddress, MAX_UINT256).send({
            from: fromAddress,
            gasPrice: web3.utils.toWei(approveGasPrice || '5', 'gwei'),
            gas: parseInt(approveGasLimit)
          });
          console.log(`授權成功!`);
        }
        
        let amountIn;
        
        if (monitorMode === 'buy' || (monitorMode === 'both' && buyMode === 'percentage')) {
          // 按比例跟單
          const percentageMultiplier = parseFloat(buyPercentage) / 100;
          amountIn = BigInt(Math.floor(
            parseInt(parsedTx.amountIn) * percentageMultiplier
          )).toString();
          console.log(`按比例買入: ${buyPercentage}%, 原值: ${parsedTx.amountIn}, 計算後: ${amountIn}`);
        } else if (monitorMode === 'both' && buyMode === 'fixed') {
          // 固定金額買入 (需要先計算等值金額)
          const balance = await tokenContract.methods.balanceOf(fromAddress).call();
          amountIn = balance; // 簡化處理，實際應轉換固定金額
          console.log(`固定金額買入模式，使用餘額: ${balance}`);
        } else if (monitorMode === 'sell') {
          if (sellMode === 'all') {
            // 全部賣出
            const balance = await tokenContract.methods.balanceOf(fromAddress).call();
            amountIn = balance;
            console.log(`全部賣出模式，當前餘額: ${balance}`);
          } else {
            // 按比例賣出
            const balance = await tokenContract.methods.balanceOf(fromAddress).call();
            const percentage = parseFloat(sellPercentage) / 100;
            amountIn = BigInt(Math.floor(parseInt(balance) * percentage)).toString();
            console.log(`按比例賣出: ${sellPercentage}%, 當前餘額: ${balance}, 賣出數量: ${amountIn}`);
          }
        }
        
        // 如果餘額為0，退出
        if (BigInt(amountIn) <= 0n) {
          throw new Error('代幣餘額為0');
        }
        
        // 計算最小輸出金額
        const amountOutMin = BigInt(Math.floor(
          parseInt(parsedTx.amountOutMin) * slippageMultiplier
        )).toString();
        
        console.log(`最小輸出: ${parsedTx.amountOutMin} * ${slippageMultiplier} = ${amountOutMin}`);
        console.log(`交易路徑: ${parsedTx.path.join(' -> ')}`);
        
        return {
          to: routerAddress,
          data: router.methods.swapExactTokensForTokens(
            amountIn,
            amountOutMin,
            parsedTx.path,
            fromAddress,
            deadline
          ).encodeABI(),
          gas: 400000 // 預設gas限制
        };
      }
      
      return null;
    } catch (error) {
      console.error('Prepare UniswapV2 transaction error:', error);
      throw error;
    }
  };
// 準備UniswapV3交易
  const prepareUniswapV3Transaction = async (parsedTx, txInfo, routerAddress, fromAddress, chainId) => {
    const web3 = web3Ref.current;
    const router = new web3.eth.Contract(UNISWAP_V3_ROUTER_ABI, routerAddress);
    const slippageMultiplier = (100 - parseFloat(slippage)) / 100;
    const deadline = Math.floor(Date.now() / 1000) + 300; // 5分鐘超時
    
    try {
      console.log(`準備UniswapV3交易, 方法: ${txInfo.method}`);
      
      // exactInputSingle 交易
      if (txInfo.method === 'exactInputSingle') {
        // 買入類型交易
        if (parsedTx.operation === 'buy') {
          let amountIn;
          
          if (buyMode === 'percentage') {
            // 按比例跟單
            const percentageMultiplier = parseFloat(buyPercentage) / 100;
            amountIn = BigInt(Math.floor(
              parseInt(parsedTx.amountIn) * percentageMultiplier
            )).toString();
            console.log(`按比例買入: ${buyPercentage}%, 原值: ${parsedTx.amountIn}, 計算後: ${amountIn}`);
          } else {
            // 固定金額
            amountIn = web3.utils.toWei(fixedAmount, 'ether');
            console.log(`固定金額買入: ${fixedAmount} ${nativeTokenRef.current.symbol}, Wei: ${amountIn}`);
          }
          
          // 計算最小輸出金額
          const amountOutMinimum = BigInt(Math.floor(
            parseInt(parsedTx.amountOutMinimum) * slippageMultiplier
          )).toString();
          
          console.log(`最小輸出: ${parsedTx.amountOutMinimum} * ${slippageMultiplier} = ${amountOutMinimum}`);
          
          // 是否需要value (ETH交易)
          const value = parsedTx.tokenIn.toLowerCase() === wrappedNativeTokenRef.current.toLowerCase() ? 
            amountIn : '0';
          
          console.log(`交易參數: tokenIn=${parsedTx.tokenIn}, tokenOut=${parsedTx.tokenOut}, 是否ETH交易=${Boolean(value)}`);
          
          // 如果是WETH交易，需要先批准
          if (parsedTx.tokenIn.toLowerCase() !== wrappedNativeTokenRef.current.toLowerCase()) {
            const tokenContract = new web3.eth.Contract(ABIS.ERC20, parsedTx.tokenIn);
            const allowance = await tokenContract.methods.allowance(fromAddress, routerAddress).call();
            
            console.log(`檢查授權狀態: ${fromAddress} -> ${routerAddress}, 當前額度: ${allowance}`);
            
            if (BigInt(allowance) < BigInt(amountIn)) {
              console.log(`授權額度不足，執行approve...`);
              await tokenContract.methods.approve(routerAddress, MAX_UINT256).send({
                from: fromAddress,
                gasPrice: web3.utils.toWei(approveGasPrice || '5', 'gwei'),
                gas: parseInt(approveGasLimit)
              });
              console.log(`授權成功!`);
            }
          }
          
          return {
            to: routerAddress,
            value,
            data: router.methods.exactInputSingle({
              tokenIn: parsedTx.tokenIn,
              tokenOut: parsedTx.tokenOut,
              fee: parsedTx.fee,
              recipient: fromAddress,
              deadline,
              amountIn,
              amountOutMinimum,
              sqrtPriceLimitX96: 0
            }).encodeABI(),
            gas: 400000
          };
        }
        // 賣出類型交易
        else if (parsedTx.operation === 'sell') {
          const tokenContract = new web3.eth.Contract(ABIS.ERC20, parsedTx.tokenIn);
          
          // 檢查授權
          console.log(`檢查授權狀態: ${fromAddress} -> ${routerAddress}`);
          const allowance = await tokenContract.methods.allowance(fromAddress, routerAddress).call();
          console.log(`當前授權額度: ${allowance}`);
          
          if (BigInt(allowance) < BigInt(parsedTx.amountIn)) {
            console.log(`授權額度不足，執行approve...`);
            await tokenContract.methods.approve(routerAddress, MAX_UINT256).send({
              from: fromAddress,
              gasPrice: web3.utils.toWei(approveGasPrice || '5', 'gwei'),
              gas: parseInt(approveGasLimit)
            });
            console.log(`授權成功!`);
          }
          
          let amountIn;
          
          if (sellMode === 'all') {
            // 全部賣出
            const balance = await tokenContract.methods.balanceOf(fromAddress).call();
            amountIn = balance;
            console.log(`全部賣出模式，當前餘額: ${balance}`);
          } else {
            // 按比例賣出
            const balance = await tokenContract.methods.balanceOf(fromAddress).call();
            const percentage = parseFloat(sellPercentage) / 100;
            amountIn = BigInt(Math.floor(parseInt(balance) * percentage)).toString();
            console.log(`按比例賣出: ${sellPercentage}%, 當前餘額: ${balance}, 賣出數量: ${amountIn}`);
          }
          
          // 如果餘額為0，退出
          if (BigInt(amountIn) <= 0n) {
            throw new Error('代幣餘額為0');
          }
          
          // 計算最小輸出金額
          const amountOutMinimum = BigInt(Math.floor(
            parseInt(parsedTx.amountOutMinimum) * slippageMultiplier
          )).toString();
          
          console.log(`最小輸出: ${parsedTx.amountOutMinimum} * ${slippageMultiplier} = ${amountOutMinimum}`);
          
          return {
            to: routerAddress,
            value: '0',
            data: router.methods.exactInputSingle({
              tokenIn: parsedTx.tokenIn,
              tokenOut: parsedTx.tokenOut,
              fee: parsedTx.fee,
              recipient: fromAddress,
              deadline,
              amountIn,
              amountOutMinimum,
              sqrtPriceLimitX96: 0
            }).encodeABI(),
            gas: 400000
          };
        }
      }
      // exactInput 交易 (暫不支持)
      else if (txInfo.method === 'exactInput') {
        console.log(`暫不支持exactInput交易類型`);
        throw new Error('暫不支持exactInput交易');
      }
      
      return null;
    } catch (error) {
      console.error('Prepare UniswapV3 transaction error:', error);
      throw error;
    }
  };
  
  // 檢查持倉和授權狀態
  const checkHoldingsAndAllowance = async () => {
    if (!targetAddress) {
      setError('請先輸入目標錢包地址');
      return;
    }
    try {
      setLoading(true);
      console.log(`開始檢查 ${targetAddress} 的持倉和授權狀態...`);
      
      // 初始化 Web3
      const chainId = await initWeb3();
      if (!chainId) return;
      
      const web3 = web3Ref.current;
      if (!web3) {
        throw new Error('Web3 初始化失敗');
      }
      const network = await findCurrentNetwork(web3);
      if (!network?.explorer?.apiUrl) {
        throw new Error('當前網絡不支持合約掃描');
      }
      // 構建API請求URL
      const url = new URL(network.explorer.apiUrl);
      url.searchParams.append('module', 'account');
      url.searchParams.append('action', 'tokentx');
      url.searchParams.append('address', targetAddress);
      if (network.explorer.apiKey) {
        url.searchParams.append('apikey', network.explorer.apiKey);
      }
      
      console.log(`準備獲取代幣交易歷史: ${url.toString()}`);
      const response = await fetch(url.toString());
      const data = await response.json();
      
      const tokenList = [];
      if (data.status === '1' && data.result) {
        console.log(`成功獲取交易歷史，找到 ${data.result.length} 筆交易`);
        
        // 獲取唯一的代幣地址列表
        const uniqueTokens = [...new Set(data.result.map(tx => tx.contractAddress.toLowerCase()))];
        console.log(`檢測到 ${uniqueTokens.length} 個唯一代幣地址`);
        
        // 檢查每個代幣
        for (const tokenAddress of uniqueTokens) {
          console.log(`檢查代幣: ${tokenAddress}`);
          const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
          
          try {
            // 檢查餘額
            const balance = await tokenContract.methods.balanceOf(targetAddress).call();
            console.log(`代幣 ${tokenAddress} 餘額: ${balance}`);
            
            if (BigInt(balance) > 0n) {
              // 獲取代幣信息
              const [symbol, decimals] = await Promise.all([
                tokenContract.methods.symbol().call().catch(() => 'Unknown'),
                tokenContract.methods.decimals().call().catch(() => '18')
              ]);
              console.log(`代幣信息: 符號=${symbol}, 精度=${decimals}`);
              
              // 檢查授權狀態 (对目标可能使用的所有DEX路由检查)
              const dexs = DEX_ROUTERS[chainId] || {};
              const dexAllowances = {};
              
              for (const [dexType, routerAddress] of Object.entries(dexs)) {
                try {
                  console.log(`檢查 ${dexType} 授權: ${targetAddress} -> ${routerAddress}`);
                  const allowance = await tokenContract.methods
                    .allowance(targetAddress, routerAddress)
                    .call();
                  dexAllowances[dexType] = BigInt(allowance) > 0n;
                  console.log(`${dexType} 授權狀態: ${dexAllowances[dexType] ? '已授權' : '未授權'}`);
                } catch (err) {
                  console.error(`Error checking allowance for ${dexType}:`, err);
                  dexAllowances[dexType] = false;
                }
              }
              // 格式化餘額
              const decimalsNum = parseInt(decimals);
              const divisor = BigInt(10) ** BigInt(decimalsNum);
              const formattedBalance = (BigInt(balance) * BigInt(10000) / divisor) / 10000;
              tokenList.push({
                address: tokenAddress,
                symbol,
                decimals: decimalsNum,
                balance,
                dexAllowances,
                formattedBalance: formattedBalance.toString()
              });
              console.log(`已添加代幣 ${symbol} 到列表，格式化餘額: ${formattedBalance}`);
            }
          } catch (err) {
            console.error(`Error processing token ${tokenAddress}:`, err);
          }
        }
      }
      
      setHoldingTokens(tokenList);
      setHasCheckedAllowance(true);
      console.log(`持倉檢查完成，找到 ${tokenList.length} 個代幣`);
      
    } catch (err) {
      console.error('Check holdings error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  // 授權單個代幣
  const approveToken = async (tokenAddress, dexType) => {
    try {
      if (!window._web3Instance || !window._userAddresses?.length) {
        throw new Error('請先連接錢包');
      }
      if (!approveGasPrice) {
        throw new Error('請輸入Gas Price');
      }
      setAllowanceChecking(true);
      console.log(`開始授權代幣 ${tokenAddress} 給 ${dexType} 路由器...`);
      
      // 獲取當前鏈ID和DEX路由器地址
      const chainId = await window._web3Instance.eth.getChainId();
      const chainIdStr = chainId.toString();
      const routerAddress = DEX_ROUTERS[chainIdStr]?.[dexType];
      
      if (!routerAddress) {
        throw new Error('未找到DEX路由器地址');
      }
      console.log(`找到 ${dexType} 路由器地址: ${routerAddress}`);
      
      const web3 = window._web3Instance;
      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      console.log(`執行授權交易: ${window._userAddresses[0]} -> ${routerAddress}, GasPrice: ${approveGasPrice} Gwei`);
      
      const tx = await tokenContract.methods
        .approve(routerAddress, MAX_UINT256)
        .send({
          from: window._userAddresses[0],
          gasPrice: web3.utils.toWei(approveGasPrice, 'gwei'),
          gas: parseInt(approveGasLimit)
        });
      
      console.log(`授權交易成功! 交易哈希: ${tx.transactionHash}`);
      
      // 更新代幣列表中的授權狀態
      setHoldingTokens(prev => prev.map(token => {
        if (token.address === tokenAddress) {
          const updatedDexAllowances = { ...token.dexAllowances };
          updatedDexAllowances[dexType] = true;
          return { ...token, dexAllowances: updatedDexAllowances };
        }
        return token;
      }));
      
      return tx;
    } catch (err) {
      console.error('Approve error:', err);
      setError(err.message);
      throw err;
    } finally {
      setAllowanceChecking(false);
    }
  };
// 效果掛鉤 - 檢查鏈支持情況
  React.useEffect(() => {
    const checkChainSupport = async () => {
      if (!window._web3Instance) return;
      try {
        const chainId = await window._web3Instance.eth.getChainId();
        const chainIdString = chainId.toString();
        const isFrontRunnable = !!SAME_BLOCK_FRONTRUN_CHAINS[chainIdString];
        const isBlockSupported = !!BLOCK_SUBSCRIPTION_CHAINS[chainIdString];
        const chainName = ALL_SUPPORTED_CHAINS[chainIdString] || '未知鏈';
        
        console.log(`檢測到鏈: ${chainIdString} (${chainName}), 前台運行: ${isFrontRunnable}, 區塊支持: ${isBlockSupported}`);
        
        setChainSupport({
          chainId: chainIdString,
          chainName,
          isFrontRunnable,
          isSupported: isFrontRunnable || isBlockSupported
        });
      } catch (err) {
        console.error('Check chain support error:', err);
      }
    };
    checkChainSupport();
    const interval = setInterval(checkChainSupport, 5000);
    return () => clearInterval(interval);
  }, []);
  
  // 監控鏈上 Gas Price
  React.useEffect(() => {
    const updateGasPrice = async () => {
      if (!window._web3Instance) return;
      try {
        const price = await window._web3Instance.eth.getGasPrice();
        setCurrentGasPrice(Web3.utils.fromWei(price, 'gwei'));
      } catch (error) {
        console.error('Failed to get gas price:', error);
      }
    };
    updateGasPrice();
    const interval = setInterval(updateGasPrice, 10000);
    return () => clearInterval(interval);
  }, []);
  
  // 清理效果
  React.useEffect(() => {
    return () => {
      stopMonitoring();
      console.log('組件卸載，已清理資源');
    };
  }, []);
  
  // 渲染監控統計
  const renderMonitoringStats = () => {
    if (!isMonitoring && !monitoringStats.startTime) return null;
    const duration = monitoringStats.startTime ? 
      Math.floor((Date.now() - monitoringStats.startTime) / 1000) : 0;
    return (
      <Card sx={{ mb: 2, bgcolor: 'primary.dark' }}>
        <CardContent>
          <Typography variant="h6" color="common.white" gutterBottom>
            監控統計
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                掃描交易: {monitoringStats.totalScanned}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                匹配交易: {monitoringStats.matchedTx}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                成功執行: {monitoringStats.successfulTx}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                失敗執行: {monitoringStats.failedTx}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="common.white">
                運行時間: {Math.floor(duration / 3600)}時 {Math.floor((duration % 3600) / 60)}分 {duration % 60}秒
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  // 渲染交易記錄
  const renderTransactions = () => {
    if (transactions.length === 0) return null;
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            交易記錄
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>交易類型</TableCell>
                  <TableCell>協議</TableCell>
                  <TableCell>DEX</TableCell>
                  <TableCell>狀態</TableCell>
                  <TableCell>時間</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((tx) => (
                  <TableRow key={tx.hash}>
                    <TableCell>
                      {tx.type === 'buy' ? '買入' : 
                       tx.type === 'sell' ? '賣出' : '代幣交換'}
                    </TableCell>
                    <TableCell>{DEX_PROTOCOLS[tx.protocol] || tx.protocol}</TableCell>
                    <TableCell>{tx.dexType}</TableCell>
                    <TableCell>
                      {tx.status === 'detected' && <Chip size="small" label="已檢測" color="primary" />}
                      {tx.status === 'processing' && <Chip size="small" label="處理中" color="warning" />}
                      {tx.status === 'success' && <Chip size="small" label="成功" color="success" />}
                      {tx.status === 'failed' && <Chip size="small" label="失敗" color="error" />}
                    </TableCell>
                    <TableCell>
                      {new Date(tx.timestamp).toLocaleTimeString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };
  
  // 渲染已檢測到的DEX
  const renderDetectedDexes = () => {
    if (detectedDexes.length === 0) return null;
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            已檢測到的DEX
          </Typography>
          <Grid container spacing={1}>
            {detectedDexes.map((dex, index) => (
              <Grid item key={index}>
                <Chip 
                  label={`${DEX_PROTOCOLS[dex.protocol] || dex.protocol} (${dex.type})`} 
                  color="info" 
                  variant="outlined" 
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  // 渲染目標持倉列表
  const renderHoldingTokens = () => {
    if (holdingTokens.length === 0) return null;
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            目標持倉列表
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>代幣</TableCell>
                  <TableCell>餘額</TableCell>
                  <TableCell>授權狀態</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holdingTokens.map((token) => (
                  <TableRow key={token.address}>
                    <TableCell>{token.symbol}</TableCell>
                    <TableCell>{token.formattedBalance}</TableCell>
                    <TableCell>
                      {Object.entries(token.dexAllowances || {}).map(([dexType, isAllowed]) => (
                        <Chip 
                          key={dexType}
                          label={`${dexType}: ${isAllowed ? '已授權' : '未授權'}`} 
                          color={isAllowed ? "success" : "error"}
                          size="small"
                          sx={{ mr: 0.5, mb: 0.5 }}
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                      {token.dexAllowances && Object.entries(token.dexAllowances)
                        .filter(([_, isAllowed]) => !isAllowed)
                        .map(([dexType]) => (
                          <Button
                            key={dexType}
                            size="small"
                            variant="contained"
                            onClick={() => approveToken(token.address, dexType)}
                            disabled={allowanceChecking}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          >
                            {allowanceChecking ? <CircularProgress size={20} /> : `授權 ${dexType}`}
                          </Button>
                        ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };
// 渲染主要內容
  return (
    <Box>
      {/* 全局設置 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            全局設置
          </Typography>
          {/* 鏈支持狀態提示 */}
          <Alert 
            severity={chainSupport.isSupported ? (chainSupport.isFrontRunnable ? "success" : "info") : "warning"}
            sx={{ mb: 2 }}
          >
            {chainSupport.isSupported ? 
              (chainSupport.isFrontRunnable ? 
                `${chainSupport.chainName} 支持同區塊搶跑` : 
                `${chainSupport.chainName} 只支持區塊訂閱`) : 
              "當前鏈不支持監控"
            }
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Gas倍數 (1.1-10)"
                type="number"
                value={gasMultiplier}
                onChange={(e) => setGasMultiplier(e.target.value)}
                InputProps={{
                  inputProps: { min: 1.1, max: 10, step: 0.1 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="滑點 (%)"
                type="number"
                value={slippage}
                onChange={(e) => setSlippage(e.target.value)}
                InputProps={{
                  inputProps: { min: 0.1, max: 100, step: 0.1 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="目標錢包地址"
                value={targetAddress}
                onChange={(e) => setTargetAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Alert 
                severity="info" 
                sx={{ 
                  mb: 2, 
                  backgroundColor: '#fff3e0', 
                  border: '1px solid #ffb74d',
                  '& .MuiAlert-icon': {
                    color: '#f57c00'
                  }
                }}
              >
                ⚠️ Gas Price 與 Gas Limit 僅用於代幣授權操作
              </Alert>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Gas Price (Gwei)"
                type="number"
                value={approveGasPrice}
                onChange={(e) => setApproveGasPrice(e.target.value)}
                helperText={`當前鏈上 Gas Price: ${currentGasPrice || '加載中...'} Gwei`}
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Gas Limit"
                type="number"
                value={approveGasLimit}
                onChange={(e) => setApproveGasLimit(e.target.value)}
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={checkHoldingsAndAllowance}
                disabled={!targetAddress || loading}
              >
                {loading ? <CircularProgress size={24} /> : '檢查目標持倉'}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* 已檢測到的DEX */}
      {renderDetectedDexes()}
      {/* 持倉列表 */}
      {renderHoldingTokens()}
      {/* 監控模式選擇 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            監控模式
          </Typography>
          <RadioGroup
            value={monitorMode}
            onChange={(e) => setMonitorMode(e.target.value)}
            sx={{ mb: 2 }}
          >
            <FormControlLabel value="buy" control={<Radio />} label="跟買" />
            <FormControlLabel value="sell" control={<Radio />} label="跟賣" />
            <FormControlLabel value="both" control={<Radio />} label="跟買賣" />
          </RadioGroup>
          {/* 買入設置 */}
          {(monitorMode === 'buy' || monitorMode === 'both') && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                買入設置
              </Typography>
              <RadioGroup
                value={buyMode}
                onChange={(e) => setBuyMode(e.target.value)}
                sx={{ mb: 2 }}
              >
                <FormControlLabel value="percentage" control={<Radio />} label="比例模式" />
                <FormControlLabel value="fixed" control={<Radio />} label="固定金額模式" />
              </RadioGroup>
              {buyMode === 'percentage' ? (
                <TextField
                  fullWidth
                  label="買入比例 (0-1000%)"
                  type="number"
                  value={buyPercentage}
                  onChange={(e) => setBuyPercentage(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0, max: 1000 }
                  }}
                />
              ) : (
                <TextField
                  fullWidth
                  label="固定買入金額"
                  type="number"
                  value={fixedAmount}
                  onChange={(e) => setFixedAmount(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0, step: "any" }
                  }}
                />
              )}
            </Box>
          )}
          {/* 賣出設置 */}
          {(monitorMode === 'sell' || monitorMode === 'both') && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                賣出設置
              </Typography>
              <RadioGroup
                value={sellMode}
                onChange={(e) => setSellMode(e.target.value)}
                sx={{ mb: 2 }}
              >
                <FormControlLabel value="all" control={<Radio />} label="全賣" />
                <FormControlLabel value="percentage" control={<Radio />} label="比例賣出" />
              </RadioGroup>
              {sellMode === 'percentage' && (
                <TextField
                  fullWidth
                  label="賣出比例 (0-100%)"
                  type="number"
                  value={sellPercentage}
                  onChange={(e) => setSellPercentage(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0, max: 100 }
                  }}
                />
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      {/* 交易記錄 */}
      {renderTransactions()}
      {/* 監控統計和操作按鈕 */}
      {renderMonitoringStats()}
      <Button
        variant="contained"
        fullWidth
        onClick={isMonitoring ? stopMonitoring : startMonitoring}
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : 
         isMonitoring ? "停止監控" : "開始監控"}
      </Button>
      {/* 錯誤提示 */}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      {/* 監控中提示 */}
      {isMonitoring && (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      )}
    </Box>
  );
};
module.exports = SmartMoneyTracker;
