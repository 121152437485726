const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Select,
  MenuItem,
  Alert,
  TextField
} = require('@mui/material');
const Web3 = require('web3');

// DEX配置對象
const DEX_CONFIG = {
  1: [
    {
      name: 'Uniswap V2',
      factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      router: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
      type: 'UniswapV2'
    },
    {
      name: 'SushiSwap',
      factory: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
      router: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
      type: 'UniswapV2'
    }
  ],
  56: [
    {
      name: 'PancakeSwap V2',
      factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
      router: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
      type: 'UniswapV2'
    },
    {
      name: 'PancakeSwap V3',
      factory: '0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865',
      router: '0x13f4EA83D0bd40E75C8222255bc855a974568Dd4',
      positionManager: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
      quoter: '0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997',
      type: 'UniswapV3'
    },
    {
      name: 'BiSwap',
      factory: '0x858E3312ed3A876947EA49d572A7C42DE08af7EE',
      router: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
      type: 'UniswapV2'
    },
    {
      name: 'ApeSwap',
      factory: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
      router: '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7',
      type: 'UniswapV2'
    }
  ],
  8453: [
    {
      name: 'BaseSwap',
      factory: '0xFDa619b6d20975be80A10332cD39b9a4b0FAa8BB',
      router: '0x327Df1E6de05895d2ab08513aaDD9313Fe505d86',
      type: 'UniswapV2'
    },
    {
      name: 'SwapBased',
      factory: '0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6',
      router: '0x327Df1E6de05895d2ab08513aaDD9313Fe505d86',
      type: 'UniswapV2'
    }
  ],
  42161: [
    {
      name: 'Camelot',
      factory: '0x6EcCab422D763aC031210895C81787E87B43A652',
      router: '0xc873fEcbd354f5A56E00E710B90EF4201db2448d',
      type: 'UniswapV2'
    },
    {
      name: 'SushiSwap',
      factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      router: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      type: 'UniswapV2'
    }
  ],
  20201022: [
    {
      name: 'PegoSwap',
      factory: '0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E',
      router: '0x2C9c8B9d6326a399E64e0a6f8E89582f255A54E9',
      type: 'UniswapV2'
    }
  ],
  1116: [
    {
      name: 'CoreDEX',
      factory: '0x70f51d68D16e8f9e418441280342BD43AC9Dff9f',
      router: '0x3C87F3d06DDf481Fa67A0E63D8bB8b9514a26e33',
      type: 'UniswapV2'
    },
    {
      name: 'IceCreamSwap',
      factory: '0x9E6d21E759A7A288b80eef94E4737D313D31c13f',
      router: '0xBb5e1777A331ED93E07cF043363e48d320eb96c4',
      type: 'UniswapV2'
    }
  ],
  146: [
    {
      name: 'SonicSwap',
      factory: '0x5fA0F4D168f162F553Ae97E9127fc267b1c73db8',
      router: '0x28e0F3EAD48c20EB87Fc759DF5BfD3D212D95489',
      type: 'UniswapV2'
    },
    {
      name: 'SonicDEX',
      factory: '0x7C7461bBB593F26787e9DD83ed7F23DF6528FA25',
      router: '0xaB0738320A9f8066e19Ea71f3ADa468D7996E289',
      type: 'UniswapV2'
    },
    {
      name: 'SonicDeFi',
      factory: '0xEF45d134b73241eDa7703fa787148D9C9F4950b0',
      router: '0xbFBaD502a765f3C027697A5EEfc37d9404A2acF0',
      type: 'UniswapV2'
    }
  ],
  43114: [
    {
      name: 'Trader Joe',
      factory: '0x9Ad6C38BE94206cA50bb0d90783181662f0Cfa10',
      router: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
      type: 'UniswapV2'
    },
    {
      name: 'Pangolin',
      factory: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
      router: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
      type: 'UniswapV2'
    },
    {
      name: 'SushiSwap',
      factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      router: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      type: 'UniswapV2'
    },
    {
      name: 'Trader Joe V2.1',
      factory: '0x8e42f2F4101563bF679975178e880FD87d3eFd4e',
      router: '0xE3Ffc583dC176575eEA7FD9dF2A7c65F7E23f4C3',
      quoter: '0xb12C86eeF3264fF6071E977f1e5502b61F5De261',
      type: 'UniswapV3'
    }
  ]
};

const DEXPanel = () => {
  // 狀態管理
  const [selectedDex, setSelectedDex] = React.useState('');
  const [currentChainId, setCurrentChainId] = React.useState(null);
  const [error, setError] = React.useState('');
  const [selectedDexInfo, setSelectedDexInfo] = React.useState(null);
  const [customRouter, setCustomRouter] = React.useState('');
  const [customFactory, setCustomFactory] = React.useState('');
  
  const prevChainIdRef = React.useRef(null);
  const mountedRef = React.useRef(true);

  // 檢查並更新當前Chain ID
  const updateChainId = async () => {
    if (!window._web3Instance || !mountedRef.current) return;

    try {
      const chainId = await window._web3Instance.eth.getChainId();
      const chainIdString = chainId.toString();

      if (chainIdString !== prevChainIdRef.current) {
        // 網絡變化時才重置
        prevChainIdRef.current = chainIdString;
        setCurrentChainId(chainIdString);
        setSelectedDex('');
        setCustomRouter('');
        setCustomFactory('');
        window._selectedDex = null;
      } else if (currentChainId === null) {
        // 初始化時設置chainId
        setCurrentChainId(chainIdString);
      }
    } catch (err) {
      console.error('Failed to get chain ID:', err);
      if (mountedRef.current) {
        setError('無法獲取網路ID');
        setCurrentChainId(null);
        prevChainIdRef.current = null;
      }
    }
  };

  // 監聽RPC變化
  React.useEffect(() => {
    mountedRef.current = true;
    updateChainId();

    const interval = setInterval(() => {
      if (window._web3Instance) {
        updateChainId();
      }
    }, 1000);

    return () => {
      mountedRef.current = false;
      clearInterval(interval);
    };
  }, []);

  // 獲取當前鏈上可用的DEX列表
  const availableDexes = React.useMemo(() => {
    return currentChainId ? (DEX_CONFIG[currentChainId] || []) : [];
  }, [currentChainId]);

  // 選擇DEX的處理函數
  const handleDexChange = (event) => {
    const selectedValue = event.target.value;
    if (!mountedRef.current) return;

    setSelectedDex(selectedValue);
    
    if (selectedValue === 'custom') {
      setSelectedDexInfo({
        name: 'Custom DEX',
        type: 'UniswapV2'
      });
      if (customRouter && customFactory) {
        window._selectedDex = {
          name: 'Custom DEX',
          router: customRouter,
          factory: customFactory,
          type: 'UniswapV2',
          chainId: currentChainId
        };
      } else {
        window._selectedDex = null;
      }
    } else if (selectedValue) {
      const dexInfo = availableDexes.find(dex => dex.name === selectedValue);
      if (dexInfo) {
        window._selectedDex = {
          ...dexInfo,
          chainId: currentChainId
        };
      }
      setSelectedDexInfo(dexInfo);
    } else {
      window._selectedDex = null;
      setSelectedDexInfo(null);
    }
  };

  // 渲染函數
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          DEX 選擇
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {currentChainId ? (
          availableDexes.length > 0 ? (
            <Box>
              <Select
                fullWidth
                value={selectedDex}
                onChange={handleDexChange}
                sx={{ mb: 2 }}
              >
                <MenuItem value="">
                  <em>請選擇DEX</em>
                </MenuItem>
                {availableDexes.map((dex) => (
                  <MenuItem key={dex.name} value={dex.name}>
                    {dex.name}
                  </MenuItem>
                ))}
                <MenuItem value="custom">自訂Router Factory</MenuItem>
              </Select>

              {selectedDex === 'custom' && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Router地址"
                    value={customRouter}
                    onChange={(e) => {
                      setCustomRouter(e.target.value);
                      if (e.target.value && customFactory) {
                        window._selectedDex = {
                          name: 'Custom DEX',
                          router: e.target.value,
                          factory: customFactory,
                          type: 'UniswapV2',
                          chainId: currentChainId
                        };
                      } else {
                        window._selectedDex = null;
                      }
                    }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Factory地址"
                    value={customFactory}
                    onChange={(e) => {
                      setCustomFactory(e.target.value);
                      if (customRouter && e.target.value) {
                        window._selectedDex = {
                          name: 'Custom DEX',
                          router: customRouter,
                          factory: e.target.value,
                          type: 'UniswapV2',
                          chainId: currentChainId
                        };
                      } else {
                        window._selectedDex = null;
                      }
                    }}
                  />
                </Box>
              )}
              
              {selectedDexInfo && selectedDex !== 'custom' && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    Factory: {selectedDexInfo.factory}
                  </Typography>
                  <Typography variant="body2">
                    Router: {selectedDexInfo.router}
                  </Typography>
                  <Typography variant="body2">
                    Type: {selectedDexInfo.type}
                  </Typography>
                  {selectedDexInfo.quoter && (
                    <Typography variant="body2">
                      Quoter: {selectedDexInfo.quoter}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Typography color="warning">
              當前網路無可用DEX
            </Typography>
          )
        ) : (
          <Typography>
            請先選擇並連接RPC節點
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

module.exports = DEXPanel;
