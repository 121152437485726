const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Select,
  MenuItem,
  Alert,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
  InputAdornment,
  CircularProgress,
  Radio,
  RadioGroup,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar
} = require('@mui/material');
const Web3 = require('web3');

// 市值監控面板組件
const MarketCapPanel = () => {
  // 内部定义接口ABI
  const INTERNAL_ABIS = {
    // ERC20标准接口
    ERC20: [
      { inputs: [], name: 'name', outputs: [{ name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
      { inputs: [], name: 'symbol', outputs: [{ name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
      { inputs: [], name: 'decimals', outputs: [{ name: '', type: 'uint8' }], stateMutability: 'view', type: 'function' },
      { inputs: [{ name: '', type: 'address' }], name: 'balanceOf', outputs: [{ name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
      { inputs: [{ name: 'spender', type: 'address' }, { name: 'amount', type: 'uint256' }], name: 'approve', outputs: [{ name: '', type: 'bool' }], stateMutability: 'nonpayable', type: 'function' },
      { inputs: [{ name: 'owner', type: 'address' }, { name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' }
    ],
    
    // UniswapV2/PancakeSwap V2工厂接口
    FACTORY_V2: [
      { inputs: [{ name: 'tokenA', type: 'address' }, { name: 'tokenB', type: 'address' }], name: 'getPair', outputs: [{ name: 'pair', type: 'address' }], stateMutability: 'view', type: 'function' },
      { inputs: [{ name: '', type: 'uint256' }], name: 'allPairs', outputs: [{ name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
      { inputs: [], name: 'allPairsLength', outputs: [{ name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' }
    ],
    
    // UniswapV2/PancakeSwap V2配对接口
    PAIR_V2: [
      { inputs: [], name: 'token0', outputs: [{ name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
      { inputs: [], name: 'token1', outputs: [{ name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
      { inputs: [], name: 'getReserves', outputs: [{ name: '_reserve0', type: 'uint112' }, { name: '_reserve1', type: 'uint112' }, { name: '_blockTimestampLast', type: 'uint32' }], stateMutability: 'view', type: 'function' }
    ],
    
    // UniswapV2/PancakeSwap V2路由接口
    ROUTER_V2: [
      { 
        inputs: [
          { name: 'amountIn', type: 'uint256' },
          { name: 'amountOutMin', type: 'uint256' },
          { name: 'path', type: 'address[]' },
          { name: 'to', type: 'address' },
          { name: 'deadline', type: 'uint256' }
        ], 
        name: 'swapExactTokensForTokens', 
        outputs: [{ name: 'amounts', type: 'uint256[]' }], 
        stateMutability: 'nonpayable', 
        type: 'function' 
      },
      { 
        inputs: [
          { name: 'amountOutMin', type: 'uint256' },
          { name: 'path', type: 'address[]' },
          { name: 'to', type: 'address' },
          { name: 'deadline', type: 'uint256' }
        ], 
        name: 'swapExactETHForTokens', 
        outputs: [{ name: 'amounts', type: 'uint256[]' }], 
        stateMutability: 'payable', 
        type: 'function' 
      },
      { 
        inputs: [
          { name: 'amountIn', type: 'uint256' },
          { name: 'amountOutMin', type: 'uint256' },
          { name: 'path', type: 'address[]' },
          { name: 'to', type: 'address' },
          { name: 'deadline', type: 'uint256' }
        ], 
        name: 'swapExactTokensForETH', 
        outputs: [{ name: 'amounts', type: 'uint256[]' }], 
        stateMutability: 'nonpayable', 
        type: 'function' 
      }
    ],
// UniswapV3/PancakeSwap V3 Quoter接口 - 更新适配PancakeSwap V3
    QUOTER_V3: [
      {
        inputs: [
          { name: 'tokenIn', type: 'address' },
          { name: 'tokenOut', type: 'address' },
          { name: 'fee', type: 'uint24' },
          { name: 'amountIn', type: 'uint256' },
          { name: 'sqrtPriceLimitX96', type: 'uint160' }
        ],
        name: 'quoteExactInputSingle',
        outputs: [{ name: 'amountOut', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { name: 'path', type: 'bytes' },
          { name: 'amountIn', type: 'uint256' }
        ],
        name: 'quoteExactInput',
        outputs: [{ name: 'amountOut', type: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ],
    
    // PancakeSwap V3 QuoterV2接口 - 新增兼容QuoterV2
    QUOTER_V3_V2: [
      {
        inputs: [
          { name: 'params', type: 'tuple', components: [
            { name: 'tokenIn', type: 'address' },
            { name: 'tokenOut', type: 'address' },
            { name: 'amount', type: 'uint256' },
            { name: 'fee', type: 'uint24' },
            { name: 'sqrtPriceLimitX96', type: 'uint160' }
          ]}
        ],
        name: 'quoteExactInputSingle',
        outputs: [
          { name: 'amountOut', type: 'uint256' },
          { name: 'sqrtPriceX96After', type: 'uint160' },
          { name: 'initializedTicksCrossed', type: 'uint32' },
          { name: 'gasEstimate', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          { name: 'params', type: 'tuple', components: [
            { name: 'path', type: 'bytes' },
            { name: 'amount', type: 'uint256' }
          ]}
        ],
        name: 'quoteExactInput',
        outputs: [
          { name: 'amountOut', type: 'uint256' },
          { name: 'sqrtPriceX96AfterList', type: 'uint160[]' },
          { name: 'initializedTicksCrossedList', type: 'uint32[]' },
          { name: 'gasEstimate', type: 'uint256' }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ],
    
    // UniswapV3/PancakeSwap V3 SwapRouter接口 - 更新适应最新接口
    ROUTER_V3: [
      {
        inputs: [
          {
            components: [
              { name: 'tokenIn', type: 'address' },
              { name: 'tokenOut', type: 'address' },
              { name: 'fee', type: 'uint24' },
              { name: 'recipient', type: 'address' },
              { name: 'deadline', type: 'uint256' },
              { name: 'amountIn', type: 'uint256' },
              { name: 'amountOutMinimum', type: 'uint256' },
              { name: 'sqrtPriceLimitX96', type: 'uint160' }
            ],
            name: 'params',
            type: 'tuple'
          }
        ],
        name: 'exactInputSingle',
        outputs: [{ name: 'amountOut', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function'
      },
      // 添加exactInput函数支持
      {
        inputs: [
          {
            components: [
              { name: 'path', type: 'bytes' },
              { name: 'recipient', type: 'address' },
              { name: 'deadline', type: 'uint256' },
              { name: 'amountIn', type: 'uint256' },
              { name: 'amountOutMinimum', type: 'uint256' }
            ],
            name: 'params',
            type: 'tuple'
          }
        ],
        name: 'exactInput',
        outputs: [{ name: 'amountOut', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function'
      }
    ]
  };
  
// 定义网络配置
  const NETWORK_CONFIG = {
    '1': { // Ethereum
      name: 'Ethereum',
      chainId: '1',
      contracts: {
        wrappedNative: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2' // WETH
      },
      stablecoins: {
        USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        BUSD: '0x4Fabb145d64652a948d72533023f6E7A623C7C53'
      },
      v3FeeTiers: [100, 500, 3000, 10000] // Uniswap V3费率等级
    },
    '56': { // BSC
      name: 'BNB Smart Chain',
      chainId: '56',
      contracts: {
        wrappedNative: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c' // WBNB
      },
      stablecoins: {
        USDT: '0x55d398326f99059fF775485246999027B3197955', // BSC-USD
        USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        DAI: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3'
      },
      v3FeeTiers: [100, 500, 2500, 10000] // PancakeSwap V3费率等级
    },
    '137': { // Polygon
      name: 'Polygon',
      chainId: '137',
      contracts: {
        wrappedNative: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' // WMATIC
      },
      stablecoins: {
        USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        DAI: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
      },
      v3FeeTiers: [100, 500, 3000, 10000]
    },
    '42161': { // Arbitrum
      name: 'Arbitrum',
      chainId: '42161',
      contracts: {
        wrappedNative: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1' // WETH
      },
      stablecoins: {
        USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        USDC: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        DAI: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'
      },
      v3FeeTiers: [100, 500, 3000, 10000]
    }
  };
// 添加获取区块时间的函数
  const getBlockTimeByChainId = (chainId) => {
    // 根据不同网络定义区块时间（毫秒）
    const blockTimes = {
      '1': 12000,    // Ethereum: ~12 秒
      '56': 3000,    // BSC: ~3 秒
      '137': 2000,   // Polygon: ~2 秒
      '42161': 250,  // Arbitrum: ~0.25 秒
      '43114': 2000, // Avalanche: ~2 秒
      '10': 2000,    // Optimism: ~2 秒
      '250': 1000,   // Fantom: ~1 秒
      '100': 5000,   // xDai/Gnosis: ~5 秒
      '128': 3000,   // HECO: ~3 秒
      '1666600000': 2000, // Harmony: ~2 秒
      '321': 13000,  // KCC: ~13 秒
      '19': 15000,   // Songbird: ~15 秒
      '288': 1000,   // Boba: ~1 秒
      '66': 13000,   // OKEx: ~13 秒
      '25': 5000,    // Cronos: ~5 秒
      '122': 5000,   // Fuse: ~5 秒
      '336': 1000,   // Shiden: ~1 秒
    };
    
    return blockTimes[chainId] || 15000; // 默认使用15秒
  };
  
  // 狀態變量
  const [targetTokenAddress, setTargetTokenAddress] = React.useState('');
  const [poolTokenType, setPoolTokenType] = React.useState('USDT'); 
  const [customPoolTokenAddress, setCustomPoolTokenAddress] = React.useState(''); 
  const [tokenInfo, setTokenInfo] = React.useState(null);
  const [poolTokenInfo, setPoolTokenInfo] = React.useState(null);
  const [inputToken, setInputToken] = React.useState('NATIVE');
  const [customInputTokenAddress, setCustomInputTokenAddress] = React.useState('');
  const [outputToken, setOutputToken] = React.useState('USDT');
  const [customOutputTokenAddress, setCustomOutputTokenAddress] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [fetchingInfo, setFetchingInfo] = React.useState(false); // 新增：用于显示获取状态但不禁用按钮
  const [error, setError] = React.useState('');
  const [currentPrice, setCurrentPrice] = React.useState(null);
  const [buyThreshold, setBuyThreshold] = React.useState('');
  const [sellThreshold, setSellThreshold] = React.useState('');
  const [buyAmount, setBuyAmount] = React.useState('');
  const [sellAmount, setSellAmount] = React.useState('');
  const [buyAmountType, setBuyAmountType] = React.useState('fixed'); 
  const [sellAmountType, setSellAmountType] = React.useState('fixed'); 
  const [targetMultiple, setTargetMultiple] = React.useState('');
  const [useAntiSandwich, setUseAntiSandwich] = React.useState(false);
  const [antiSandwichRPC, setAntiSandwichRPC] = React.useState('');
  const [isTaskRunning, setIsTaskRunning] = React.useState(false);
  const [taskStatus, setTaskStatus] = React.useState('');
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  const [currentChainId, setCurrentChainId] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success'
  });
  // 增加V3专用状态
  const [v3FeeTier, setV3FeeTier] = React.useState(2500); // 默认费率2500（0.25%），适用于PancakeSwap V3
  const [priceMethod, setPriceMethod] = React.useState(''); 
  const [debugLogs, setDebugLogs] = React.useState([]);
  const [formattedPrice, setFormattedPrice] = React.useState('$0.00000000');
  const [quoterVersion, setQuoterVersion] = React.useState('v1'); // 新增：记录Quoter版本
  const [infoRequestId, setInfoRequestId] = React.useState(0); // 新增：用于取消旧的请求

  // 參考變量
  const taskIntervalRef = React.useRef(null);
  const initialBalancesRef = React.useRef({
    input: '0',
    output: '0'
  });
  const currentBalancesRef = React.useRef({
    input: '0',
    output: '0'
  });
  const mountedRef = React.useRef(true);
  const originalRpcRef = React.useRef(null);
  const priceUpdateIntervalRef = React.useRef(null);
  // 新增缓存
  const v3PoolCacheRef = React.useRef({});
  const feeTierTriedRef = React.useRef({});
  const lastRequestIdRef = React.useRef(0); // 新增：最后一次请求ID引用

// 格式化代币价格显示 - 改进版
const formatTokenPrice = (price) => {
  if (!price || isNaN(parseFloat(price))) return '$0.00000000';
  
  // 将价格解析为数字
  let priceNum = parseFloat(price);
  
  // 如果价格是0，直接返回0
  if (priceNum === 0) return '$0.00000000';
  
  // 获取原始数字的字符串表示
  const priceStr = price.toString();
  
  // 检查是否是科学计数法 (例如: 4.056e-12)
  if (priceStr.includes('e-')) {
    // 解析科学计数法的组成部分
    const parts = priceStr.split('e-');
    const baseNum = parseFloat(parts[0]);
    const exponent = parseInt(parts[1]);
    
    // 科学计数法表示的位数不能直接处理，我们需要自己构建一个小数
    // 例如：4.056e-12 应该是 0.000000000004056
    
    // 获取基础数字的字符串，移除小数点
    let baseStr = baseNum.toString().replace('.', '');
    
    // 移除末尾的0
    baseStr = baseStr.replace(/0+$/, '');
    
    // 计算需要的前导零数量
    // 如果baseNum < 10，需要exponent-1个前导零 (因为小数点已经算一位)
    // 如果baseNum >= 10，需要exponent个前导零 (因为基数有多位整数)
    let leadingZeros;
    
    if (baseNum < 10) {
      leadingZeros = exponent - 1;
    } else {
      // 计算基数中有多少位整数
      const intDigits = Math.floor(Math.log10(baseNum)) + 1;
      leadingZeros = exponent - intDigits;
    }
    
    // 确保前导零数量不小于0
    leadingZeros = Math.max(0, leadingZeros);
    
    // 构建最终价格字符串
    return `$0.${'0'.repeat(leadingZeros)}${baseStr}`;
  }
  
  // 处理非常小但不是科学计数法的数字
  if (priceNum < 0.000001) {
    // 找到小数点后第一个非零数字
    const match = priceStr.match(/\.0+(?=\d)/);
    if (match) {
      const leadingZeros = match[0].length - 1; // 减去小数点
      const significantPart = priceStr.substring(match[0].length);
      
      // 取前8位有效数字
      return `$0.${'0'.repeat(leadingZeros)}${significantPart.substring(0, 8)}`;
    }
  }
  
  // 价格较大时，保留8位小数
  return `$${priceNum.toFixed(8)}`;
};

// 编码V3路径 - 新增
const encodeV3Path = (tokenA, tokenB, fee) => {
  const web3 = window._web3Instance;
  if (!web3) return null;
  
  try {
    // 移除0x前缀，然后将地址和费率编码为一个字节序列
    const encodedPath = 
      tokenA.toLowerCase().slice(2) + 
      fee.toString(16).padStart(6, '0') + 
      tokenB.toLowerCase().slice(2);
    
    return '0x' + encodedPath;
  } catch (err) {
    console.error('编码V3路径错误:', err);
    return null;
  }
};

// 日志函数
const addLog = (message) => {
  console.log(message);
  setDebugLogs(prev => [...prev, { time: new Date().toLocaleTimeString(), message }].slice(-100)); // 只保留最近100条日志
};

// 检查合约方法是否存在
const isMethodAvailable = async (contract, methodName) => {
  try {
    const method = contract.methods[methodName];
    return typeof method === 'function';
  } catch (err) {
    addLog(`检查方法 ${methodName} 失败: ${err.message}`);
    return false;
  }
};

// 检查是否是稳定币
const isStablecoin = (tokenAddress) => {
  if (!tokenAddress) return false;
  
  try {
    const stablecoins = Object.values(NETWORK_CONFIG).flatMap(network => 
      Object.values(network.stablecoins || {})
    ).filter(Boolean).map(addr => addr.toLowerCase());
    
    return stablecoins.includes(tokenAddress.toLowerCase());
  } catch (err) {
    addLog(`检查稳定币失败: ${err.message}`);
    return false;
  }
};
// 组件卸载时清理资源
React.useEffect(() => {
  return () => {
    mountedRef.current = false;
    if (taskIntervalRef.current) {
      clearInterval(taskIntervalRef.current);
    }
    if (priceUpdateIntervalRef.current) {
      clearInterval(priceUpdateIntervalRef.current);
    }
    // 如果有切换到防夹子RPC，恢复原始RPC
    if (originalRpcRef.current) {
      window._rpcUrl = originalRpcRef.current;
      originalRpcRef.current = null;
    }
  };
}, []);

// 监听当前连接的网络变化，更新当前链ID
React.useEffect(() => {
  const checkCurrentChain = async () => {
    if (!window._web3Instance) return;
    
    try {
      const chainId = await window._web3Instance.eth.getChainId();
      setCurrentChainId(chainId.toString());
      
      // 根据链ID自动设置默认V3费率 - 新增
      if (NETWORK_CONFIG[chainId.toString()]?.v3FeeTiers) {
        // PancakeSwap V3默认使用2500 (0.25%)，Uniswap V3默认使用3000 (0.3%)
        const defaultFeeTier = chainId.toString() === '56' ? 2500 : 3000;
        setV3FeeTier(defaultFeeTier);
      }
      
      // 如果链ID变化且已有代币信息，则自动更新价格
      if (tokenInfo && poolTokenInfo) {
        fetchTokenPrice().then(price => {
          if (price !== null) {
            setCurrentPrice(price);
            setFormattedPrice(formatTokenPrice(price));
          }
        });
      }
    } catch (err) {
      console.error('Failed to get chain ID:', err);
    }
  };

  checkCurrentChain();
  
  const interval = setInterval(() => {
    if (window._web3Instance) {
      checkCurrentChain();
    }
  }, 3000);

  return () => clearInterval(interval);
}, [tokenInfo, poolTokenInfo]);

// 监听targetTokenAddress的变化，如果变化且正在运行监控任务，则自动停止并重启
React.useEffect(() => {
  // 只在非初始化状态下执行
  if (tokenInfo && tokenInfo.address.toLowerCase() !== targetTokenAddress.toLowerCase() && targetTokenAddress) {
    addLog(`检测到代币地址变更，自动停止旧任务...`);
    stopMonitoringTask();
    // 清除旧的代币信息，但不设置为null，让用户可以看到之前的信息直到新的加载完成
    // 新的代币信息会在fetchTokensInfoAndPrice中更新
  }
}, [targetTokenAddress]);

// 自动更新价格功能 - 使用动态区块时间
React.useEffect(() => {
  // 清除之前的定时器
  if (priceUpdateIntervalRef.current) {
    clearInterval(priceUpdateIntervalRef.current);
  }
  
  // 如果有代币信息，设置定时自动更新价格
  if (tokenInfo && poolTokenInfo) {
    // 根据当前链ID获取区块时间
    const blockTime = getBlockTimeByChainId(currentChainId);
    
    addLog(`根据链ID ${currentChainId} 设置价格更新间隔: ${blockTime}毫秒`);
    
    // 使用区块时间设置更新间隔
    priceUpdateIntervalRef.current = setInterval(() => {
      fetchTokenPrice().then(price => {
        if (price !== null) {
          setCurrentPrice(price);
          setFormattedPrice(formatTokenPrice(price));
          addLog(`价格自动更新: ${price} (${formatTokenPrice(price)})`);
        }
      });
    }, blockTime);
  }
  
  return () => {
    if (priceUpdateIntervalRef.current) {
      clearInterval(priceUpdateIntervalRef.current);
    }
  };
}, [tokenInfo, poolTokenInfo, currentChainId]); // 添加currentChainId作为依赖项

// 添加交易到历史记录
const addTransactionToHistory = (transaction) => {
  setTransactionHistory(prevHistory => [transaction, ...prevHistory]);
};

// 关闭提示框
const handleCloseSnackbar = () => {
  setSnackbar(prev => ({
    ...prev,
    open: false
  }));
};

// 根据选择获取代币地址
const getSelectedTokenAddress = async (tokenType, customAddress) => {
  if (!window._web3Instance) return null;
  
  try {
    const web3 = window._web3Instance;
    const chainId = await web3.eth.getChainId();
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    
    if (!networkInfo) {
      throw new Error('不支援的網絡');
    }
    
    switch (tokenType) {
      case 'NATIVE':
        return 'NATIVE';
      case 'WRAPPED_NATIVE':
        return networkInfo.contracts?.wrappedNative;
      case 'USDT':
        return networkInfo.stablecoins?.USDT;
      case 'USDC':
        return networkInfo.stablecoins?.USDC;
      case 'CUSTOM':
        if (!customAddress || !web3.utils.isAddress(customAddress)) {
          throw new Error('無效的自定義代幣地址');
        }
        return customAddress;
      default:
        return null;
    }
  } catch (err) {
    addLog(`获取代币地址错误: ${err.message}`);
    return null;
  }
};
// 切换到防夹子RPC
const switchToAntiSandwichRPC = async () => {
  if (!antiSandwichRPC || !window._web3Instance) return false;
  
  try {
    // 保存原始RPC URL
    originalRpcRef.current = window._rpcUrl;
    
    // 创建新的provider
    const provider = new Web3.providers.HttpProvider(antiSandwichRPC, {
      timeout: 30000,
      headers: [
        {
          name: 'Content-Type',
          value: 'application/json'
        }
      ]
    });
    
    // 更新Web3实例
    window._web3Instance.setProvider(provider);
    window._rpcUrl = antiSandwichRPC;
    window._currentProvider = provider;
    window._isWebSocket = false;
    
    return true;
  } catch (err) {
    addLog(`切换到防夹子RPC错误: ${err.message}`);
    return false;
  }
};

// 恢复原始RPC
const restoreOriginalRPC = async () => {
  if (!originalRpcRef.current || !window._web3Instance) return false;
  
  try {
    // 判断是HTTP还是WebSocket
    const isWs = originalRpcRef.current.startsWith('ws://') || 
               originalRpcRef.current.startsWith('wss://');
    
    // 创建原始provider
    const provider = isWs ?
      new Web3.providers.WebsocketProvider(originalRpcRef.current, {
        timeout: 30000,
        clientConfig: {
          maxReceivedFrameSize: 100000000,
          maxReceivedMessageSize: 100000000,
          keepalive: true,
          keepaliveInterval: 60000
        },
        reconnect: {
          auto: true,
          delay: 5000,
          maxAttempts: 5,
          onTimeout: false
        }
      }) :
      new Web3.providers.HttpProvider(originalRpcRef.current, {
        timeout: 30000,
        headers: [
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ]
      });
    
    // 更新Web3实例
    window._web3Instance.setProvider(provider);
    window._rpcUrl = originalRpcRef.current;
    window._currentProvider = provider;
    window._isWebSocket = isWs;
    
    // 清除引用
    originalRpcRef.current = null;
    
    return true;
  } catch (err) {
    addLog(`恢复原始RPC错误: ${err.message}`);
    return false;
  }
};

// 新增函数：尝试使用不同的V3 Quoter合约方式获取价格
const tryV3QuoterMethods = async (quoterContract, tokenIn, tokenOut, amountIn, feeTier) => {
  try {
    // 首先检查v1的方法
    if (await isMethodAvailable(quoterContract, 'quoteExactInputSingle')) {
      try {
        // 尝试v1的直接调用方式
        addLog(`尝试使用V3 Quoter V1方法 (fee=${feeTier})...`);
        
        const quotedAmountOut = await quoterContract.methods.quoteExactInputSingle(
          tokenIn,
          tokenOut,
          feeTier,
          amountIn,
          0 // sqrtPriceLimitX96
        ).call();
        
        setQuoterVersion('v1');
        return quotedAmountOut;
      } catch (v1err) {
        addLog(`V3 Quoter V1方法失败: ${v1err.message}`);
      }
    }
    
    // 然后尝试v2的方法
    if (await isMethodAvailable(quoterContract, 'quoteExactInputSingle') && !quoterContract.methods.quoteExactInputSingle.length) {
      try {
        // QuoterV2使用params对象
        addLog(`尝试使用V3 Quoter V2方法 (fee=${feeTier})...`);
        const params = {
          tokenIn,
          tokenOut,
          amount: amountIn,
          fee: feeTier,
          sqrtPriceLimitX96: 0
        };
        
        const result = await quoterContract.methods.quoteExactInputSingle(params).call();
        
        // QuoterV2返回多个值，我们只取第一个(amountOut)
        setQuoterVersion('v2');
        return result.amountOut || result[0];
      } catch (v2err) {
        addLog(`V3 Quoter V2方法失败: ${v2err.message}`);
      }
    }
    
    // 最后尝试使用path方式
    if (await isMethodAvailable(quoterContract, 'quoteExactInput')) {
      try {
        addLog(`尝试使用V3 quoteExactInput方法 (fee=${feeTier})...`);
        const path = encodeV3Path(tokenIn, tokenOut, feeTier);
        
        // 尝试v1的path方式
        const result = await quoterContract.methods.quoteExactInput(
          path,
          amountIn
        ).call();
        
        setQuoterVersion('v1-path');
        return result;
      } catch (pathErr) {
        addLog(`V3 quoteExactInput方法失败: ${pathErr.message}`);
      }
      
      // 尝试v2的path方式
      try {
        addLog(`尝试使用V3 Quoter V2 quoteExactInput方法 (fee=${feeTier})...`);
        const path = encodeV3Path(tokenIn, tokenOut, feeTier);
        
        const params = {
          path,
          amount: amountIn
        };
        
        const result = await quoterContract.methods.quoteExactInput(params).call();
        
        setQuoterVersion('v2-path');
        return result.amountOut || result[0];
      } catch (pathV2Err) {
        addLog(`V3 Quoter V2 quoteExactInput方法失败: ${pathV2Err.message}`);
      }
    }
    
    // 所有方法都失败
    return null;
  } catch (err) {
    addLog(`尝试V3 Quoter方法错误: ${err.message}`);
    return null;
  }
};

// 新增函数：尝试不同费率档位获取价格
const tryMultipleFeeTiers = async (quoterContract, tokenIn, tokenOut, amountIn, chainId) => {
  try {
    const networkInfo = NETWORK_CONFIG[chainId] || NETWORK_CONFIG['56']; // 默认使用BSC
    const feeTiers = networkInfo.v3FeeTiers || [100, 500, 2500, 10000];
    
    // 检查是否有之前在该交易对上成功的费率
    const pairKey = `${tokenIn.toLowerCase()}-${tokenOut.toLowerCase()}`;
    if (v3PoolCacheRef.current[pairKey]) {
      // 先尝试缓存的成功费率
      addLog(`使用缓存的费率: ${v3PoolCacheRef.current[pairKey]}`);
      const result = await tryV3QuoterMethods(
        quoterContract, 
        tokenIn, 
        tokenOut, 
        amountIn, 
        v3PoolCacheRef.current[pairKey]
      );
      
      if (result) {
        return result;
      }
    }
    
    // 重置费率尝试记录
    feeTierTriedRef.current = {};
    
    // 尝试每个费率档位
    for (const fee of feeTiers) {
      // 跳过已尝试的费率
      if (feeTierTriedRef.current[fee]) continue;
      
      feeTierTriedRef.current[fee] = true;
      
      addLog(`尝试费率档位: ${fee}`);
      const result = await tryV3QuoterMethods(quoterContract, tokenIn, tokenOut, amountIn, fee);
      
      if (result) {
        // 缓存成功的费率
        v3PoolCacheRef.current[pairKey] = fee;
        return result;
      }
    }
    
    return null;
  } catch (err) {
    addLog(`尝试多个费率档位错误: ${err.message}`);
    return null;
  }
};
// 获取原生代币价格
const getNativeTokenPrice = async () => {
  if (!window._web3Instance) return null;
  
  try {
    const web3 = window._web3Instance;
    const chainId = await web3.eth.getChainId();
    setPriceMethod('Web3合约调用');
    
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    
    if (!networkInfo) {
      throw new Error('不支援的網絡');
    }
    
    // 获取原生代币包装地址
    const wrappedNativeToken = networkInfo.contracts.wrappedNative;
    if (!wrappedNativeToken) {
      throw new Error('找不到包装后的原生代币地址');
    }
    
    // 获取稳定币地址
    const stablecoinAddress = networkInfo.stablecoins?.USDT || networkInfo.stablecoins?.USDC || networkInfo.stablecoins?.BUSD;
    if (!stablecoinAddress) {
      throw new Error('找不到稳定币地址');
    }
    
    // 判断DEX类型
    const dexType = window._selectedDex.type || 'UniswapV2';
    
    if (dexType === 'UniswapV2') {
      // 对于 UniswapV2/PancakeSwap V2
      // 获取工厂合约
      const factoryContract = new web3.eth.Contract(
        INTERNAL_ABIS.FACTORY_V2, 
        window._selectedDex.factory
      );
      
      // 检查工厂合约是否有getPair方法
      if (!await isMethodAvailable(factoryContract, 'getPair')) {
        addLog('工厂合约没有getPair方法，可能不是标准V2合约');
        return null;
      }
      
      // 获取配对合约地址
      const pairAddress = await factoryContract.methods.getPair(
        wrappedNativeToken,
        stablecoinAddress
      ).call();
      
      if (!pairAddress || pairAddress === '0x0000000000000000000000000000000000000000') {
        throw new Error('找不到原生代币与稳定币的配对');
      }
      
      // 检查合约代码
      const pairCode = await web3.eth.getCode(pairAddress);
      if (pairCode === '0x' || pairCode === '0x0') {
        throw new Error(`配对合约地址无效: ${pairAddress}`);
      }
      
      // 从配对合约获取储备量
      const pairContract = new web3.eth.Contract(INTERNAL_ABIS.PAIR_V2, pairAddress);
      
      try {
        // 验证合约是否实现了需要的方法
        const hasToken0 = await isMethodAvailable(pairContract, 'token0');
        const hasGetReserves = await isMethodAvailable(pairContract, 'getReserves');
        
        if (!hasToken0 || !hasGetReserves) {
          addLog('配对合约不支持标准V2接口方法，尝试直接获取储备量');
          
          // 尝试直接获取储备量
          try {
            const reserves = await pairContract.methods.getReserves().call();
            addLog(`已获取储备量: ${JSON.stringify(reserves)}`);
            
            // 因为我们不知道哪个是哪个，先假设0是WETH，1是稳定币
            const nativeReserve = reserves[0];
            const stablecoinReserve = reserves[1];
            
            // 获取小数位
            const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoinAddress);
            const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
            
            // 计算价格 (两种可能都尝试)
            const nativeAmount1 = parseFloat(nativeReserve) / (10 ** 18);
            const stablecoinAmount1 = parseFloat(stablecoinReserve) / (10 ** parseInt(stablecoinDecimals));
            
            const price1 = stablecoinAmount1 / nativeAmount1;
            
            // 尝试反过来计算
            const nativeAmount2 = parseFloat(stablecoinReserve) / (10 ** 18);
            const stablecoinAmount2 = parseFloat(nativeReserve) / (10 ** parseInt(stablecoinDecimals));
            
            const price2 = stablecoinAmount2 / nativeAmount2;
            
            // 选择更合理的价格 (通常原生代币价格在$10-$5000之间)
            if (price1 >= 10 && price1 <= 5000) {
              return price1;
            } else if (price2 >= 10 && price2 <= 5000) {
              return price2;
            } else {
              // 如果都不合理，选择价格更高的那个 (不太可能低于$10)
              return Math.max(price1, price2);
            }
          } catch (reserveErr) {
            addLog(`直接获取储备量失败: ${reserveErr.message}`);
          }
        } else {
          // 标准方式获取原生代币价格
          const token0 = await pairContract.methods.token0().call();
          const reserves = await pairContract.methods.getReserves().call();
          
          // 确定哪个储备代表我们的代币和稳定币
          const isNativeToken0 = token0.toLowerCase() === wrappedNativeToken.toLowerCase();
          const nativeReserve = isNativeToken0 ? reserves[0] : reserves[1];
          const stablecoinReserve = isNativeToken0 ? reserves[1] : reserves[0];
          
          // 获取小数位
          const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoinAddress);
          const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
          
          // 计算价格
          const nativeAmount = parseFloat(nativeReserve) / (10 ** 18); // 原生代币几乎都是18位小数
          const stablecoinAmount = parseFloat(stablecoinReserve) / (10 ** parseInt(stablecoinDecimals));
          
          if (nativeAmount > 0) {
            return stablecoinAmount / nativeAmount; // USD每原生代币的价格
          }
        }
      } catch (err) {
        addLog(`获取配对储备量失败: ${err.message}`);
        throw err;
      }
    } 
    else if (dexType === 'UniswapV3') {
      // 对于 UniswapV3/PancakeSwap V3
      if (!window._selectedDex.quoter) {
        throw new Error('V3 Quoter合约地址未设置');
      }
      
      try {
        // 使用两个不同的Quoter ABI
        let quoterContract;
        
        // 先尝试标准QuoterV1
        quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3, window._selectedDex.quoter);
        
        // 1 ETH/BNB = ? USDT
        const amountIn = web3.utils.toWei('1', 'ether'); // 1个原生代币
        
        // 尝试获取价格 - 使用新的多费率尝试函数
        const quotedAmountOut = await tryMultipleFeeTiers(
          quoterContract,
          wrappedNativeToken,
          stablecoinAddress,
          amountIn,
          chainId.toString()
        );
        
        if (quotedAmountOut) {
          const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoinAddress);
          const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
          
          // 计算价格
          const stablecoinAmount = parseFloat(quotedAmountOut) / (10 ** parseInt(stablecoinDecimals));
          return stablecoinAmount; // 原生代币的USD价格
        }
        
        // 如果第一个Quoter失败，尝试QuoterV2 ABI
        addLog('尝试使用QuoterV2获取原生代币价格...');
        quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3_V2, window._selectedDex.quoter);
        
        // 尝试QuoterV2方式
        const quotedAmountOutV2 = await tryMultipleFeeTiers(
          quoterContract,
          wrappedNativeToken,
          stablecoinAddress,
          amountIn,
          chainId.toString()
        );
        
        if (quotedAmountOutV2) {
          const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoinAddress);
          const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
          
          // 计算价格
          const stablecoinAmount = parseFloat(quotedAmountOutV2) / (10 ** parseInt(stablecoinDecimals));
          return stablecoinAmount; // 原生代币的USD价格
        }
      } catch (quoterErr) {
        addLog(`V3 Quoter获取价格失败: ${quoterErr.message}`);
        throw quoterErr;
      }
    }
    
    return null;
  } catch (err) {
    addLog(`获取原生代币价格错误: ${err.message}`);
    return null;
  }
};
// 从稳定币对中获取代币USD价格
const getTokenUSDPriceFromStablePair = async (tokenAddr, tokenDecimals) => {
  try {
    const web3 = window._web3Instance;
    const chainId = await web3.eth.getChainId();
    
    addLog('使用Web3直接调用合约获取稳定币对价格...');
    setPriceMethod('Web3稳定币对');
    
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    
    // 获取所有稳定币地址
    const stablecoins = [];
    if (networkInfo.stablecoins?.USDT) {
      stablecoins.push({
        address: networkInfo.stablecoins.USDT,
        symbol: 'USDT'
      });
    }
    if (networkInfo.stablecoins?.USDC) {
      stablecoins.push({
        address: networkInfo.stablecoins.USDC,
        symbol: 'USDC'
      });
    }
    if (networkInfo.stablecoins?.DAI) {
      stablecoins.push({
        address: networkInfo.stablecoins.DAI,
        symbol: 'DAI'
      });
    }
    if (networkInfo.stablecoins?.BUSD) {
      stablecoins.push({
        address: networkInfo.stablecoins.BUSD,
        symbol: 'BUSD'
      });
    }
    
    if (stablecoins.length === 0) {
      return null;
    }
    
    // 判断使用的DEX类型
    const dexType = window._selectedDex.type || 'UniswapV2';
    
    // 尝试每个稳定币
    for (const stablecoin of stablecoins) {
      try {
        addLog(`尝试与 ${stablecoin.symbol} 的交易对...`);
        
        // 针对不同DEX类型使用不同的获取方式
        if (dexType === 'UniswapV2') {
          // UniswapV2/PancakeSwap V2
          const factoryContract = new web3.eth.Contract(
            INTERNAL_ABIS.FACTORY_V2, 
            window._selectedDex.factory
          );
          
          // 检查工厂合约
          if (!await isMethodAvailable(factoryContract, 'getPair')) {
            addLog('工厂合约没有getPair方法，跳过此稳定币');
            continue;
          }
          
          // 获取配对合约地址
          const pairAddress = await factoryContract.methods.getPair(
            tokenAddr,
            stablecoin.address
          ).call();
          
          if (!pairAddress || pairAddress === '0x0000000000000000000000000000000000000000') {
            addLog(`找不到 ${stablecoin.symbol} 的配对合约地址`);
            continue;
          }
          
          // 检查代码
          const pairCode = await web3.eth.getCode(pairAddress);
          if (pairCode === '0x' || pairCode === '0x0') {
            addLog(`配对合约地址无效: ${pairAddress}`);
            continue;
          }
          
          // 从配对合约获取储备量
          const pairContract = new web3.eth.Contract(INTERNAL_ABIS.PAIR_V2, pairAddress);
          
          try {
            // 验证合约是否实现了需要的方法
            const hasToken0 = await isMethodAvailable(pairContract, 'token0');
            const hasGetReserves = await isMethodAvailable(pairContract, 'getReserves');
            
            if (!hasToken0 || !hasGetReserves) {
              addLog('配对合约不支持标准V2接口方法，尝试直接获取储备量');
              
              // 尝试直接获取储备量
              try {
                const reserves = await pairContract.methods.getReserves().call();
                addLog(`已获取储备量: ${JSON.stringify(reserves)}`);
                
                // 因为我们不知道哪个是哪个，先假设0是目标代币，1是稳定币
                const tokenReserve = reserves[0];
                const stablecoinReserve = reserves[1];
                
                // 获取稳定币小数位
                const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoin.address);
                const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
                
                // 计算价格 (两种可能都尝试)
                const tokenAmount1 = parseFloat(tokenReserve) / (10 ** tokenDecimals);
                const stablecoinAmount1 = parseFloat(stablecoinReserve) / (10 ** parseInt(stablecoinDecimals));
                
                if (tokenAmount1 > 0) {
                  const price1 = stablecoinAmount1 / tokenAmount1;
                  
                  // 尝试反过来计算
                  const tokenAmount2 = parseFloat(stablecoinReserve) / (10 ** tokenDecimals);
                  const stablecoinAmount2 = parseFloat(tokenReserve) / (10 ** parseInt(stablecoinDecimals));
                  
                  const price2 = stablecoinAmount2 / tokenAmount2;
                  
                  // 选择非零、非无穷的价格
                  if (isFinite(price1) && price1 > 0) {
                    return price1.toString();
                  } else if (isFinite(price2) && price2 > 0) {
                    return price2.toString();
                  }
                }
              } catch (reserveErr) {
                addLog(`直接获取储备量失败: ${reserveErr.message}`);
                continue;
              }
            } else {
              // 标准方式获取价格
              const token0 = await pairContract.methods.token0().call();
              const reserves = await pairContract.methods.getReserves().call();
              
              // 确定哪个储备代表我们的代币和稳定币
              const isToken0 = token0.toLowerCase() === tokenAddr.toLowerCase();
              const tokenReserve = isToken0 ? reserves[0] : reserves[1];
              const stablecoinReserve = isToken0 ? reserves[1] : reserves[0];
              
              addLog(`找到配对: ${pairAddress}`);
              addLog(`代币储备: ${tokenReserve}, 稳定币储备: ${stablecoinReserve}`);
              
              // 获取稳定币小数位
              const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoin.address);
              const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
              
              // 计算价格
              const tokenAmount = parseFloat(tokenReserve) / (10 ** tokenDecimals);
              const stablecoinAmount = parseFloat(stablecoinReserve) / (10 ** parseInt(stablecoinDecimals));
              
              if (tokenAmount > 0) {
                return (stablecoinAmount / tokenAmount).toString();
              }
            }
          } catch (err) {
            addLog(`获取配对储备量失败: ${err.message}`);
            continue;
          }
        } 
        else if (dexType === 'UniswapV3') {
          try {
            // 确保有Quoter地址
            if (!window._selectedDex.quoter) {
              addLog('V3 Quoter合约地址未设置');
              continue;
            }
            
            // 首先尝试使用V1 Quoter
            let quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3, window._selectedDex.quoter);
            
            // 尝试获取1个代币的价格
            const amountIn = web3.utils.toBN(10).pow(web3.utils.toBN(tokenDecimals)).toString();
            
            // 使用多费率尝试函数获取价格
            let quotedAmountOut = await tryMultipleFeeTiers(
              quoterContract,
              tokenAddr,
              stablecoin.address,
              amountIn,
              chainId.toString()
            );
            
            // 如果失败，尝试V2 Quoter
            if (!quotedAmountOut) {
              addLog(`尝试使用QuoterV2获取与${stablecoin.symbol}的价格...`);
              quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3_V2, window._selectedDex.quoter);
              
              quotedAmountOut = await tryMultipleFeeTiers(
                quoterContract,
                tokenAddr,
                stablecoin.address,
                amountIn,
                chainId.toString()
              );
            }
            
            if (quotedAmountOut) {
              const stablecoinContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, stablecoin.address);
              const stablecoinDecimals = await stablecoinContract.methods.decimals().call().catch(() => 18);
              
              const price = parseFloat(quotedAmountOut) / (10 ** parseInt(stablecoinDecimals));
              return price.toString();
            }
          } catch (quoteErr) {
            addLog(`V3 Quoter获取价格失败: ${quoteErr.message}`);
            continue;
          }
        }
      } catch (err) {
        addLog(`获取 ${stablecoin.symbol} 交易对价格失败: ${err.message}`);
      }
    }
    
    // 如果所有尝试都失败，返回null
    return null;
  } catch (err) {
    addLog(`从稳定币对获取代币价格错误: ${err.message}`);
    return null;
  }
};
// 通过原生代币获取USD价格
const getTokenUSDPriceViaWrappedNative = async (tokenAddr, tokenDecimals) => {
  try {
    const web3 = window._web3Instance;
    const chainId = await web3.eth.getChainId();

    addLog('尝试通过原生代币路径获取价格...');
    setPriceMethod('Web3原生代币路径');
    
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    
    // 获取原生代币包装地址
    const wrappedNative = networkInfo.contracts.wrappedNative;
    if (!wrappedNative) {
      return null;
    }
    
    // 判断使用的DEX类型
    const dexType = window._selectedDex.type || 'UniswapV2';
    
    if (dexType === 'UniswapV2') {
      // UniswapV2/PancakeSwap V2
      const factoryContract = new web3.eth.Contract(
        INTERNAL_ABIS.FACTORY_V2, 
        window._selectedDex.factory
      );
      
      // 检查工厂合约
      if (!await isMethodAvailable(factoryContract, 'getPair')) {
        addLog('工厂合约没有getPair方法');
        return null;
      }
      
      // 获取代币与原生代币的交易对
      const pairAddress = await factoryContract.methods.getPair(
        tokenAddr,
        wrappedNative
      ).call();
      
      if (!pairAddress || pairAddress === '0x0000000000000000000000000000000000000000') {
        addLog('找不到与原生代币的交易对');
        return null;
      }
      
      // 检查代码
      const pairCode = await web3.eth.getCode(pairAddress);
      if (pairCode === '0x' || pairCode === '0x0') {
        addLog(`配对合约地址无效: ${pairAddress}`);
        return null;
      }
      
      // 从配对合约获取储备量
      const pairContract = new web3.eth.Contract(INTERNAL_ABIS.PAIR_V2, pairAddress);
      
      try {
        // 验证合约是否实现了需要的方法
        const hasToken0 = await isMethodAvailable(pairContract, 'token0');
        const hasGetReserves = await isMethodAvailable(pairContract, 'getReserves');
        
        if (!hasToken0 || !hasGetReserves) {
          addLog('配对合约不支持标准V2接口方法，尝试直接获取储备量');
          
          // 尝试直接获取储备量
          try {
            const reserves = await pairContract.methods.getReserves().call();
            addLog(`已获取储备量: ${JSON.stringify(reserves)}`);
            
            // 因为我们不知道哪个是哪个，先假设0是目标代币，1是原生代币
            const tokenReserve = reserves[0];
            const nativeReserve = reserves[1];
            
            // 获取原生代币的USD价格
            const nativeUsdPrice = await getNativeTokenPrice();
            if (nativeUsdPrice === null) {
              addLog('无法获取原生代币价格');
              return null;
            }
            
            // 计算价格 (两种可能都尝试)
            const tokenAmount1 = parseFloat(tokenReserve) / (10 ** tokenDecimals);
            const nativeAmount1 = parseFloat(nativeReserve) / (10 ** 18);
            
            if (tokenAmount1 > 0 && nativeAmount1 > 0) {
              const price1 = (nativeAmount1 * nativeUsdPrice) / tokenAmount1;
              
              // 尝试反过来计算
              const tokenAmount2 = parseFloat(nativeReserve) / (10 ** tokenDecimals);
              const nativeAmount2 = parseFloat(tokenReserve) / (10 ** 18);
              
              const price2 = (nativeAmount2 * nativeUsdPrice) / tokenAmount2;
              
              // 选择非零、非无穷的价格
              if (isFinite(price1) && price1 > 0) {
                return price1.toString();
              } else if (isFinite(price2) && price2 > 0) {
                return price2.toString();
              }
            }
          } catch (reserveErr) {
            addLog(`直接获取储备量失败: ${reserveErr.message}`);
            return null;
          }
        } else {
          // 标准方式获取价格
          const token0 = await pairContract.methods.token0().call();
          const reserves = await pairContract.methods.getReserves().call();
          
          // 确定哪个储备代表我们的代币和原生代币
          const isToken0 = token0.toLowerCase() === tokenAddr.toLowerCase();
          const tokenReserve = isToken0 ? reserves[0] : reserves[1];
          const nativeReserve = isToken0 ? reserves[1] : reserves[0];
          
          // 获取原生代币的USD价格
          const nativeUsdPrice = await getNativeTokenPrice();
          if (nativeUsdPrice === null) {
            addLog('无法获取原生代币价格');
            return null;
          }
          
          // 计算代币价格
          const tokenAmount = parseFloat(tokenReserve) / (10 ** tokenDecimals);
          const nativeAmount = parseFloat(nativeReserve) / (10 ** 18); // 原生代币几乎都是18位小数
          
          if (tokenAmount > 0 && nativeAmount > 0) {
            // 原生代币价值 / 代币数量 = 代币价格
            const tokenPrice = (nativeAmount * nativeUsdPrice) / tokenAmount;
            return tokenPrice.toString();
          }
        }
      } catch (err) {
        addLog(`获取配对储备量失败: ${err.message}`);
        return null;
      }
    }
    else if (dexType === 'UniswapV3') {
      // 对于V3，使用改进的方法
      if (!window._selectedDex.quoter) {
        addLog('V3 Quoter合约地址未设置');
        return null;
      }
      
      try {
        // 首先尝试使用V1 Quoter
        let quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3, window._selectedDex.quoter);
        
        // 尝试获取1个代币的原生代币价格
        const amountIn = web3.utils.toBN(10).pow(web3.utils.toBN(tokenDecimals)).toString();
        
        // 使用多费率尝试函数获取价格
        let quotedAmountOut = await tryMultipleFeeTiers(
          quoterContract,
          tokenAddr,
          wrappedNative,
          amountIn,
          chainId.toString()
        );
        
        // 如果失败，尝试V2 Quoter
        if (!quotedAmountOut) {
          addLog(`尝试使用QuoterV2获取原生代币路径价格...`);
          quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3_V2, window._selectedDex.quoter);
          
          quotedAmountOut = await tryMultipleFeeTiers(
            quoterContract,
            tokenAddr,
            wrappedNative,
            amountIn,
            chainId.toString()
          );
        }
        
        if (quotedAmountOut) {
          // 获取原生代币USD价格
          const nativeUsdPrice = await getNativeTokenPrice();
          if (nativeUsdPrice === null) {
            addLog('无法获取原生代币价格');
            return null;
          }
          
          const wrappedNativeAmount = parseFloat(quotedAmountOut) / (10 ** 18);
          const tokenPrice = wrappedNativeAmount * nativeUsdPrice;
          return tokenPrice.toString();
        }
      } catch (quoteErr) {
        addLog(`V3 Quoter获取价格失败: ${quoteErr.message}`);
        return null;
      }
    }
    
    return null;
  } catch (err) {
    addLog(`通过原生代币获取价格错误: ${err.message}`);
    return null;
  }
};
// 获取特定池子中的代币价格
const getTokenPriceFromSpecificPool = async (tokenAddr, poolTokenAddr, tokenDecimals, poolTokenDecimals) => {
  try {
    const web3 = window._web3Instance;
    
    addLog(`尝试从指定池子获取价格: 代币=${tokenAddr}, 池子代币=${poolTokenAddr}`);
    setPriceMethod('指定池子价格');
    
    // 判断使用的DEX类型
    const dexType = window._selectedDex.type || 'UniswapV2';
    addLog(`DEX类型: ${dexType}, 工厂合约: ${window._selectedDex.factory}`);
    
    if (dexType === 'UniswapV2') {
      // UniswapV2/PancakeSwap V2
      const factoryContract = new web3.eth.Contract(
        INTERNAL_ABIS.FACTORY_V2, 
        window._selectedDex.factory
      );
      
      // 检查工厂合约
      if (!await isMethodAvailable(factoryContract, 'getPair')) {
        addLog('工厂合约没有getPair方法');
        return null;
      }
      
      // 获取代币与池子代币的交易对
      addLog(`尝试获取交易对: ${tokenAddr} - ${poolTokenAddr}`);
      const pairAddress = await factoryContract.methods.getPair(
        tokenAddr,
        poolTokenAddr
      ).call();
      
      if (!pairAddress || pairAddress === '0x0000000000000000000000000000000000000000') {
        addLog('找不到指定池子交易对');
        return null;
      }
      
      // 检查代码
      const pairCode = await web3.eth.getCode(pairAddress);
      if (pairCode === '0x' || pairCode === '0x0') {
        addLog(`配对合约地址无效: ${pairAddress}`);
        return null;
      }
      
      // 从配对合约获取储备量
      const pairContract = new web3.eth.Contract(INTERNAL_ABIS.PAIR_V2, pairAddress);
      
      try {
        // 验证合约是否实现了需要的方法
        const hasToken0 = await isMethodAvailable(pairContract, 'token0');
        const hasGetReserves = await isMethodAvailable(pairContract, 'getReserves');
        
        if (!hasToken0 || !hasGetReserves) {
          addLog('配对合约不支持标准V2接口方法，尝试直接获取储备量');
          
          // 尝试直接获取储备量
          try {
            const reserves = await pairContract.methods.getReserves().call();
            addLog(`已获取储备量: ${JSON.stringify(reserves)}`);
            
            // 因为我们不知道哪个是哪个，先假设0是目标代币，1是池子代币
            const tokenReserve = reserves[0];
            const poolTokenReserve = reserves[1];
            
            addLog(`尝试假设目标代币在储备0，池子代币在储备1`);
            
            // 计算价格 (只计算比例，不考虑美元价值)
            const tokenAmount = parseFloat(tokenReserve) / (10 ** tokenDecimals);
            const poolTokenAmount = parseFloat(poolTokenReserve) / (10 ** poolTokenDecimals);
            
            if (tokenAmount > 0 && poolTokenAmount > 0) {
              // 如果池子代币是稳定币，则价格就是比例
              if (isStablecoin(poolTokenAddr)) {
                const price = poolTokenAmount / tokenAmount;
                addLog(`计算的稳定币价格: ${price}`);
                return price.toString();
              }
              
              // 如果池子代币是原生代币包装，则需要乘以原生代币价格
              const chainId = await web3.eth.getChainId();
              const networkInfo = NETWORK_CONFIG[chainId.toString()];
              
              if (poolTokenAddr.toLowerCase() === networkInfo.contracts.wrappedNative?.toLowerCase()) {
                const nativePrice = await getNativeTokenPrice();
                if (nativePrice !== null) {
                  const price = (poolTokenAmount * parseFloat(nativePrice)) / tokenAmount;
                  addLog(`计算的原生代币价格: ${price}`);
                  return price.toString();
                }
              }
              
              // 其他情况，尝试获取池子代币价格
              const poolTokenPrice = await getTokenUSDPriceFromStablePair(poolTokenAddr, poolTokenDecimals);
              if (poolTokenPrice !== null) {
                const price = (poolTokenAmount * parseFloat(poolTokenPrice)) / tokenAmount;
                addLog(`计算的其他代币价格: ${price}`);
                return price.toString();
              }
              
              // 最后尝试通过反向假设计算
              addLog(`尝试假设目标代币在储备1，池子代币在储备0`);
              const tokenAmount2 = parseFloat(poolTokenReserve) / (10 ** tokenDecimals);
              const poolTokenAmount2 = parseFloat(tokenReserve) / (10 ** poolTokenDecimals);
              
              if (isStablecoin(poolTokenAddr)) {
                const price = poolTokenAmount2 / tokenAmount2;
                addLog(`计算的稳定币价格(反向): ${price}`);
                return price.toString();
              }
              
              if (poolTokenAddr.toLowerCase() === networkInfo.contracts.wrappedNative?.toLowerCase()) {
                const nativePrice = await getNativeTokenPrice();
                if (nativePrice !== null) {
                  const price = (poolTokenAmount2 * parseFloat(nativePrice)) / tokenAmount2;
                  addLog(`计算的原生代币价格(反向): ${price}`);
                  return price.toString();
                }
              }
            }
          } catch (reserveErr) {
            addLog(`直接获取储备量失败: ${reserveErr.message}`);
            return null;
          }
        } else {
          // 标准方式获取价格
          const token0 = await pairContract.methods.token0().call();
          const reserves = await pairContract.methods.getReserves().call();
          
          // 确定哪个储备代表我们的代币和池子代币
          const isToken0 = token0.toLowerCase() === tokenAddr.toLowerCase();
          const tokenReserve = isToken0 ? reserves[0] : reserves[1];
          const poolTokenReserve = isToken0 ? reserves[1] : reserves[0];
          
          addLog(`找到指定池子配对: ${pairAddress}`);
          addLog(`目标代币储备: ${tokenReserve}, 池子代币储备: ${poolTokenReserve}`);
          
          // 计算目标代币价格
          const tokenAmount = parseFloat(tokenReserve) / (10 ** tokenDecimals);
          const poolTokenAmount = parseFloat(poolTokenReserve) / (10 ** poolTokenDecimals);
          
          if (tokenAmount > 0 && poolTokenAmount > 0) {
            // 如果池子代币是稳定币，则价格就是比例
            if (isStablecoin(poolTokenAddr)) {
              const price = poolTokenAmount / tokenAmount;
              addLog(`计算的稳定币价格: ${price}`);
              return price.toString();
            }
            
            // 如果池子代币是原生代币包装，则需要乘以原生代币价格
            const chainId = await web3.eth.getChainId();
            const networkInfo = NETWORK_CONFIG[chainId.toString()];
            
            if (poolTokenAddr.toLowerCase() === networkInfo.contracts.wrappedNative?.toLowerCase()) {
              const nativePrice = await getNativeTokenPrice();
              if (nativePrice !== null) {
                const price = (poolTokenAmount * parseFloat(nativePrice)) / tokenAmount;
                addLog(`计算的原生代币价格: ${price}`);
                return price.toString();
              }
            }
            
            // 其他情况，尝试获取池子代币价格
            const poolTokenPrice = await getTokenUSDPriceFromStablePair(poolTokenAddr, poolTokenDecimals);
            if (poolTokenPrice !== null) {
              const price = (poolTokenAmount * parseFloat(poolTokenPrice)) / tokenAmount;
              addLog(`计算的其他代币价格: ${price}`);
              return price.toString();
            }
          }
        }
      } catch (err) {
        addLog(`获取配对储备量失败: ${err.message}`);
        return null;
      }
    }
    else if (dexType === 'UniswapV3') {
      // 改进的V3价格获取逻辑
      try {
        if (!window._selectedDex.quoter) {
          addLog('V3 Quoter合约地址未设置');
          return null;
        }
        
        // 获取链ID
        const chainId = await web3.eth.getChainId();
        
        // 首先尝试使用V1 Quoter
        let quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3, window._selectedDex.quoter);
        
        // 尝试获取1个代币的池子代币价格
        const amountIn = web3.utils.toBN(10).pow(web3.utils.toBN(tokenDecimals)).toString();
        
        // 使用多费率尝试函数获取价格
        let quotedAmountOut = await tryMultipleFeeTiers(
          quoterContract,
          tokenAddr,
          poolTokenAddr,
          amountIn,
          chainId.toString()
        );
        
        // 如果失败，尝试V2 Quoter
        if (!quotedAmountOut) {
          addLog(`尝试使用QuoterV2获取指定池子价格...`);
          quoterContract = new web3.eth.Contract(INTERNAL_ABIS.QUOTER_V3_V2, window._selectedDex.quoter);
          
          quotedAmountOut = await tryMultipleFeeTiers(
            quoterContract,
            tokenAddr,
            poolTokenAddr,
            amountIn,
            chainId.toString()
          );
        }
        
        if (quotedAmountOut) {
          // 如果池子代币是稳定币，则直接返回价格
          if (isStablecoin(poolTokenAddr)) {
            const price = parseFloat(quotedAmountOut) / (10 ** poolTokenDecimals);
            addLog(`V3计算的稳定币价格: ${price}`);
            return price.toString();
          }
          
          // 如果池子代币是原生代币包装，则需要乘以原生代币价格
          const networkInfo = NETWORK_CONFIG[chainId.toString()];
          
          if (poolTokenAddr.toLowerCase() === networkInfo.contracts.wrappedNative?.toLowerCase()) {
            const nativePrice = await getNativeTokenPrice();
            if (nativePrice !== null) {
              const wrappedAmount = parseFloat(quotedAmountOut) / (10 ** 18);
              const price = wrappedAmount * parseFloat(nativePrice);
              addLog(`V3计算的原生代币价格: ${price}`);
              return price.toString();
            }
          }
          
          // 其他情况，尝试获取池子代币价格
          const poolTokenPrice = await getTokenUSDPriceFromStablePair(poolTokenAddr, poolTokenDecimals);
          if (poolTokenPrice !== null) {
            const poolAmount = parseFloat(quotedAmountOut) / (10 ** poolTokenDecimals);
            const price = poolAmount * parseFloat(poolTokenPrice);
            addLog(`V3计算的其他代币价格: ${price}`);
            return price.toString();
          }
        }
      } catch (quoteErr) {
        addLog(`V3 Quoter获取价格失败: ${quoteErr.message}`);
        return null;
      }
    }
    
    return null;
  } catch (err) {
    addLog(`从指定池子获取价格错误: ${err.message}`);
    return null;
  }
};
// 获取代币价格
const fetchTokenPrice = async () => {
  if (!tokenInfo || !poolTokenInfo || !window._web3Instance || !window._selectedDex) return null;
  
  try {
    addLog('正在获取代币价格...');
    
    // 首先尝试从指定的池子中获取价格
    const price = await getTokenPriceFromSpecificPool(
      tokenInfo.address, 
      poolTokenInfo.address, 
      tokenInfo.decimals, 
      poolTokenInfo.decimals
    );
    
    if (price !== null) {
      addLog(`成功从指定池子获取价格: $${price}`);
      return price;
    }
    
    addLog('从指定池子获取价格失败，尝试其他方法...');
    
    // 尝试从稳定币对获取价格
    let alternativePrice = await getTokenUSDPriceFromStablePair(tokenInfo.address, tokenInfo.decimals);
    
    // 如果失败，通过原生代币获取
    if (alternativePrice === null) {
      addLog('稳定币对获取失败，尝试通过原生代币路径...');
      alternativePrice = await getTokenUSDPriceViaWrappedNative(tokenInfo.address, tokenInfo.decimals);
    }
    
    if (alternativePrice !== null) {
      addLog(`成功通过备选方法获取价格: $${alternativePrice}`);
    } else {
      addLog('所有方法获取价格均失败');
    }
    
    return alternativePrice;
  } catch (err) {
    addLog(`获取代币价格错误: ${err.message}`);
    return null;
  }
};

// 获取代币信息
const fetchTokenInfo = async (address) => {
  if (!address || !window._web3Instance) {
    return null;
  }

  try {
    const web3 = window._web3Instance;
    
    // 检查是否为有效的合约地址
    if (!web3.utils.isAddress(address)) {
      throw new Error('无效的代币合约地址');
    }
    
    const tokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, address);
    
    // 获取代币基本信息
    const [name, symbol, decimals] = await Promise.all([
      tokenContract.methods.name().call().catch(() => 'Unknown Token'),
      tokenContract.methods.symbol().call().catch(() => 'UNKNOWN'),
      tokenContract.methods.decimals().call().then(d => Number(d)).catch(() => 18)
    ]);
    
    // 获取当前链信息
    const chainId = await web3.eth.getChainId();
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    
    if (!networkInfo) {
      throw new Error('不支援的网络');
    }
    
    // 构建代币信息对象
    const tokenData = {
      address: address,
      name,
      symbol,
      decimals,
      network: networkInfo.name,
      chainId: chainId.toString()
    };
    
    return tokenData;
  } catch (err) {
    addLog(`获取代币信息错误: ${err.message}`);
    return null;
  }
};

// 获取目标代币与底池代币信息以及价格 - 修改为不禁用按钮
const fetchTokensInfoAndPrice = async () => {
  if (!targetTokenAddress || !window._web3Instance || !window._selectedDex) {
    setError('请确保连接了RPC节点、选择了DEX并输入了目标代币合约地址');
    return;
  }

  // 增加当前请求ID
  const currentRequestId = lastRequestIdRef.current + 1;
  lastRequestIdRef.current = currentRequestId;
  setInfoRequestId(currentRequestId);
  
  // 显示获取中状态但不禁用按钮
  setFetchingInfo(true);
  setError('');
  setDebugLogs([]); // 清空日志
  // 重置缓存
  v3PoolCacheRef.current = {};
  feeTierTriedRef.current = {};

  try {
    // 如果已有监控任务在运行，先停止它
    if (isTaskRunning) {
      addLog('检测到监控任务正在运行，自动停止...');
      stopMonitoringTask();
    }
    
    // 获取底池代币地址
    const poolTokenAddr = await getSelectedTokenAddress(poolTokenType, customPoolTokenAddress);
    if (!poolTokenAddr) {
      throw new Error('无效的底池代币');
    }

    addLog(`开始获取代币信息: 目标代币=${targetTokenAddress}, 底池代币=${poolTokenAddr}`);

    // 获取目标代币信息
    const targetTokenData = await fetchTokenInfo(targetTokenAddress);
    if (!targetTokenData) {
      throw new Error('无法获取目标代币信息');
    }
    
    // 检查请求是否已过期
    if (currentRequestId !== lastRequestIdRef.current) {
      addLog('当前请求已过期，不再继续处理');
      return;
    }

    addLog(`目标代币信息: ${targetTokenData.name} (${targetTokenData.symbol}), 小数位: ${targetTokenData.decimals}`);

    // 获取底池代币信息
    let poolTokenData = null;
    if (poolTokenAddr.toLowerCase() === 'native') {
      // 如果是原生代币，使用包装后的地址获取信息
      const web3 = window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const networkInfo = NETWORK_CONFIG[chainId.toString()];
      
      if (!networkInfo || !networkInfo.contracts.wrappedNative) {
        throw new Error('找不到包装后的原生代币地址');
      }
      
      poolTokenData = await fetchTokenInfo(networkInfo.contracts.wrappedNative);
      if (poolTokenData) {
        poolTokenData.isNative = true;
      }
    } else {
      poolTokenData = await fetchTokenInfo(poolTokenAddr);
    }

    // 检查请求是否已过期
    if (currentRequestId !== lastRequestIdRef.current) {
      addLog('当前请求已过期，不再继续处理');
      return;
    }

    if (!poolTokenData) {
      throw new Error('无法获取底池代币信息');
    }

    addLog(`底池代币信息: ${poolTokenData.name} (${poolTokenData.symbol}), 小数位: ${poolTokenData.decimals}`);

    // 更新状态
    setTokenInfo(targetTokenData);
    setPoolTokenInfo(poolTokenData);
    
    // 获取价格
    const price = await getTokenPriceFromSpecificPool(
      targetTokenData.address, 
      poolTokenData.address, 
      targetTokenData.decimals, 
      poolTokenData.decimals
    );
    
    // 检查请求是否已过期
    if (currentRequestId !== lastRequestIdRef.current) {
      addLog('当前请求已过期，不再继续处理');
      return;
    }
    
    let usdPrice = price;
    
    // 如果从指定池子获取失败，尝试其他方法
    if (usdPrice === null) {
      addLog('从指定池子获取价格失败，尝试稳定币对...');
      usdPrice = await getTokenUSDPriceFromStablePair(targetTokenData.address, targetTokenData.decimals);
    }
    
    // 检查请求是否已过期
    if (currentRequestId !== lastRequestIdRef.current) {
      addLog('当前请求已过期，不再继续处理');
      return;
    }
    
    // 如果仍失败，尝试通过原生代币获取价格
    if (usdPrice === null) {
      addLog('无法从稳定币对获取价格，尝试通过原生代币...');
      usdPrice = await getTokenUSDPriceViaWrappedNative(targetTokenData.address, targetTokenData.decimals);
    }
    
    // 检查请求是否已过期
    if (currentRequestId !== lastRequestIdRef.current) {
      addLog('当前请求已过期，不再继续处理');
      return;
    }
    
    // 如果所有方法都失败，则提醒用户
    if (usdPrice === null) {
      addLog('无法获取价格，请确保代币具有足够的流动性');
      usdPrice = '0';
      setPriceMethod('获取失败');
    }
    
    addLog(`代币价格: $${usdPrice}`);
    addLog(`价格获取方法: ${priceMethod}`);
    
    setCurrentPrice(usdPrice);
    setFormattedPrice(formatTokenPrice(usdPrice));
    
    // 显示通知
    setSnackbar({
      open: true,
      message: `成功获取 ${targetTokenData.symbol} 代币信息，当前价格: ${formatTokenPrice(usdPrice)}，方法: ${priceMethod}`,
      severity: 'success'
    });
    
  } catch (err) {
    addLog(`获取代币信息失败: ${err.message}`);
    setError(`获取代币信息失败: ${err.message}`);
    
    // 只有当请求未过期时才更新状态
    if (currentRequestId === lastRequestIdRef.current) {
      // 保持旧的代币信息，只在获取成功时才更新
      setCurrentPrice(null);
      setFormattedPrice('$0.00000000');
      setPriceMethod('');
      
      setSnackbar({
        open: true,
        message: `获取代币信息失败: ${err.message}`,
        severity: 'error'
      });
    }
  } finally {
    if (mountedRef.current && currentRequestId === lastRequestIdRef.current) {
      setFetchingInfo(false);
    }
  }
};
// 获取代币余额
const getTokenBalance = async (tokenAddr, walletAddr) => {
  try {
    const web3 = window._web3Instance;
    
    if (tokenAddr.toLowerCase() === 'native') {
      // 原生代币 (ETH, BNB等)
      const balance = await web3.eth.getBalance(walletAddr);
      return balance;
    } else {
      // ERC20代币
      const tokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, tokenAddr);
      const balance = await tokenContract.methods.balanceOf(walletAddr).call();
      return balance;
    }
  } catch (err) {
    addLog(`获取余额错误: ${err.message}`);
    return '0';
  }
};

// 检查当前任务是否应该停止
const checkStopCondition = async () => {
  if (!targetMultiple || targetMultiple === '0') return false;
  
  try {
    const initial = initialBalancesRef.current;
    const current = currentBalancesRef.current;
    
    // 如果初始余额为0，无法计算倍数
    if (initial.output === '0') return false;
    
    // 计算当前倍数
    const multiple = parseFloat(current.output) / parseFloat(initial.output);
    
    // 如果达到目标倍数，停止任务
    if (multiple >= parseFloat(targetMultiple)) {
      setTaskStatus(`已达到目标${targetMultiple}倍收益，任务停止`);
      return true;
    }
    
    return false;
  } catch (err) {
    addLog(`检查停止条件错误: ${err.message}`);
    return false;
  }
};

// 启动市值监控任务
const startMonitoringTask = async () => {
  if (!window._web3Instance || !window._selectedDex || !tokenInfo || !poolTokenInfo) {
    setError('请确保连接了RPC节点、选择了DEX并获取了代币信息');
    return;
  }
  
  if (!buyThreshold && !sellThreshold) {
    setError('请至少设置一个买入或卖出阈值');
    return;
  }
  
  if ((buyThreshold && !buyAmount) || (sellThreshold && !sellAmount)) {
    setError('请设置买入/卖出金额');
    return;
  }
  
  if (!window._userAddresses || window._userAddresses.length === 0) {
    setError('请先导入钱包');
    return;
  }

  setIsTaskRunning(true);
  setTaskStatus('任务启动中...');
  setError('');
  addLog('开始监控任务');
  
  try {
    const web3 = window._web3Instance;
    
    // 根据选择获取输入/输出代币地址
    const inputTokenAddress = await getSelectedTokenAddress(inputToken, customInputTokenAddress);
    const outputTokenAddress = tokenInfo.address; // 输出代币始终是监控的代币
    
    if (!inputTokenAddress) {
      throw new Error('无效的输入代币');
    }
    
    // 处理防夹子RPC
    if (useAntiSandwich && antiSandwichRPC) {
      const switched = await switchToAntiSandwichRPC();
      if (switched) {
        addLog(`已切换到防夹子RPC: ${antiSandwichRPC}`);
      } else {
        addLog('切换到防夹子RPC失败，将使用原始RPC');
      }
    }
    
    // 初始化余额参考
    initialBalancesRef.current = {
      input: '0',
      output: '0'
    };
    
    // 获取所有选定钱包的初始余额
    for (const address of window._userAddresses) {
      const inputBalance = await getTokenBalance(inputTokenAddress, address);
      const outputBalance = await getTokenBalance(outputTokenAddress, address);
      
      initialBalancesRef.current.input = web3.utils.toBN(initialBalancesRef.current.input)
        .add(web3.utils.toBN(inputBalance))
        .toString();
      
      initialBalancesRef.current.output = web3.utils.toBN(initialBalancesRef.current.output)
        .add(web3.utils.toBN(outputBalance))
        .toString();
    }
    
    addLog(`初始余额 - 输入: ${initialBalancesRef.current.input}, 输出: ${initialBalancesRef.current.output}`);
    
    // 复制初始余额到当前余额
    currentBalancesRef.current = {
      ...initialBalancesRef.current
    };
    
    // 获取当前链的区块时间
    const chainId = await web3.eth.getChainId();
    const blockTime = getBlockTimeByChainId(chainId.toString());
    addLog(`使用链ID ${chainId} 的区块时间 ${blockTime}毫秒 设置任务检查间隔`);
    
    // 启动监控循环 - 使用区块时间作为检查间隔
    taskIntervalRef.current = setInterval(async () => {
      if (!mountedRef.current) {
        clearInterval(taskIntervalRef.current);
        return;
      }
      
      try {
        // 检查目标代币是否发生了变化
        if (tokenInfo.address.toLowerCase() !== targetTokenAddress.toLowerCase() && targetTokenAddress) {
          addLog(`检测到代币地址变更，停止当前任务`);
          clearInterval(taskIntervalRef.current);
          setIsTaskRunning(false);
          setTaskStatus('检测到代币地址变更，任务已停止');
          return;
        }
        
        // 获取当前价格
        const price = await fetchTokenPrice();
        if (price === null) {
          setTaskStatus(`无法获取当前价格，重试中... (上次尝试方法: ${priceMethod || '未知'})`);
          return;
        }
        
        setCurrentPrice(price);
        setFormattedPrice(formatTokenPrice(price));
        setTaskStatus(`当前价格: ${formatTokenPrice(price)}，获取方法: ${priceMethod}`);
        
        const priceFloat = parseFloat(price);
        
        // 检查是否达到买入条件
        if (buyThreshold && priceFloat <= parseFloat(buyThreshold)) {
          addLog(`价格 ${formatTokenPrice(price)} 低于买入阈值 $${buyThreshold}，执行买入`);
          await executeBuyOrder();
        }
        
        // 检查是否达到卖出条件
        if (sellThreshold && priceFloat >= parseFloat(sellThreshold)) {
          addLog(`价格 ${formatTokenPrice(price)} 高于卖出阈值 $${sellThreshold}，执行卖出`);
          await executeSellOrder();
        }
        
        // 更新当前余额并检查停止条件
        for (const address of window._userAddresses) {
          const outputBalance = await getTokenBalance(outputTokenAddress, address);
          currentBalancesRef.current.output = outputBalance;
        }
        
        // 检查是否应该停止任务
        const shouldStop = await checkStopCondition();
        if (shouldStop) {
          clearInterval(taskIntervalRef.current);
          setIsTaskRunning(false);
        }
        
      } catch (err) {
        addLog(`监控任务错误: ${err.message}`);
        setTaskStatus(`执行错误: ${err.message}`);
      }
    }, blockTime); // 使用区块时间作为检查间隔
    
    setSnackbar({
      open: true,
      message: '市值监控任务已启动',
      severity: 'success'
    });
    
  } catch (err) {
    addLog(`启动任务错误: ${err.message}`);
    setError(`启动任务失败: ${err.message}`);
    setIsTaskRunning(false);
    setTaskStatus('');
    
    setSnackbar({
      open: true,
      message: `启动任务失败: ${err.message}`,
      severity: 'error'
    });
  }
};

// 停止监控任务
const stopMonitoringTask = () => {
  if (taskIntervalRef.current) {
    clearInterval(taskIntervalRef.current);
    taskIntervalRef.current = null;
  }
  
  // 如果使用了防夹子RPC，恢复原始RPC
  if (originalRpcRef.current) {
    restoreOriginalRPC();
    addLog('已恢复原始RPC');
  }
  
  setIsTaskRunning(false);
  setTaskStatus('任务已停止');
  addLog('监控任务已停止');
  
  setSnackbar({
    open: true,
    message: '市值监控任务已停止',
    severity: 'info'
  });
};
// 执行买入订单
const executeBuyOrder = async () => {
  try {
    setTaskStatus('执行买入订单...');
    addLog('开始执行买入订单');
    
    const web3 = window._web3Instance;
    const dexType = window._selectedDex.type || 'UniswapV2';
    
    // 获取代币信息
    const inputTokenAddress = await getSelectedTokenAddress(inputToken, customInputTokenAddress);
    const outputTokenAddress = tokenInfo.address;
    
    // 计算买入金额
    let buyAmountValue;
    if (buyAmountType === 'percent') {
      // 百分比模式，计算用户代币余额的百分比
      const percent = parseFloat(buyAmount) / 100;
      
      // 针对每个钱包计算并执行交易
      for (const walletAddress of window._userAddresses) {
        let walletBalance;
        
        if (inputTokenAddress.toLowerCase() === 'native') {
          // 原生代币，保留一些Gas费用
          const fullBalance = await web3.eth.getBalance(walletAddress);
          const gasReserve = web3.utils.toWei('0.01', 'ether'); // 保留0.01用于Gas
          walletBalance = web3.utils.toBN(fullBalance).gt(web3.utils.toBN(gasReserve)) ? 
                        web3.utils.toBN(fullBalance).sub(web3.utils.toBN(gasReserve)).toString() : 
                        '0';
        } else {
          walletBalance = await getTokenBalance(inputTokenAddress, walletAddress);
        }
        
        buyAmountValue = web3.utils.toBN(walletBalance)
          .mul(web3.utils.toBN(Math.floor(percent * 1000)))
          .div(web3.utils.toBN(1000))
          .toString();
        
        if (buyAmountValue === '0') {
          addLog(`钱包 ${walletAddress} 余额为0，跳过`);
          continue;
        }
        
        addLog(`钱包 ${walletAddress} 使用${percent * 100}%余额买入，金额: ${buyAmountValue}`);
        
        // 执行单个钱包的交易
        if (dexType === 'UniswapV2') {
          await executeSingleWalletBuyV2(
            walletAddress,
            inputTokenAddress,
            outputTokenAddress,
            buyAmountValue
          );
        } else if (dexType === 'UniswapV3') {
          await executeSingleWalletBuyV3(
            walletAddress,
            inputTokenAddress,
            outputTokenAddress,
            buyAmountValue
          );
        }
      }
    } else {
      // 固定金额模式
      if (inputTokenAddress.toLowerCase() === 'native') {
        // 如果是原生代币，直接使用ether单位
        buyAmountValue = web3.utils.toWei(buyAmount, 'ether');
      } else {
        // 如果是ERC20代币，需要获取小数位数
        const inputTokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, inputTokenAddress);
        const decimals = await inputTokenContract.methods.decimals().call();
        buyAmountValue = web3.utils.toBN(Math.floor(parseFloat(buyAmount) * (10 ** parseInt(decimals)))).toString();
      }
      
      addLog(`使用固定金额买入: ${buyAmount} (实际值: ${buyAmountValue})`);
      
      // 针对每个钱包执行交易
      for (const walletAddress of window._userAddresses) {
        if (dexType === 'UniswapV2') {
          await executeSingleWalletBuyV2(
            walletAddress,
            inputTokenAddress,
            outputTokenAddress,
            buyAmountValue
          );
        } else if (dexType === 'UniswapV3') {
          await executeSingleWalletBuyV3(
            walletAddress,
            inputTokenAddress,
            outputTokenAddress,
            buyAmountValue
          );
        }
      }
    }
    
    setTaskStatus('买入订单执行完成');
    addLog('买入订单执行完成');
    
  } catch (err) {
    addLog(`买入订单错误: ${err.message}`);
    setTaskStatus(`买入订单执行失败: ${err.message}`);
    
    setSnackbar({
      open: true,
      message: `买入订单执行失败: ${err.message}`,
      severity: 'error'
    });
  }
};

// 执行单个钱包的V2买入交易
const executeSingleWalletBuyV2 = async (walletAddress, inputTokenAddress, outputTokenAddress, amount) => {
  try {
    addLog(`钱包 ${walletAddress} 开始执行V2买入交易`);
    const web3 = window._web3Instance;
    
    // 获取Router合约
    const router = new web3.eth.Contract(
      INTERNAL_ABIS.ROUTER_V2,
      window._selectedDex.router
    );
    
    // 检查Router合约方法
    if (inputTokenAddress.toLowerCase() === 'native') {
      if (!await isMethodAvailable(router, 'swapExactETHForTokens')) {
        addLog('Router合约不支持swapExactETHForTokens方法');
        throw new Error('路由合约不支持所需的交换方法');
      }
    } else {
      if (!await isMethodAvailable(router, 'swapExactTokensForTokens')) {
        addLog('Router合约不支持swapExactTokensForTokens方法');
        throw new Error('路由合约不支持所需的交换方法');
      }
    }
    
    // 设置交易参数
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20分钟后过期
    
    let tx;
    
    // 检查是否需要批准代币授权
    if (inputTokenAddress.toLowerCase() !== 'native') {
      // ERC20代币需要先授权Router合约
      const inputTokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, inputTokenAddress);
      const allowance = await inputTokenContract.methods.allowance(
        walletAddress,
        window._selectedDex.router
      ).call();
      
      if (web3.utils.toBN(allowance).lt(web3.utils.toBN(amount))) {
        // 授权金额不足，需要增加授权
        addLog(`需要授权代币使用权给路由合约`);
        const approveTx = await inputTokenContract.methods.approve(
          window._selectedDex.router,
          web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()
        ).send({
          from: walletAddress,
          gas: 50000
        });
        
        addLog(`授权交易已完成: ${approveTx.transactionHash}`);
      } else {
        addLog('代币已有足够授权');
      }
      
      // 执行代币兑换交易
      addLog(`执行代币到代币交换: ${amount} ${inputTokenAddress} → ${outputTokenAddress}`);
      tx = await router.methods.swapExactTokensForTokens(
        amount,
        0, // minAmountOut设为0，不关心滑点
        [inputTokenAddress, outputTokenAddress],
        walletAddress,
        deadline
      ).send({
        from: walletAddress,
        gas: 200000
      });
    } else {
      // 获取包装后的原生代币地址
      const chainId = await web3.eth.getChainId();
      const networkInfo = NETWORK_CONFIG[chainId.toString()];
      const wrappedNativeToken = networkInfo.contracts.wrappedNative;
      
      if (!wrappedNativeToken) {
        throw new Error('找不到包装后的原生代币地址');
      }
      
      // 原生代币直接使用swapExactETHForTokens
      addLog(`执行原生代币到代币交换: ${amount} 原生代币 → ${outputTokenAddress}`);
      tx = await router.methods.swapExactETHForTokens(
        0, // minAmountOut设为0，不关心滑点
        [wrappedNativeToken, outputTokenAddress],
        walletAddress,
        deadline
      ).send({
        from: walletAddress,
        value: amount,
        gas: 200000
      });
    }
    
    addLog(`买入交易已完成: ${tx.transactionHash}`);
    
    // 记录交易历史
    addTransactionToHistory({
      type: 'BUY (V2)',
      hash: tx.transactionHash,
      amount: amount,
      wallet: walletAddress,
      timestamp: Date.now()
    });
    
    return tx;
  } catch (err) {
    addLog(`V2买入错误: ${err.message}`);
    throw err;
  }
};
// 执行单个钱包的V3买入交易
const executeSingleWalletBuyV3 = async (walletAddress, inputTokenAddress, outputTokenAddress, amount) => {
  try {      
    addLog(`钱包 ${walletAddress} 开始执行V3买入交易`);
    const web3 = window._web3Instance;
    
    // 检查是否设置了V3 SwapRouter
    if (!window._selectedDex.router) {
      throw new Error('未设置V3 SwapRouter');
    }
    
    // 获取当前链ID和对应的费率信息
    const chainId = await web3.eth.getChainId();
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    const feeTiers = networkInfo?.v3FeeTiers || [100, 500, 2500, 10000];
    
    // 确定使用的费率 - 先尝试从缓存获取
    let usedFeeTier = v3FeeTier; // 默认使用当前选择的费率
    
    // 获取缓存的成功费率
    const pairKey = `${inputTokenAddress.toLowerCase()}-${outputTokenAddress.toLowerCase()}`;
    if (v3PoolCacheRef.current[pairKey]) {
      usedFeeTier = v3PoolCacheRef.current[pairKey];
      addLog(`使用缓存的费率: ${usedFeeTier}`);
    }
    
    // 获取V3 Router合约
    const router = new web3.eth.Contract(
      INTERNAL_ABIS.ROUTER_V3,
      window._selectedDex.router
    );
    
    // 检查Router合约方法
    if (!await isMethodAvailable(router, 'exactInputSingle')) {
      addLog('Router合约不支持exactInputSingle方法');
      throw new Error('V3路由合约不支持所需的交换方法');
    }
    
    let tx;
    
    // 处理原生代币和ERC20代币的不同情况
    if (inputTokenAddress.toLowerCase() !== 'native') {
      // ERC20代币需要先授权Router合约
      const inputTokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, inputTokenAddress);
      const allowance = await inputTokenContract.methods.allowance(
        walletAddress,
        window._selectedDex.router
      ).call();
      
      if (web3.utils.toBN(allowance).lt(web3.utils.toBN(amount))) {
        // 授权金额不足，需要增加授权
        addLog(`需要授权代币使用权给V3路由合约`);
        const approveTx = await inputTokenContract.methods.approve(
          window._selectedDex.router,
          web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()
        ).send({
          from: walletAddress,
          gas: 50000
        });
        
        addLog(`授权交易已完成: ${approveTx.transactionHash}`);
      } else {
        addLog('代币已有足够授权');
      }
      
      // 设置V3交易参数
      const params = {
        tokenIn: inputTokenAddress,
        tokenOut: outputTokenAddress,
        fee: usedFeeTier,
        recipient: walletAddress,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20分钟后过期
        amountIn: amount,
        amountOutMinimum: 0, // 不设最小输出
        sqrtPriceLimitX96: 0 // 不限制价格
      };
      
      // 执行V3交易
      addLog(`执行V3代币到代币交换: ${amount} ${inputTokenAddress} → ${outputTokenAddress} (费率: ${usedFeeTier})`);
      try {
        tx = await router.methods.exactInputSingle(params).send({
          from: walletAddress,
          gas: 350000
        });
      } catch (error) {
        // 如果交易失败且有缓存的费率，尝试其他费率
        if (v3PoolCacheRef.current[pairKey]) {
          addLog(`使用缓存费率失败 (${usedFeeTier}): ${error.message}`);
          delete v3PoolCacheRef.current[pairKey]; // 清除缓存
          
          // 尝试其他费率
          for (const fee of feeTiers) {
            if (fee === usedFeeTier) continue; // 跳过已尝试的费率
            
            addLog(`尝试其他费率: ${fee}`);
            params.fee = fee;
            
            try {
              tx = await router.methods.exactInputSingle(params).send({
                from: walletAddress,
                gas: 350000
              });
              
              // 交易成功，缓存该费率
              v3PoolCacheRef.current[pairKey] = fee;
              addLog(`使用费率 ${fee} 交易成功`);
              break;
            } catch (subError) {
              addLog(`费率 ${fee} 交易失败: ${subError.message}`);
            }
          }
          
          // 如果所有费率都失败，则抛出错误
          if (!tx) {
            throw new Error('所有费率尝试均失败');
          }
        } else {
          throw error; // 没有缓存费率，直接抛出原始错误
        }
      }
    } else {
      // 获取包装后的原生代币地址
      const wrappedNativeToken = networkInfo.contracts.wrappedNative;
      
      if (!wrappedNativeToken) {
        throw new Error('找不到包装后的原生代币地址');
      }
      
      // 设置V3交易参数
      const params = {
        tokenIn: wrappedNativeToken,
        tokenOut: outputTokenAddress,
        fee: usedFeeTier,
        recipient: walletAddress,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20,
        amountIn: amount,
        amountOutMinimum: 0,
        sqrtPriceLimitX96: 0
      };
      
      // 执行V3交易
      addLog(`执行V3原生代币到代币交换: ${amount} 原生代币 → ${outputTokenAddress} (费率: ${usedFeeTier})`);
      try {
        tx = await router.methods.exactInputSingle(params).send({
          from: walletAddress,
          value: amount,
          gas: 350000
        });
      } catch (error) {
        // 如果交易失败且有缓存的费率，尝试其他费率
        if (v3PoolCacheRef.current[pairKey]) {
          addLog(`使用缓存费率失败 (${usedFeeTier}): ${error.message}`);
          delete v3PoolCacheRef.current[pairKey]; // 清除缓存
          
          // 尝试其他费率
          for (const fee of feeTiers) {
            if (fee === usedFeeTier) continue; // 跳过已尝试的费率
            
            addLog(`尝试其他费率: ${fee}`);
            params.fee = fee;
            
            try {
              tx = await router.methods.exactInputSingle(params).send({
                from: walletAddress,
                value: amount,
                gas: 350000
              });
              
              // 交易成功，缓存该费率
              v3PoolCacheRef.current[pairKey] = fee;
              addLog(`使用费率 ${fee} 交易成功`);
              break;
            } catch (subError) {
              addLog(`费率 ${fee} 交易失败: ${subError.message}`);
            }
          }
          
          // 如果所有费率都失败，则抛出错误
          if (!tx) {
            throw new Error('所有费率尝试均失败');
          }
        } else {
          throw error; // 没有缓存费率，直接抛出原始错误
        }
      }
    }
    
    addLog(`V3买入交易已完成: ${tx.transactionHash}`);
    
    // 记录交易历史
    addTransactionToHistory({
      type: 'BUY (V3)',
      hash: tx.transactionHash,
      amount: amount,
      wallet: walletAddress,
      timestamp: Date.now()
    });
    
    return tx;
  } catch (err) {
    addLog(`V3买入错误: ${err.message}`);
    throw err;
  }
};
// 执行卖出订单
const executeSellOrder = async () => {
  try {
    setTaskStatus('执行卖出订单...');
    addLog('开始执行卖出订单');
    
    const web3 = window._web3Instance;
    const dexType = window._selectedDex.type || 'UniswapV2';
    
    // 获取代币信息
    const inputTokenAddress = tokenInfo.address;
    const outputTokenAddress = await getSelectedTokenAddress(outputToken, customOutputTokenAddress);
    
    if (!outputTokenAddress) {
      throw new Error('无效的输出代币');
    }
    
    // 针对每个钱包计算并执行交易
    for (const walletAddress of window._userAddresses) {
      let sellAmountValue;
      
      if (sellAmountType === 'percent') {
        // 百分比模式，计算用户代币余额的百分比
        const percent = parseFloat(sellAmount) / 100;
        const walletBalance = await getTokenBalance(inputTokenAddress, walletAddress);
        sellAmountValue = web3.utils.toBN(walletBalance)
          .mul(web3.utils.toBN(Math.floor(percent * 1000)))
          .div(web3.utils.toBN(1000))
          .toString();
        
        addLog(`钱包 ${walletAddress} 使用${percent * 100}%余额卖出，金额: ${sellAmountValue}`);
      } else {
        // 固定金额模式，将以代币的小数位数为准
        const decimals = tokenInfo.decimals;
        sellAmountValue = web3.utils.toBN(Math.floor(parseFloat(sellAmount) * (10 ** decimals))).toString();
        
        addLog(`钱包 ${walletAddress} 使用固定金额卖出: ${sellAmount} (实际值: ${sellAmountValue})`);
      }
      
      // 跳过余额为0的钱包
      if (sellAmountValue === '0') {
        addLog(`钱包 ${walletAddress} 余额为0，跳过`);
        continue;
      }
      
      // 执行单个钱包的交易
      if (dexType === 'UniswapV2') {
        await executeSingleWalletSellV2(
          walletAddress,
          inputTokenAddress,
          outputTokenAddress,
          sellAmountValue
        );
      } else if (dexType === 'UniswapV3') {
        await executeSingleWalletSellV3(
          walletAddress,
          inputTokenAddress,
          outputTokenAddress,
          sellAmountValue
        );
      }
    }
    
    setTaskStatus('卖出订单执行完成');
    addLog('卖出订单执行完成');
    
  } catch (err) {
    addLog(`卖出订单错误: ${err.message}`);
    setTaskStatus(`卖出订单执行失败: ${err.message}`);
    
    setSnackbar({
      open: true,
      message: `卖出订单执行失败: ${err.message}`,
      severity: 'error'
    });
  }
};

// 执行单个钱包的V2卖出交易
const executeSingleWalletSellV2 = async (walletAddress, inputTokenAddress, outputTokenAddress, amount) => {
  try {
    addLog(`钱包 ${walletAddress} 开始执行V2卖出交易`);
    const web3 = window._web3Instance;
    
    // 获取Router合约
    const router = new web3.eth.Contract(
      INTERNAL_ABIS.ROUTER_V2,
      window._selectedDex.router
    );
    
    // 设置交易参数
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20分钟后过期
    
    // 检查是否需要批准代币授权
    const inputTokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, inputTokenAddress);
    const allowance = await inputTokenContract.methods.allowance(
      walletAddress,
      window._selectedDex.router
    ).call();
    
    if (web3.utils.toBN(allowance).lt(web3.utils.toBN(amount))) {
      // 授权金额不足，需要增加授权
      addLog(`需要授权代币使用权给路由合约`);
      const approveTx = await inputTokenContract.methods.approve(
        window._selectedDex.router,
        web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()
      ).send({
        from: walletAddress,
        gas: 50000
      });
      
      addLog(`授权交易已完成: ${approveTx.transactionHash}`);
    } else {
      addLog('代币已有足够授权');
    }
    
    let tx;
    
    // 获取包装后的原生代币地址
    const chainId = await web3.eth.getChainId();
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    const wrappedNativeToken = networkInfo.contracts.wrappedNative;
    
    if (!wrappedNativeToken) {
      throw new Error('找不到包装后的原生代币地址');
    }
    
    if (outputTokenAddress.toLowerCase() === 'native') {
      // 检查Router合约方法
      if (!await isMethodAvailable(router, 'swapExactTokensForETH')) {
        addLog('Router合约不支持swapExactTokensForETH方法');
        throw new Error('路由合约不支持所需的交换方法');
      }
      
      // 卖出为原生代币
      addLog(`执行代币到原生代币交换: ${amount} ${inputTokenAddress} → 原生代币`);
      tx = await router.methods.swapExactTokensForETH(
        amount,
        0, // minAmountOut设为0，不关心滑点
        [inputTokenAddress, wrappedNativeToken],
        walletAddress,
        deadline
      ).send({
        from: walletAddress,
        gas: 200000
      });
    } else {
      // 检查Router合约方法
      if (!await isMethodAvailable(router, 'swapExactTokensForTokens')) {
        addLog('Router合约不支持swapExactTokensForTokens方法');
        throw new Error('路由合约不支持所需的交换方法');
      }
      
      // 卖出为ERC20代币
      addLog(`执行代币到代币交换: ${amount} ${inputTokenAddress} → ${outputTokenAddress}`);
      tx = await router.methods.swapExactTokensForTokens(
        amount,
        0, // minAmountOut设为0，不关心滑点
        [inputTokenAddress, outputTokenAddress],
        walletAddress,
        deadline
      ).send({
        from: walletAddress,
        gas: 200000
      });
    }
    
    addLog(`卖出交易已完成: ${tx.transactionHash}`);
    
    // 记录交易历史
    addTransactionToHistory({
      type: 'SELL (V2)',
      hash: tx.transactionHash,
      amount: amount,
      wallet: walletAddress,
      timestamp: Date.now()
    });
    
    return tx;
  } catch (err) {
    addLog(`V2卖出错误: ${err.message}`);
    throw err;
  }
};
// 执行单个钱包的V3卖出交易
const executeSingleWalletSellV3 = async (walletAddress, inputTokenAddress, outputTokenAddress, amount) => {
  try {
    addLog(`钱包 ${walletAddress} 开始执行V3卖出交易`);
    const web3 = window._web3Instance;
    
    // 检查是否设置了V3 SwapRouter
    if (!window._selectedDex.router) {
      throw new Error('未设置V3 SwapRouter');
    }
    
    // 获取当前链ID和对应的费率信息
    const chainId = await web3.eth.getChainId();
    const networkInfo = NETWORK_CONFIG[chainId.toString()];
    const feeTiers = networkInfo?.v3FeeTiers || [100, 500, 2500, 10000];
    
    // 确定使用的费率 - 先尝试从缓存获取
    let usedFeeTier = v3FeeTier; // 默认使用当前选择的费率
    
    // 计算缓存键
    let outputAddr = outputTokenAddress;
    if (outputAddr.toLowerCase() === 'native') {
      outputAddr = networkInfo.contracts.wrappedNative;
    }
    
    // 获取缓存的成功费率
    const pairKey = `${inputTokenAddress.toLowerCase()}-${outputAddr.toLowerCase()}`;
    if (v3PoolCacheRef.current[pairKey]) {
      usedFeeTier = v3PoolCacheRef.current[pairKey];
      addLog(`使用缓存的费率: ${usedFeeTier}`);
    }
    
    // 获取V3 Router合约
    const router = new web3.eth.Contract(
      INTERNAL_ABIS.ROUTER_V3,
      window._selectedDex.router
    );
    
    // 检查Router合约方法
    if (!await isMethodAvailable(router, 'exactInputSingle')) {
      addLog('Router合约不支持exactInputSingle方法');
      throw new Error('V3路由合约不支持所需的交换方法');
    }
    
    // 检查是否需要批准代币授权
    const inputTokenContract = new web3.eth.Contract(INTERNAL_ABIS.ERC20, inputTokenAddress);
    const allowance = await inputTokenContract.methods.allowance(
      walletAddress,
      window._selectedDex.router
    ).call();
    
    if (web3.utils.toBN(allowance).lt(web3.utils.toBN(amount))) {
      // 授权金额不足，需要增加授权
      addLog(`需要授权代币使用权给V3路由合约`);
      const approveTx = await inputTokenContract.methods.approve(
        window._selectedDex.router,
        web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()
      ).send({
        from: walletAddress,
        gas: 50000
      });
      
      addLog(`授权交易已完成: ${approveTx.transactionHash}`);
    } else {
      addLog('代币已有足够授权');
    }
    
    let tx;
    
    // 获取包装后的原生代币地址
    const wrappedNativeToken = networkInfo.contracts.wrappedNative;
    
    if (!wrappedNativeToken) {
      throw new Error('找不到包装后的原生代币地址');
    }
    
    // 处理不同的输出代币类型
    let actualOutputToken = outputTokenAddress;
    if (outputTokenAddress.toLowerCase() === 'native') {
      actualOutputToken = wrappedNativeToken;
    }
    
    // 设置V3交易参数
    const params = {
      tokenIn: inputTokenAddress,
      tokenOut: actualOutputToken,
      fee: usedFeeTier,
      recipient: walletAddress,
      deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20分钟后过期
      amountIn: amount,
      amountOutMinimum: 0, // 不设最小输出
      sqrtPriceLimitX96: 0 // 不限制价格
    };
    
    // 执行V3交易
    addLog(`执行V3代币到${outputTokenAddress.toLowerCase() === 'native' ? '原生代币' : '代币'}交换: ${amount} ${inputTokenAddress} → ${params.tokenOut} (费率: ${usedFeeTier})`);
    
    try {
      tx = await router.methods.exactInputSingle(params).send({
        from: walletAddress,
        gas: 350000
      });
    } catch (error) {
      // 如果交易失败且有缓存的费率，尝试其他费率
      if (v3PoolCacheRef.current[pairKey]) {
        addLog(`使用缓存费率失败 (${usedFeeTier}): ${error.message}`);
        delete v3PoolCacheRef.current[pairKey]; // 清除缓存
        
        // 尝试其他费率
        for (const fee of feeTiers) {
          if (fee === usedFeeTier) continue; // 跳过已尝试的费率
          
          addLog(`尝试其他费率: ${fee}`);
          params.fee = fee;
          
          try {
            tx = await router.methods.exactInputSingle(params).send({
              from: walletAddress,
              gas: 350000
            });
            
            // 交易成功，缓存该费率
            v3PoolCacheRef.current[pairKey] = fee;
            addLog(`使用费率 ${fee} 交易成功`);
            break;
          } catch (subError) {
            addLog(`费率 ${fee} 交易失败: ${subError.message}`);
          }
        }
        
        // 如果所有费率都失败，则抛出错误
        if (!tx) {
          throw new Error('所有费率尝试均失败');
        }
      } else if (error.message.includes('pool') || error.message.includes('liquidity')) {
        // 尝试其他费率
        addLog(`交易失败，尝试其他费率: ${error.message}`);
        
        for (const fee of feeTiers) {
          if (fee === usedFeeTier) continue; // 跳过已尝试的费率
          
          addLog(`尝试费率: ${fee}`);
          params.fee = fee;
          
          try {
            tx = await router.methods.exactInputSingle(params).send({
              from: walletAddress,
              gas: 350000
            });
            
            // 交易成功，缓存该费率
            v3PoolCacheRef.current[pairKey] = fee;
            addLog(`使用费率 ${fee} 交易成功`);
            break;
          } catch (subError) {
            addLog(`费率 ${fee} 交易失败: ${subError.message}`);
          }
        }
        
        // 如果所有费率都失败，则抛出错误
        if (!tx) {
          throw new Error('所有费率尝试均失败');
        }
      } else {
        throw error; // 其他错误，直接抛出
      }
    }
    
    addLog(`V3卖出交易已完成: ${tx.transactionHash}`);
    
    // 记录交易历史
    addTransactionToHistory({
      type: 'SELL (V3)',
      hash: tx.transactionHash,
      amount: amount,
      wallet: walletAddress,
      timestamp: Date.now()
    });
    
    return tx;
  } catch (err) {
    addLog(`V3卖出错误: ${err.message}`);
    throw err;
  }
};
// 渲染代币选择组件
const renderTokenSelector = (tokenType, setTokenType, customAddress, setCustomAddress, label) => {
  const renderOptions = () => {
    if (!window._web3Instance) return [];
    
    try {
      // 基本选项
      const options = [
        { value: 'NATIVE', label: '原生代币' },
        { value: 'WRAPPED_NATIVE', label: '包装原生代币' },
        { value: 'USDT', label: 'USDT' },
        { value: 'USDC', label: 'USDC' },
        { value: 'CUSTOM', label: '自定义代币' }
      ];
      
      return options;
    } catch (err) {
      addLog(`渲染代币选项错误: ${err.message}`);
      return [];
    }
  };
  
  return (
    <Box sx={{ mb: 2 }}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={tokenType}
          onChange={(e) => setTokenType(e.target.value)}
          label={label}
        >
          {renderOptions().map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
      {tokenType === 'CUSTOM' && (
        <TextField
          fullWidth
          label="自定义代币地址"
          placeholder="输入代币合约地址"
          value={customAddress}
          onChange={(e) => setCustomAddress(e.target.value)}
          sx={{ mt: 1 }}
        />
      )}
    </Box>
  );
};

// 防夹子RPC选择器
const renderAntiSandwichSelector = () => {
  // 只在BSC(56)或ETH(1)链上显示相关选项
  if (currentChainId !== '56' && currentChainId !== '1') {
    return null;
  }
  
  let rpcOptions = [];
  
  // BSC链的RPC选项
  if (currentChainId === '56') {
    rpcOptions = [
      { value: 'https://bsc.rpc.blocksec.com', label: 'https://bsc.rpc.blocksec.com' }
    ];
  }
  // ETH链的RPC选项
  else if (currentChainId === '1') {
    rpcOptions = [
      { value: 'https://eth.rpc.blocksec.com', label: 'https://eth.rpc.blocksec.com' },
      { value: 'https://rpc.mevblocker.io', label: 'https://rpc.mevblocker.io' },
      { value: 'https://rpc.flashbots.net/?builder=f1b.io&builder=rsync&builder=beaverbuild.org&builder=builder0x69&builder=Titan&builder=EigenPhi&builder=boba-builder&builder=Gambit+Labs&builder=payload&builder=Loki&builder=BuildAI&builder=JetBuilder&builder=tbuilder&builder=penguinbuild&builder=bobthebuilder&builder=BTCS&builder=bloXroute&builder=Blockbeelder&hint=hash', 
        label: 'Flashbots RPC' }
    ];
  }
  
  return (
    <Box sx={{ mb: 2 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={useAntiSandwich}
            onChange={(e) => {
              setUseAntiSandwich(e.target.checked);
              // 如果取消勾选，清空RPC选择
              if (!e.target.checked) {
                setAntiSandwichRPC('');
              }
              // 自动选择第一个RPC选项
              else if (rpcOptions.length > 0) {
                setAntiSandwichRPC(rpcOptions[0].value);
              }
            }}
          />
        }
        label="启用防夹子保护"
      />
      
      {useAntiSandwich && (
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel>选择防夹子RPC</InputLabel>
          <Select
            value={antiSandwichRPC}
            onChange={(e) => setAntiSandwichRPC(e.target.value)}
            label="选择防夹子RPC"
            displayEmpty={false}
          >
            {rpcOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

// 渲染V3费率选择器 - 新增
const renderV3FeeTierSelector = () => {
  // 只在V3类型的DEX上显示
  if (!window._selectedDex || window._selectedDex.type !== 'UniswapV3') {
    return null;
  }
  
  let feeTierOptions = [];
  
  // 根据链ID获取可用的费率档位
  if (currentChainId && NETWORK_CONFIG[currentChainId]?.v3FeeTiers) {
    feeTierOptions = NETWORK_CONFIG[currentChainId].v3FeeTiers.map(tier => {
      // 将费率转换为人类可读的形式 (例如: 3000 -> 0.3%)
      const percentage = tier / 10000;
      return {
        value: tier,
        label: `${percentage}% (${tier})`
      };
    });
  } else {
    // 默认费率选项
    feeTierOptions = [
      { value: 100, label: '0.01% (100)' },
      { value: 500, label: '0.05% (500)' },
      { value: 2500, label: '0.25% (2500)' },
      { value: 3000, label: '0.3% (3000)' },
      { value: 10000, label: '1% (10000)' }
    ];
  }
  
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        V3费率档位选择
      </Typography>
      <FormControl fullWidth>
        <InputLabel>默认费率档位</InputLabel>
        <Select
          value={v3FeeTier}
          onChange={(e) => setV3FeeTier(e.target.value)}
          label="默认费率档位"
        >
          {feeTierOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="caption" color="text.secondary">
        系统会自动为每个交易对尝试最适合的费率档位，此设置仅作为初始尝试值。
      </Typography>
    </Box>
  );
};

// 渲染自定义价格和代币信息区域
const renderTokenInfoSection = () => {
  if (!tokenInfo) {
    return (
      <Box>
        <Typography variant="subtitle1">目标代币: (尚未设置)</Typography>
        <Typography variant="body2">
          请输入代币合约地址并获取信息
        </Typography>
        
        <Typography variant="body1" sx={{ mt: 2 }}>
          当前价格: {formattedPrice}
        </Typography>
      </Box>
    );
  }
  
  return (
    <Box>
      <Typography variant="subtitle1">
        目标代币: {tokenInfo.name} ({tokenInfo.symbol})
      </Typography>
      <Typography variant="body2">
        地址: {tokenInfo.address.substring(0, 6)}...{tokenInfo.address.substring(tokenInfo.address.length - 4)}
      </Typography>
      <Typography variant="body2">
        小数位数: {tokenInfo.decimals}
      </Typography>
      
      <Typography variant="body1" sx={{ mt: 2 }}>
        当前价格: {formattedPrice}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        价格来源: {priceMethod || "未获取"}
      </Typography>
      {quoterVersion && priceMethod && priceMethod.includes('V3') && (
        <Typography variant="body2" color="text.secondary">
          Quoter版本: {quoterVersion}
        </Typography>
      )}
    </Box>
  );
};
// 渲染组件
return (
  <Box>
    {/* 提示消息 */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        onClose={handleCloseSnackbar} 
        severity={snackbar.severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>

    {/* 代币信息部分 */}
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          代币设置
        </Typography>
        
        <Grid container spacing={2}>
          {/* 目标代币设置 */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              目标代币合约
            </Typography>
            <TextField
              fullWidth
              label="目标代币合约地址"
              placeholder="输入代币合约地址"
              value={targetTokenAddress}
              onChange={(e) => setTargetTokenAddress(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          
          {/* 底池代币设置 */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              底池代币
            </Typography>
            {renderTokenSelector(
              poolTokenType, 
              setPoolTokenType, 
              customPoolTokenAddress, 
              setCustomPoolTokenAddress,
              "选择底池代币"
            )}
          </Grid>
          
          {/* V3费率选择器 */}
          <Grid item xs={12}>
            {renderV3FeeTierSelector()}
          </Grid>
          
          {/* 获取代币信息按钮 */}
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              onClick={fetchTokensInfoAndPrice}
              startIcon={fetchingInfo ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {fetchingInfo ? '获取中...' : '获取代币信息和价格'}
            </Button>
          </Grid>
        </Grid>
        
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        
        {tokenInfo && poolTokenInfo && (
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">
                  目标代币: {tokenInfo.name} ({tokenInfo.symbol})
                </Typography>
                <Typography variant="body2">
                  地址: {tokenInfo.address.substring(0, 6)}...{tokenInfo.address.substring(tokenInfo.address.length - 4)}
                </Typography>
                <Typography variant="body2">
                  小数位数: {tokenInfo.decimals}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">
                  底池代币: {poolTokenInfo.name} ({poolTokenInfo.symbol})
                  {poolTokenInfo.isNative && " (原生代币)"}
                </Typography>
                <Typography variant="body2">
                  地址: {poolTokenInfo.address.substring(0, 6)}...{poolTokenInfo.address.substring(poolTokenInfo.address.length - 4)}
                </Typography>
                <Typography variant="body2">
                  小数位数: {poolTokenInfo.decimals}
                </Typography>
              </Grid>
            </Grid>
            
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>
              当前价格: {formattedPrice}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              价格获取方法: {priceMethod || "未获取"}
            </Typography>
            {quoterVersion && priceMethod && priceMethod.includes('V3') && (
              <Typography variant="body2" color="text.secondary">
                Quoter版本: {quoterVersion}
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
    
    {/* 监控设置部分 */}
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          监控设置
        </Typography>
        
        <Grid container spacing={3}>
          {/* 买入设置 */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              买入设置
            </Typography>
            
            {/* 输入代币选择 */}
            {renderTokenSelector(
              inputToken, 
              setInputToken, 
              customInputTokenAddress, 
              setCustomInputTokenAddress,
              "买入使用代币"
            )}
            
            {/* 买入阈值 */}
            <TextField
              fullWidth
              label="买入阈值 (价格低于此值时买入)"
              type="number"
              inputProps={{ step: "0.00000001" }}
              value={buyThreshold}
              onChange={(e) => setBuyThreshold(e.target.value)}
              sx={{ mb: 2 }}
            />
            
            {/* 买入金额类型选择 */}
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">买入金额类型</FormLabel>
              <RadioGroup
                row
                value={buyAmountType}
                onChange={(e) => setBuyAmountType(e.target.value)}
              >
                <FormControlLabel value="fixed" control={<Radio />} label="固定金额" />
                <FormControlLabel value="percent" control={<Radio />} label="百分比" />
              </RadioGroup>
            </FormControl>
            
            {/* 买入金额 */}
            <TextField
              fullWidth
              label={buyAmountType === 'percent' ? '买入百分比 (%)' : '买入金额'}
              type="number"
              inputProps={{ 
                step: buyAmountType === 'percent' ? "0.1" : "0.00000001",
                min: "0",
                max: buyAmountType === 'percent' ? "100" : undefined 
              }}
              InputProps={buyAmountType === 'percent' ? {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              } : undefined}
              value={buyAmount}
              onChange={(e) => setBuyAmount(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
          
          {/* 卖出设置 */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              卖出设置
            </Typography>
            
            {/* 输出代币选择 */}
            {renderTokenSelector(
              outputToken, 
              setOutputToken, 
              customOutputTokenAddress, 
              setCustomOutputTokenAddress,
              "卖出接收代币"
            )}
            
            {/* 卖出阈值 */}
            <TextField
              fullWidth
              label="卖出阈值 (价格高于此值时卖出)"
              type="number"
              inputProps={{ step: "0.00000001" }}
              value={sellThreshold}
              onChange={(e) => setSellThreshold(e.target.value)}
              sx={{ mb: 2 }}
            />
            
            {/* 卖出金额类型选择 */}
            <FormControl component="fieldset" sx={{ mb: 2 }}>
              <FormLabel component="legend">卖出金额类型</FormLabel>
              <RadioGroup
                row
                value={sellAmountType}
                onChange={(e) => setSellAmountType(e.target.value)}
              >
                <FormControlLabel value="fixed" control={<Radio />} label="固定金额" />
                <FormControlLabel value="percent" control={<Radio />} label="百分比" />
              </RadioGroup>
            </FormControl>
            
            {/* 卖出金额 */}
            <TextField
              fullWidth
              label={sellAmountType === 'percent' ? '卖出百分比 (%)' : '卖出金额'}
              type="number"
              inputProps={{ 
                step: sellAmountType === 'percent' ? "0.1" : "0.00000001",
                min: "0",
                max: sellAmountType === 'percent' ? "100" : undefined 
              }}
              InputProps={sellAmountType === 'percent' ? {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              } : undefined}
              value={sellAmount}
              onChange={(e) => setSellAmount(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 2 }} />
        
        {/* 通用设置 */}
        <Typography variant="subtitle1" gutterBottom>
          通用设置
        </Typography>
        
        {/* 目标倍数 */}
        <TextField
          label="停止任务倍数 (任务收益达到初始价值的X倍时停止)"
          type="number"
          inputProps={{ step: "0.1", min: "0" }}
          value={targetMultiple}
          onChange={(e) => setTargetMultiple(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          helperText="如不设置则持续执行"
        />
        
        {/* 防夹子设置 */}
        {renderAntiSandwichSelector()}
        
        {/* 任务操作按钮 */}
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={startMonitoringTask}
            disabled={isTaskRunning || !tokenInfo || !poolTokenInfo}
          >
            开始监控
          </Button>
          
          <Button
            variant="contained"
            color="error"
            onClick={stopMonitoringTask}
            disabled={!isTaskRunning}
          >
            停止监控
          </Button>
        </Box>
        
        {taskStatus && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {taskStatus}
          </Alert>
        )}
      </CardContent>
    </Card>
    
    {/* 调试日志区域 */}
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          调试日志
        </Typography>
        <Box 
          sx={{ 
            maxHeight: '200px', 
            overflowY: 'auto', 
            bgcolor: 'black', 
            color: 'lightgreen', 
            p: 2, 
            fontFamily: 'monospace',
            fontSize: '12px'
          }}
        >
          {debugLogs.length === 0 ? (
            <Typography color="grey">暂无日志...</Typography>
          ) : (
            debugLogs.map((log, idx) => (
              <Box key={idx} sx={{ mb: 0.5 }}>
                <Typography component="span" color="grey">[{log.time}]</Typography>
                <Typography component="span" ml={1}>{log.message}</Typography>
              </Box>
            ))
          )}
        </Box>
      </CardContent>
    </Card>
    
    {/* 交易记录部分 */}
    {transactionHistory.length > 0 && (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            交易记录
          </Typography>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>类型</TableCell>
                  <TableCell>交易哈希</TableCell>
                  <TableCell>钱包地址</TableCell>
                  <TableCell>时间</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((tx, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {tx.type.includes('BUY') ? (
                        <Typography color="primary">{tx.type}</Typography>
                      ) : (
                        <Typography color="error">{tx.type}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {tx.hash.substring(0, 6)}...{tx.hash.substring(tx.hash.length - 4)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {tx.wallet.substring(0, 6)}...{tx.wallet.substring(tx.wallet.length - 4)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {new Date(tx.timestamp).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    )}
  </Box>
);
};

module.exports = MarketCapPanel
