// Import Web3
const Web3 = require('web3');

// Create Web3 instance for utility functions
const web3 = new Web3();

// Custom error class for EVM related errors
class EVMError extends Error {
    constructor(message, code, originalError = null) {
        super(message);
        this.name = 'EVMError';
        this.code = code;
        this.originalError = originalError;
    }
}

// Base constants
const CONSTANTS = {
    // Address related constants
    ADDRESSES: {
        ZERO: '0x0000000000000000000000000000000000000000',
        DEAD: '0x000000000000000000000000000000000000dEaD'
    },

    // Number related constants
    NUMBERS: {
        ZERO_BYTES32: '0x' + '0'.repeat(64),
        MAX_UINT256: '0x' + 'f'.repeat(64),
        ZERO: '0',
        ONE: '1',
        MAX_UINT8: '255',
        MAX_UINT16: '65535',
        MAX_UINT32: '4294967295',
        MAX_UINT64: '18446744073709551615',
        MAX_UINT128: '340282366920938463463374607431768211455',
        MAX_UINT256: '115792089237316195423570985008687907853269984665640564039457584007913129639935',
        MIN_INT256: '-57896044618658097711785492504343953926634992332820282019728792003956564819968',
        MAX_INT256: '57896044618658097711785492504343953926634992332820282019728792003956564819967'
    },

    // Time related constants (in seconds)
    TIME: {
        SECOND: 1,
        MINUTE: 60,
        HOUR: 3600,
        DAY: 86400,
        WEEK: 604800,
        MONTH: 2592000,
        YEAR: 31536000
    },

    // Gas related constants
    GAS: {
        LIMITS: {
            APPROVE: '46000',
            TRANSFER: '65000',
            SWAP: '200000',
            ADD_LIQUIDITY: '250000',
            REMOVE_LIQUIDITY: '200000',
            WRAP: '50000',
            UNWRAP: '50000',
            PERMIT: '100000'
        },
        MULTIPLIERS: {
            SAFE: '120',     // 1.2x
            STANDARD: '150', // 1.5x
            FAST: '200'      // 2.0x
        },
        BASE_MULTIPLIER: '100'
    },

    // Decimals for common tokens
    DECIMALS: {
        ETH: 18,
        USDC: 6,
        USDT: 6,
        WBTC: 8,
        DAI: 18
    },

    // Transaction related constants
    TRANSACTION: {
        TYPES: {
            LEGACY: '0x0',
            ACCESS_LIST: '0x1',
            EIP1559: '0x2'
        },
        TIMEOUTS: {
            CONFIRMATION: 180000,  // 3 minutes
            EXECUTION: 60000,      // 1 minute
            RPC: 30000            // 30 seconds
        },
        CONFIRMATIONS: {
            DEFAULT: 1,
            SAFE: 3,
            FINALIZED: 12
        },
        DEFAULTS: {
            BATCH_SIZE: 100,
            BLOCKS_RANGE: 2000,
            MAX_RETRIES: 3,
            RETRY_DELAY: 1000,
            TIMEOUT: 60000
        }
    },

    // Error codes 
    ERRORS: {
        RPC: {
            INVALID_INPUT: -32000,
            RESOURCE_NOT_FOUND: -32001,
            RESOURCE_UNAVAILABLE: -32002,
            TRANSACTION_REJECTED: -32003,
            METHOD_NOT_SUPPORTED: -32004,
            LIMIT_EXCEEDED: -32005,
            PARSE_ERROR: -32700,
            INVALID_REQUEST: -32600,
            METHOD_NOT_FOUND: -32601,
            INVALID_PARAMS: -32602,
            INTERNAL_ERROR: -32603
        },
        CONTRACT: {
            EXECUTION_REVERTED: 'CALL_EXCEPTION',
            INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
            NONCE_TOO_LOW: 'NONCE_EXPIRED',
            GAS_TOO_LOW: 'UNPREDICTABLE_GAS_LIMIT',
            REPLACEMENT_UNDERPRICED: 'REPLACEMENT_UNDERPRICED'
        },
        PROVIDER: {
            CHAIN_DISCONNECTED: 4901,
            UNAUTHORIZED: 4100,
            UNSUPPORTED_METHOD: 4200,
            USER_REJECTED: 4001
        }
    },

    // Provider configuration
    PROVIDER_CONFIG: {
        timeout: 30000,
        reconnect: {
            auto: true,
            delay: 5000,
            maxAttempts: 3
        },
        batch: {
            size: 100,
            wait: 0
        },
        http: {
            headers: {
                'Content-Type': 'application/json'
            }
        },
        ws: {
            clientConfig: {
                maxReceivedFrameSize: 100000000,
                maxReceivedMessageSize: 100000000,
                keepalive: true,
                keepaliveInterval: 60000
            }
        }
    }
};

// Network configurations
const NETWORK_CONFIG = {
    ETH: {
        name: 'Ethereum',
        chainId: '1',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18
        },
        blockTime: 12,
        explorer: {
            url: 'https://etherscan.io',
            apiKey: 'YFWRUMAFQB9XAVXDN6SCMJ8FZEPRUXGPAC',
            apiUrl: 'https://api.etherscan.io/api',
            browserUrl: 'https://etherscan.io'
        },
        contracts: {
            multicall2: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
            wrappedNative: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
        },
        stablecoins: {
            USDC: '0xA0b86991C6218B36c1d19D4a2e9Eb0cE3606eB48',
            USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F'
        },
        gasPrice: {
            eip1559: true,
            priorityFee: {
                low: web3.utils.toWei('0.5', 'gwei'),
                medium: web3.utils.toWei('1.5', 'gwei'),
                high: web3.utils.toWei('2.5', 'gwei')
            },
            maxFeeMultiplier: {
                low: '120',
                medium: '150',
                high: '200'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    ARB: {
        name: 'Arbitrum One',
        chainId: '42161',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18
        },
        blockTime: 0.25,
        explorer: {
            url: 'https://arbiscan.io',
            apiKey: '5VF7MV21H53X6YQVWTSE3GD1HH595PQ4JG',
            apiUrl: 'https://api.arbiscan.io/api',
            browserUrl: 'https://arbiscan.io'
        },
        contracts: {
            multicall2: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
            wrappedNative: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'
        },
        stablecoins: {
            USDC: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
            USDT: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
            DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1'
        },
        gasPrice: {
            eip1559: true,
            priorityFee: {
                low: web3.utils.toWei('0.01', 'gwei'),
                medium: web3.utils.toWei('0.1', 'gwei'),
                high: web3.utils.toWei('0.5', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '120',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },
BSC: {
        name: 'BNB Smart Chain',
        chainId: '56',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        blockTime: 3,
        explorer: {
            url: 'https://bscscan.com',
            apiKey: 'U372TERCNRND6MM52BDUHJNP1EKNUDD793',
            apiUrl: 'https://api.bscscan.com/api',
            browserUrl: 'https://bscscan.com'
        },
        contracts: {
            multicall2: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
            wrappedNative: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
        },
        stablecoins: {
            USDC: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            USDT: '0x55d398326f99059fF775485246999027B3197955',
            BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
        },
        gasPrice: {
            eip1559: false,
            priorityFee: {
                low: web3.utils.toWei('3', 'gwei'),
                medium: web3.utils.toWei('5', 'gwei'),
                high: web3.utils.toWei('7', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '120',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0 
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    CORE: {
        name: 'Core DAO',
        chainId: '1116',
        nativeCurrency: {
            name: 'CORE',
            symbol: 'CORE',
            decimals: 18
        },
        blockTime: 3,
        explorer: {
            url: 'https://scan.coredao.org',
            apiKey: 'f72f14b4e0dd4f4b8bdd5dc242dd88e4',
            apiUrl: 'https://openapi.coredao.org/api',
            browserUrl: 'https://scan.coredao.org'
        },
        contracts: {
            multicall2: '0x0b77B5CEd570601997eB9A7cF3DcDB054d507714',
            wrappedNative: '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f'
        },
        stablecoins: {
            USDC: '0x6Fb05b156788E88c16b1265912936c92a8d03a7D',
            USDT: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
            BUSD: '0x0000000000000000000000000000000000000000'
        },
        gasPrice: {
            eip1559: false,
            priorityFee: {
                low: web3.utils.toWei('1', 'gwei'),
                medium: web3.utils.toWei('2', 'gwei'),
                high: web3.utils.toWei('3', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '120',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    AVAX: {
        name: 'Avalanche',
        chainId: '43114',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        blockTime: 2,
        explorer: {
            url: 'https://snowtrace.io',
            apiKey: '',
            apiUrl: 'https://api.snowtrace.io/api',
            browserUrl: 'https://snowtrace.io'
        },
        contracts: {
            multicall2: '0x8755b94F88D120AB2Cc13b1f6582329b067C760d',
            wrappedNative: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
        },
        stablecoins: {
            USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
            USDT: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
            DAI: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70'
        },
        gasPrice: {
            eip1559: false,
            priorityFee: {
                low: web3.utils.toWei('25', 'gwei'),
                medium: web3.utils.toWei('35', 'gwei'),
                high: web3.utils.toWei('50', 'gwei')
            },
            maxFeeMultiplier: {
                low: '120',
                medium: '140',
                high: '160'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    BASE: {
        name: 'Base',
        chainId: '8453',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18
        },
        blockTime: 2,
        explorer: {
            url: 'https://basescan.org',
            apiKey: 'DZCFU2K2ZM2AG4I5W9P77JPF78BMU7FUID',
            apiUrl: 'https://api.basescan.org/api',
            browserUrl: 'https://basescan.org'
        },
        contracts: {
            multicall2: '0x61Dc41b07aCc075E9b52F0D7AF327d63D42549aA',
            wrappedNative: '0x4200000000000000000000000000000000000006'
        },
stablecoins: {
            USDC: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
            USDT: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
            DAI: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'
        },
        gasPrice: {
            eip1559: true,
            priorityFee: {
                low: web3.utils.toWei('0.001', 'gwei'),
                medium: web3.utils.toWei('0.01', 'gwei'),
                high: web3.utils.toWei('0.1', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '120',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    FTM: {
        name: 'Fantom',
        chainId: '250',
        nativeCurrency: {
            name: 'Fantom',
            symbol: 'FTM',
            decimals: 18
        },
        blockTime: 1,
        explorer: {
            url: 'https://ftmscan.com',
            apiKey: 'ZQ81MCMZR11SWG2XSV8BKGJ3YEKANTEDXC',
            apiUrl: 'https://api.ftmscan.com/api',
            browserUrl: 'https://ftmscan.com'
        },
        contracts: {
            multicall2: '0xD98e3dBE5950Ca8Ce5a4b59630a5652110403E5c',
            wrappedNative: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'
        },
        stablecoins: {
            USDC: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
            USDT: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
            DAI: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e'
        },
        gasPrice: {
            eip1559: false,
            priorityFee: {
                low: web3.utils.toWei('50', 'gwei'),
                medium: web3.utils.toWei('100', 'gwei'),
                high: web3.utils.toWei('200', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '130',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    POLYGON: {
        name: 'Polygon',
        chainId: '137',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18
        },
        blockTime: 2,
        explorer: {
            url: 'https://polygonscan.com',
            apiKey: 'RV459CGWPNSFCN46SNKR2NP4CUCD4YM36D',
            apiUrl: 'https://api.polygonscan.com/api',
            browserUrl: 'https://polygonscan.com'
        },
        contracts: {
            multicall2: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
            wrappedNative: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
        },
        stablecoins: {
            USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            USDT: '0xc2132D05D31c914a87C6611C10748aEB04B58e8F',
            DAI: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
        },
        gasPrice: {
            eip1559: true,
            priorityFee: {
                low: web3.utils.toWei('30', 'gwei'),
                medium: web3.utils.toWei('50', 'gwei'),
                high: web3.utils.toWei('80', 'gwei')
            },
            maxFeeMultiplier: {
                low: '130',
                medium: '150',
                high: '200'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    },

    PEGO: {
        name: 'Pego Network',
        chainId: '20201022',
        nativeCurrency: {
            name: 'PEGO',
            symbol: 'PEGO',
            decimals: 18
        },
blockTime: 3,
        explorer: {
            url: 'https://scan.pego.network',
            apiKey: '',
            apiUrl: 'https://scan.pego.network/api',
            browserUrl: 'https://scan.pego.network'
        },
        contracts: {
            multicall2: '0x0000000000000000000000000000000000000000',
            wrappedNative: '0x580e2b6889f927e90c97c5e432e9018e708b37af'
        },
        stablecoins: {
            USDC: '0x0f85e4e2af96bbb71129837f36166df959790a4c',
            USDT: '0x0767b4ff36eb68458a46f7c6903d4c0a7dd76b76',
            BUSD: '0x0000000000000000000000000000000000000000'
        },
        gasPrice: {
            eip1559: false,
            priorityFee: {
                low: web3.utils.toWei('1', 'gwei'),
                medium: web3.utils.toWei('2', 'gwei'),
                high: web3.utils.toWei('3', 'gwei')
            },
            maxFeeMultiplier: {
                low: '110',
                medium: '130',
                high: '150'
            }
        },
        options: {
            timeout: 30000,
            reconnect: {
                auto: true,
                delay: 5000,
                maxAttempts: 5,
                onTimeout: false
            },
            batch: {
                enabled: true,
                size: 100,
                wait: 0
            },
            skipFetchSetup: false,
            keepAlive: true
        }
    }
};

// Contract ABIs
const ABIS = {
    // ERC20 Standard Interface
    ERC20: [
        // Events
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Approval',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Transfer',
            type: 'event'
        },
        // Read Functions
        {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'owner', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        // Write Functions
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'sender', type: 'address' },
                { internalType: 'address', name: 'recipient', type: 'address' },
                { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],

    // Multicall2 Interface
    MULTICALL2: [
        {
            inputs: [
                {
                    components: [
                        { internalType: 'address', name: 'target', type: 'address' },
                        { internalType: 'bytes', name: 'callData', type: 'bytes' }
                    ],
                    internalType: 'struct Multicall2.Call[]',
                    name: 'calls',
                    type: 'tuple[]'
                }
            ],
            name: 'aggregate',
            outputs: [
                { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
                { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        { internalType: 'address', name: 'target', type: 'address' },
                        { internalType: 'bytes', name: 'callData', type: 'bytes' }
                    ],
                    internalType: 'struct Multicall2.Call[]',
                    name: 'calls',
                    type: 'tuple[]'
                }
            ],
            name: 'tryAggregate',
            outputs: [
                {
                    components: [
                        { internalType: 'bool', name: 'success', type: 'bool' },
                        { internalType: 'bytes', name: 'returnData', type: 'bytes' }
                    ],
                    internalType: 'struct Multicall2.Result[]',
                    name: 'returnData',
                    type: 'tuple[]'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],

    // Factory Interface
    FACTORY: [
        // Events
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'token0', type: 'address' },
                { indexed: true, internalType: 'address', name: 'token1', type: 'address' },
                { indexed: false, internalType: 'address', name: 'pair', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'totalPairs', type: 'uint256' }
            ],
            name: 'PairCreated',
            type: 'event'
        },
        // View Functions
        {
            inputs: [],
            name: 'feeTo',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'feeToSetter',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' }
            ],
            name: 'getPair',
            outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            name: 'allPairs',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
{
            inputs: [],
            name: 'allPairsLength',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        // State-Changing Functions
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' }
            ],
            name: 'createPair',
            outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: '_feeTo', type: 'address' }],
            name: 'setFeeTo',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [{ internalType: 'address', name: '_feeToSetter', type: 'address' }],
            name: 'setFeeToSetter',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],

    // Router Interface
    ROUTER: [
        // Factory & WETH
        {
            inputs: [],
            name: 'factory',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'WETH',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        // Liquidity Functions
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' },
                { internalType: 'uint256', name: 'amountADesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBDesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'addLiquidity',
            outputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'amountB', type: 'uint256' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'amountTokenDesired', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'addLiquidityETH',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        // Remove Liquidity Functions
        {
            inputs: [
                { internalType: 'address', name: 'tokenA', type: 'address' },
                { internalType: 'address', name: 'tokenB', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountAMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountBMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'removeLiquidity',
            outputs: [
                { internalType: 'uint256', name: 'amountA', type: 'uint256' },
                { internalType: 'uint256', name: 'amountB', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [
                { internalType: 'address', name: 'token', type: 'address' },
                { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
                { internalType: 'uint256', name: 'amountTokenMin', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETHMin', type: 'uint256' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'removeLiquidityETH',
            outputs: [
                { internalType: 'uint256', name: 'amountToken', type: 'uint256' },
                { internalType: 'uint256', name: 'amountETH', type: 'uint256' }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        // Swap Functions
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapTokensForExactTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactETHForTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapETHForExactTokens',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForETH',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'uint256', name: 'amountInMax', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapTokensForExactETH',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        // Fee-supporting swap functions
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'uint256', name: 'amountOutMin', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' },
                { internalType: 'address', name: 'to', type: 'address' },
                { internalType: 'uint256', name: 'deadline', type: 'uint256' }
            ],
            name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        // Quote Functions
        {
            inputs: [
                { internalType: 'uint256', name: 'amountIn', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' }
            ],
            name: 'getAmountsOut',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'uint256', name: 'amountOut', type: 'uint256' },
                { internalType: 'address[]', name: 'path', type: 'address[]' }
            ],
            name: 'getAmountsIn',
            outputs: [{ internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' }],
            stateMutability: 'view',
            type: 'function'
        }
    ],

    // Pair Interface
    PAIR: [
        // Events
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Approval',
            type: 'event'
        },
{
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' }
            ],
            name: 'Burn',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256' }
            ],
            name: 'Mint',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'amount0In', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1In', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
                { indexed: false, internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' }
            ],
            name: 'Swap',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: 'uint112', name: 'reserve0', type: 'uint112' },
                { indexed: false, internalType: 'uint112', name: 'reserve1', type: 'uint112' }
            ],
            name: 'Sync',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'Transfer',
            type: 'event'
        },
        // Core Functions
        {
            inputs: [],
            name: 'DOMAIN_SEPARATOR',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'MINIMUM_LIQUIDITY',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'PERMIT_TYPEHASH',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'owner', type: 'address' },
                { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                { internalType: 'address', name: 'spender', type: 'address' },
                { internalType: 'uint256', name: 'value', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'pure',
            type: 'function'
        },
        {
            inputs: [],
            name: 'factory',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'getReserves',
            outputs: [
                { internalType: 'uint112', name: 'reserve0', type: 'uint112' },
                { internalType: 'uint112', name: 'reserve1', type: 'uint112' },
                { internalType: 'uint32', name: 'blockTimestampLast', type: 'uint32' }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'price0CumulativeLast',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'price1CumulativeLast',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
        }
    ],

    // Uniswap V3 - Factory Interface
    UNISWAPV3_FACTORY: [
        {
            inputs: [],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickSpacing',
                    type: 'int24'
                }
            ],
            name: 'FeeAmountEnabled',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'oldOwner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address'
                }
            ],
            name: 'OwnerChanged',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token0',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token1',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    indexed: false,
                    internalType: 'int24',
                    name: 'tickSpacing',
                    type: 'int24'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'pool',
                    type: 'address'
                }
            ],
            name: 'PoolCreated',
            type: 'event'
        },
{
            inputs: [
                {
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    internalType: 'int24',
                    name: 'tickSpacing',
                    type: 'int24'
                }
            ],
            name: 'enableFeeAmount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint24',
                    name: '',
                    type: 'uint24'
                }
            ],
            name: 'feeAmountTickSpacing',
            outputs: [
                {
                    internalType: 'int24',
                    name: '',
                    type: 'int24'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                },
                {
                    internalType: 'uint24',
                    name: '',
                    type: 'uint24'
                }
            ],
            name: 'getPool',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'parameters',
            outputs: [
                {
                    internalType: 'address',
                    name: 'factory',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'token0',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'token1',
                    type: 'address'
                },
                {
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    internalType: 'int24',
                    name: 'tickSpacing',
                    type: 'int24'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token0',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'token1',
                    type: 'address'
                },
                {
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                }
            ],
            name: 'createPool',
            outputs: [
                {
                    internalType: 'address',
                    name: 'pool',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [
                {
                    internalType: 'address',
                    name: '_owner',
                    type: 'address'
                }
            ],
            name: 'setOwner',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ],

    // Uniswap V3 - Pool Interface
    UNISWAPV3_POOL: [
        {
            inputs: [],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount',
                    type: 'uint128'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            name: 'Burn',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount0',
                    type: 'uint128'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount1',
                    type: 'uint128'
                }
            ],
            name: 'Collect',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount0',
                    type: 'uint128'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount1',
                    type: 'uint128'
                }
            ],
            name: 'CollectProtocol',
            type: 'event'
        },
{
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'paid0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'paid1',
                    type: 'uint256'
                }
            ],
            name: 'Flash',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint16',
                    name: 'observationCardinalityNextOld',
                    type: 'uint16'
                },
                {
                    indexed: false,
                    internalType: 'uint16',
                    name: 'observationCardinalityNextNew',
                    type: 'uint16'
                }
            ],
            name: 'IncreaseObservationCardinalityNext',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint160',
                    name: 'sqrtPriceX96',
                    type: 'uint160'
                },
                {
                    indexed: false,
                    internalType: 'int24',
                    name: 'tick',
                    type: 'int24'
                }
            ],
            name: 'Initialize',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    indexed: true,
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'amount',
                    type: 'uint128'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            name: 'Mint',
            type: 'event'
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint8',
                    name: 'feeProtocol0Old',
                    type: 'uint8'
                },
                {
                    indexed: false,
                    internalType: 'uint8',
                    name: 'feeProtocol1Old',
                    type: 'uint8'
                },
                {
                    indexed: false,
                    internalType: 'uint8',
                    name: 'feeProtocol0New',
                    type: 'uint8'
                },
                {
                    indexed: false,
                    internalType: 'uint8',
                    name: 'feeProtocol1New',
                    type: 'uint8'
                }
            ],
            name: 'SetFeeProtocol',
            type: 'event'
        },

{
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address'
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    indexed: false,
                    internalType: 'int256',
                    name: 'amount0',
                    type: 'int256'
                },
                {
                    indexed: false,
                    internalType: 'int256',
                    name: 'amount1',
                    type: 'int256'
                },
                {
                    indexed: false,
                    internalType: 'uint160',
                    name: 'sqrtPriceX96',
                    type: 'uint160'
                },
                {
                    indexed: false,
                    internalType: 'uint128',
                    name: 'liquidity',
                    type: 'uint128'
                },
                {
                    indexed: false,
                    internalType: 'int24',
                    name: 'tick',
                    type: 'int24'
                }
            ],
            name: 'Swap',
            type: 'event'
        },
        {
            inputs: [
                {
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    internalType: 'uint128',
                    name: 'amount',
                    type: 'uint128'
                }
            ],
            name: 'burn',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    internalType: 'uint128',
                    name: 'amount0Requested',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'amount1Requested',
                    type: 'uint128'
                }
            ],
            name: 'collect',
            outputs: [
                {
                    internalType: 'uint128',
                    name: 'amount0',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'amount1',
                    type: 'uint128'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'uint128',
                    name: 'amount0Requested',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'amount1Requested',
                    type: 'uint128'
                }
            ],
            name: 'collectProtocol',
            outputs: [
                {
                    internalType: 'uint128',
                    name: 'amount0',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'amount1',
                    type: 'uint128'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [],
            name: 'factory',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'fee',
            outputs: [
                {
                    internalType: 'uint24',
                    name: '',
                    type: 'uint24'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'feeGrowthGlobal0X128',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'feeGrowthGlobal1X128',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'flash',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint16',
                    name: 'observationCardinalityNext',
                    type: 'uint16'
                }
            ],
            name: 'increaseObservationCardinalityNext',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint160',
                    name: 'sqrtPriceX96',
                    type: 'uint160'
                }
            ],
            name: 'initialize',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'liquidity',
            outputs: [
                {
                    internalType: 'uint128',
                    name: '',
                    type: 'uint128'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'maxLiquidityPerTick',
            outputs: [
                {
                    internalType: 'uint128',
                    name: '',
                    type: 'uint128'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
{
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                },
                {
                    internalType: 'uint128',
                    name: 'amount',
                    type: 'uint128'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'mint',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amount0',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'amount1',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            name: 'observations',
            outputs: [
                {
                    internalType: 'uint32',
                    name: 'blockTimestamp',
                    type: 'uint32'
                },
                {
                    internalType: 'int56',
                    name: 'tickCumulative',
                    type: 'int56'
                },
                {
                    internalType: 'uint160',
                    name: 'secondsPerLiquidityCumulativeX128',
                    type: 'uint160'
                },
                {
                    internalType: 'bool',
                    name: 'initialized',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint32[]',
                    name: 'secondsAgos',
                    type: 'uint32[]'
                }
            ],
            name: 'observe',
            outputs: [
                {
                    internalType: 'int56[]',
                    name: 'tickCumulatives',
                    type: 'int56[]'
                },
                {
                    internalType: 'uint160[]',
                    name: 'secondsPerLiquidityCumulativeX128s',
                    type: 'uint160[]'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes32',
                    name: '',
                    type: 'bytes32'
                }
            ],
            name: 'positions',
            outputs: [
                {
                    internalType: 'uint128',
                    name: 'liquidity',
                    type: 'uint128'
                },
                {
                    internalType: 'uint256',
                    name: 'feeGrowthInside0LastX128',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'feeGrowthInside1LastX128',
                    type: 'uint256'
                },
                {
                    internalType: 'uint128',
                    name: 'tokensOwed0',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'tokensOwed1',
                    type: 'uint128'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
{
            inputs: [],
            name: 'protocolFees',
            outputs: [
                {
                    internalType: 'uint128',
                    name: 'token0',
                    type: 'uint128'
                },
                {
                    internalType: 'uint128',
                    name: 'token1',
                    type: 'uint128'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'uint8',
                    name: 'feeProtocol0',
                    type: 'uint8'
                },
                {
                    internalType: 'uint8',
                    name: 'feeProtocol1',
                    type: 'uint8'
                }
            ],
            name: 'setFeeProtocol',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [],
            name: 'slot0',
            outputs: [
                {
                    internalType: 'uint160',
                    name: 'sqrtPriceX96',
                    type: 'uint160'
                },
                {
                    internalType: 'int24',
                    name: 'tick',
                    type: 'int24'
                },
                {
                    internalType: 'uint16',
                    name: 'observationIndex',
                    type: 'uint16'
                },
                {
                    internalType: 'uint16',
                    name: 'observationCardinality',
                    type: 'uint16'
                },
                {
                    internalType: 'uint16',
                    name: 'observationCardinalityNext',
                    type: 'uint16'
                },
                {
                    internalType: 'uint8',
                    name: 'feeProtocol',
                    type: 'uint8'
                },
                {
                    internalType: 'bool',
                    name: 'unlocked',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'int24',
                    name: 'tickLower',
                    type: 'int24'
                },
                {
                    internalType: 'int24',
                    name: 'tickUpper',
                    type: 'int24'
                }
            ],
            name: 'snapshotCumulativesInside',
            outputs: [
                {
                    internalType: 'int56',
                    name: 'tickCumulativeInside',
                    type: 'int56'
                },
                {
                    internalType: 'uint160',
                    name: 'secondsPerLiquidityInsideX128',
                    type: 'uint160'
                },
                {
                    internalType: 'uint32',
                    name: 'secondsInside',
                    type: 'uint32'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address'
                },
                {
                    internalType: 'bool',
                    name: 'zeroForOne',
                    type: 'bool'
                },
                {
                    internalType: 'int256',
                    name: 'amountSpecified',
                    type: 'int256'
                },
                {
                    internalType: 'uint160',
                    name: 'sqrtPriceLimitX96',
                    type: 'uint160'
                },
                {
                    internalType: 'bytes',
                    name: 'data',
                    type: 'bytes'
                }
            ],
            name: 'swap',
            outputs: [
                {
                    internalType: 'int256',
                    name: 'amount0',
                    type: 'int256'
                },
                {
                    internalType: 'int256',
                    name: 'amount1',
                    type: 'int256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
{
            inputs: [
                {
                    internalType: 'int16',
                    name: '',
                    type: 'int16'
                }
            ],
            name: 'tickBitmap',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'tickSpacing',
            outputs: [
                {
                    internalType: 'int24',
                    name: '',
                    type: 'int24'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'int24',
                    name: '',
                    type: 'int24'
                }
            ],
            name: 'ticks',
            outputs: [
                {
                    internalType: 'uint128',
                    name: 'liquidityGross',
                    type: 'uint128'
                },
                {
                    internalType: 'int128',
                    name: 'liquidityNet',
                    type: 'int128'
                },
                {
                    internalType: 'uint256',
                    name: 'feeGrowthOutside0X128',
                    type: 'uint256'
                },
                {
                    internalType: 'uint256',
                    name: 'feeGrowthOutside1X128',
                    type: 'uint256'
                },
                {
                    internalType: 'int56',
                    name: 'tickCumulativeOutside',
                    type: 'int56'
                },
                {
                    internalType: 'uint160',
                    name: 'secondsPerLiquidityOutsideX128',
                    type: 'uint160'
                },
                {
                    internalType: 'uint32',
                    name: 'secondsOutside',
                    type: 'uint32'
                },
                {
                    internalType: 'bool',
                    name: 'initialized',
                    type: 'bool'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token0',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'token1',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        }
    ],

    // Uniswap V3 - Router Interface
    UNISWAPV3_ROUTER: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_factory',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_WETH9',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
{
            inputs: [],
            name: 'WETH9',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'factory',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes[]',
                    name: 'data',
                    type: 'bytes[]'
                }
            ],
            name: 'multicall',
            outputs: [
                {
                    internalType: 'bytes[]',
                    name: 'results',
                    type: 'bytes[]'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'bytes',
                            name: 'path',
                            type: 'bytes'
                        },
                        {
                            internalType: 'address',
                            name: 'recipient',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMinimum',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ISwapRouter.ExactInputParams',
                    name: 'params',
                    type: 'tuple'
                }
            ],
            name: 'exactInput',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenIn',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tokenOut',
                            type: 'address'
                        },
                        {
                            internalType: 'uint24',
                            name: 'fee',
                            type: 'uint24'
                        },
                        {
                            internalType: 'address',
                            name: 'recipient',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMinimum',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint160',
                            name: 'sqrtPriceLimitX96',
                            type: 'uint160'
                        }
                    ],
                    internalType: 'struct ISwapRouter.ExactInputSingleParams',
                    name: 'params',
                    type: 'tuple'
                }
            ],
            name: 'exactInputSingle',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
{
            inputs: [
                {
                    components: [
                        {
                            internalType: 'bytes',
                            name: 'path',
                            type: 'bytes'
                        },
                        {
                            internalType: 'address',
                            name: 'recipient',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMaximum',
                            type: 'uint256'
                        }
                    ],
                    internalType: 'struct ISwapRouter.ExactOutputParams',
                    name: 'params',
                    type: 'tuple'
                }
            ],
            name: 'exactOutput',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    components: [
                        {
                            internalType: 'address',
                            name: 'tokenIn',
                            type: 'address'
                        },
                        {
                            internalType: 'address',
                            name: 'tokenOut',
                            type: 'address'
                        },
                        {
                            internalType: 'uint24',
                            name: 'fee',
                            type: 'uint24'
                        },
                        {
                            internalType: 'address',
                            name: 'recipient',
                            type: 'address'
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMaximum',
                            type: 'uint256'
                        },
                        {
                            internalType: 'uint160',
                            name: 'sqrtPriceLimitX96',
                            type: 'uint160'
                        }
                    ],
                    internalType: 'struct ISwapRouter.ExactOutputSingleParams',
                    name: 'params',
                    type: 'tuple'
                }
            ],
            name: 'exactOutputSingle',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                }
            ],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'token',
                    type: 'address'
                },
                {
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256'
                }
            ],
            name: 'unwrapWETH9',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
        },
        {
            stateMutability: 'payable',
            type: 'receive'
        }
    ],

    // Uniswap V3 - Quoter Interface
    UNISWAPV3_QUOTER: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_factory',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: '_WETH9',
                    type: 'address'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
        },
{
            inputs: [],
            name: 'WETH9',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [],
            name: 'factory',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address'
                }
            ],
            stateMutability: 'view',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes',
                    name: 'path',
                    type: 'bytes'
                },
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                }
            ],
            name: 'quoteExactInput',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'tokenIn',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'tokenOut',
                    type: 'address'
                },
                {
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                },
                {
                    internalType: 'uint160',
                    name: 'sqrtPriceLimitX96',
                    type: 'uint160'
                }
            ],
            name: 'quoteExactInputSingle',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'bytes',
                    name: 'path',
                    type: 'bytes'
                },
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                }
            ],
            name: 'quoteExactOutput',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'tokenIn',
                    type: 'address'
                },
                {
                    internalType: 'address',
                    name: 'tokenOut',
                    type: 'address'
                },
                {
                    internalType: 'uint24',
                    name: 'fee',
                    type: 'uint24'
                },
                {
                    internalType: 'uint256',
                    name: 'amountOut',
                    type: 'uint256'
                },
                {
                    internalType: 'uint160',
                    name: 'sqrtPriceLimitX96',
                    type: 'uint160'
                }
            ],
            name: 'quoteExactOutputSingle',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amountIn',
                    type: 'uint256'
                }
            ],
            stateMutability: 'nonpayable',
            type: 'function'
        }
    ]
};
// Utility functions
const utils = {
    // Provider utilities 
    providers: {
        createHttpProvider: (url, options = {}) => {
            return new Web3.providers.HttpProvider(url, {
                timeout: options.timeout || 30000,
                headers: options.headers,
                keepAlive: options.keepAlive || false,
                withCredentials: options.withCredentials || false
            });
        },

        createWsProvider: (url, options = {}) => {
            return new Web3.providers.WebsocketProvider(url, {
                timeout: options.timeout || 30000,
                reconnect: {
                    auto: true,
                    delay: 5000,
                    maxAttempts: 5,
                    onTimeout: false,
                    ...options.reconnect
                },
                clientConfig: {
                    maxReceivedFrameSize: 100000000,
                    maxReceivedMessageSize: 100000000,
                    keepalive: true,
                    keepaliveInterval: 60000,
                    ...options.clientConfig
                }
            });
        }
    },

    // Blockchain monitoring
    monitor: {
        watchBlocks: (web3, callback) => {
            return web3.eth.subscribe('newBlockHeaders')
                .on('data', callback)
                .on('error', error => {
                    throw new EVMError(`Block monitoring failed: ${error.message}`, 'MONITOR_ERROR', error);
                });
        },

        watchPendingTx: (web3, callback) => {
            return web3.eth.subscribe('pendingTransactions')
                .on('data', callback)
                .on('error', error => {
                    throw new EVMError(`Transaction monitoring failed: ${error.message}`, 'MONITOR_ERROR', error);
                });
        },

        watchEvents: (contract, eventName, options = {}, callback) => {
            return contract.events[eventName](options)
                .on('data', event => callback(event))
                .on('error', error => {
                    throw new EVMError(`Event monitoring failed: ${error.message}`, 'MONITOR_ERROR', error);
                });
        }
    },

    // Token utilities
    tokens: {
        getTokenInfo: async (web3, tokenAddress) => {
            try {
                const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
                const [name, symbol, decimals, totalSupply] = await Promise.all([
                    token.methods.name().call(),
                    token.methods.symbol().call(),
                    token.methods.decimals().call(),
                    token.methods.totalSupply().call()
                ]);

                return {
                    address: tokenAddress,
                    name,
                    symbol,
                    decimals: Number(decimals),
                    totalSupply: totalSupply
                };
            } catch (error) {
                throw new EVMError(`Get token info failed: ${error.message}`, 'TOKEN_INFO_ERROR', error);
            }
        },

        getAllowance: async (web3, tokenAddress, ownerAddress, spenderAddress) => {
            try {
                const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
                return await token.methods.allowance(ownerAddress, spenderAddress).call();
            } catch (error) {
                throw new EVMError(`Get allowance failed: ${error.message}`, 'ALLOWANCE_ERROR', error);
            }
        },

        approveToken: async (web3, tokenAddress, spenderAddress, amount, options = {}) => {
            try {
                const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
                const gasLimit = options.gasLimit || CONSTANTS.GAS.LIMITS.APPROVE;
                const txData = token.methods.approve(spenderAddress, amount).encodeABI();

                const tx = {
                    from: options.from,
                    to: tokenAddress,
                    data: txData,
                    gas: gasLimit,
                    ...options
                };

                return await web3.eth.sendTransaction(tx);
            } catch (error) {
                throw new EVMError(`Token approval failed: ${error.message}`, 'APPROVE_ERROR', error);
            }
        }
    },

    // DEX utilities
    dex: {
        getPairAddress: async (web3, factoryAddress, token0, token1) => {
            try {
                const factory = new web3.eth.Contract(ABIS.FACTORY, factoryAddress);
                return await factory.methods.getPair(token0, token1).call();
            } catch (error) {
                throw new EVMError(`Get pair address failed: ${error.message}`, 'PAIR_ADDRESS_ERROR', error);
            }
        },
getReserves: async (web3, pairAddress) => {
            try {
                const pair = new web3.eth.Contract(ABIS.PAIR, pairAddress);
                const { reserve0, reserve1, blockTimestampLast } = await pair.methods.getReserves().call();
                return {
                    reserve0,
                    reserve1,
                    blockTimestampLast: Number(blockTimestampLast)
                };
            } catch (error) {
                throw new EVMError(`Get reserves failed: ${error.message}`, 'RESERVES_ERROR', error);
            }
        },

        getTokenPrice: async (web3, pairAddress, tokenAddress) => {
            try {
                const pair = new web3.eth.Contract(ABIS.PAIR, pairAddress);
                const [token0, token1] = await Promise.all([
                    pair.methods.token0().call(),
                    pair.methods.token1().call()
                ]);

                const { reserve0, reserve1 } = await pair.methods.getReserves().call();
                const isToken0 = tokenAddress.toLowerCase() === token0.toLowerCase();
                
                const price = isToken0 ? 
                    web3.utils.toBN(reserve1).mul(web3.utils.toBN('1000000000000000000')).div(web3.utils.toBN(reserve0)) :
                    web3.utils.toBN(reserve0).mul(web3.utils.toBN('1000000000000000000')).div(web3.utils.toBN(reserve1));
                
                return {
                    price: price.toString(),
                    decimals: 18
                };
            } catch (error) {
                throw new EVMError(`Get token price failed: ${error.message}`, 'PRICE_ERROR', error);
            }
        },

        calculatePriceImpact: (amountIn, reserveIn, reserveOut) => {
            try {
                const numerator = web3.utils.toBN(amountIn).mul(web3.utils.toBN(reserveOut));
                const denominator = web3.utils.toBN(reserveIn).mul(web3.utils.toBN('1000'));
                return numerator.mul(web3.utils.toBN('10000')).div(denominator).toNumber() / 10000;
            } catch (error) {
                throw new EVMError(`Calculate price impact failed: ${error.message}`, 'CALC_ERROR', error);
            }
        }
    }
};

// Validation utilities
const validators = {
    isValidAddress: (web3, address) => {
        return web3.utils.isAddress(address);
    },

    isValidPrivateKey: (privateKey) => {
        try {
            const key = privateKey.startsWith('0x') ? privateKey : '0x' + privateKey;
            return /^0x[0-9a-fA-F]{64}$/.test(key);
        } catch {
            return false;
        }
    },

    validateTxParams: (params) => {
        const required = ['from', 'to', 'value'];
        const missing = required.filter(key => !params[key]);
        if (missing.length > 0) {
            throw new EVMError(`Missing required parameters: ${missing.join(', ')}`, 'INVALID_PARAMS');
        }
        return true;
    },

    isValidRpcUrl: (url) => {
        try {
            new URL(url);
            return url.startsWith('http://') || 
                   url.startsWith('https://') || 
                   url.startsWith('ws://') || 
                   url.startsWith('wss://');
        } catch {
            return false;
        }
    }
};

// Initialization function
const initialize = async (web3) => {
    try {
        // Check network connection
        await web3.eth.net.isListening();
        
        // Get network ID
        const chainId = await web3.eth.getChainId();
        const networkConfig = NETWORK_CONFIG[chainId];
        
        if (!networkConfig) {
            throw new EVMError(`Unsupported network: ${chainId}`, 'UNSUPPORTED_NETWORK');
        }

        // Check Multicall contract
        const multicallAddress = networkConfig.contracts.multicall2;
        const code = await web3.eth.getCode(multicallAddress);
        
        if (code === '0x') {
            throw new EVMError('Multicall contract not deployed', 'CONTRACT_NOT_FOUND');
        }

        return {
            initialized: true,
            chainId,
            networkConfig
        };
    } catch (error) {
        throw new EVMError(`Initialization failed: ${error.message}`, 'INIT_ERROR', error);
    }
};

// Module exports
module.exports = {
    CONSTANTS,
    NETWORK_CONFIG,
    ABIS,
    utils,
    validators,
    initialize,
    EVMError
};
