const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Alert,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  CircularProgress
} = require('@mui/material');
const Web3 = require('web3');
const {
  CONSTANTS,
  NETWORK_CONFIG,
  ABIS
} = require('./evm');

// 常量定義
const BATCH_MODES = {
  TRANSFER: 'transfer',
  COLLECT: 'collect'
};

const BatchTokenPanel = () => {
  // 基礎狀態
  const [mode, setMode] = React.useState(BATCH_MODES.TRANSFER);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [walletWarning, setWalletWarning] = React.useState('');

  // 共用設置
  const [gasPrice, setGasPrice] = React.useState('');
  const [gasLimit, setGasLimit] = React.useState('100000');
  const [tokenContract, setTokenContract] = React.useState('');

  // 批量轉出設置
  const [transferAmount, setTransferAmount] = React.useState('');
  const [receiverAddresses, setReceiverAddresses] = React.useState('');

  // 批量歸集設置
  const [collectAmount, setCollectAmount] = React.useState('');
  const [collectAddress, setCollectAddress] = React.useState('');
  const [useMax, setUseMax] = React.useState(false);
  const [maxAmounts, setMaxAmounts] = React.useState({});

  // 監控錢包數量
  React.useEffect(() => {
    const checkWallets = () => {
      if (!window._userAddresses) return;

      const walletCount = window._userAddresses.length;
      if (mode === BATCH_MODES.TRANSFER && walletCount > 1) {
        setWalletWarning('批量轉出只能使用一個錢包，請移除多餘的錢包');
      } else if (mode === BATCH_MODES.COLLECT && walletCount < 2) {
        setWalletWarning('批量歸集需要至少兩個錢包，請添加更多錢包');
      } else {
        setWalletWarning('');
      }
    };

    checkWallets();
  }, [mode, window._userAddresses]);

  // 獲取代幣餘額
  const getTokenBalance = async (tokenAddress, walletAddress) => {
    try {
      const web3 = window._web3Instance;
      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      const balance = await tokenContract.methods.balanceOf(walletAddress).call();
      return balance;
    } catch (err) {
      console.error('Get token balance error:', err);
      throw err;
    }
  };

  // 更新最大可用金額
  const updateMaxAmounts = async () => {
    if (!tokenContract || !window._userAddresses) return;

    try {
      const amounts = {};
      for (const address of window._userAddresses) {
        const balance = await getTokenBalance(tokenContract, address);
        amounts[address] = balance;
      }
      setMaxAmounts(amounts);
    } catch (err) {
      console.error('Update max amounts error:', err);
      setError('獲取餘額失敗: ' + err.message);
    }
  };
  // 執行批量轉出
  const executeTransfer = async () => {
    try {
      if (!window._web3Instance) {
        throw new Error('請先連接RPC節點');
      }

      if (!window._userAddresses?.length === 1) {
        throw new Error('批量轉出只能使用一個錢包');
      }

      const web3 = window._web3Instance;
      const fromAddress = window._userAddresses[0];
      const addresses = receiverAddresses.split('\n')
        .map(addr => addr.trim())
        .filter(addr => addr);

      if (addresses.length === 0) {
        throw new Error('請輸入接收地址');
      }

      // 驗證所有地址
      for (const addr of addresses) {
        if (!web3.utils.isAddress(addr)) {
          throw new Error(`無效的接收地址: ${addr}`);
        }
      }

      // 驗證代幣合約
      if (!web3.utils.isAddress(tokenContract)) {
        throw new Error('無效的代幣合約地址');
      }

      // 檢查餘額
      const token = new web3.eth.Contract(ABIS.ERC20, tokenContract);
      const balance = await token.methods.balanceOf(fromAddress).call();
      const amount = web3.utils.toWei(transferAmount, 'ether');
      const totalNeeded = BigInt(amount) * BigInt(addresses.length);

      if (BigInt(balance) < totalNeeded) {
        throw new Error('餘額不足');
      }

      setLoading(true);
      setError('');

      // 執行轉賬
      for (const toAddress of addresses) {
        await token.methods.transfer(toAddress, amount).send({
          from: fromAddress,
          gasPrice: web3.utils.toWei(gasPrice, 'gwei'),
          gas: gasLimit
        });
      }

      alert('批量轉出完成');
    } catch (err) {
      console.error('Transfer error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // 執行批量歸集
  const executeCollect = async () => {
    try {
      if (!window._web3Instance) {
        throw new Error('請先連接RPC節點');
      }

      if (!window._userAddresses || window._userAddresses.length < 2) {
        throw new Error('批量歸集需要至少兩個錢包');
      }

      const web3 = window._web3Instance;
      
      // 驗證歸集地址
      if (!web3.utils.isAddress(collectAddress)) {
        throw new Error('無效的歸集地址');
      }

      // 驗證代幣合約
      if (!web3.utils.isAddress(tokenContract)) {
        throw new Error('無效的代幣合約地址');
      }

      setLoading(true);
      setError('');

      const token = new web3.eth.Contract(ABIS.ERC20, tokenContract);

      // 執行歸集
      for (const fromAddress of window._userAddresses) {
        // 跳過歸集地址本身
        if (fromAddress.toLowerCase() === collectAddress.toLowerCase()) continue;

        let amount;
        if (useMax) {
          // 使用最大可用金額
          amount = await token.methods.balanceOf(fromAddress).call();
          if (BigInt(amount) === BigInt(0)) continue;
        } else {
          // 使用指定金額
          amount = web3.utils.toWei(collectAmount, 'ether');
          // 檢查餘額
          const balance = await token.methods.balanceOf(fromAddress).call();
          if (BigInt(balance) < BigInt(amount)) {
            console.warn(`地址 ${fromAddress} 餘額不足，已跳過`);
            continue;
          }
        }

        await token.methods.transfer(collectAddress, amount).send({
          from: fromAddress,
          gasPrice: web3.utils.toWei(gasPrice, 'gwei'),
          gas: gasLimit
        });
      }

      alert('批量歸集完成');
    } catch (err) {
      console.error('Collect error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // 檢查執行條件
  const validateExecuteConditions = () => {
    if (!gasPrice) {
      setError('請輸入Gas Price');
      return false;
    }

    if (!gasLimit) {
      setError('請輸入Gas Limit');
      return false;
    }

    if (!tokenContract) {
      setError('請輸入代幣合約地址');
      return false;
    }

    if (mode === BATCH_MODES.TRANSFER) {
      if (!transferAmount) {
        setError('請輸入轉出數量');
        return false;
      }
      if (!receiverAddresses) {
        setError('請輸入接收地址');
        return false;
      }
    } else {
      if (!useMax && !collectAmount) {
        setError('請輸入歸集數量或選擇最大金額');
        return false;
      }
      if (!collectAddress) {
        setError('請輸入歸集地址');
        return false;
      }
    }

    return true;
  };

  // 執行操作
  const handleExecute = async () => {
    if (!validateExecuteConditions()) return;

    try {
      if (mode === BATCH_MODES.TRANSFER) {
        await executeTransfer();
      } else {
        await executeCollect();
      }
    } catch (err) {
      console.error('Execute error:', err);
      setError(err.message);
    }
  };
  // 主渲染
  return (
    <Box>
      {/* 功能選擇 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                value={mode}
                onChange={(e) => setMode(e.target.value)}
                row
              >
                <FormControlLabel 
                  value={BATCH_MODES.TRANSFER} 
                  control={<Radio />} 
                  label="代幣批量轉出" 
                />
                <FormControlLabel 
                  value={BATCH_MODES.COLLECT} 
                  control={<Radio />} 
                  label="代幣批量歸集" 
                />
              </RadioGroup>
            </Grid>

            {/* 錢包警告 */}
            {walletWarning && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  {walletWarning}
                </Alert>
              </Grid>
            )}

            {/* 共用設置 */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Gas Price (Gwei)"
                type="number"
                value={gasPrice}
                onChange={(e) => setGasPrice(e.target.value)}
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Gas Limit"
                type="number"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="代幣合約地址"
                value={tokenContract}
                onChange={(e) => setTokenContract(e.target.value)}
              />
            </Grid>

            {/* 批量轉出設置 */}
            {mode === BATCH_MODES.TRANSFER && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="每個地址轉出數量"
                    type="number"
                    value={transferAmount}
                    onChange={(e) => setTransferAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="接收地址列表 (一行一個)"
                    multiline
                    rows={4}
                    value={receiverAddresses}
                    onChange={(e) => setReceiverAddresses(e.target.value)}
                    placeholder="0x..."
                  />
                </Grid>
              </>
            )}

            {/* 批量歸集設置 */}
            {mode === BATCH_MODES.COLLECT && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="每個錢包歸集數量"
                    type="number"
                    value={collectAmount}
                    onChange={(e) => setCollectAmount(e.target.value)}
                    disabled={useMax}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setUseMax(!useMax);
                      if (!useMax) {
                        setCollectAmount('');
                        updateMaxAmounts();
                      }
                    }}
                    fullWidth
                    sx={{ height: '56px' }}
                  >
                    {useMax ? '取消最大數量' : '使用最大數量'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="歸集接收地址"
                    value={collectAddress}
                    onChange={(e) => setCollectAddress(e.target.value)}
                    placeholder="0x..."
                  />
                </Grid>
                {useMax && Object.keys(maxAmounts).length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      各地址可用餘額:
                    </Typography>
                    {Object.entries(maxAmounts).map(([address, amount]) => (
                      <Typography key={address} variant="body2">
                        {address.slice(0, 6)}...{address.slice(-4)}: {
                          window._web3Instance?.utils.fromWei(amount, 'ether')
                        }
                      </Typography>
                    ))}
                  </Grid>
                )}
              </>
            )}

            {/* 執行按鈕 */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleExecute}
                fullWidth
                disabled={loading || !!walletWarning}
              >
                {loading ? 
                  <CircularProgress size={24} /> : 
                  mode === BATCH_MODES.TRANSFER ? '執行批量轉出' : '執行批量歸集'
                }
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* 錯誤提示 */}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

module.exports = BatchTokenPanel;
