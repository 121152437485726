const React = require('react');
const { 
  Box, Card, CardContent, Typography, TextField, Button, Alert, RadioGroup,
  Radio, FormControlLabel, Grid, LinearProgress, Divider, List, ListItem,
  ListItemText, Link, Select, MenuItem, CircularProgress, Tooltip, IconButton,
  Chip, FormControl, InputLabel
} = require('@mui/material');

const { Info: InfoIcon } = require('@mui/icons-material');
const Web3 = require('web3');

// 常量定義
const CONSTANTS = {
  ADDRESSES: {
    ZERO: "0x0000000000000000000000000000000000000000"
  }
};

// WebSocket相關常量
const WS_RECONNECT_DELAY = 5000;
const WS_CONNECTION_TIMEOUT = 10000;
const WS_RECONNECT_MAX_RETRIES = 3;
const WS_PING_INTERVAL = 15000;
const WS_PONG_TIMEOUT = 5000;

// 交易相關常量
const MAX_GAS_PRICE = BigInt("500000000000"); // 500 Gwei
const DEFAULT_DEADLINE_SECONDS = 300;
const BATCH_SIZE = 10;
const CACHE_EXPIRY = 30000; // 緩存過期時間，30秒

// 鏈相關常量
const SAME_BLOCK_FRONTRUN_CHAINS = ['1', '56', '146', '1116', '20201022'];
const L2_CHAINS = ['8453', '42161', '43114'];

// 各鏈區塊時間 (毫秒)
const CHAIN_BLOCK_TIME = {
  '1': 12000,    // ETH: 12秒
  '56': 3000,    // BSC: 3秒
  '146': 1000,   // SONIC: 1秒
  '8453': 2000,  // Base: 2秒
  '42161': 250,  // Arbitrum: 0.25秒
  '1116': 3000,  // Core: 3秒
  '20201022': 3000, // PEGO: 3秒
  '43114': 2000  // AVAX: 2秒
};

// WebSocket状态常量
const WS_STATUS = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3
};

// 路徑類型常量
const PATH_TYPES = {
  TWO_HOP: 'two_hop',  // 兩級路徑
  THREE_HOP: 'three_hop' // 三級路徑
};

// 內置必要的ABI
const ABIS = {
  // ERC20代币合约ABI
  ERC20: [
    {
      "constant": true,
      "inputs": [],
      "name": "name",
      "outputs": [{ "name": "", "type": "string" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "symbol",
      "outputs": [{ "name": "", "type": "string" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "decimals",
      "outputs": [{ "name": "", "type": "uint8" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [{ "name": "owner", "type": "address" }],
      "name": "balanceOf",
      "outputs": [{ "name": "", "type": "uint256" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [{ "name": "owner", "type": "address" }, { "name": "spender", "type": "address" }],
      "name": "allowance",
      "outputs": [{ "name": "", "type": "uint256" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [{ "name": "spender", "type": "address" }, { "name": "value", "type": "uint256" }],
      "name": "approve",
      "outputs": [{ "name": "", "type": "bool" }],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [{ "name": "to", "type": "address" }, { "name": "value", "type": "uint256" }],
      "name": "transfer",
      "outputs": [{ "name": "", "type": "bool" }],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  
  // Uniswap/Pancakeswap工厂合约ABI
  FACTORY: [
    {
      "constant": true,
      "inputs": [{ "internalType": "address", "name": "tokenA", "type": "address" }, { "internalType": "address", "name": "tokenB", "type": "address" }],
      "name": "getPair",
      "outputs": [{ "internalType": "address", "name": "pair", "type": "address" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "feeTo",
      "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ],
  
  // Uniswap/Pancakeswap配对合约ABI
  PAIR: [
    {
      "constant": true,
      "inputs": [],
      "name": "token0",
      "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "token1",
      "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "getReserves",
      "outputs": [
        { "internalType": "uint112", "name": "_reserve0", "type": "uint112" },
        { "internalType": "uint112", "name": "_reserve1", "type": "uint112" },
        { "internalType": "uint32", "name": "_blockTimestampLast", "type": "uint32" }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ]
};
// 補充 ABIS 對象中的路由器合約 ABI
Object.assign(ABIS, {
  // Uniswap/Pancakeswap路由器合约ABI
  ROUTER: [
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactTokensForTokens",
      "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactTokensForETH",
      "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactETHForTokens",
      "outputs": [{ "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
        { "internalType": "uint256", "name": "amountOutMin", "type": "uint256" },
        { "internalType": "address[]", "name": "path", "type": "address[]" },
        { "internalType": "address", "name": "to", "type": "address" },
        { "internalType": "uint256", "name": "deadline", "type": "uint256" }
      ],
      "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ],
  
  // UniswapV3 Router ABI (簡化版)
  ROUTER_V3: [
    {
      "inputs": [
        {
          "components": [
            { "internalType": "address", "name": "tokenIn", "type": "address" },
            { "internalType": "address", "name": "tokenOut", "type": "address" },
            { "internalType": "uint24", "name": "fee", "type": "uint24" },
            { "internalType": "address", "name": "recipient", "type": "address" },
            { "internalType": "uint256", "name": "deadline", "type": "uint256" },
            { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
            { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" },
            { "internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160" }
          ],
          "internalType": "struct ISwapRouter.ExactInputSingleParams",
          "name": "params",
          "type": "tuple"
        }
      ],
      "name": "exactInputSingle",
      "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "components": [
            { "internalType": "bytes", "name": "path", "type": "bytes" },
            { "internalType": "address", "name": "recipient", "type": "address" },
            { "internalType": "uint256", "name": "deadline", "type": "uint256" },
            { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
            { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" }
          ],
          "internalType": "struct ISwapRouter.ExactInputParams",
          "name": "params",
          "type": "tuple"
        }
      ],
      "name": "exactInput",
      "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
      "stateMutability": "payable",
      "type": "function"
    }
  ]
});

// DEX函數簽名 (V2)
const DEX_SWAP_FUNCTIONS = {
  // Uniswap V2/PancakeSwap等
  '0x38ed1739': 'swapExactTokensForTokens',
  '0x8803dbee': 'swapTokensForExactTokens',
  '0x7ff36ab5': 'swapExactETHForTokens',
  '0x4a25d94a': 'swapTokensForExactETH',
  '0x18cbafe5': 'swapExactTokensForETH',
  '0xfb3bdb41': 'swapETHForExactTokens',
  // 支持轉賬稅的函數
  '0x5c11d795': 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
  '0xb6f9de95': 'swapExactETHForTokensSupportingFeeOnTransferTokens',
  '0x791ac947': 'swapExactTokensForETHSupportingFeeOnTransferTokens'
};

// DEX函數簽名 (V3)
const DEX_SWAP_FUNCTIONS_V3 = {
  '0x414bf389': 'exactInputSingle',
  '0xc04b8d59': 'exactInput'
};

// 統一所有DEX函數簽名
const ALL_DEX_SWAP_FUNCTIONS = {
  ...DEX_SWAP_FUNCTIONS,
  ...DEX_SWAP_FUNCTIONS_V3
};
// 找到當前網絡配置
const findCurrentNetwork = async (web3) => {
  try {
    // 获取当前链ID
    const chainId = await web3.eth.getChainId();
    const chainIdStr = chainId.toString();
    
    // 简单网络配置
    const networkConfigs = {
      '1': {
        chainId: '1',
        name: 'Ethereum Mainnet',
        nativeCurrency: { symbol: 'ETH' },
        explorer: { url: 'https://etherscan.io' },
        contracts: { 
          wrappedNative: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          v3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984', 
          v3Router: '0xE592427A0AEce92De3Edee1F18E0157C05861564'  
        },
        stablecoins: { 
          USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F'
        }
      },
      '56': {
        chainId: '56',
        name: 'Binance Smart Chain',
        nativeCurrency: { symbol: 'BNB' },
        explorer: { url: 'https://bscscan.com' },
        contracts: { 
          wrappedNative: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
          v3Factory: '0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865', 
          v3Router: '0x13f4EA83D0bd40E75C8222255bc855a974568Dd4'  
        },
        stablecoins: { 
          USDT: '0x55d398326f99059fF775485246999027B3197955',
          USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
          BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
        }
      },
      '146': {
        chainId: '146',
        name: 'Sonic Chain',
        nativeCurrency: { symbol: 'SONIC' },
        explorer: { url: 'https://explorer.sonic.org' },
        contracts: { 
          wrappedNative: '0xBdE6D5290bb866f3DAb641080FBd9274e7FCF985'
        },
        stablecoins: { 
          USDT: '0x922D641a426DcFFaeF11680e5358F34d97d112E1'
        }
      },
      '1116': {
        chainId: '1116',
        name: 'Core Chain',
        nativeCurrency: { symbol: 'CORE' },
        explorer: { url: 'https://scan.coredao.org' },
        contracts: { 
          wrappedNative: '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f'
        },
        stablecoins: { 
          USDT: '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1'
        }
      },
      '8453': {
        chainId: '8453',
        name: 'Base',
        nativeCurrency: { symbol: 'ETH' },
        explorer: { url: 'https://basescan.org' },
        contracts: { 
          wrappedNative: '0x4200000000000000000000000000000000000006',
          v3Factory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
          v3Router: '0x4200000000000000000000000000000000000015'
        },
        stablecoins: { 
          USDT: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
          USDC: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
        }
      },
      '42161': {
        chainId: '42161',
        name: 'Arbitrum One',
        nativeCurrency: { symbol: 'ETH' },
        explorer: { url: 'https://arbiscan.io' },
        contracts: { 
          wrappedNative: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
          v3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
          v3Router: '0xE592427A0AEce92De3Edee1F18E0157C05861564'
        },
        stablecoins: { 
          USDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
          USDC: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
        }
      },
      '43114': {
        chainId: '43114',
        name: 'Avalanche C-Chain',
        nativeCurrency: { symbol: 'AVAX' },
        explorer: { url: 'https://snowtrace.io' },
        contracts: { 
          wrappedNative: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
          v3Factory: '0x740b1c1de25031C31FF4fC9A62f554A55cdC1baD',
          v3Router: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4'
        },
        stablecoins: { 
          USDT: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
          USDC: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'
        }
      },
      '20201022': {
        chainId: '20201022',
        name: 'PEGO Network',
        nativeCurrency: { symbol: 'PG' },
        explorer: { url: 'https://scan.pego.network' },
        contracts: { 
          wrappedNative: '0x98f7A83361F7Ac8765CcEBAB1425da6b341958a7'
        },
        stablecoins: { 
          USDT: '0x02F9Ec8F7A9A6EF5dfC2D3041164BcrE2dCaa4C' 
        }
      }
    };
    
    // 返回相应网络配置
    const network = networkConfigs[chainIdStr];

    // 找不到配置时给出警告
    if (!network) {
      console.warn(`Network config not found for chain ID ${chainIdStr}`);
    }

    return network;
  } catch (error) {
    console.error('Find network error:', error);
    return null;
  }
};

// 緩存系統 - 提高性能
const cache = {
  data: {},
  // 設置緩存
  set: function(key, value, expiryMs = CACHE_EXPIRY) {
    this.data[key] = {
      value,
      expiry: Date.now() + expiryMs
    };
  },
  // 獲取緩存
  get: function(key) {
    const item = this.data[key];
    if (!item) return null;
    
    if (item.expiry < Date.now()) {
      delete this.data[key];
      return null;
    }
    
    return item.value;
  },
  // 刪除緩存
  delete: function(key) {
    delete this.data[key];
  },
  // 清空緩存
  clear: function() {
    this.data = {};
  }
};

// 格式化數字
const formatNumber = (num, decimals = 2) => {
  if (num === undefined || num === null) return '-';
  return num.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

// 格式化時間
const formatDuration = (milliseconds) => {
  if (!milliseconds) return '-';
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  
  return hours > 0 
    ? `${hours}h ${minutes % 60}m ${seconds % 60}s` 
    : minutes > 0 
      ? `${minutes}m ${seconds % 60}s` 
      : `${seconds}s`;
};

// 簡化地址顯示
const shortenAddress = (address, prefixLength = 6, suffixLength = 4) => {
  if (!address) return '';
  if (address.length <= prefixLength + suffixLength) return address;
  return `${address.slice(0, prefixLength)}...${address.slice(-suffixLength)}`;
};
// 檢查交易對是否存在
const checkPairExists = async (tokenA, tokenB, factory) => {
  if (!tokenA || !tokenB || !factory) return false;
  
  // 檢查緩存
  const cacheKey = `pair_exists_${tokenA.toLowerCase()}_${tokenB.toLowerCase()}`;
  const cachedResult = cache.get(cacheKey);
  if (cachedResult !== null) {
    return cachedResult;
  }
  
  try {
    const pairAddress = await factory.methods.getPair(tokenA, tokenB).call();
    const exists = pairAddress !== CONSTANTS.ADDRESSES.ZERO;
    // 設置緩存
    cache.set(cacheKey, exists);
    return exists;
  } catch (error) {
    console.error(`Check pair exists error (${tokenA}, ${tokenB}):`, error);
    return false;
  }
};

// 獲取代幣信息（精度、符號等）
const getTokenInfo = async (tokenAddress, web3) => {
  if (!tokenAddress || !web3) {
    return { decimals: 18, symbol: '?', name: '?' };
  }
  
  // 檢查緩存
  const cacheKey = `token_info_${tokenAddress.toLowerCase()}`;
  const cachedInfo = cache.get(cacheKey);
  if (cachedInfo) {
    return cachedInfo;
  }
  
  try {
    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    
    // 並行獲取信息
    const [decimalsResult, symbolResult, nameResult] = await Promise.allSettled([
      tokenContract.methods.decimals().call(),
      tokenContract.methods.symbol().call(),
      tokenContract.methods.name().call()
    ]);
    
    const decimals = decimalsResult.status === 'fulfilled' ? parseInt(decimalsResult.value) : 18;
    const symbol = symbolResult.status === 'fulfilled' ? symbolResult.value : '?';
    const name = nameResult.status === 'fulfilled' ? nameResult.value : symbol;
    
    const tokenInfo = { decimals, symbol, name };
    
    // 設置緩存
    cache.set(cacheKey, tokenInfo);
    
    return tokenInfo;
  } catch (error) {
    console.error(`Get token info error (${tokenAddress}):`, error);
    return { decimals: 18, symbol: '?', name: '?' };
  }
};

// 驗證代幣合約是否存在
const validateTokenContract = async (tokenAddress, web3) => {
  if (!tokenAddress || !web3) return false;
  
  // 檢查緩存
  const cacheKey = `token_valid_${tokenAddress.toLowerCase()}`;
  const cachedResult = cache.get(cacheKey);
  if (cachedResult !== null) {
    return cachedResult;
  }
  
  try {
    const code = await web3.eth.getCode(tokenAddress);
    const isValid = code !== '0x' && code !== '0x0';
    
    // 設置緩存
    cache.set(cacheKey, isValid);
    
    return isValid;
  } catch (error) {
    console.error(`Validate token contract error (${tokenAddress}):`, error);
    return false;
  }
};

// 從流動性池中獲取代幣價格 - 使用 BigInt
const getTokenPriceFromPair = async (tokenA, tokenB, pairAddress, web3) => {
  // 檢查緩存
  const cacheKey = `token_price_${tokenA.toLowerCase()}_${tokenB.toLowerCase()}`;
  const cachedPrice = cache.get(cacheKey);
  if (cachedPrice !== null) {
    return cachedPrice;
  }
  
  try {
    // 检查参数有效性
    if (!tokenA || !tokenB || !pairAddress || !web3) {
      console.warn('Invalid parameters for getTokenPriceFromPair');
      return 0;
    }
    
    // 检查合约是否存在
    const code = await web3.eth.getCode(pairAddress);
    if (code === '0x' || code === '0x0') {
      console.warn(`Pair contract does not exist: ${pairAddress}`);
      return 0;
    }
    
    const pairContract = new web3.eth.Contract(ABIS.PAIR, pairAddress);
    
    // 並行獲取數據提高效率
    const [token0, reserves, tokenAInfo, tokenBInfo] = await Promise.all([
      pairContract.methods.token0().call(),
      pairContract.methods.getReserves().call(),
      getTokenInfo(tokenA, web3),
      getTokenInfo(tokenB, web3)
    ]);
    
    // 確定哪個代幣是token0，哪個是token1
    const isTokenAToken0 = tokenA.toLowerCase() === token0.toLowerCase();
    
    // 提取並調整儲備量 - 全面使用 BigInt
    const reserveA = BigInt(isTokenAToken0 ? reserves._reserve0 : reserves._reserve1);
    const reserveB = BigInt(isTokenAToken0 ? reserves._reserve1 : reserves._reserve0);
    
    // 將 BigInt 轉換回可讀格式用於計算
    const adjustedReserveA = parseFloat(fromBigInt(reserveA, tokenAInfo.decimals));
    const adjustedReserveB = parseFloat(fromBigInt(reserveB, tokenBInfo.decimals));
    
    // 防止除以零
    if (adjustedReserveA === 0) {
      console.warn('Token A reserve is zero, cannot calculate price');
      return 0;
    }
    
    // 計算價格(B/A)：每單位A值多少B
    const price = adjustedReserveB / adjustedReserveA;
    
    // 儲存到緩存
    cache.set(cacheKey, price, 10000); // 10秒緩存
    
    return price;
  } catch (error) {
    console.error(`Get price from pair error (${tokenA}, ${tokenB}):`, error);
    return 0;
  }
};
// 安全地轉換為大整數，處理各種精度 - 全面使用 BigInt
const toBigInt = (amount, decimals = 18) => {
  try {
    // 處理空值或負值
    if (!amount || parseFloat(amount) < 0) return BigInt(0);
    
    const floatValue = parseFloat(amount);
    if (isNaN(floatValue)) return BigInt(0);
    
    // 計算乘數
    const multiplier = BigInt(10) ** BigInt(decimals);
    
    // 防止精度丟失，先將小數部分轉換，再與整數部分組合
    const parts = amount.toString().split('.');
    const wholePart = parts[0] || '0';
    let fractionalPart = parts[1] || '';
    
    // 截斷過長的小數部分
    if (fractionalPart.length > decimals) {
      fractionalPart = fractionalPart.slice(0, decimals);
    } else {
      // 補足小數部分長度
      fractionalPart = fractionalPart.padEnd(decimals, '0');
    }
    
    // 組合整數和小數部分
    try {
      const wholeValue = BigInt(wholePart) * multiplier;
      const fractionalValue = fractionalPart.length > 0 ? BigInt(fractionalPart) : BigInt(0);
      return wholeValue + fractionalValue;
    } catch (e) {
      console.error('Error in toBigInt:', e, { amount, decimals, wholePart, fractionalPart });
      
      // 使用更安全的轉換方法作為後備方案
      if (floatValue === 0) return BigInt(0);
      
      // 處理大數轉換，防止超過 53 位精度限制
      const wholeNumber = Math.floor(floatValue);
      const fraction = floatValue - wholeNumber;
      
      // 轉換整數部分
      let result = BigInt(wholeNumber) * multiplier;
      
      // 處理小數部分 - 使用字符串操作避免精度問題
      if (fraction > 0) {
        const fractionStr = fraction.toFixed(decimals).split('.')[1];
        if (fractionStr) {
          const fractionValue = BigInt(fractionStr);
          result += fractionValue;
        }
      }
      
      return result;
    }
  } catch (error) {
    console.error('Convert to BigInt error:', error, { amount, decimals });
    return BigInt(0);
  }
};

// 安全地從大整數轉換為人類可讀格式
const fromBigInt = (bigIntValue, decimals = 18) => {
  try {
    if (!bigIntValue) return '0';
    
    // 確保輸入是BigInt
    const value = BigInt(bigIntValue.toString());
    
    // 如果是0，直接返回
    if (value === BigInt(0)) return '0';
    
    // 計算除數
    const divisor = BigInt(10) ** BigInt(decimals);
    
    // 計算整數部分和小數部分
    const integerPart = value / divisor;
    const fractionalPart = value % divisor;
    
    // 格式化小數部分
    let fractionalStr = fractionalPart.toString().padStart(decimals, '0');
    // 移除尾部的0
    fractionalStr = fractionalStr.replace(/0+$/, '');
    
    // 組合結果
    if (fractionalStr.length > 0) {
      return `${integerPart}.${fractionalStr}`;
    } else {
      return integerPart.toString();
    }
  } catch (error) {
    console.error('Convert from BigInt error:', error, { bigIntValue, decimals });
    return '0';
  }
};
// 解析交易路徑 - 支持v2和v3
const decodeSwapPath = (data, methodId) => {
  try {
    if (!data || data === '0x') return null;
    
    const web3 = window._web3Instance;
    if (!web3) {
      console.warn('Web3 instance not available');
      return null;
    }
    
    // 檢查是否是V3方法
    if (DEX_SWAP_FUNCTIONS_V3[methodId]) {
      return decodeV3SwapPath(data, methodId, web3);
    }
    
    // 以下處理V2方法
    let pathOffset;
    
    if (['0x38ed1739', '0x8803dbee', '0x5c11d795', '0x18cbafe5', '0x4a25d94a', '0x791ac947'].includes(methodId)) {
      // swapExactTokensForTokens, swapTokensForExactTokens, swapExactTokensForETH
      pathOffset = 3; // 在第4個參數
    } else if (['0x7ff36ab5', '0xfb3bdb41', '0xb6f9de95'].includes(methodId)) {
      // swapExactETHForTokens, swapETHForExactTokens
      pathOffset = 2; // 在第3個參數
    } else {
      return null;
    }
    
    // 解析路徑參數的位置和長度
    const pathPosition = 2 + pathOffset * 32; // 頭部 + 偏移量
    
    // 確保數據長度足夠
    if (data.length < pathPosition + 66) {
      console.warn('Data too short for path parameter');
      return null;
    }
    
    // 使用 BigInt 處理位置解析，避免大數問題
    const pathDataOffsetHex = '0x' + data.slice(pathPosition + 2, pathPosition + 66);
    
    // 特別檢查: 如果這個值超過安全整數範圍，直接用 BigInt
    let pathDataOffset;
    try {
      const bigPathOffset = BigInt(pathDataOffsetHex);
      // 根據 BigInt 值計算偏移，但確保結果在安全範圍內
      if (bigPathOffset > BigInt(Number.MAX_SAFE_INTEGER)) {
        console.warn('Path offset is too large for safe integers, using string operations');
        // 對於超大值，我們使用字符串操作而不是數字運算
        const offsetStr = bigPathOffset.toString(16); // 轉為十六進制字符串
        pathDataOffset = parseInt(offsetStr, 16) * 2;
      } else {
        pathDataOffset = Number(bigPathOffset) * 2;
      }
    } catch (e) {
      console.error('Error parsing path offset:', e);
      return null;
    }
    
    const pathStartPos = pathDataOffset + 2; // 加上 "0x" 
    
    // 確保數據長度足夠
    if (data.length < pathStartPos + 64) {
      console.warn('Data too short for path extraction');
      return null;
    }
    
    // 解析路徑數組長度 - 安全處理大數
    const pathLengthHex = '0x' + data.slice(pathStartPos, pathStartPos + 64);
    let pathLength;
    
    try {
      const bigPathLength = BigInt(pathLengthHex);
      if (bigPathLength > BigInt(1000)) {
        // 長度不可能很大，這是錯誤的數據
        console.warn('Suspicious path length:', bigPathLength.toString());
        return null;
      }
      pathLength = Number(bigPathLength);
    } catch (e) {
      console.error('Error parsing path length:', e);
      return null;
    }
    
    // 驗證路徑長度合理性
    if (pathLength <= 0 || pathLength > 10) {
      console.warn(`Invalid path length: ${pathLength}`);
      return null;
    }
    
    // 確保數據長度足夠包含所有地址
    if (data.length < pathStartPos + 64 + pathLength * 64) {
      console.warn('Data too short for all path addresses');
      return null;
    }
    
    // 解析路徑中的每個地址
    const path = [];
    for (let i = 0; i < pathLength; i++) {
      const addrPos = pathStartPos + 64 + i * 64;
      const addr = '0x' + data.slice(addrPos + 24, addrPos + 64);
      try {
        path.push(web3.utils.toChecksumAddress(addr));
      } catch (error) {
        console.warn(`Invalid address in path: ${addr}`);
        return null;
      }
    }
    
    return path;
  } catch (error) {
    console.error('Decode swap path error:', error);
    return null;
  }
};

// 解析V3交易路徑
const decodeV3SwapPath = (data, methodId, web3) => {
  try {
    // 對於 exactInputSingle
    if (methodId === '0x414bf389') {
      // exactInputSingle 的第一個參數是一個包含 tokenIn 和 tokenOut 的結構體
      // tokenIn 在偏移量 0, tokenOut 在偏移量 32
      
      // 獲取 tokenIn
      const tokenInPos = 2 + 0 * 32 + 0 * 32;
      const tokenIn = '0x' + data.slice(tokenInPos + 24, tokenInPos + 64);
      
      // 獲取 tokenOut
      const tokenOutPos = 2 + 0 * 32 + 1 * 32;
      const tokenOut = '0x' + data.slice(tokenOutPos + 24, tokenOutPos + 64);
      
      try {
        const path = [
          web3.utils.toChecksumAddress(tokenIn),
          web3.utils.toChecksumAddress(tokenOut)
        ];
        return path;
      } catch (error) {
        console.warn('Invalid V3 addresses:', error);
        return null;
      }
    }
    
    // 對於 exactInput
    else if (methodId === '0xc04b8d59') {
      // 對於V3的exactInput，路徑被編碼為字節數組
      // 獲取字節路徑的偏移量
      const pathOffsetPos = 2 + 0 * 32;
      
      // 使用 BigInt 處理大數
      const pathOffsetHex = '0x' + data.slice(pathOffsetPos + 2, pathOffsetPos + 66);
      const pathOffset = Number(BigInt(pathOffsetHex)) * 2;
      
      // 獲取字節路徑的長度
      const pathLengthPos = pathOffset + 2;
      
      // 確保數據長度足夠
      if (data.length < pathLengthPos + 64) {
        console.warn('Data too short for V3 path length');
        return null;
      }
      
      const pathLengthHex = '0x' + data.slice(pathLengthPos, pathLengthPos + 64);
      const pathLength = Number(BigInt(pathLengthHex)) * 2;
      
      // 獲取字節路徑內容
      const pathBytesPos = pathLengthPos + 64;
      
      // 確保數據長度足夠
      if (data.length < pathBytesPos + pathLength) {
        console.warn('Data too short for V3 path content');
        return null;
      }
      
      const pathBytes = '0x' + data.slice(pathBytesPos, pathBytesPos + pathLength);
      
      // 解析字節路徑中的地址
      // V3路徑格式：(address)(uint24)(address)(uint24)(address)...
      // 每個地址20字節，每個fee 3字節
      const path = [];
      
      for (let i = 2; i < pathBytes.length;) {
        if (i + 40 <= pathBytes.length) {
          const addr = '0x' + pathBytes.slice(i, i + 40);
          try {
            path.push(web3.utils.toChecksumAddress(addr));
          } catch (error) {
            console.warn(`Invalid V3 address in path: ${addr}`);
          }
          
          // 跳過當前地址和下一個fee（如果有）
          i += 40; // 20字節地址
          if (i + 6 <= pathBytes.length) {
            i += 6; // 3字節fee
          }
        } else {
          break;
        }
      }
      
      return path.length >= 2 ? path : null;
    }
    
    return null;
  } catch (error) {
    console.error('Decode V3 swap path error:', error);
    return null;
  }
};
// 從交易數據中解析代幣交易金額 - 全面使用 BigInt
const extractTokenAmount = async (tx, methodId, path, tokenIndex) => {
  try {
    const web3 = window._web3Instance;
    const data = tx.input || tx.data;
    if (!data || !path || tokenIndex === undefined || tokenIndex < 0 || tokenIndex >= path.length) {
      return null;
    }
    
    // 獲取代幣合約和精度
    const tokenAddress = path[tokenIndex];
    try {
      const tokenInfo = await getTokenInfo(tokenAddress, web3);
      const decimals = tokenInfo.decimals;

      let amountWei;
      
      // V3方法處理
      if (DEX_SWAP_FUNCTIONS_V3[methodId]) {
        return extractV3TokenAmount(tx, methodId, path, tokenIndex, decimals);
      }
      
      // 以下處理V2方法
      if (methodId === '0x7ff36ab5' || methodId === '0xb6f9de95') {
        // 對於 swapExactETHForTokens 和 swapExactETHForTokensSupportingFeeOnTransferTokens
        // 如果目標代幣在路徑末尾 (目標是買入代幣)
        if (tokenIndex === path.length - 1) {
          // 使用交易值作為參考
          amountWei = BigInt(tx.value || '0');
          return { input: true, amount: parseFloat(fromBigInt(amountWei, 18)) };
        }
      } else if (methodId === '0x18cbafe5' || methodId === '0x791ac947') {
        // 對於 swapExactTokensForETH 和 swapExactTokensForETHSupportingFeeOnTransferTokens
        // 如果目標代幣在路徑開頭 (目標是賣出代幣)
        if (tokenIndex === 0) {
          // 金額在第一個參數
          const amountPos = 2 + 0 * 32;
          const amountHex = '0x' + data.slice(amountPos + 2, amountPos + 66);
          
          // 使用 BigInt 處理大數
          try {
            amountWei = BigInt(amountHex);
            const amount = parseFloat(fromBigInt(amountWei, decimals));
            return { input: true, amount };
          } catch (e) {
            console.error('Error parsing amount as BigInt:', e);
            return null;
          }
        }
      } else if (methodId === '0x38ed1739' || methodId === '0x5c11d795') {
        // 對於 swapExactTokensForTokens 和 swapExactTokensForTokensSupportingFeeOnTransferTokens
        // 確定是買入還是賣出
        if (tokenIndex === 0) {
          // 賣出操作，金額在第一個參數
          const amountPos = 2 + 0 * 32;
          const amountHex = '0x' + data.slice(amountPos + 2, amountPos + 66);
          
          try {
            amountWei = BigInt(amountHex);
            const amount = parseFloat(fromBigInt(amountWei, decimals));
            return { input: true, amount };
          } catch (e) {
            console.error('Error parsing amount as BigInt:', e);
            return null;
          }
        } else if (tokenIndex === path.length - 1) {
          // 買入操作，記錄為輸入金額參考
          const amountPos = 2 + 0 * 32;
          const amountHex = '0x' + data.slice(amountPos + 2, amountPos + 66);
          
          try {
            amountWei = BigInt(amountHex);
            // 這裡我們不知道實際獲得的數量，因此僅返回輸入參考
            return { input: false, reference: true };
          } catch (e) {
            console.error('Error parsing amount as BigInt:', e);
            return null;
          }
        }
      }
    } catch (error) {
      console.error(`Error getting token details for ${tokenAddress}:`, error);
    }
    
    return null;
  } catch (error) {
    console.error('Extract token amount error:', error);
    return null;
  }
};

// 從V3交易中提取代幣金額 - 全面使用 BigInt
const extractV3TokenAmount = async (tx, methodId, path, tokenIndex, decimals) => {
  try {
    const data = tx.input || tx.data;
    
    // 處理exactInputSingle方法
    if (methodId === '0x414bf389') {
      // 獲取amountIn
      const amountInPos = 2 + 0 * 32 + 5 * 32; // tokenIn, tokenOut, fee, recipient, deadline, amountIn
      const amountInHex = '0x' + data.slice(amountInPos + 2, amountInPos + 66);
      
      try {
        const amountIn = BigInt(amountInHex);
        
        // 如果是輸入代幣
        if (tokenIndex === 0) {
          const amount = parseFloat(fromBigInt(amountIn, decimals));
          return { input: true, amount };
        }
        // 如果是輸出代幣
        else if (tokenIndex === path.length - 1) {
          return { input: false, reference: true };
        }
      } catch (e) {
        console.error('Error parsing V3 amount as BigInt:', e);
        return null;
      }
    }
    // 處理exactInput方法
    else if (methodId === '0xc04b8d59') {
      // 獲取amountIn
      const amountInPos = 2 + 0 * 32 + 3 * 32; // path, recipient, deadline, amountIn
      const amountInHex = '0x' + data.slice(amountInPos + 2, amountInPos + 66);
      
      try {
        const amountIn = BigInt(amountInHex);
        
        // 只能確定路徑中的第一個代幣是輸入
        if (tokenIndex === 0) {
          const amount = parseFloat(fromBigInt(amountIn, decimals));
          return { input: true, amount };
        }
        // 如果是輸出代幣（路徑的最後一個）
        else if (tokenIndex === path.length - 1) {
          return { input: false, reference: true };
        }
      } catch (e) {
        console.error('Error parsing V3 amount as BigInt:', e);
        return null;
      }
    }
    
    return null;
  } catch (error) {
    console.error('Extract V3 token amount error:', error);
    return null;
  }
};

// 編碼V3路徑
const encodeV3Path = (path) => {
  if (!path || path.length < 2) return '0x';
  
  // V3路徑格式：(address)(uint24)(address)(uint24)(address)...
  let encodedPath = '0x';
  
  for (let i = 0; i < path.length; i++) {
    // 添加地址
    encodedPath += path[i].slice(2).toLowerCase();
    
    // 如果不是最後一個地址，添加fee（3000 = 0.3%）
    if (i < path.length - 1) {
      // 3000 的 hex值是 0xbb8，需要前面補0到6位
      encodedPath += '000bb8';
    }
  }
  
  return encodedPath;
};
// 改進版本WebSocket創建函數
const createWebSocketProvider = (url, onConnectCallback, onErrorCallback) => {
  if (!url?.startsWith('ws')) {
    console.error('Invalid WebSocket URL:', url);
    if (onErrorCallback) onErrorCallback(new Error('無效的WebSocket URL，請確保使用ws://或wss://開頭的地址'));
    return null;
  }
  
  console.log('Creating WebSocket provider for URL:', url);
  
  // 創建WebSocket提供者
  const wsProvider = new Web3.providers.WebsocketProvider(url, {
    timeout: WS_CONNECTION_TIMEOUT,
    clientConfig: {
      maxReceivedFrameSize: 100000000,
      maxReceivedMessageSize: 100000000,
      keepalive: true,
      keepaliveInterval: 60000
    },
    reconnect: {
      auto: true, 
      delay: WS_RECONNECT_DELAY,
      maxAttempts: WS_RECONNECT_MAX_RETRIES,
      onTimeout: true
    }
  });
  
  // 保持連接的ping定時器
  let pingTimer = null;
  let pongTimeout = null;
  let waitingForPong = false;
  
  // 連接事件處理
  wsProvider.on('connect', () => {
    console.log('WebSocket Connected Successfully');
    
    // 清理之前的定時器
    if (pingTimer) clearInterval(pingTimer);
    if (pongTimeout) clearTimeout(pongTimeout);
    
    // 啟動簡單的心跳檢測，不使用標準ping/pong機制
    pingTimer = setInterval(() => {
      if (waitingForPong) {
        console.warn('Previous heartbeat still pending, skipping this cycle');
        return;
      }
      
      if (wsProvider.connection && wsProvider.connection.readyState === WS_STATUS.OPEN) {
        console.log('Sending heartbeat to keep connection alive');
        waitingForPong = true;
        
        // 設置心跳超時
        pongTimeout = setTimeout(() => {
          console.error('Heartbeat timeout, connection might be dead');
          waitingForPong = false;
          
          // 嘗試重新連接
          try {
            wsProvider.reconnect();
          } catch (e) {
            console.error('Error during reconnection after heartbeat timeout:', e);
            if (onErrorCallback) onErrorCallback(new Error('WebSocket心跳超時無響應'));
          }
        }, WS_PONG_TIMEOUT);
        
        // 簡單的心跳檢查（假設連接健康）
        setTimeout(() => {
          if (waitingForPong) {
            waitingForPong = false;
            if (pongTimeout) {
              clearTimeout(pongTimeout);
              pongTimeout = null;
            }
          }
        }, 1000);
      }
    }, WS_PING_INTERVAL);
    
    if (onConnectCallback) onConnectCallback();
  });
  
  // 錯誤事件處理
  wsProvider.on('error', (error) => {
    console.error('WebSocket Error:', error);
    if (onErrorCallback) onErrorCallback(error);
  });
  
  // 結束事件處理
  wsProvider.on('end', () => {
    console.log('WebSocket Connection Ended');
    
    // 清理定時器
    if (pingTimer) {
      clearInterval(pingTimer);
      pingTimer = null;
    }
    
    if (pongTimeout) {
      clearTimeout(pongTimeout);
      pongTimeout = null;
    }
  });
  
  // 重連事件處理
  wsProvider.on('reconnect', (attempt) => {
    console.log(`WebSocket Reconnecting: Attempt ${attempt}`);
  });
  
  // 連接超時處理
  const connectionTimeout = setTimeout(() => {
    // 檢查連接是否成功建立
    if (!wsProvider.connection || wsProvider.connection.readyState !== WS_STATUS.OPEN) {
      console.error('WebSocket connection timeout');
      if (onErrorCallback) onErrorCallback(new Error('WebSocket連接超時'));
      try {
        wsProvider.disconnect();
      } catch (e) {
        console.error('Error disconnecting timed out WebSocket:', e);
      }
    }
  }, WS_CONNECTION_TIMEOUT);
  
  // 連接成功後清除超時定時器
  wsProvider.on('connect', () => {
    clearTimeout(connectionTimeout);
  });
  
  return wsProvider;
};

// 檢查WebSocket連接狀態
const isWebSocketConnected = (provider) => {
  if (!provider || !provider.connection) return false;
  return provider.connection.readyState === WS_STATUS.OPEN;
};

// 建立安全的訂閱
const createSafeSubscription = async (web3, type, onData, onError) => {
  try {
    if (!web3) {
      throw new Error('Web3實例未初始化');
    }
    
    console.log(`Creating ${type} subscription`);
    
    // 建立訂閱
    const subscription = await web3.eth.subscribe(type);
    
    // 註冊資料處理程序
    subscription.on('data', (data) => {
      try {
        onData(data);
      } catch (error) {
        console.error(`Error in ${type} data handler:`, error);
      }
    });
    
    // 註冊錯誤處理程序
    subscription.on('error', (error) => {
      console.error(`${type} subscription error:`, error);
      if (onError) onError(error);
    });
    
    return subscription;
  } catch (error) {
    console.error(`Failed to create ${type} subscription:`, error);
    if (onError) onError(error);
    return null;
  }
};
// 監控管理物件 - 集中管理所有監控相關狀態和方法
const createMonitoringManager = () => {
  // 內部狀態
  let wsProvider = null;
  let web3WsInstance = null;
  let pendingTxSubscription = null;
  let blockHeaderSubscription = null;
  let blockPollingInterval = null;
  let isMonitoring = false;
  let wsConnectionAttempts = 0;
  const maxWSConnectionAttempts = 3;
  
  // 保存最後處理的區塊號 - 使用字符串存儲
  let lastProcessedBlockStr = null;
  
  return {
    // 開始WebSocket監控
    startWebSocketMonitoring: async (wsUrl, onPendingTx, onNewBlock, onError) => {
      if (wsConnectionAttempts >= maxWSConnectionAttempts) {
        console.log('Max WebSocket connection attempts reached, using polling instead');
        onError(new Error('WebSocket連接嘗試次數過多，切換到輪詢模式'));
        return false;
      }
      
      wsConnectionAttempts++;
      
      // 創建WebSocket連接
      wsProvider = createWebSocketProvider(
        wsUrl,
        // 連接成功回調
        async () => {
          try {
            web3WsInstance = new Web3(wsProvider);
            
            // 訂閱待處理交易
            pendingTxSubscription = await createSafeSubscription(
              web3WsInstance,
              'pendingTransactions',
              // 數據處理
              (txHash) => {
                if (isMonitoring) onPendingTx(txHash);
              },
              // 錯誤處理
              (error) => {
                console.error('Pending tx subscription error:', error);
                onError(error);
              }
            );
            
            console.log('WebSocket monitoring started successfully');
          } catch (error) {
            console.error('Failed to start WebSocket monitoring:', error);
            onError(error);
          }
        },
        // 錯誤回調
        (error) => {
          console.error('WebSocket connection error:', error);
          onError(error);
        }
      );
      
      // 檢查是否成功創建WebSocket提供者
      if (!wsProvider) {
        console.error('Failed to create WebSocket provider');
        return false;
      }
      
      return true;
    },
    
    // 開始區塊訂閱監控
    startBlockHeaderMonitoring: async (web3, onNewBlock, onError) => {
      try {
        // 訂閱新區塊頭
        blockHeaderSubscription = await createSafeSubscription(
          web3,
          'newBlockHeaders',
          // 數據處理
          (blockHeader) => {
            if (isMonitoring) onNewBlock(blockHeader);
          },
          // 錯誤處理
          (error) => {
            console.error('Block header subscription error:', error);
            onError(error);
          }
        );
        
        return true;
      } catch (error) {
        console.error('Failed to start block header monitoring:', error);
        onError(error);
        return false;
      }
    },
    
    // 開始區塊輪詢監控 - 使用字符串處理區塊號
    startBlockPolling: (web3, chainId, onNewBlock) => {
      if (blockPollingInterval) {
        clearInterval(blockPollingInterval);
      }
      
      // 獲取區塊時間，如果未配置則使用默認值10秒
      const blockTime = CHAIN_BLOCK_TIME[chainId] || 10000;
      console.log(`Starting block polling with interval: ${blockTime}ms`);
      
      blockPollingInterval = setInterval(async () => {
        try {
          if (!isMonitoring) return;
          
          // 以字符串形式獲取和處理區塊號
          const currentBlockNumber = await web3.eth.getBlockNumber();
          const currentBlockStr = currentBlockNumber.toString();
          
          // 只處理新區塊 - 使用字符串比較
          if (lastProcessedBlockStr === null || currentBlockStr !== lastProcessedBlockStr) {
            console.log(`Polling detected new block: ${currentBlockStr}`);
            lastProcessedBlockStr = currentBlockStr;
            
            try {
              // 使用字符串獲取區塊
              const block = await web3.eth.getBlock(currentBlockStr, true);
              if (block) {
                onNewBlock({
                  number: currentBlockStr,
                  hash: block.hash
                });
              }
            } catch (blockError) {
              console.error(`Error fetching block ${currentBlockStr}:`, blockError);
            }
          }
        } catch (error) {
          console.error('Block polling error:', error);
        }
      }, blockTime);
      
      return true;
    },
    
    // 設置監控狀態
    setMonitoring: (status) => {
      isMonitoring = status;
    },
    
    // 獲取監控狀態
    isMonitoring: () => isMonitoring,
    
    // 更新最後處理的區塊 - 使用字符串
    setLastProcessedBlock: (blockNumber) => {
      // 確保始終以字符串形式儲存
      if (blockNumber !== null && blockNumber !== undefined) {
        lastProcessedBlockStr = blockNumber.toString();
      }
    },
    
    // 獲取最後處理的區塊
    getLastProcessedBlock: () => lastProcessedBlockStr,
    
    // 重置WebSocket連接嘗試次數
    resetConnectionAttempts: () => {
      wsConnectionAttempts = 0;
    },
    
    // 停止所有監控
    stopAllMonitoring: async () => {
      isMonitoring = false;
      
      // 停止待處理交易訂閱
      if (pendingTxSubscription) {
        try {
          await pendingTxSubscription.unsubscribe();
          console.log('Unsubscribed from pending transactions');
        } catch (e) {
          console.error('Error unsubscribing from pending transactions:', e);
        }
        pendingTxSubscription = null;
      }
      
      // 停止區塊頭訂閱
      if (blockHeaderSubscription) {
        try {
          await blockHeaderSubscription.unsubscribe();
          console.log('Unsubscribed from block headers');
        } catch (e) {
          console.error('Error unsubscribing from block headers:', e);
        }
        blockHeaderSubscription = null;
      }
      
      // 停止區塊輪詢
      if (blockPollingInterval) {
        clearInterval(blockPollingInterval);
        blockPollingInterval = null;
        console.log('Stopped block polling');
      }
      
      // 斷開WebSocket連接
      if (wsProvider) {
        try {
          wsProvider.disconnect();
          console.log('Disconnected WebSocket provider');
        } catch (e) {
          console.error('Error disconnecting WebSocket provider:', e);
        }
        wsProvider = null;
      }
      
      web3WsInstance = null;
      wsConnectionAttempts = 0; // 重置連接嘗試次數
      lastProcessedBlockStr = null; // 重置最後處理的區塊
      
      return true;
    },
    
    // 獲取當前監控狀態信息
    getMonitoringInfo: () => {
      return {
        wsConnected: isWebSocketConnected(wsProvider),
        hasPendingTxSubscription: !!pendingTxSubscription,
        hasBlockHeaderSubscription: !!blockHeaderSubscription,
        hasBlockPolling: !!blockPollingInterval,
        isMonitoring,
        lastProcessedBlock: lastProcessedBlockStr
      };
    }
  };
};
// 構建買入交易 - 全面使用 BigInt
const buildBuyTransaction = async (config) => {
  try {
    const {
      web3,
      path,
      buyMode,
      buyAmount,
      minOutputAmount,
      gasPrice,
      gasLimit,
      userAddress,
      routerAddress,
      dexVersion
    } = config;
    
    if (!path || path.length < 2) {
      throw new Error('無效的交易路徑');
    }

    const deadline = Math.floor(Date.now() / 1000) + DEFAULT_DEADLINE_SECONDS;
    let txData;
    let value = '0';

    // 檢查是否使用V3路由
    if (dexVersion === 'v3') {
      return buildV3BuyTransaction(config);
    }

    // V2交易構建
    const routerContract = new web3.eth.Contract(ABIS.ROUTER, routerAddress);

    // 根據買入代幣類型構建交易數據
    if (buyMode === 'NATIVE') {
      // 使用ETH/BNB等原生代幣購買
      
      // 檢查路徑是否符合預期
      const wrappedNative = (await findCurrentNetwork(web3)).contracts.wrappedNative;
      if (path[0].toLowerCase() !== wrappedNative.toLowerCase()) {
        throw new Error('交易路徑必須從包裝代幣開始');
      }
      
      // 轉換最小輸出金額，默認為0（搶跑時通常不設定最小值）
      const minOutput = minOutputAmount ? 
        toBigInt(minOutputAmount).toString() : 
        '0';
      
      // 轉換購買金額為wei - 使用 BigInt
      value = toBigInt(buyAmount, 18).toString();
      
      txData = routerContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(
          minOutput,
          path,
          userAddress,
          deadline
        ).encodeABI();
    } else if (buyMode === 'TOKEN') {
      // 使用代幣購買，如USDT或其他自定義代幣
      
      // 獲取輸入代幣的精度
      const tokenInfo = await getTokenInfo(path[0], web3);
      const decimals = tokenInfo.decimals;
      
      // 轉換購買金額為代幣精度的wei - 使用 BigInt
      const buyAmountWei = toBigInt(buyAmount, decimals).toString();
      
      // 轉換最小輸出金額
      const minOutput = minOutputAmount ? 
        toBigInt(minOutputAmount).toString() : 
        '0';
      
      // 檢查授權
      const tokenContract = new web3.eth.Contract(ABIS.ERC20, path[0]);
      const allowance = await tokenContract.methods.allowance(userAddress, routerAddress).call();
      
      // 如果授權不足，提示用戶 - 使用 BigInt 比較
      if (BigInt(allowance) < BigInt(buyAmountWei)) {
        throw new Error(`需要授權${tokenInfo.symbol}，當前授權: ${fromBigInt(allowance, decimals)}，需要: ${fromBigInt(buyAmountWei, decimals)}`);
      }
      
      txData = routerContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(
          buyAmountWei,
          minOutput,
          path,
          userAddress,
          deadline
        ).encodeABI();
    } else {
      throw new Error('不支持的買入模式');
    }

    console.log('Built buy transaction:', {
      path: path.map(p => p.substring(0, 10)),
      value,
      gasPrice
    });

    return {
      from: userAddress,
      to: routerAddress,
      data: txData,
      value,
      gas: gasLimit,
      gasPrice
    };
  } catch (error) {
    console.error('Build buy transaction error:', error);
    throw error;
  }
};

// 構建V3買入交易 - 全面使用 BigInt
const buildV3BuyTransaction = async (config) => {
  try {
    const {
      web3,
      path,
      buyMode,
      buyAmount,
      minOutputAmount,
      gasPrice,
      gasLimit,
      userAddress,
      routerAddress
    } = config;
    
    if (!path || path.length < 2) {
      throw new Error('無效的交易路徑');
    }

    const deadline = Math.floor(Date.now() / 1000) + DEFAULT_DEADLINE_SECONDS;
    let txData;
    let value = '0';

    // 獲取V3路由合約
    const network = await findCurrentNetwork(web3);
    const v3RouterAddress = routerAddress || network.contracts.v3Router;
    const routerContract = new web3.eth.Contract(ABIS.ROUTER_V3, v3RouterAddress);

    // 根據路徑長度決定使用exactInputSingle還是exactInput
    if (path.length === 2) {
      // 使用exactInputSingle - 一個交易對
      const tokenIn = path[0];
      const tokenOut = path[1];
      
      if (buyMode === 'NATIVE') {
        // 使用原生代幣購買
        const wrappedNative = network.contracts.wrappedNative;
        if (tokenIn.toLowerCase() !== wrappedNative.toLowerCase()) {
          throw new Error('原生代幣買入時，路徑必須從包裝代幣開始');
        }
        
        // 轉換購買金額為wei - 使用 BigInt
        value = toBigInt(buyAmount, 18).toString();
        
        // 構建參數
        const params = {
          tokenIn,
          tokenOut,
          fee: 3000, // 0.3%的交易費率，可以根據需要調整
          recipient: userAddress,
          deadline,
          amountIn: value,
          amountOutMinimum: minOutputAmount ? toBigInt(minOutputAmount).toString() : '0',
          sqrtPriceLimitX96: '0' // 不設置價格限制
        };
        
        txData = routerContract.methods.exactInputSingle(params).encodeABI();
      } else {
        // 使用代幣購買
        const tokenInfo = await getTokenInfo(tokenIn, web3);
        const decimals = tokenInfo.decimals;
        
        // 轉換購買金額 - 使用 BigInt
        const buyAmountWei = toBigInt(buyAmount, decimals).toString();
        
        // 檢查授權
        const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenIn);
        const allowance = await tokenContract.methods.allowance(userAddress, v3RouterAddress).call();
        
        if (BigInt(allowance) < BigInt(buyAmountWei)) {
          throw new Error(`需要授權${tokenInfo.symbol}，當前授權: ${fromBigInt(allowance, decimals)}，需要: ${fromBigInt(buyAmountWei, decimals)}`);
        }
        
        // 構建參數
        const params = {
          tokenIn,
          tokenOut,
          fee: 3000, // 0.3%的交易費率
          recipient: userAddress,
          deadline,
          amountIn: buyAmountWei,
          amountOutMinimum: minOutputAmount ? toBigInt(minOutputAmount).toString() : '0',
          sqrtPriceLimitX96: '0' // 不設置價格限制
        };
        
        txData = routerContract.methods.exactInputSingle(params).encodeABI();
      }
    } else {
      // 使用exactInput - 多個交易對路徑
      // 構建編碼的路徑
      const pathBytes = encodeV3Path(path);
      
      if (buyMode === 'NATIVE') {
        // 使用原生代幣購買
        const wrappedNative = network.contracts.wrappedNative;
        if (path[0].toLowerCase() !== wrappedNative.toLowerCase()) {
          throw new Error('原生代幣買入時，路徑必須從包裝代幣開始');
        }
        
        // 轉換購買金額為wei - 使用 BigInt
        value = toBigInt(buyAmount, 18).toString();
        
        // 構建參數
        const params = {
          path: pathBytes,
          recipient: userAddress,
          deadline,
          amountIn: value,
          amountOutMinimum: minOutputAmount ? toBigInt(minOutputAmount).toString() : '0'
        };
        
        txData = routerContract.methods.exactInput(params).encodeABI();
      } else {
        // 使用代幣購買
        const tokenInfo = await getTokenInfo(path[0], web3);
        const decimals = tokenInfo.decimals;
        
        // 轉換購買金額 - 使用 BigInt
        const buyAmountWei = toBigInt(buyAmount, decimals).toString();
        
        // 檢查授權
        const tokenContract = new web3.eth.Contract(ABIS.ERC20, path[0]);
        const allowance = await tokenContract.methods.allowance(userAddress, v3RouterAddress).call();
        
        if (BigInt(allowance) < BigInt(buyAmountWei)) {
          throw new Error(`需要授權${tokenInfo.symbol}，當前授權: ${fromBigInt(allowance, decimals)}，需要: ${fromBigInt(buyAmountWei, decimals)}`);
        }
        
        // 構建參數
        const params = {
          path: pathBytes,
          recipient: userAddress,
          deadline,
          amountIn: buyAmountWei,
          amountOutMinimum: minOutputAmount ? toBigInt(minOutputAmount).toString() : '0'
        };
        
        txData = routerContract.methods.exactInput(params).encodeABI();
      }
    }

    console.log('Built V3 buy transaction:', {
      path: path.map(p => p.substring(0, 10)),
      value,
      gasPrice
    });

    return {
      from: userAddress,
      to: v3RouterAddress,
      data: txData,
      value,
      gas: gasLimit,
      gasPrice
    };
  } catch (error) {
    console.error('Build V3 buy transaction error:', error);
    throw error;
  }
};
// 構建賣出交易 - 全面使用 BigInt
const buildSellTransaction = async (config) => {
  try {
    const {
      web3,
      path,
      sellMode,
      sellAmount,
      sellPercentage,
      gasPrice,
      gasLimit,
      userAddress,
      routerAddress,
      dexVersion
    } = config;
    
    if (!path || path.length < 2) {
      throw new Error('無效的交易路徑');
    }

    // 檢查是否使用V3路由
    if (dexVersion === 'v3') {
      return buildV3SellTransaction(config);
    }

    // V2交易構建
    const routerContract = new web3.eth.Contract(ABIS.ROUTER, routerAddress);
    const deadline = Math.floor(Date.now() / 1000) + DEFAULT_DEADLINE_SECONDS;

    // 檢查路徑是否以目標代幣開始
    const tokenAddress = path[0];
    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);

    // 獲取代幣精度
    const tokenInfo = await getTokenInfo(tokenAddress, web3);
    const decimals = tokenInfo.decimals;

    // 獲取當前持有的代幣餘額
    const balance = await tokenContract.methods.balanceOf(userAddress).call();
    if (BigInt(balance) === BigInt(0)) {
      throw new Error(`目標代幣餘額為0，無法賣出 (${tokenInfo.symbol})`);
    }

    // 計算賣出數量 - 全面使用 BigInt
    let sellTokenAmount;
    if (sellMode === 'percentage') {
      // 計算百分比數量
      const percentage = Math.max(0, Math.min(100, parseFloat(sellPercentage)));
      // 使用 BigInt 計算百分比，避免精度問題
      const percentageBasis = BigInt(10000); // 使用10000作為基數提高精度
      const percentageValue = BigInt(Math.floor(percentage * 100));
      sellTokenAmount = (BigInt(balance) * percentageValue) / percentageBasis;
    } else {
      // 固定數量
      sellTokenAmount = toBigInt(sellAmount, decimals);
    }

    // 確保賣出數量大於0且不超過餘額
    if (sellTokenAmount <= BigInt(0)) {
      throw new Error('賣出數量必須大於0');
    }
    
    // 使用 BigInt 比較大小
    if (sellTokenAmount > BigInt(balance)) {
      sellTokenAmount = BigInt(balance); // 限制為最大餘額
    }

    // 檢查授權
    const allowance = await tokenContract.methods.allowance(userAddress, routerAddress).call();
    // 使用 BigInt 比較大小
    if (BigInt(allowance) < sellTokenAmount) {
      throw new Error(`需要授權${tokenInfo.symbol}，當前授權: ${fromBigInt(allowance, decimals)}，需要: ${fromBigInt(sellTokenAmount, decimals)}`);
    }

    let txData;
    // 檢查賣出獲得的代幣類型
    const lastToken = path[path.length - 1];
    const network = await findCurrentNetwork(web3);
    
    if (lastToken.toLowerCase() === network.contracts.wrappedNative.toLowerCase()) {
      // 賣出獲得ETH/BNB等原生代幣
      txData = routerContract.methods
        .swapExactTokensForETHSupportingFeeOnTransferTokens(
          sellTokenAmount.toString(),
          '0', // 最小獲得數量為0，搶跑時通常不設置滑點保護
          path,
          userAddress,
          deadline
        ).encodeABI();
    } else {
      // 賣出獲得USDT等代幣
      txData = routerContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(
          sellTokenAmount.toString(),
          '0', // 最小獲得數量為0
          path,
          userAddress,
          deadline
        ).encodeABI();
    }

    console.log('Built sell transaction:', {
      path: path.map(p => p.substring(0, 10)),
      amount: fromBigInt(sellTokenAmount, decimals),
      gasPrice
    });

    return {
      from: userAddress,
      to: routerAddress,
      data: txData,
      value: '0',
      gas: gasLimit,
      gasPrice
    };
  } catch (error) {
    console.error('Build sell transaction error:', error);
    throw error;
  }
};

// 構建V3賣出交易 - 全面使用 BigInt
const buildV3SellTransaction = async (config) => {
  try {
    const {
      web3,
      path,
      sellMode,
      sellAmount,
      sellPercentage,
      gasPrice,
      gasLimit,
      userAddress,
      routerAddress
    } = config;
    
    if (!path || path.length < 2) {
      throw new Error('無效的交易路徑');
    }

    // 獲取V3路由合約
    const network = await findCurrentNetwork(web3);
    const v3RouterAddress = routerAddress || network.contracts.v3Router;
    const routerContract = new web3.eth.Contract(ABIS.ROUTER_V3, v3RouterAddress);
    
    const deadline = Math.floor(Date.now() / 1000) + DEFAULT_DEADLINE_SECONDS;

    // 檢查路徑是否以目標代幣開始
    const tokenAddress = path[0];
    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);

    // 獲取代幣精度
    const tokenInfo = await getTokenInfo(tokenAddress, web3);
    const decimals = tokenInfo.decimals;

    // 獲取當前持有的代幣餘額
    const balance = await tokenContract.methods.balanceOf(userAddress).call();
    if (BigInt(balance) === BigInt(0)) {
      throw new Error(`目標代幣餘額為0，無法賣出 (${tokenInfo.symbol})`);
    }

    // 計算賣出數量 - 全面使用 BigInt
    let sellTokenAmount;
    if (sellMode === 'percentage') {
      // 計算百分比數量
      const percentage = Math.max(0, Math.min(100, parseFloat(sellPercentage)));
      // 使用 BigInt 計算百分比，避免精度問題
      const percentageBasis = BigInt(10000); // 使用10000作為基數提高精度
      const percentageValue = BigInt(Math.floor(percentage * 100));
      sellTokenAmount = (BigInt(balance) * percentageValue) / percentageBasis;
    } else {
      // 固定數量
      sellTokenAmount = toBigInt(sellAmount, decimals);
    }

    // 確保賣出數量大於0且不超過餘額
    if (sellTokenAmount <= BigInt(0)) {
      throw new Error('賣出數量必須大於0');
    }
    
    // 使用 BigInt 比較大小
    if (sellTokenAmount > BigInt(balance)) {
      sellTokenAmount = BigInt(balance); // 限制為最大餘額
    }

    // 檢查授權
    const allowance = await tokenContract.methods.allowance(userAddress, v3RouterAddress).call();
    // 使用 BigInt 比較大小
    if (BigInt(allowance) < sellTokenAmount) {
      throw new Error(`需要授權${tokenInfo.symbol}，當前授權: ${fromBigInt(allowance, decimals)}，需要: ${fromBigInt(sellTokenAmount, decimals)}`);
    }

    let txData;
    
    // 根據路徑長度決定使用exactInputSingle還是exactInput
    if (path.length === 2) {
      // 使用exactInputSingle - 一個交易對
      const tokenIn = path[0];
      const tokenOut = path[1];
      
      // 構建參數
      const params = {
        tokenIn,
        tokenOut,
        fee: 3000, // 0.3%的交易費率
        recipient: userAddress,
        deadline,
        amountIn: sellTokenAmount.toString(),
        amountOutMinimum: '0', // 在搶跑時不設最小值
        sqrtPriceLimitX96: '0' // 不設置價格限制
      };
      
      txData = routerContract.methods.exactInputSingle(params).encodeABI();
    } else {
      // 使用exactInput - 多個交易對路徑
      // 構建編碼的路徑
      const pathBytes = encodeV3Path(path);
      
      // 構建參數
      const params = {
        path: pathBytes,
        recipient: userAddress,
        deadline,
        amountIn: sellTokenAmount.toString(),
        amountOutMinimum: '0' // 在搶跑時不設最小值
      };
      
      txData = routerContract.methods.exactInput(params).encodeABI();
    }

    console.log('Built V3 sell transaction:', {
      path: path.map(p => p.substring(0, 10)),
      amount: fromBigInt(sellTokenAmount, decimals),
      gasPrice
    });

    return {
      from: userAddress,
      to: v3RouterAddress,
      data: txData,
      value: '0',
      gas: gasLimit,
      gasPrice
    };
  } catch (error) {
    console.error('Build V3 sell transaction error:', error);
    throw error;
  }
};
// 發送前執行交易預檢
const simulateTransaction = async (txConfig, web3) => {
  try {
    // 預檢查交易
    await web3.eth.call(txConfig);
    return true;
  } catch (error) {
    console.error('Transaction simulation failed:', error);
    // 提取錯誤信息中的關鍵信息
    let errorMessage = error.message || '交易模擬失敗';
    if (errorMessage.includes('execution reverted')) {
      const revertReason = errorMessage.match(/execution reverted: (.*?)(?:\n|$)/);
      if (revertReason && revertReason[1]) {
        errorMessage = `交易會回滾: ${revertReason[1]}`;
      } else {
        errorMessage = '交易會回滾，但未返回原因';
      }
    }
    throw new Error(errorMessage);
  }
};

// 發送交易並等待確認
const sendAndConfirmTransaction = async (txConfig, web3) => {
  try {
    // 首先模擬交易
    await simulateTransaction(txConfig, web3);
    
    // 發送交易
    const receipt = await web3.eth.sendTransaction(txConfig);
    
    console.log(`Transaction sent: ${receipt.transactionHash}`);
    
    // 返回收據
    return receipt;
  } catch (error) {
    console.error('Send transaction error:', error);
    throw error;
  }
};

// FrontrunPanel 组件
const FrontrunPanel = () => {
  // 基礎狀態
  const [isMonitoring, setIsMonitoring] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [operationResults, setOperationResults] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const [explorerUrl, setExplorerUrl] = React.useState('');
  const [nativeSymbol, setNativeSymbol] = React.useState('ETH');
  const [lastProcessedBlock, setLastProcessedBlock] = React.useState(null);
  const [currentTime, setCurrentTime] = React.useState(Date.now());

  // 鏈支持狀態
  const [chainSupport, setChainSupport] = React.useState({
    chainId: null,
    isSameBlockSupported: false,
    isL2Chain: false
  });

  // 監控設置
  const [gasMultiplier, setGasMultiplier] = React.useState('1.5');
  const [gasLimit, setGasLimit] = React.useState('300000');

  // 交易設置
  const [targetToken, setTargetToken] = React.useState('');
  const [monitorMode, setMonitorMode] = React.useState('buy');
  
  // 買入設置
  const [buyAmount, setBuyAmount] = React.useState('');
  const [minOutputAmount, setMinOutputAmount] = React.useState('');
  
  // 路徑配置相關狀態
  const [dexVersion, setDexVersion] = React.useState('v2');
  const [buyPathType, setBuyPathType] = React.useState(PATH_TYPES.TWO_HOP);
  const [sellPathType, setSellPathType] = React.useState(PATH_TYPES.TWO_HOP);
  
  // 買入路徑配置 
  const [buyStartToken, setBuyStartToken] = React.useState('NATIVE');
  const [buyMiddleToken, setBuyMiddleToken] = React.useState('WRAPPED_NATIVE');
  const [buyCustomStartToken, setBuyCustomStartToken] = React.useState('');
  const [buyCustomMiddleToken, setBuyCustomMiddleToken] = React.useState('');
  
  // 賣出路徑配置
  const [sellEndToken, setSellEndToken] = React.useState('NATIVE');
  const [sellMiddleToken, setSellMiddleToken] = React.useState('WRAPPED_NATIVE');
  const [sellCustomEndToken, setSellCustomEndToken] = React.useState('');
  const [sellCustomMiddleToken, setSellCustomMiddleToken] = React.useState('');

  // 買入觸發條件
  const [buyTriggerTokenAmount, setBuyTriggerTokenAmount] = React.useState('');
  const [buyTriggerUsdAmount, setBuyTriggerUsdAmount] = React.useState('');

  // 賣出設置
  const [sellTriggerTokenAmount, setSellTriggerTokenAmount] = React.useState('');
  const [sellTriggerUsdAmount, setSellTriggerUsdAmount] = React.useState('');
  const [sellMode, setSellMode] = React.useState('percentage');
  const [sellAmount, setSellAmount] = React.useState('');

  // 監控統計
  const [monitoringStats, setMonitoringStats] = React.useState({
    totalScanned: 0,
    matchedTx: 0,
    successfulFrontrun: 0,
    failedFrontrun: 0,
    startTime: null,
    currentBlockNumber: null,
    lastTxHash: null
  });

  // 監控狀態信息
  const [monitoringInfo, setMonitoringInfo] = React.useState({
    wsConnected: false,
    hasPendingTxSubscription: false,
    hasBlockHeaderSubscription: false,
    hasBlockPolling: false,
    lastProcessedBlock: null
  });

  // 緩存網絡鏈上可用代幣列表
  const [availableTokens, setAvailableTokens] = React.useState({
    native: { address: '', symbol: 'ETH' },
    stablecoins: []
  });

  // Web3相關引用
  const pendingTxRef = React.useRef(new Set());
  
  // 創建監控管理器
  const monitoringManagerRef = React.useRef(null);
// 初始化監控管理器
  React.useEffect(() => {
    monitoringManagerRef.current = createMonitoringManager();
    return () => {
      // 組件卸載時停止所有監控
      if (monitoringManagerRef.current) {
        monitoringManagerRef.current.stopAllMonitoring();
      }
    };
  }, []);

  // 初始化可用代幣列表
  React.useEffect(() => {
    const initAvailableTokens = async () => {
      if (!window._web3Instance) return;
      
      try {
        const network = await findCurrentNetwork(window._web3Instance);
        if (!network) return;
        
        const native = {
          address: network.contracts.wrappedNative,
          symbol: network.nativeCurrency.symbol
        };
        
        const stablecoins = [];
        for (const [symbol, address] of Object.entries(network.stablecoins || {})) {
          if (address && window._web3Instance.utils.isAddress(address)) {
            // 獲取代幣詳細信息
            try {
              const tokenInfo = await getTokenInfo(address, window._web3Instance);
              stablecoins.push({
                address,
                symbol: tokenInfo.symbol || symbol,
                name: tokenInfo.name || symbol,
                decimals: tokenInfo.decimals
              });
            } catch (e) {
              console.error(`Error fetching token info for ${symbol}:`, e);
              stablecoins.push({ address, symbol });
            }
          }
        }
        
        setAvailableTokens({ native, stablecoins });
      } catch (error) {
        console.error('Initialize available tokens error:', error);
      }
    };
    
    initAvailableTokens();
  }, [chainSupport.chainId]);
  
  // 檢查鏈支持狀態
  React.useEffect(() => {
    const checkChainSupport = async () => {
      if (!window._web3Instance) return;

      try {
        const chainId = await window._web3Instance.eth.getChainId();
        const chainIdString = chainId.toString();
        
        setChainSupport({
          chainId: chainIdString,
          isSameBlockSupported: SAME_BLOCK_FRONTRUN_CHAINS.includes(chainIdString),
          isL2Chain: L2_CHAINS.includes(chainIdString)
        });

        const network = await findCurrentNetwork(window._web3Instance);
        if (network) {
          setExplorerUrl(network.explorer?.url || '');
          setNativeSymbol(network.nativeCurrency?.symbol || 'ETH');
        }
      } catch (error) {
        console.error('Chain support check error:', error);
      }
    };

    checkChainSupport();
    const interval = setInterval(checkChainSupport, 5000);
    return () => clearInterval(interval);
  }, []);
  
  // 計時器效果
  React.useEffect(() => {
    let timer;
    if (isMonitoring) {
      timer = setInterval(() => {
        setCurrentTime(Date.now());
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isMonitoring]);
  
  // 組件卸載時清理資源
  React.useEffect(() => {
    return () => {
      const monitoringManager = monitoringManagerRef.current;
      if (monitoringManager) {
        monitoringManager.stopAllMonitoring();
      }
    };
  }, []);

// 獲取買入路徑
  const getBuyPath = React.useCallback(() => {
    const web3 = window._web3Instance;
    if (!web3 || !targetToken) return [];
    
    try {
      let path = [];
      
      // 解析目標代幣地址
      const targetTokenAddress = web3.utils.toChecksumAddress(targetToken);
      
      // 根據路徑類型構建
      if (buyPathType === PATH_TYPES.TWO_HOP) {
        // 兩級路徑: 起始代幣 -> 目標代幣
        let startTokenAddress = '';
        
        if (buyStartToken === 'NATIVE') {
          startTokenAddress = availableTokens.native.address;
        } else if (buyStartToken === 'CUSTOM') {
          if (!buyCustomStartToken || !web3.utils.isAddress(buyCustomStartToken)) {
            return [];
          }
          startTokenAddress = web3.utils.toChecksumAddress(buyCustomStartToken);
        } else {
          // 從可用代幣列表中查找
          const token = availableTokens.stablecoins.find(t => t.symbol === buyStartToken);
          if (token && token.address) {
            startTokenAddress = token.address;
          }
        }
        
        if (!startTokenAddress) return [];
        
        path = [startTokenAddress, targetTokenAddress];
      } else if (buyPathType === PATH_TYPES.THREE_HOP) {
        // 三級路徑: 起始代幣 -> 中間代幣 -> 目標代幣
        let startTokenAddress = '';
        let middleTokenAddress = '';
        
        // 獲取起始代幣地址
        if (buyStartToken === 'NATIVE') {
          startTokenAddress = availableTokens.native.address;
        } else if (buyStartToken === 'CUSTOM') {
          if (!buyCustomStartToken || !web3.utils.isAddress(buyCustomStartToken)) {
            return [];
          }
          startTokenAddress = web3.utils.toChecksumAddress(buyCustomStartToken);
        } else {
          const token = availableTokens.stablecoins.find(t => t.symbol === buyStartToken);
          if (token && token.address) {
            startTokenAddress = token.address;
          }
        }
        
        // 獲取中間代幣地址
        if (buyMiddleToken === 'WRAPPED_NATIVE') {
          middleTokenAddress = availableTokens.native.address;
        } else if (buyMiddleToken === 'CUSTOM') {
          if (!buyCustomMiddleToken || !web3.utils.isAddress(buyCustomMiddleToken)) {
            return [];
          }
          middleTokenAddress = web3.utils.toChecksumAddress(buyCustomMiddleToken);
        } else {
          const token = availableTokens.stablecoins.find(t => t.symbol === buyMiddleToken);
          if (token && token.address) {
            middleTokenAddress = token.address;
          }
        }
        
        if (!startTokenAddress || !middleTokenAddress) return [];
        
        path = [startTokenAddress, middleTokenAddress, targetTokenAddress];
      }
      
      return path;
    } catch (error) {
      console.error('Get buy path error:', error);
      return [];
    }
  }, [
    targetToken, 
    buyPathType, 
    buyStartToken, 
    buyMiddleToken,
    buyCustomStartToken,
    buyCustomMiddleToken,
    availableTokens
  ]);

  // 獲取賣出路徑
  const getSellPath = React.useCallback(() => {
    const web3 = window._web3Instance;
    if (!web3 || !targetToken) return [];
    
    try {
      let path = [];
      
      // 解析目標代幣地址
      const targetTokenAddress = web3.utils.toChecksumAddress(targetToken);
      
      // 根據路徑類型構建
      if (sellPathType === PATH_TYPES.TWO_HOP) {
        // 兩級路徑: 目標代幣 -> 結束代幣
        let endTokenAddress = '';
        
        if (sellEndToken === 'NATIVE') {
          endTokenAddress = availableTokens.native.address;
        } else if (sellEndToken === 'CUSTOM') {
          if (!sellCustomEndToken || !web3.utils.isAddress(sellCustomEndToken)) {
            return [];
          }
          endTokenAddress = web3.utils.toChecksumAddress(sellCustomEndToken);
        } else {
          // 從可用代幣列表中查找
          const token = availableTokens.stablecoins.find(t => t.symbol === sellEndToken);
          if (token && token.address) {
            endTokenAddress = token.address;
          }
        }
        
        if (!endTokenAddress) return [];
        
        path = [targetTokenAddress, endTokenAddress];
      } else if (sellPathType === PATH_TYPES.THREE_HOP) {
        // 三級路徑: 目標代幣 -> 中間代幣 -> 結束代幣
        let middleTokenAddress = '';
        let endTokenAddress = '';
        
        // 獲取中間代幣地址
        if (sellMiddleToken === 'WRAPPED_NATIVE') {
          middleTokenAddress = availableTokens.native.address;
        } else if (sellMiddleToken === 'CUSTOM') {
          if (!sellCustomMiddleToken || !web3.utils.isAddress(sellCustomMiddleToken)) {
            return [];
          }
          middleTokenAddress = web3.utils.toChecksumAddress(sellCustomMiddleToken);
        } else {
          const token = availableTokens.stablecoins.find(t => t.symbol === sellMiddleToken);
          if (token && token.address) {
            middleTokenAddress = token.address;
          }
        }
        
        // 獲取結束代幣地址
        if (sellEndToken === 'NATIVE') {
          endTokenAddress = availableTokens.native.address;
        } else if (sellEndToken === 'CUSTOM') {
          if (!sellCustomEndToken || !web3.utils.isAddress(sellCustomEndToken)) {
            return [];
          }
          endTokenAddress = web3.utils.toChecksumAddress(sellCustomEndToken);
        } else {
          const token = availableTokens.stablecoins.find(t => t.symbol === sellEndToken);
          if (token && token.address) {
            endTokenAddress = token.address;
          }
        }
        
        if (!middleTokenAddress || !endTokenAddress) return [];
        
        path = [targetTokenAddress, middleTokenAddress, endTokenAddress];
      }
      
      return path;
    } catch (error) {
      console.error('Get sell path error:', error);
      return [];
    }
  }, [
    targetToken, 
    sellPathType, 
    sellMiddleToken,
    sellEndToken,
    sellCustomMiddleToken,
    sellCustomEndToken,
    availableTokens
  ]);
// 優化後的處理待處理交易函數
  const handlePendingTransaction = React.useCallback(async (txHash) => {
    try {
      if (!txHash) return;
      
      // 防止重複處理
      if (pendingTxRef.current.has(txHash)) return;
      pendingTxRef.current.add(txHash);

      const web3 = window._web3Instance;
      if (!web3) return;
      
      // 使用更高的優先級獲取交易
      let tx = null;
      try {
        tx = await web3.eth.getTransaction(txHash);
      } catch (err) {
        console.error(`Error fetching transaction ${txHash}:`, err);
        pendingTxRef.current.delete(txHash); // 可能是暫時性錯誤，允許再次嘗試
        return;
      }
      
      if (!tx) {
        pendingTxRef.current.delete(txHash); // 交易不存在，允許再次嘗試
        return;
      }

      setMonitoringStats(prev => ({
        ...prev,
        totalScanned: prev.totalScanned + 1
      }));

      // 快速篩選 - 優先檢查基本條件
      if (!tx.to || tx.to.toLowerCase() !== window._selectedDex?.router.toLowerCase()) {
        return; // 不是DEX交易，直接跳過
      }
      
      // 確保所有交易數值都作為字符串處理
      const safeTx = {
        ...tx,
        gasPrice: tx.gasPrice ? tx.gasPrice.toString() : '0',
        gas: tx.gas ? tx.gas.toString() : '0',
        value: tx.value ? tx.value.toString() : '0'
      };
      
      // 交易詳細檢查 - 使用快取避免重複計算
      const checkResult = await checkTransactionConditions(safeTx);
      if (!checkResult.matches) return;

      setMonitoringStats(prev => ({
        ...prev,
        matchedTx: prev.matchedTx + 1,
        lastTxHash: txHash
      }));

      // 計算gas價格 - 使用字符串處理大整數
      const targetGasPrice = tx.gasPrice ? tx.gasPrice.toString() : '0';
      const multiplier = parseFloat(gasMultiplier);
      
      // 使用 BigInt 安全處理大整數計算
      const targetGasPriceBigInt = BigInt(targetGasPrice);
      const multiplierBasis = BigInt(Math.floor(multiplier * 100));
      const calculatedGasPrice = (targetGasPriceBigInt * multiplierBasis) / BigInt(100);
      
      // 確保gas價格不超過最大值 - 使用 BigInt 比較
      const finalGasPrice = (calculatedGasPrice > MAX_GAS_PRICE) ? 
                        MAX_GAS_PRICE.toString() : 
                        calculatedGasPrice.toString();

      // 執行搶跑交易 - 使用較高的優先級
      await executeFrontrun(safeTx, checkResult.type, finalGasPrice, true);
    } catch (error) {
      console.error('Handle pending transaction error:', error);
    }
  }, [gasMultiplier]);
  
  // 處理新區塊 - 修复区块号处理，使用字符串
  const handleNewBlock = React.useCallback(async (blockHeader) => {
    try {
      const web3 = window._web3Instance;
      if (!web3) return;
      
      // 确保区块号始终作为字符串处理，避免数字精度问题
      const blockNumberStr = blockHeader.number.toString();
      
      console.log(`Processing block ${blockNumberStr}`);
      setLastProcessedBlock(blockNumberStr);
      
      // 更新监控管理器中的最后处理区块 - 使用字符串方式保存
      if (monitoringManagerRef.current) {
        monitoringManagerRef.current.setLastProcessedBlock(blockNumberStr);
      }
      
      try {
        // 使用字符串获取区块，避免BigInt问题
        const block = await web3.eth.getBlock(blockNumberStr, true);
        if (!block?.transactions) return;
        
        setMonitoringStats(prev => ({
          ...prev,
          totalScanned: prev.totalScanned + block.transactions.length,
          currentBlockNumber: parseInt(blockNumberStr, 10) // 仅用于显示，解析为数字
        }));
        
        // 批量處理交易
        const processedHashes = new Set();
        // 使用 Promise.all 進行並行處理，但控制批量大小避免過載
        for (let i = 0; i < block.transactions.length; i += BATCH_SIZE) {
          const batch = block.transactions.slice(i, i + BATCH_SIZE);
          await Promise.all(batch.map(async (tx) => {
            try {
              // 跳過已處理的交易
              if (processedHashes.has(tx.hash)) return;
              processedHashes.add(tx.hash);
              
              // 快速篩選非目標DEX交易
              if (!tx.to || tx.to.toLowerCase() !== window._selectedDex?.router.toLowerCase()) {
                return;
              }
              
              // 確保處理用來比較的屬性是安全的，特別是數字屬性
              // 使用字符串存儲數值
              const safeTx = {
                ...tx,
                gasPrice: tx.gasPrice ? tx.gasPrice.toString() : '0',
                gas: tx.gas ? tx.gas.toString() : '0',
                value: tx.value ? tx.value.toString() : '0'
              };
              
              const checkResult = await checkTransactionConditions(safeTx);
              if (checkResult.matches) {
                setMonitoringStats(prev => ({
                  ...prev,
                  matchedTx: prev.matchedTx + 1,
                  lastTxHash: tx.hash
                }));
                
                // 計算 gas 價格 - 使用 BigInt 處理
                const targetGasPrice = BigInt(safeTx.gasPrice);
                const multiplier = parseFloat(gasMultiplier);
                // 轉換為更精確的計算方式
                const multiplierBigInt = BigInt(Math.floor(multiplier * 100));
                const calculatedGasPrice = (targetGasPrice * multiplierBigInt) / BigInt(100);
                
                // 確保 gas 價格不超過最大值 - 使用 BigInt 比較
                const gasPrice = (calculatedGasPrice > MAX_GAS_PRICE) ? 
                                  MAX_GAS_PRICE.toString() : 
                                  calculatedGasPrice.toString();
                
                await executeFrontrun(safeTx, checkResult.type, gasPrice, false);
              }
            } catch (txError) {
              console.error(`Error processing tx ${tx.hash}:`, txError);
            }
          }));
        }
      } catch (blockError) {
        console.error(`Error processing block ${blockNumberStr}:`, blockError);
      }
    } catch (error) {
      console.error('Handle new block error:', error);
    }
  }, [gasMultiplier]);
// 檢查交易條件 - 優化版本，全面使用字符串和 BigInt
  const checkTransactionConditions = React.useCallback(async (tx) => {
    try {
      if (!targetToken || !window._selectedDex) return { matches: false };
      
      const web3 = window._web3Instance;
      if (!web3) return { matches: false };
      
      // 檢查是否是DEX路由器交易
      if (!tx.to || tx.to.toLowerCase() !== window._selectedDex.router.toLowerCase()) {
        return { matches: false };
      }
      
      // 解析交易输入数据
      const data = tx.input || tx.data;
      if (!data || data === '0x') return { matches: false };
      
      // 解析方法签名（前4字节）
      const methodId = data.slice(0, 10);
      
      // 检查是否为支持的DEX函数
      if (!Object.keys(ALL_DEX_SWAP_FUNCTIONS).includes(methodId)) {
        return { matches: false };
      }
      
      // 解析交易路径
      const path = decodeSwapPath(data, methodId);
      if (!path || path.length < 2) return { matches: false };
      
      // 获取目标代币的索引
      const targetTokenLower = targetToken.toLowerCase();
      const tokenIndex = path.findIndex(addr => 
        addr.toLowerCase() === targetTokenLower
      );
      
      if (tokenIndex === -1) return { matches: false };
      
      // 确定交易类型（买入或卖出）
      const isBuy = tokenIndex === path.length - 1;
      const isSell = tokenIndex === 0;
      
      // 不符合监控模式的交易
      if ((monitorMode === 'buy' && !isBuy) || 
          (monitorMode === 'sell' && !isSell) ||
          (monitorMode === 'both' && !isBuy && !isSell)) {
        return { matches: false };
      }
      
      // 解析交易代幣金額 - 確保使用安全的 tx 物件
      const tokenAmountInfo = await extractTokenAmount(tx, methodId, path, tokenIndex);
      if (!tokenAmountInfo) return { matches: false };
      
      const { amount } = tokenAmountInfo;
      if (!amount || amount <= 0) return { matches: false };
      
      // 根據監控模式和觸發條件返回結果
      if (isBuy && (monitorMode === 'buy' || monitorMode === 'both')) {
        const triggerTokenAmount = parseFloat(buyTriggerTokenAmount || 0);
        const triggerUsdAmount = parseFloat(buyTriggerUsdAmount || 0);
        
        if ((triggerTokenAmount > 0 && amount >= triggerTokenAmount) ||
            (triggerUsdAmount > 0 && amount * getEstimatedTokenPrice() >= triggerUsdAmount)) {
          console.log('Buy condition matched:', { 
            amount, 
            usdValue: amount * getEstimatedTokenPrice() 
          });
          return { matches: true, type: 'buy', amount };
        }
      }
      
      if (isSell && (monitorMode === 'sell' || monitorMode === 'both')) {
        const triggerTokenAmount = parseFloat(sellTriggerTokenAmount || 0);
        const triggerUsdAmount = parseFloat(sellTriggerUsdAmount || 0);
        
        if ((triggerTokenAmount > 0 && amount >= triggerTokenAmount) ||
            (triggerUsdAmount > 0 && amount * getEstimatedTokenPrice() >= triggerUsdAmount)) {
          console.log('Sell condition matched:', { 
            amount, 
            usdValue: amount * getEstimatedTokenPrice() 
          });
          return { matches: true, type: 'sell', amount };
        }
      }
      
      return { matches: false };
    } catch (error) {
      console.error('Check conditions error:', error);
      return { matches: false };
    }
  }, [
    targetToken, 
    monitorMode, 
    buyTriggerTokenAmount, 
    buyTriggerUsdAmount,
    sellTriggerTokenAmount, 
    sellTriggerUsdAmount
  ]);
  
  // 獲取估計的代幣價格（用於觸發條件）
  const getEstimatedTokenPrice = React.useCallback(() => {
    // 這裡可以添加實際的價格獲取邏輯，例如從 API 或緩存
    // 為了簡化，暫時返回 1
    return 1;
  }, [targetToken]);
  
  // 執行抢跑交易
  const executeFrontrun = React.useCallback(async (tx, txType, gasPrice, isMempool) => {
    try {
      const web3 = window._web3Instance;
      if (!web3 || !window._selectedDex || !window._userAddresses?.length) {
        throw new Error('未連接RPC或未選擇DEX或錢包');
      }

      const results = [];
      
      // 根據交易類型確定路徑
      const path = txType === 'buy' ? getBuyPath() : getSellPath();
      
      if (!path || path.length < 2) {
        throw new Error(`無效的${txType === 'buy' ? '買入' : '賣出'}路徑`);
      }

      // 構建交易
      for (const userAddress of window._userAddresses) {
        try {
          let txConfig;
          
          if (txType === 'buy') {
            txConfig = await buildBuyTransaction({
              web3,
              path,
              buyMode: buyStartToken === 'NATIVE' ? 'NATIVE' : 'TOKEN',
              buyAmount: buyAmount,
              minOutputAmount,
              gasPrice,
              gasLimit,
              userAddress,
              routerAddress: window._selectedDex.router,
              dexVersion
            });
          } else {
            txConfig = await buildSellTransaction({
              web3,
              path,
              sellMode,
              sellAmount,
              sellPercentage: sellAmount, // 百分比模式使用相同字段
              gasPrice,
              gasLimit,
              userAddress,
              routerAddress: window._selectedDex.router,
              dexVersion
            });
          }

          // 執行交易
          const receipt = await sendAndConfirmTransaction(txConfig, web3);

          console.log(`${txType} transaction successful:`, receipt.transactionHash);
          
          // 记录成功结果
          results.push({
            type: txType,
            success: true,
            hash: receipt.transactionHash,
            mode: isMempool ? 'mempool' : 'block'
          });

          setMonitoringStats(prev => ({
            ...prev,
            successfulFrontrun: prev.successfulFrontrun + 1
          }));

        } catch (error) {
          console.error(`${txType} transaction failed:`, error);
          // 记录失败结果
          results.push({
            type: txType,
            success: false,
            error: error.message,
            mode: isMempool ? 'mempool' : 'block'
          });

          setMonitoringStats(prev => ({
            ...prev,
            failedFrontrun: prev.failedFrontrun + 1
          }));
        }
      }

      // 更新操作结果显示
      if (results.length > 0) {
        setOperationResults(prev => [...prev, ...results]);
        setShowResults(true);
      }

    } catch (error) {
      console.error('Execute frontrun error:', error);
      setError(`執行抢跑失敗: ${error.message}`);
    }
  }, [getBuyPath, getSellPath, buyStartToken, buyAmount, minOutputAmount, sellMode, sellAmount, gasLimit, dexVersion]);
// 開始監控
  const startMonitoring = async () => {
    if (!validateInputs()) return;

    try {
      console.log('Starting monitoring...');
      setIsMonitoring(true);
      setLoading(true);
      setError('');
      pendingTxRef.current.clear();

      // 初始化監控統計
      setMonitoringStats({
        totalScanned: 0,
        matchedTx: 0,
        successfulFrontrun: 0,
        failedFrontrun: 0,
        startTime: Date.now(),
        currentBlockNumber: null,
        lastTxHash: null
      });

      // 獲取監控管理器
      const monitoringManager = monitoringManagerRef.current;
      if (!monitoringManager) {
        throw new Error('監控管理器未初始化');
      }

      // 停止之前的所有監控
      await monitoringManager.stopAllMonitoring();
      
      // 設置監控狀態為活動
      monitoringManager.setMonitoring(true);
      
      // 處理監控錯誤
      const handleMonitoringError = (error) => {
        console.error('Monitoring error:', error);
        setError(`監控錯誤: ${error.message || '未知錯誤'}`);
        
        // 如果是WebSocket錯誤，自動切換到區塊輪詢
        if (error.message?.includes('WebSocket') || 
            error.message?.includes('connection not open') || 
            error.message?.includes('execution reverted')) {
          console.log('WebSocket or contract error detected, switching to block polling');
          const web3 = window._web3Instance;
          const chainId = chainSupport.chainId || '1';
          monitoringManager.startBlockPolling(web3, chainId, handleNewBlock);
          setMonitoringInfo(prev => ({
            ...prev,
            wsConnected: false,
            hasPendingTxSubscription: false,
            hasBlockPolling: true
          }));
        }
      };

      // 配置WebSocket監聽 - 實現0區塊延遲監控
      let wsMonitoringStarted = false;
      if (chainSupport.isSameBlockSupported && window._rpcUrl?.startsWith('ws')) {
        console.log('Attempting to set up mempool monitoring (0-block delay)');
        
        wsMonitoringStarted = await monitoringManager.startWebSocketMonitoring(
          window._rpcUrl,
          handlePendingTransaction,
          handleNewBlock,
          handleMonitoringError
        );
        
        if (wsMonitoringStarted) {
          console.log('WebSocket mempool monitoring started successfully');
          setMonitoringInfo(prev => ({
            ...prev,
            wsConnected: true,
            hasPendingTxSubscription: true
          }));
        } else {
          console.warn('Failed to start WebSocket monitoring, falling back to block monitoring');
        }
      } else {
        console.log('Mempool monitoring not supported, using block monitoring only');
      }

      // 設置區塊監聽 - 實現1區塊延遲監控（使用HTTP或WSS）
      const web3 = window._web3Instance;
      const blockMonitoringStarted = await monitoringManager.startBlockHeaderMonitoring(
        web3,
        handleNewBlock,
        handleMonitoringError
      );
      
      if (blockMonitoringStarted) {
        console.log('Block header monitoring started successfully');
        setMonitoringInfo(prev => ({
          ...prev,
          hasBlockHeaderSubscription: true
        }));
      } else {
        console.warn('Failed to start block header monitoring, trying polling instead');
        
        // 如果區塊訂閱失敗，使用輪詢作為後備方案
        const chainId = chainSupport.chainId || '1';
        const pollingStarted = monitoringManager.startBlockPolling(web3, chainId, handleNewBlock);
        
        if (pollingStarted) {
          console.log('Block polling started successfully');
          setMonitoringInfo(prev => ({
            ...prev,
            hasBlockPolling: true
          }));
        } else {
          throw new Error('無法啟動任何形式的監控');
        }
      }
      
      // 更新監控信息
      setMonitoringInfo(monitoringManager.getMonitoringInfo());
      setLoading(false);
      
    } catch (error) {
      console.error('Start monitoring error:', error);
      setError('啟動監控失敗: ' + error.message);
      stopMonitoring();
      setLoading(false);
    }
  };

  // 停止監控
  const stopMonitoring = React.useCallback(async () => {
    console.log('Stopping monitoring...');
    
    try {
      const monitoringManager = monitoringManagerRef.current;
      if (monitoringManager) {
        await monitoringManager.stopAllMonitoring();
        
        // 更新監控信息
        setMonitoringInfo({
          wsConnected: false,
          hasPendingTxSubscription: false,
          hasBlockHeaderSubscription: false,
          hasBlockPolling: false,
          lastProcessedBlock: null
        });
      }
      
      setLastProcessedBlock(null);
      setIsMonitoring(false);
      
      console.log('Monitoring stopped');
    } catch (error) {
      console.error('Error stopping monitoring:', error);
      setError(`停止監控時發生錯誤: ${error.message}`);
    }
  }, []);
  
  // 驗證輸入
  const validateInputs = React.useCallback(() => {
    if (!targetToken || !Web3.utils.isAddress(targetToken)) {
      setError('請輸入有效的目標代幣地址');
      return false;
    }

    const multiplier = parseFloat(gasMultiplier);
    if (isNaN(multiplier) || multiplier < 1.1 || multiplier > 10) {
      setError('Gas倍數必須在1.1~10之間');
      return false;
    }

    if (!window._web3Instance || !window._selectedDex) {
      setError('請先連接RPC並選擇DEX');
      return false;
    }

    if (!window._userAddresses?.length) {
      setError('請先選擇要操作的錢包');
      return false;
    }
    
    // 檢查買入路徑
    if (monitorMode === 'buy' || monitorMode === 'both') {
      // 確保至少設置了一個觸發條件
      if (!buyTriggerTokenAmount && !buyTriggerUsdAmount) {
        setError('請至少設置一個買入觸發條件');
        return false;
      }
      
      if (!buyAmount) {
        setError('請設置買入數量');
        return false;
      }
      
      // 驗證買入代幣數量格式
      if (isNaN(parseFloat(buyAmount)) || parseFloat(buyAmount) <= 0) {
        setError('買入數量必須是大於0的數字');
        return false;
      }
      
      // 檢查自定義買入代幣
      if (buyStartToken === 'CUSTOM' && (!buyCustomStartToken || !Web3.utils.isAddress(buyCustomStartToken))) {
        setError('請輸入有效的自定義買入代幣地址');
        return false;
      }
      
      // 檢查三級路徑中的中間代幣
      if (buyPathType === PATH_TYPES.THREE_HOP) {
        if (buyMiddleToken === 'CUSTOM' && (!buyCustomMiddleToken || !Web3.utils.isAddress(buyCustomMiddleToken))) {
          setError('請輸入有效的自定義中間代幣地址');
          return false;
        }
      }
      
      // 檢查買入路徑是否有效
      const buyPath = getBuyPath();
      if (!buyPath || buyPath.length < 2) {
        setError('無法確定買入路徑，請檢查代幣設置');
        return false;
      }
    }
    
    // 檢查賣出路徑
    if (monitorMode === 'sell' || monitorMode === 'both') {
      // 確保至少設置了一個觸發條件
      if (!sellTriggerTokenAmount && !sellTriggerUsdAmount) {
        setError('請至少設置一個賣出觸發條件');
        return false;
      }
      
      if (!sellAmount) {
        setError('請設置賣出數量/百分比');
        return false;
      }
      
      // 驗證賣出數量/百分比格式
      if (isNaN(parseFloat(sellAmount)) || parseFloat(sellAmount) <= 0) {
        setError('賣出數量/百分比必須是大於0的數字');
        return false;
      }
      
      if (sellMode === 'percentage' && parseFloat(sellAmount) > 100) {
        setError('賣出百分比不能超過100%');
        return false;
      }
      
      // 檢查自定義賣出代幣
      if (sellEndToken === 'CUSTOM' && (!sellCustomEndToken || !Web3.utils.isAddress(sellCustomEndToken))) {
        setError('請輸入有效的自定義賣出代幣地址');
        return false;
      }
      
      // 檢查三級路徑中的中間代幣
      if (sellPathType === PATH_TYPES.THREE_HOP) {
        if (sellMiddleToken === 'CUSTOM' && (!sellCustomMiddleToken || !Web3.utils.isAddress(sellCustomMiddleToken))) {
          setError('請輸入有效的自定義中間代幣地址');
          return false;
        }
      }
      
      // 檢查賣出路徑是否有效
      const sellPath = getSellPath();
      if (!sellPath || sellPath.length < 2) {
        setError('無法確定賣出路徑，請檢查代幣設置');
        return false;
      }
    }

    return true;
  }, [
    targetToken, gasMultiplier, monitorMode, 
    buyTriggerTokenAmount, buyTriggerUsdAmount, buyAmount, 
    sellTriggerTokenAmount, sellTriggerUsdAmount, sellAmount, sellMode,
    buyStartToken, buyMiddleToken, buyCustomStartToken, buyCustomMiddleToken,
    sellEndToken, sellMiddleToken, sellCustomEndToken, sellCustomMiddleToken,
    buyPathType, sellPathType, getBuyPath, getSellPath
  ]);
// 渲染監控統計
  const renderMonitoringStats = () => {
    if (!isMonitoring && !monitoringStats.startTime) return null;

    const duration = monitoringStats.startTime ? 
      Math.max(0, currentTime - monitoringStats.startTime) : 0;
    
    // 確定當前運行模式
    let runningMode = "";
    if (monitoringInfo.wsConnected && monitoringInfo.hasPendingTxSubscription) {
      runningMode += "內存池(0區塊延遲)";
      if (monitoringInfo.hasBlockHeaderSubscription || monitoringInfo.hasBlockPolling) {
        runningMode += " + ";
      }
    }
    
    if (monitoringInfo.hasBlockHeaderSubscription) {
      runningMode += "區塊監控(1區塊延遲)";
    } else if (monitoringInfo.hasBlockPolling) {
      runningMode += "區塊輪詢(1區塊延遲)";
    }
    
    if (!runningMode) {
      runningMode = "未監控";
    }

    return (
      <Card sx={{ mb: 2, bgcolor: 'info.dark' }}>
        <CardContent>
          <Typography variant="h6" color="common.white" gutterBottom>
            監控統計
          </Typography>
          <Grid container spacing={2}>
            {/* 運行模式 */}
            <Grid item xs={12}>
              <Typography color="common.white">
                當前運行模式: {runningMode}
              </Typography>
            </Grid>
            
            {/* 當前交易路徑 */}
            {monitorMode === 'buy' || monitorMode === 'both' ? (
              <Grid item xs={12}>
                <Typography color="common.white">
                  買入路徑: {
                    getBuyPath().map((addr, index) => (
                      <React.Fragment key={addr}>
                        <Chip 
                          label={shortenAddress(addr)}
                          size="small"
                          color={index === 0 ? 'primary' : 
                                 index === getBuyPath().length - 1 ? 'success' : 
                                 'default'}
                          sx={{ mx: 0.5 }}
                        />
                        {index < getBuyPath().length - 1 && '→'}
                      </React.Fragment>
                    ))
                  }
                </Typography>
              </Grid>
            ) : null}
            
            {monitorMode === 'sell' || monitorMode === 'both' ? (
              <Grid item xs={12}>
                <Typography color="common.white">
                  賣出路徑: {
                    getSellPath().map((addr, index) => (
                      <React.Fragment key={addr}>
                        <Chip 
                          label={shortenAddress(addr)}
                          size="small"
                          color={index === 0 ? 'primary' : 
                                 index === getSellPath().length - 1 ? 'success' : 
                                 'default'}
                          sx={{ mx: 0.5 }}
                        />
                        {index < getSellPath().length - 1 && '→'}
                      </React.Fragment>
                    ))
                  }
                </Typography>
              </Grid>
            ) : null}

            {/* 統計數據 */}
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                已掃描交易: {monitoringStats.totalScanned}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                匹配交易: {monitoringStats.matchedTx}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                成功抢跑: {monitoringStats.successfulFrontrun}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                失敗抢跑: {monitoringStats.failedFrontrun}
              </Typography>
            </Grid>
            
            {/* 運行時間和區塊信息 */}
            <Grid item xs={6} md={6}>
              <Typography color="common.white">
                運行時間: {formatDuration(duration)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography color="common.white">
                當前區塊: {monitoringStats.currentBlockNumber || '-'}
              </Typography>
            </Grid>
            
            {/* 最後交易 */}
            {monitoringStats.lastTxHash && (
              <Grid item xs={12}>
                <Typography color="common.white">
                  最後匹配交易: 
                  <Link 
                    href={`${explorerUrl}/tx/${monitoringStats.lastTxHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'common.white', ml: 1 }}
                  >
                    {shortenAddress(monitoringStats.lastTxHash, 10, 8)}
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 渲染結果列表
  const renderResults = () => {
    if (!showResults || operationResults.length === 0) return null;

    return (
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            操作結果
            <Button 
              size="small" 
              onClick={() => setShowResults(false)}
              sx={{ ml: 2 }}
            >
              關閉
            </Button>
          </Typography>
          <List>
            {operationResults.map((result, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={`操作類型: ${result.type === 'buy' ? '買入' : '賣出'} (${result.mode === 'mempool' ? '0區塊延遲' : '1區塊延遲'})`}
                    secondary={
                      result.success ? (
                        <Link 
                          href={`${explorerUrl}/tx/${result.hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          查看交易: {shortenAddress(result.hash, 10, 8)}
                        </Link>
                      ) : (
                        `失敗: ${result.error}`
                      )
                    }
                    sx={{
                      color: result.success ? 'success.main' : 'error.main'
                    }}
                  />
                </ListItem>
                {index < operationResults.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    );
  };

// 渲染主要UI
  return (
    <Box>
      {/* 鏈支持狀態提示 */}
      <Alert 
        severity={chainSupport.isSameBlockSupported ? "success" : "warning"}
        sx={{ mb: 2 }}
      >
        {chainSupport.isSameBlockSupported ? 
          "此鏈支持0區塊抢跑 (通過內存池)" : 
          "此鏈僅支持1區塊觸發 (通過區塊訂閱)"
        }
      </Alert>

      {/* 監控統計 */}
      {renderMonitoringStats()}

      {/* 主設置卡片 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Gas設置 */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                基本設置
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Gas Price倍數 (1.1~10)"
                type="number"
                value={gasMultiplier}
                onChange={(e) => setGasMultiplier(e.target.value)}
                InputProps={{
                  inputProps: { min: 1.1, max: 10, step: 0.1 },
                  endAdornment: (
                    <Tooltip title="設置你的交易Gas Price為目標交易的倍數">
                      <IconButton size="small">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Gas Limit"
                type="number"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                InputProps={{
                  inputProps: { min: 100000, step: 50000 }
                }}
              />
            </Grid>

            {/* DEX版本選擇 */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>DEX版本</InputLabel>
                <Select
                  value={dexVersion}
                  onChange={(e) => setDexVersion(e.target.value)}
                  label="DEX版本"
                >
                  <MenuItem value="v2">UniswapV2 / PancakeswapV2</MenuItem>
                  <MenuItem value="v3">UniswapV3 / PancakeswapV3</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* 目標代幣設置 */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="目標代幣地址"
                value={targetToken}
                onChange={(e) => setTargetToken(e.target.value)}
                error={targetToken !== '' && !Web3.utils.isAddress(targetToken)}
                helperText={targetToken !== '' && !Web3.utils.isAddress(targetToken) ? '無效的地址格式' : ''}
              />
            </Grid>

            {/* 監控模式選擇 */}
            <Grid item xs={12}>
              <RadioGroup
                value={monitorMode}
                onChange={(e) => setMonitorMode(e.target.value)}
                row
              >
                <FormControlLabel value="buy" control={<Radio />} label="跑買" />
                <FormControlLabel value="sell" control={<Radio />} label="跑賣" />
                <FormControlLabel value="both" control={<Radio />} label="跑買賣" />
              </RadioGroup>
            </Grid>

            {/* 買入設置 */}
            {(monitorMode === 'buy' || monitorMode === 'both') && (
              <>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="h6" gutterBottom>
                    買入設置
                  </Typography>
                </Grid>
                
                {/* 買入路徑設置 */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    買入路徑配置
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>路徑類型</InputLabel>
                    <Select
                      value={buyPathType}
                      onChange={(e) => setBuyPathType(e.target.value)}
                      label="路徑類型"
                    >
                      <MenuItem value={PATH_TYPES.TWO_HOP}>兩級路徑</MenuItem>
                      <MenuItem value={PATH_TYPES.THREE_HOP}>三級路徑</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>買入起始代幣</InputLabel>
                    <Select
                      value={buyStartToken}
                      onChange={(e) => setBuyStartToken(e.target.value)}
                      label="買入起始代幣"
                    >
                      <MenuItem value="NATIVE">原生代幣 ({nativeSymbol})</MenuItem>
                      {availableTokens.stablecoins.map(token => (
                        <MenuItem key={token.address} value={token.symbol}>
                          {token.symbol || token.name}
                        </MenuItem>
                      ))}
                      <MenuItem value="CUSTOM">自定義代幣</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                {buyStartToken === 'CUSTOM' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="自定義買入起始代幣地址"
                      value={buyCustomStartToken}
                      onChange={(e) => setBuyCustomStartToken(e.target.value)}
                      error={buyCustomStartToken !== '' && !Web3.utils.isAddress(buyCustomStartToken)}
                    />
                  </Grid>
                )}
                
                {buyPathType === PATH_TYPES.THREE_HOP && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>買入中間代幣</InputLabel>
                        <Select
                          value={buyMiddleToken}
                          onChange={(e) => setBuyMiddleToken(e.target.value)}
                          label="買入中間代幣"
                        >
                          <MenuItem value="WRAPPED_NATIVE">包裝原生代幣 ({nativeSymbol}W)</MenuItem>
                          {availableTokens.stablecoins.map(token => (
                            <MenuItem key={token.address} value={token.symbol}>
                              {token.symbol || token.name}
                            </MenuItem>
                          ))}
                          <MenuItem value="CUSTOM">自定義代幣</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    
                    {buyMiddleToken === 'CUSTOM' && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="自定義買入中間代幣地址"
                          value={buyCustomMiddleToken}
                          onChange={(e) => setBuyCustomMiddleToken(e.target.value)}
                          error={buyCustomMiddleToken !== '' && !Web3.utils.isAddress(buyCustomMiddleToken)}
                        />
                      </Grid>
                    )}
                  </>
                )}
                
                {/* 買入觸發條件 */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    買入觸發條件 (至少填寫一項)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="觸發代幣數量"
                    type="number"
                    value={buyTriggerTokenAmount}
                    onChange={(e) => setBuyTriggerTokenAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="觸發美金金額"
                    type="number"
                    value={buyTriggerUsdAmount}
                    onChange={(e) => setBuyTriggerUsdAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>

                {/* 買入交易設置 */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    買入設置
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={`買入數量${buyStartToken === 'NATIVE' ? ` (${nativeSymbol})` : ''}`}
                    type="number"
                    value={buyAmount}
                    onChange={(e) => setBuyAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="最少獲得數量"
                    type="number"
                    value={minOutputAmount}
                    onChange={(e) => setMinOutputAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
              </>
            )}

            {/* 賣出設置 */}
            {(monitorMode === 'sell' || monitorMode === 'both') && (
              <>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="h6" gutterBottom>
                    賣出設置
                  </Typography>
                </Grid>
                
                {/* 賣出路徑設置 */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    賣出路徑配置
                  </Typography>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>路徑類型</InputLabel>
                    <Select
                      value={sellPathType}
                      onChange={(e) => setSellPathType(e.target.value)}
                      label="路徑類型"
                    >
                      <MenuItem value={PATH_TYPES.TWO_HOP}>兩級路徑</MenuItem>
                      <MenuItem value={PATH_TYPES.THREE_HOP}>三級路徑</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                {sellPathType === PATH_TYPES.THREE_HOP && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>賣出中間代幣</InputLabel>
                        <Select
                          value={sellMiddleToken}
                          onChange={(e) => setSellMiddleToken(e.target.value)}
                          label="賣出中間代幣"
                        >
                          <MenuItem value="WRAPPED_NATIVE">包裝原生代幣 ({nativeSymbol}W)</MenuItem>
                          {availableTokens.stablecoins.map(token => (
                            <MenuItem key={token.address} value={token.symbol}>
                              {token.symbol || token.name}
                            </MenuItem>
                          ))}
                          <MenuItem value="CUSTOM">自定義代幣</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    
                    {sellMiddleToken === 'CUSTOM' && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="自定義賣出中間代幣地址"
                          value={sellCustomMiddleToken}
                          onChange={(e) => setSellCustomMiddleToken(e.target.value)}
                          error={sellCustomMiddleToken !== '' && !Web3.utils.isAddress(sellCustomMiddleToken)}
                        />
                      </Grid>
                    )}
                  </>
                )}
                
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>賣出結束代幣</InputLabel>
                    <Select
                      value={sellEndToken}
                      onChange={(e) => setSellEndToken(e.target.value)}
                      label="賣出結束代幣"
                    >
                      <MenuItem value="NATIVE">原生代幣 ({nativeSymbol})</MenuItem>
                      {availableTokens.stablecoins.map(token => (
                        <MenuItem key={token.address} value={token.symbol}>
                          {token.symbol || token.name}
                        </MenuItem>
                      ))}
                      <MenuItem value="CUSTOM">自定義代幣</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                {sellEndToken === 'CUSTOM' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="自定義賣出結束代幣地址"
                      value={sellCustomEndToken}
                      onChange={(e) => setSellCustomEndToken(e.target.value)}
                      error={sellCustomEndToken !== '' && !Web3.utils.isAddress(sellCustomEndToken)}
                    />
                  </Grid>
                )}
                
                {/* 賣出觸發條件 */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    賣出觸發條件 (至少填寫一項)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="觸發代幣數量"
                    type="number"
                    value={sellTriggerTokenAmount}
                    onChange={(e) => setSellTriggerTokenAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="觸發美金金額"
                    type="number"
                    value={sellTriggerUsdAmount}
                    onChange={(e) => setSellTriggerUsdAmount(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RadioGroup
                    value={sellMode}
                    onChange={(e) => setSellMode(e.target.value)}
                    row
                  >
                    <FormControlLabel 
                      value="percentage" 
                      control={<Radio />} 
                      label="百分比" 
                    />
                    <FormControlLabel 
                      value="fixed" 
                      control={<Radio />} 
                      label="固定數量" 
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={sellMode === 'percentage' ? '賣出百分比 (%)' : '賣出數量'}
                    type="number"
                    value={sellAmount}
                    onChange={(e) => setSellAmount(e.target.value)}
                    InputProps={sellMode === 'percentage' ? {
                      inputProps: { min: 0, max: 100, step: 0.1 }
                    } : {
                      inputProps: { min: 0, step: "any" }
                    }}
                  />
                </Grid>
              </>
            )}

            {/* 監控控制按鈕 */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={isMonitoring ? stopMonitoring : startMonitoring}
                disabled={loading}
                size="large"
                sx={{ height: '48px', mt: 2 }}
              >
                {loading ? <CircularProgress size={24} /> : 
                 isMonitoring ? "停止監控" : "開始監控"}
              </Button>
            </Grid>

            {/* 錯誤提示 */}
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">
                  {error}
                </Alert>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* 結果列表 */}
      {renderResults()}

      {/* 監控中提示 */}
      {isMonitoring && (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      )}

      {/* 區塊信息顯示 */}
      {isMonitoring && lastProcessedBlock && (
        <Typography variant="body2" color="textSecondary" align="center">
          最後處理區塊: {lastProcessedBlock}
        </Typography>
      )}

      {/* 系統狀態顯示 */}
      {isMonitoring && monitoringStats.startTime && (
        <Typography variant="body2" color="textSecondary" align="center">
          系統運行中... {formatDuration(currentTime - monitoringStats.startTime)}
        </Typography>
      )}

      {/* 网络状态显示 */}
      {chainSupport.chainId && (
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
          當前網絡: Chain ID {chainSupport.chainId}
          {chainSupport.isL2Chain ? ' (L2)' : ''}
        </Typography>
      )}

      {/* 底部間距 */}
      <Box sx={{ mb: 4 }} />
    </Box>
  );
};

// 導出組件
module.exports = FrontrunPanel;
