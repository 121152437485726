const React = require('react');

// 建立身份驗證上下文
const AuthContext = React.createContext(null);

// AuthProvider 組件，用於提供身份驗證狀態和方法
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  
  // 組件載入時檢查身份驗證狀態 - 始終設為未認證狀態，確保每次刷新都需要重新驗證
  React.useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        // 每次頁面刷新時重置認證狀態為未登入
        setIsAuthenticated(false);
      } catch (error) {
        console.error('身份驗證錯誤:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkAuth();
  }, []);
  
  // 從遠程URL讀取配置文件
  const getAuthConfig = async () => {
    try {
      // 從您提供的URL讀取配置，加入時間戳防止緩存
      const response = await fetch('http://file.maiko.icu/d/password.json?sign=UGsUc_CB5NXEvogCytb2SCt98MA_YzZl9MevQzjdol4=:0&t=' + Date.now());
      if (!response.ok) {
        console.error('無法讀取配置文件，使用默認配置');
        return { whitelistedIPs: [], passphrases: [] };
      }
      
      const config = await response.json();
      console.log('成功讀取遠程配置');
      return config;
    } catch (error) {
      console.error('讀取配置錯誤:', error);
      return { whitelistedIPs: [], passphrases: [] };
    }
  };
  
  // 通過 IP 進行身份驗證
  const authenticateByIP = async () => {
    setIsLoading(true);
    
    try {
      // 設置較短的超時時間 (1.5秒)
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('IP 驗證超時')), 1500);
      });
      
      // IP 驗證的 Promise
      const ipCheckPromise = async () => {
        try {
          // 獲取客戶端 IP
          const ipResponse = await fetch('https://api.ipify.org?format=json');
          if (!ipResponse.ok) {
            throw new Error('無法獲取 IP 地址');
          }
          
          const ipData = await ipResponse.json();
          const clientIP = ipData.ip;
          
          console.log('當前IP:', clientIP);
          
          // 讀取配置並檢查 IP
          const config = await getAuthConfig();
          
          if (config.whitelistedIPs.includes(clientIP)) {
            // 如果 IP 被允許，設置認證狀態，但不保存到 localStorage
            setIsAuthenticated(true);
            return { success: true };
          }
          
          // 明確返回失敗結果，不再等待
          console.log('IP不在白名單中:', clientIP);
          return { success: false, shouldShowPassphrase: true };
        } catch (error) {
          console.error('IP 獲取錯誤:', error);
          return { success: false, error: error.message, shouldShowPassphrase: true };
        }
      };
      
      // 使用 Promise.race 實現超時處理
      try {
        const result = await Promise.race([
          ipCheckPromise(),
          timeoutPromise
        ]);
        return result;
      } catch (error) {
        console.log('IP 驗證失敗或超時:', error.message);
        return { success: false, error: '驗證超時，請輸入密語', shouldShowPassphrase: true };
      }
    } finally {
      // 確保載入狀態結束
      setIsLoading(false);
    }
  };
  
  // 通過密語進行身份驗證
  const authenticateByPassphrase = async (passphrase) => {
    setIsLoading(true);
    try {
      // 讀取配置檢查密語
      const config = await getAuthConfig();
      
      // 檢查是否是有效的密語 (支持多個密語)
      if (config.passphrases.includes(passphrase)) {
        // 如果密語正確，設置認證狀態，但不保存到 localStorage
        setIsAuthenticated(true);
        return { success: true };
      }
      return { success: false, message: '密語不正確' };
    } catch (error) {
      console.error('密語驗證錯誤:', error);
      return { success: false, error: '驗證失敗，請稍後再試' };
    } finally {
      setIsLoading(false);
    }
  };
  
  // 登出函數
  const logout = () => {
    setIsAuthenticated(false);
  };
  
  // 提供給子組件的上下文值
  const contextValue = {
    isAuthenticated,
    isLoading,
    authenticateByIP,
    authenticateByPassphrase,
    logout
  };
  
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// 導出上下文和提供者
module.exports = {
  AuthContext,
  AuthProvider,
  // 自定義 Hook 方便使用 AuthContext
  useAuth: () => React.useContext(AuthContext)
};
