const React = require('react');
const { 
  Box, 
  Container, 
  AppBar, 
  Toolbar, 
  Typography, 
  Paper, 
  Tab, 
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} = require('@mui/material');

// 引入所有模組
const RPC = require('./rpc');
const DEX = require('./dex');
const Wallet = require('./wallet');
const Trade = require('./trade');
const Mempool = require('./mempool');
const FrontrunPanel = require('./frontrunPanel');
const BatchTokenPanel = require('./batchTokenPanel');
const SmartMoneyTracker = require('./SmartMoneyTracker');
const Virtual = require('./virtual');
const Fourmeme = require('./FourMemeSniper'); // 引入 fourmeme 狙擊組件
const MarketCapPanel = require('./MarketCapPanel'); // 引入市值組件

// 引入身份驗證組件
const { AuthProvider } = require('./AuthContext');
const AuthGateway = require('./AuthGateway');

const App = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [launchpadOption, setLaunchpadOption] = React.useState('virtual'); // 預設選擇 virtual

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSolanaChange = (event) => {
    const selection = event.target.value;
    if (selection === 'trade') {
      window.location.href = '/solana-trade.html';
    } else if (selection === 'copy') {
      window.location.href = '/solana-copy.html';
    }
  };

  const handleLaunchpadChange = (event) => {
    setLaunchpadOption(event.target.value);
  };

  // 主應用內容
  const MainAppContent = () => (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <AppBar position="static" elevation={2}>
        <Toolbar>
          <Typography variant="h6">
            MaikoSniper
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
        {/* RPC 設置 */}
        <Box sx={{ mb: 3 }}>
          <RPC />
        </Box>
        
        {/* DEX 設置 */}
        <Box sx={{ mb: 3 }}>
          <DEX />
        </Box>
        
        {/* 錢包管理 */}
        <Box sx={{ mb: 3 }}>
          <Wallet />
        </Box>
        
        {/* 功能標籤頁 */}
        <Paper elevation={3}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="交易" />
            <Tab label="內存池" />
            <Tab label="大單搶跑" />
            <Tab label="代幣批量操作" />
            <Tab label="聰明錢追蹤" />
            <Tab label="LaunchPad" />
            <Tab label="市值監控" />
            <Tab label="Solana专区" />
          </Tabs>
          
          <Box sx={{ p: 3 }}>
            {/* 交易 */}
            <Box sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
              <Trade />
            </Box>
            
            {/* 內存池 */}
            <Box sx={{ display: tabValue === 1 ? 'block' : 'none' }}>
              <Mempool />
            </Box>
            
            {/* 大單搶跑 */}
            <Box sx={{ display: tabValue === 2 ? 'block' : 'none' }}>
              <FrontrunPanel />
            </Box>
            
            {/* 代幣批量操作 */}
            <Box sx={{ display: tabValue === 3 ? 'block' : 'none' }}>
              <BatchTokenPanel />
            </Box>
            
            {/* 聰明錢追蹤 */}
            <Box sx={{ display: tabValue === 4 ? 'block' : 'none' }}>
              <SmartMoneyTracker />
            </Box>
            
            {/* LaunchPad */}
            <Box sx={{ display: tabValue === 5 ? 'block' : 'none' }}>
              <Paper sx={{ p: 2, mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="launchpad-section-label">選擇功能</InputLabel>
                  <Select
                    labelId="launchpad-section-label"
                    id="launchpad-section"
                    label="選擇功能"
                    value={launchpadOption}
                    onChange={handleLaunchpadChange}
                  >
                    <MenuItem value="virtual">Virtual內盤(Base)</MenuItem>
                    <MenuItem value="fourmeme">Four.Meme狙擊(BSC)</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
              
              {/* 顯示選中的LaunchPad組件 */}
              {launchpadOption === 'virtual' ? (
                <Virtual />
              ) : (
                <Fourmeme />
              )}
            </Box>
            
            {/* 市值監控 */}
            <Box sx={{ display: tabValue === 6 ? 'block' : 'none' }}>
              <MarketCapPanel />
            </Box>
            
            {/* Solana专区 */}
            <Box sx={{ display: tabValue === 7 ? 'block' : 'none' }}>
              <Paper sx={{ p: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="solana-section-label">选择功能</InputLabel>
                  <Select
                    labelId="solana-section-label"
                    id="solana-section"
                    label="选择功能"
                    onChange={handleSolanaChange}
                    defaultValue=""
                  >
                    <MenuItem value="trade">交易</MenuItem>
                    <MenuItem value="copy">跟单</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );

  // 使用 AuthProvider 包裹整個應用，並通過 AuthGateway 進行訪問控制
  return (
    <AuthProvider>
      <AuthGateway>
        <MainAppContent />
      </AuthGateway>
    </AuthProvider>
  );
};

module.exports = App;
