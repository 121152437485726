const React = require('react');
const {
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  Alert,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  LinearProgress,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Switch
} = require('@mui/material');

const {
  Search: SearchIcon,
  Info: InfoIcon,
  Shield: ShieldIcon,
  AttachMoney: AttachMoneyIcon,
  SwapHoriz: SwapHorizIcon,
  Bolt: BoltIcon,
  LocalFireDepartment: LocalFireDepartmentIcon
} = require('@mui/icons-material');

const Web3 = require('web3');

// 定義常量
const NATIVE_TOKEN = {
  BSC: {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB地址
    symbol: 'BNB',
    decimals: 18
  }
};

// 防MEV的RPC
const ANTI_MEV_RPC = 'https://bsc.rpc.blocksec.com';

const TOKENS = {
  BNB: {
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB地址
    symbol: 'BNB',
    decimals: 18,
    isNative: true
  },
  USDT: {
    address: '0x55d398326f99059fF775485246999027B3197955', // BSC USDT
    symbol: 'USDT',
    decimals: 18,
    isStable: true
  },
  USDC: {
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // BSC USDC
    symbol: 'USDC',
    decimals: 18,
    isStable: true
  }
};

// Uniswap V3 Router地址
const UNISWAP_V3_ROUTER_ADDRESS = '0x13f4EA83D0bd40E75C8222255bc855a974568Dd4'; // Pancake V3 Router on BSC
// Uniswap V3 Router ABI (簡化版)
const UNISWAP_V3_ROUTER_ABI = [
  {
    "inputs": [
      {
        "components": [
          { "internalType": "address", "name": "tokenIn", "type": "address" },
          { "internalType": "address", "name": "tokenOut", "type": "address" },
          { "internalType": "uint24", "name": "fee", "type": "uint24" },
          { "internalType": "address", "name": "recipient", "type": "address" },
          { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
          { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" },
          { "internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160" }
        ],
        "internalType": "struct ISwapRouter.ExactInputSingleParams",
        "name": "params",
        "type": "tuple"
      }
    ],
    "name": "exactInputSingle",
    "outputs": [
      { "internalType": "uint256", "name": "amountOut", "type": "uint256" }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes[]",
        "name": "data",
        "type": "bytes[]"
      }
    ],
    "name": "multicall",
    "outputs": [
      {
        "internalType": "bytes[]",
        "name": "results",
        "type": "bytes[]"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  }
];

// ERC20 ABI
const ERC20_ABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "name",
    "outputs": [{"name": "", "type": "string"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "symbol",
    "outputs": [{"name": "", "type": "string"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "decimals",
    "outputs": [{"name": "", "type": "uint8"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [{"name": "owner", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"name": "", "type": "uint256"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {"name": "spender", "type": "address"},
      {"name": "value", "type": "uint256"}
    ],
    "name": "approve",
    "outputs": [{"name": "", "type": "bool"}],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {"name": "owner", "type": "address"},
      {"name": "spender", "type": "address"}
    ],
    "name": "allowance",
    "outputs": [{"name": "", "type": "uint256"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
];
// 創建防MEV Web3實例
const createAntiMevWeb3 = () => {
  try {
    return new Web3(new Web3.providers.HttpProvider(ANTI_MEV_RPC));
  } catch (error) {
    console.error('Create Anti-MEV Web3 error:', error);
    throw error;
  }
};

// 工具函數
// 獲取代幣精度
const getTokenDecimals = async (web3, tokenAddress) => {
  try {
    const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
    const decimals = await tokenContract.methods.decimals().call();
    return parseInt(decimals);
  } catch (error) {
    console.error('Get token decimals error:', error);
    return 18; // 默認返回18位精度
  }
};

// 獲取代幣信息
const getTokenInfo = async (web3, tokenAddress) => {
  try {
    const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
    const [symbol, decimals, name] = await Promise.all([
      tokenContract.methods.symbol().call(),
      tokenContract.methods.decimals().call(),
      tokenContract.methods.name().call().catch(() => '')
    ]);
    return { symbol, decimals: parseInt(decimals), name };
  } catch (error) {
    console.error('Get token info error:', error);
    return { symbol: '???', decimals: 18, name: 'Unknown Token' };
  }
};

// 轉換金額到代幣精度
const toTokenAmount = (web3, amount, decimals) => {
  try {
    return web3.utils.toBN(web3.utils.toWei(amount.toString(), 'ether'))
      .mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals - 18 > 0 ? decimals - 18 : 0)))
      .div(web3.utils.toBN(10).pow(web3.utils.toBN(decimals - 18 < 0 ? Math.abs(decimals - 18) : 0)))
      .toString();
  } catch (error) {
    console.error('Convert to token amount error:', error);
    throw error;
  }
};

// 從代幣精度轉換回顯示金額
const fromTokenAmount = (web3, amount, decimals) => {
  try {
    return web3.utils.fromWei(
      web3.utils.toBN(amount)
        .mul(web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals > 0 ? 18 - decimals : 0)))
        .div(web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals < 0 ? Math.abs(18 - decimals) : 0)))
        .toString(),
      'ether'
    );
  } catch (error) {
    console.error('Convert from token amount error:', error);
    throw error;
  }
};
// 主組件
const fourmeme = () => {
  // Tab狀態
  const [tabValue, setTabValue] = React.useState(0);

  // 基礎狀態
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [debugInfo, setDebugInfo] = React.useState({
    lastError: null,
    txInfo: null
  });

  // 防MEV狀態
  const [useAntiMev, setUseAntiMev] = React.useState(false);
  const [antiMevWeb3, setAntiMevWeb3] = React.useState(null);

  // 模式選擇狀態
  const [mode, setMode] = React.useState('snipe');
  const [tradeType, setTradeType] = React.useState('buy');
  
  // 新增：燃燒購買選項
  const [useSpamBuy, setUseSpamBuy] = React.useState(false);
  const [spamInterval, setSpamInterval] = React.useState('3000'); // 默認3000毫秒
  const [spamCount, setSpamCount] = React.useState(''); // 可選，不填則無限購買
  const [isSpamming, setIsSpamming] = React.useState(false);
  const [spamCounter, setSpamCounter] = React.useState(0);
  const [spamIntervalId, setSpamIntervalId] = React.useState(null);
  
  // 新增：0區塊狙擊選項
  const [useZeroBlockSnipe, setUseZeroBlockSnipe] = React.useState(false);
  const [devTxGasPrice, setDevTxGasPrice] = React.useState(null);
  
  // 狙擊模式狀態
  const [devAddress, setDevAddress] = React.useState('');
  const [snipeTokenAddress, setSnipeTokenAddress] = React.useState('');
  const [inputToken, setInputToken] = React.useState('BNB');
  const [inputAmount, setInputAmount] = React.useState('');
  const [minOutputAmount, setMinOutputAmount] = React.useState('');
  const [targetTokenInfo, setTargetTokenInfo] = React.useState(null);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [pendingTxHash, setPendingTxHash] = React.useState('');
  
  // 一般交易模式狀態
  const [normalInputToken, setNormalInputToken] = React.useState('BNB');
  const [normalOutputToken, setNormalOutputToken] = React.useState('');
  const [normalInputAmount, setNormalInputAmount] = React.useState('');
  const [normalMinOutputAmount, setNormalMinOutputAmount] = React.useState('');
  const [normalTokenAddress, setNormalTokenAddress] = React.useState('');
  const [normalTokenInfo, setNormalTokenInfo] = React.useState(null);

  // Gas相關狀態 - 更新預設值為3000000
  const [gasPrice, setGasPrice] = React.useState('5');
  const [gasLimit, setGasLimit] = React.useState('3000000');
// 初始化防MEV Web3
  React.useEffect(() => {
    if (useAntiMev && !antiMevWeb3) {
      try {
        const newWeb3 = createAntiMevWeb3();
        setAntiMevWeb3(newWeb3);
      } catch (error) {
        console.error('Initialize anti-MEV Web3 error:', error);
        setError('初始化防夾子RPC失敗: ' + error.message);
      }
    }
  }, [useAntiMev]);
  
  // 處理模式變更
  const handleModeChange = (event) => {
    setMode(event.target.value);
    // 重置錯誤與成功信息
    setError('');
    setSuccess('');
  };

  // 處理交易類型變更
  const handleTradeTypeChange = (event) => {
    setTradeType(event.target.value);
    // 重置錯誤與成功信息
    setError('');
    setSuccess('');
  };

  // 處理0區塊狙擊選項變更
  const handleZeroBlockSnipeChange = (event) => {
    setUseZeroBlockSnipe(event.target.checked);
    // 如果啟用0區塊狙擊，清除用戶輸入的gas price
    if (event.target.checked) {
      setDevTxGasPrice(null);
    }
  };
  
  // 新增：處理燃燒購買選項變更
  const handleSpamBuyChange = (event) => {
    setUseSpamBuy(event.target.checked);
    // 如果禁用燃燒購買且正在執行，則停止
    if (!event.target.checked && isSpamming) {
      stopSpamBuying();
    }
  };

  // 獲取正確的Web3實例
  const getWeb3 = () => {
    return useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
  };
  
  // 檢查代幣信息
  const checkTokenInfo = async (tokenAddress, setTokenInfoState) => {
    if (!window._web3Instance) {
      setError('請先連接錢包');
      return;
    }

    if (!Web3.utils.isAddress(tokenAddress)) {
      setError('無效的代幣地址');
      return;
    }

    try {
      setLoading(true);
      const web3 = getWeb3();
      const tokenInfo = await getTokenInfo(web3, tokenAddress);
      
      setTokenInfoState({
        address: tokenAddress,
        ...tokenInfo
      });
      
      setLoading(false);
      return tokenInfo;
    } catch (error) {
      console.error('Check token info error:', error);
      setError('獲取代幣信息失敗: ' + error.message);
      setLoading(false);
      return null;
    }
  };

  // 處理狙擊代幣地址變更並取得代幣信息
  const handleSnipeTokenAddressChange = async (event) => {
    const address = event.target.value;
    setSnipeTokenAddress(address);
    
    if (address && Web3.utils.isAddress(address)) {
      await checkTokenInfo(address, setTargetTokenInfo);
    } else {
      setTargetTokenInfo(null);
    }
  };

  // 處理一般交易代幣地址變更並取得代幣信息
  const handleNormalTokenAddressChange = async (event) => {
    const address = event.target.value;
    setNormalTokenAddress(address);
    
    if (address && Web3.utils.isAddress(address)) {
      await checkTokenInfo(address, setNormalTokenInfo);
    } else {
      setNormalTokenInfo(null);
    }
  };
// 創建exactInputSingle函數調用數據
  const createExactInputSingleData = (
    web3,
    tokenIn,
    tokenOut,
    fee,
    recipient,
    amountIn,
    amountOutMinimum,
    sqrtPriceLimitX96
  ) => {
    const router = new web3.eth.Contract(UNISWAP_V3_ROUTER_ABI, UNISWAP_V3_ROUTER_ADDRESS);
    
    return router.methods.exactInputSingle([
      tokenIn,
      tokenOut,
      fee,
      recipient,
      amountIn,
      amountOutMinimum,
      sqrtPriceLimitX96
    ]).encodeABI();
  };
  
  // 授權代幣
  const approveToken = async (web3, tokenAddress, spenderAddress, amount, userAddress) => {
    try {
      const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
      
      // 檢查現有授權
      const allowance = await tokenContract.methods.allowance(userAddress, spenderAddress).call();
      
      if (BigInt(allowance) < BigInt(amount)) {
        console.log('Approving token...');
        // 需要授權
        let approveGasPrice = web3.utils.toWei(gasPrice, 'gwei');
        
        // 使用0區塊狙擊時使用開發者交易的gas price
        if (useZeroBlockSnipe && devTxGasPrice) {
          approveGasPrice = devTxGasPrice;
        }
        
        const tx = await tokenContract.methods.approve(spenderAddress, amount).send({
          from: userAddress,
          gasPrice: approveGasPrice,
          gas: '300000'
        });
        
        console.log('Approval transaction:', tx);
        return tx;
      }
      
      // 已經有足夠的授權
      return true;
    } catch (error) {
      console.error('Approve token error:', error);
      throw error;
    }
  };
// 監聽開發者地址的交易
  const startSniping = async () => {
    if (!window._web3Instance) {
      setError('請先連接錢包');
      return;
    }

    if (!devAddress || !snipeTokenAddress || !inputAmount || !minOutputAmount) {
      setError('請填寫所有必填欄位');
      return;
    }

    if (!Web3.utils.isAddress(devAddress) || !Web3.utils.isAddress(snipeTokenAddress)) {
      setError('無效的地址格式');
      return;
    }

    // 確保0區塊狙擊模式下沒有指定gas price
    if (useZeroBlockSnipe && gasPrice !== '5') {
      setError('0區塊狙擊模式下，Gas Price將跟隨開發者交易，不能手動指定');
      return;
    }

    try {
      setIsWaiting(true);
      setError('');
      setSuccess('');
      
      const web3 = getWeb3();
      
      // 检查钱包余额
      const inputTokenObj = TOKENS[inputToken];
      const userAddress = window._userAddresses[0];
      
      let userBalance;
      if (inputTokenObj.isNative) {
        userBalance = await web3.eth.getBalance(userAddress);
      } else {
        const tokenContract = new web3.eth.Contract(ERC20_ABI, inputTokenObj.address);
        userBalance = await tokenContract.methods.balanceOf(userAddress).call();
      }
      
      const inputAmountInWei = toTokenAmount(web3, inputAmount, inputTokenObj.decimals);
      if (BigInt(userBalance) < BigInt(inputAmountInWei)) {
        setError(`餘額不足: 您的${inputToken}餘額不足`);
        setIsWaiting(false);
        return;
      }
      
      // 讀取代幣精度
      const targetDecimals = await getTokenDecimals(web3, snipeTokenAddress);
      
      // 準備狙擊交易
      const minOutput = toTokenAmount(web3, minOutputAmount, targetDecimals);
      
      // 創建狙擊交易
      const data = createExactInputSingleData(
        web3,
        inputTokenObj.address,
        snipeTokenAddress,
        2500, // fee tier 0.25%
        userAddress,
        inputAmountInWei,
        minOutput,
        0
      );
      
      // 初始交易參數（gas price 會在檢測到開發者交易時設置）
      const txParams = {
        from: userAddress,
        to: UNISWAP_V3_ROUTER_ADDRESS,
        value: inputTokenObj.isNative ? inputAmountInWei : '0',
        data: data,
        gas: gasLimit
      };
      
      // 如果不是0區塊狙擊，則使用用戶設置的 gas price
      if (!useZeroBlockSnipe) {
        txParams.gasPrice = web3.utils.toWei(gasPrice, 'gwei');
      }
      
      setDebugInfo({
        txInfo: {
          params: txParams,
          inputAmount: inputAmountInWei,
          minOutput,
          useAntiMev,
          useZeroBlockSnipe,
          timestamp: new Date().toISOString()
        }
      });
      
      // 如果不是原生代幣，需要先授權
      if (!inputTokenObj.isNative) {
        await approveToken(web3, inputTokenObj.address, UNISWAP_V3_ROUTER_ADDRESS, inputAmountInWei, userAddress);
      }
      
      console.log('Prepared transaction:', txParams);
      setSuccess('正在等待開發者發送交易...');
      
      // 提示用戶使用防夾子RPC可能會導致交易較慢
      if (useAntiMev) {
        console.log('Using Anti-MEV RPC, transaction might be slower');
      }
      
      // 提示用戶使用0區塊狙擊模式
      if (useZeroBlockSnipe) {
        console.log('Using Zero-Block Sniping, will match dev transaction gas price');
      }
      
      // 監控dev地址的交易
      const subscription = web3.eth.subscribe('pendingTransactions')
        .on('data', async (txHash) => {
          try {
            // 獲取交易詳情
            const tx = await web3.eth.getTransaction(txHash);
            
            if (tx && tx.from && tx.from.toLowerCase() === devAddress.toLowerCase()) {
              console.log('Found dev transaction:', txHash);
              setPendingTxHash(txHash);
              
              // 如果是0區塊狙擊模式，則使用開發者交易的gas price
              if (useZeroBlockSnipe) {
                console.log('Zero-Block Sniping: Using dev gas price:', tx.gasPrice);
                txParams.gasPrice = tx.gasPrice;
                setDevTxGasPrice(tx.gasPrice);
              }
              
              // 發送狙擊交易
              const receipt = await web3.eth.sendTransaction(txParams);
              
              console.log('Snipe transaction sent:', receipt);
              setSuccess(`狙擊成功！交易哈希: ${receipt.transactionHash}`);
              
              // 停止監聽
              subscription.unsubscribe();
              setIsWaiting(false);
            }
          } catch (error) {
            console.error('Process pending tx error:', error);
          }
        })
        .on('error', (error) => {
          console.error('Subscription error:', error);
          setError('監聽交易失敗: ' + error.message);
          setIsWaiting(false);
        });
      
      // 提供取消按鈕
      return () => {
        if (subscription) {
          subscription.unsubscribe();
          setIsWaiting(false);
        }
      };
    } catch (error) {
      console.error('Start sniping error:', error);
      setError('啟動狙擊失敗: ' + error.message);
      setIsWaiting(false);
    }
  };

  // 停止狙擊
  const stopSniping = () => {
    setIsWaiting(false);
    setSuccess('已停止狙擊');
  };
// 執行一般交易
  const executeNormalTrade = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError('請先連接錢包');
      return;
    }

    const web3 = getWeb3();
    const userAddress = window._userAddresses[0];
    
    // 驗證輸入
    if (tradeType === 'buy') {
      if (!normalInputAmount || !normalMinOutputAmount || !normalTokenAddress) {
        setError('請填寫所有必填欄位');
        return;
      }
      
      if (!Web3.utils.isAddress(normalTokenAddress)) {
        setError('無效的代幣地址');
        return;
      }
    } else { // sell
      if (!normalInputAmount || !normalMinOutputAmount || !normalTokenAddress) {
        setError('請填寫所有必填欄位');
        return;
      }
      
      if (!Web3.utils.isAddress(normalTokenAddress)) {
        setError('無效的代幣地址');
        return;
      }
    }

    try {
      setLoading(true);
      setError('');
      setSuccess('');
      
      let tokenIn, tokenOut, tokenInDecimals, tokenOutDecimals;
      let amountIn, amountOutMin;
      
      if (tradeType === 'buy') {
        // 買入操作
        tokenIn = TOKENS[normalInputToken].address;
        tokenInDecimals = TOKENS[normalInputToken].decimals;
        
        tokenOut = normalTokenAddress;
        if (!normalTokenInfo) {
          const info = await checkTokenInfo(normalTokenAddress, setNormalTokenInfo);
          tokenOutDecimals = info?.decimals || 18;
        } else {
          tokenOutDecimals = normalTokenInfo.decimals;
        }
        
        amountIn = toTokenAmount(web3, normalInputAmount, tokenInDecimals);
        amountOutMin = toTokenAmount(web3, normalMinOutputAmount, tokenOutDecimals);
      } else {
        // 賣出操作
        tokenIn = normalTokenAddress;
        if (!normalTokenInfo) {
          const info = await checkTokenInfo(normalTokenAddress, setNormalTokenInfo);
          tokenInDecimals = info?.decimals || 18;
        } else {
          tokenInDecimals = normalTokenInfo.decimals;
        }
        
        tokenOut = TOKENS[normalInputToken].address;
        tokenOutDecimals = TOKENS[normalInputToken].decimals;
        
        amountIn = toTokenAmount(web3, normalInputAmount, tokenInDecimals);
        amountOutMin = toTokenAmount(web3, normalMinOutputAmount, tokenOutDecimals);
      }
      
      // 檢查餘額
      let userBalance;
      if (tradeType === 'buy' && TOKENS[normalInputToken].isNative) {
        userBalance = await web3.eth.getBalance(userAddress);
      } else {
        const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenIn);
        userBalance = await tokenContract.methods.balanceOf(userAddress).call();
      }
      
      if (BigInt(userBalance) < BigInt(amountIn)) {
        setError(`餘額不足: 您的${tradeType === 'buy' ? normalInputToken : normalTokenInfo?.symbol || '代幣'}餘額不足`);
        setLoading(false);
        return;
      }
      
      // 如果不是原生代幣，需要先授權
      if (!(tradeType === 'buy' && TOKENS[normalInputToken].isNative)) {
        await approveToken(web3, tokenIn, UNISWAP_V3_ROUTER_ADDRESS, amountIn, userAddress);
      }
      
      // 創建交易數據
      const data = createExactInputSingleData(
        web3,
        tokenIn,
        tokenOut,
        2500, // fee tier 0.25%
        userAddress,
        amountIn,
        amountOutMin,
        0
      );
      
      // 準備交易參數
      const txParams = {
        from: userAddress,
        to: UNISWAP_V3_ROUTER_ADDRESS,
        value: (tradeType === 'buy' && TOKENS[normalInputToken].isNative) ? amountIn : '0',
        data: data,
        gasPrice: web3.utils.toWei(gasPrice, 'gwei'),
        gas: gasLimit
      };
      
      setDebugInfo({
        txInfo: {
          params: txParams,
          inputAmount: amountIn,
          minOutput: amountOutMin,
          useAntiMev,
          timestamp: new Date().toISOString()
        }
      });
      
      console.log('Sending transaction:', txParams);
      
      // 提示用戶使用防夾子RPC可能會導致交易較慢
      if (useAntiMev) {
        console.log('Using Anti-MEV RPC, transaction might be slower');
      }
      
      // 發送交易
      const receipt = await web3.eth.sendTransaction(txParams);
      
      console.log('Transaction receipt:', receipt);
      setSuccess(`交易成功！交易哈希: ${receipt.transactionHash}`);
    } catch (error) {
      console.error('Execute normal trade error:', error);
      setError('執行交易失敗: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  // 新增：執行單筆燃燒購買交易
  const executeSingleSpamBuy = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError('請先連接錢包');
      return false;
    }

    try {
      const web3 = getWeb3();
      const userAddress = window._userAddresses[0];
      
      // 買入操作參數準備
      const tokenIn = TOKENS[normalInputToken].address;
      const tokenInDecimals = TOKENS[normalInputToken].decimals;
      
      const tokenOut = normalTokenAddress;
      let tokenOutDecimals;
      
      if (!normalTokenInfo) {
        const info = await checkTokenInfo(normalTokenAddress, setNormalTokenInfo);
        tokenOutDecimals = info?.decimals || 18;
      } else {
        tokenOutDecimals = normalTokenInfo.decimals;
      }
      
      const amountIn = toTokenAmount(web3, normalInputAmount, tokenInDecimals);
      const amountOutMin = toTokenAmount(web3, normalMinOutputAmount, tokenOutDecimals);
      
      // 檢查餘額
      let userBalance;
      if (TOKENS[normalInputToken].isNative) {
        userBalance = await web3.eth.getBalance(userAddress);
      } else {
        const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenIn);
        userBalance = await tokenContract.methods.balanceOf(userAddress).call();
      }
      
      if (BigInt(userBalance) < BigInt(amountIn)) {
        setError(`餘額不足: 您的${normalInputToken}餘額不足`);
        return false;
      }
      
      // 如果不是原生代幣，需要先授權
      if (!TOKENS[normalInputToken].isNative) {
        await approveToken(web3, tokenIn, UNISWAP_V3_ROUTER_ADDRESS, amountIn, userAddress);
      }
      
      // 創建交易數據
      const data = createExactInputSingleData(
        web3,
        tokenIn,
        tokenOut,
        2500, // fee tier 0.25%
        userAddress,
        amountIn,
        amountOutMin,
        0
      );
      
      // 準備交易參數
      const txParams = {
        from: userAddress,
        to: UNISWAP_V3_ROUTER_ADDRESS,
        value: TOKENS[normalInputToken].isNative ? amountIn : '0',
        data: data,
        gasPrice: web3.utils.toWei(gasPrice, 'gwei'),
        gas: gasLimit
      };
      
      console.log('Sending spam buy transaction:', txParams);
      
      // 發送交易
      const receipt = await web3.eth.sendTransaction(txParams);
      
      console.log('Spam buy transaction receipt:', receipt);
      
      // 更新計數器
      setSpamCounter(prev => prev + 1);
      
      // 更新成功信息
      setSuccess(`燃燒購買第 ${spamCounter + 1} 筆交易成功！交易哈希: ${receipt.transactionHash}`);
      
      return true;
    } catch (error) {
      console.error('Execute spam buy error:', error);
      setError('執行燃燒購買失敗: ' + error.message);
      return false;
    }
  };
  
  // 新增：開始燃燒購買
  const startSpamBuying = async () => {
    if (!window._web3Instance) {
      setError('請先連接錢包');
      return;
    }

    // 確保所有必要的字段都已填寫
    if (!normalInputAmount || !normalMinOutputAmount || !normalTokenAddress) {
      setError('請填寫所有必填欄位');
      return;
    }
    
    if (!Web3.utils.isAddress(normalTokenAddress)) {
      setError('無效的代幣地址');
      return;
    }
    
    // 確保間隔時間有效
    if (!spamInterval || parseInt(spamInterval) < 100) {
      setError('燃燒間隔必須大於100毫秒');
      return;
    }

    try {
      setError('');
      setSuccess('');
      setIsSpamming(true);
      setSpamCounter(0);
      
      // 立即執行第一次購買
      const firstBuySuccess = await executeSingleSpamBuy();
      if (!firstBuySuccess) {
        setIsSpamming(false);
        return;
      }
      
      // 設置定時器，定期執行購買
      const intervalId = setInterval(async () => {
        if (spamCount && spamCounter >= parseInt(spamCount) - 1) {
          // 已達到指定次數，停止
          clearInterval(intervalId);
          setSuccess(`燃燒購買完成，共執行 ${spamCounter + 1} 筆交易`);
          setIsSpamming(false);
          setSpamIntervalId(null);
          return;
        }
        
        const success = await executeSingleSpamBuy();
        if (!success) {
          // 如果失敗，停止燃燒購買
          clearInterval(intervalId);
          setIsSpamming(false);
          setSpamIntervalId(null);
        }
      }, parseInt(spamInterval));
      
      setSpamIntervalId(intervalId);
    } catch (error) {
      console.error('Start spam buying error:', error);
      setError('啟動燃燒購買失敗: ' + error.message);
      setIsSpamming(false);
    }
  };
  
  // 新增：停止燃燒購買
  const stopSpamBuying = () => {
    if (spamIntervalId) {
      clearInterval(spamIntervalId);
      setSpamIntervalId(null);
    }
    
    setIsSpamming(false);
    setSuccess(`已停止燃燒購買，共執行 ${spamCounter} 筆交易`);
  };
// Tab切換
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // 渲染防MEV設置
  const renderAntiMevSettings = () => (
    <Box sx={{ mb: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={useAntiMev}
            onChange={(e) => setUseAntiMev(e.target.checked)}
          />
        }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            啟用防夾子模式
            <Tooltip title="使用專門的防MEV節點來保護您的交易不被夾子，但可能會導致交易較慢" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      {useAntiMev && (
        <Typography variant="caption" color="text.secondary" sx={{ ml: 4 }}>
          使用BlockSec防夾子RPC: https://bsc.rpc.blocksec.com (可能會導致交易較慢)
        </Typography>
      )}
    </Box>
  );

  // 渲染狙擊模式
  const renderSnipeMode = () => (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <ShieldIcon sx={{ mr: 1 }} />
          狙擊模式
          <Tooltip title="狙擊特定開發者的合約發布交易" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>

        {renderAntiMevSettings()}
        
        {/* 0區塊狙擊選項 */}
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={useZeroBlockSnipe}
                onChange={handleZeroBlockSnipeChange}
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                啟用0區塊狙擊
                <Tooltip title="使用與開發者相同的Gas Price以確保在同一區塊上鏈" placement="right">
                  <IconButton size="small">
                    <BoltIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          />
          {useZeroBlockSnipe && (
            <Typography variant="caption" color="text.secondary" sx={{ ml: 4 }}>
              將使用與開發者相同的Gas Price，Gas Limit保持設定值
            </Typography>
          )}
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="開發者地址 (Dev Address)"
              value={devAddress}
              onChange={(e) => setDevAddress(e.target.value)}
              placeholder="輸入要狙擊的開發者錢包地址"
              disabled={isWaiting}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="狙擊代幣地址 (Target Token)"
              value={snipeTokenAddress}
              onChange={handleSnipeTokenAddressChange}
              placeholder="輸入要狙擊的代幣合約地址"
              disabled={isWaiting}
              sx={{ mb: 1 }}
            />
            {targetTokenInfo && (
              <Typography variant="body2" color="primary" sx={{ mb: 2 }}>
                已識別：{targetTokenInfo.symbol} ({targetTokenInfo.name}) - 精度：{targetTokenInfo.decimals}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>輸入代幣</InputLabel>
              <Select
                value={inputToken}
                onChange={(e) => setInputToken(e.target.value)}
                label="輸入代幣"
                disabled={isWaiting}
              >
                <MenuItem value="BNB">BNB</MenuItem>
                <MenuItem value="USDT">USDT</MenuItem>
                <MenuItem value="USDC">USDC</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={`輸入數量 (${inputToken})`}
              type="number"
              value={inputAmount}
              onChange={(e) => setInputAmount(e.target.value)}
              placeholder="輸入要使用的代幣數量"
              disabled={isWaiting}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="最小輸出數量"
              type="number"
              value={minOutputAmount}
              onChange={(e) => setMinOutputAmount(e.target.value)}
              placeholder="輸入期望獲得的最小代幣數量"
              disabled={isWaiting}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Gas Price (Gwei)"
              type="number"
              value={gasPrice}
              onChange={(e) => setGasPrice(e.target.value)}
              disabled={isWaiting || useZeroBlockSnipe}
              sx={{ mb: 2 }}
              helperText={useZeroBlockSnipe ? "0區塊狙擊模式下，將使用開發者相同的Gas Price" : ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Gas Limit"
              type="number"
              value={gasLimit}
              onChange={(e) => setGasLimit(e.target.value)}
              disabled={isWaiting}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              {!isWaiting ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={startSniping}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : '開始狙擊'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={stopSniping}
                  fullWidth
                >
                  停止狙擊
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        {isWaiting && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              正在等待開發者發布交易...
            </Typography>
            <LinearProgress sx={{ mt: 1, mb: 2 }} />
            {pendingTxHash && (
              <Alert severity="info">
                檢測到開發者交易：{pendingTxHash.substring(0, 10)}...{pendingTxHash.substring(pendingTxHash.length - 10)}
                {useZeroBlockSnipe && devTxGasPrice && (
                  <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                    開發者Gas Price: {web3.utils.fromWei(devTxGasPrice, 'gwei')} Gwei
                  </Typography>
                )}
              </Alert>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
// 渲染一般交易模式
  const renderNormalMode = () => (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <AttachMoneyIcon sx={{ mr: 1 }} />
          一般交易模式
          <Tooltip title="執行一般的代幣買入或賣出交易" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>

        {renderAntiMevSettings()}

        <RadioGroup
          value={tradeType}
          onChange={handleTradeTypeChange}
          sx={{ mb: 2 }}
        >
          <FormControlLabel value="buy" control={<Radio />} label="買入" />
          <FormControlLabel value="sell" control={<Radio />} label="賣出" />
        </RadioGroup>
        
        {/* 新增：燃燒購買選項 */}
        {tradeType === 'buy' && (
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={useSpamBuy}
                  onChange={handleSpamBuyChange}
                  disabled={loading || isSpamming}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  啟用燃燒購買
                  <Tooltip title="連續發送多筆交易購買同一代幣，以確保能成功買入" placement="right">
                    <IconButton size="small">
                      <LocalFireDepartmentIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          </Box>
        )}
        
        {/* 新增：燃燒購買設定 */}
        {tradeType === 'buy' && useSpamBuy && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="燃燒間隔 (毫秒)"
                type="number"
                value={spamInterval}
                onChange={(e) => setSpamInterval(e.target.value)}
                placeholder="輸入交易間隔時間（毫秒）"
                disabled={loading || isSpamming}
                helperText="每次發送交易的間隔時間（建議≥1000ms）"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="燃燒次數 (可選)"
                type="number"
                value={spamCount}
                onChange={(e) => setSpamCount(e.target.value)}
                placeholder="不填則持續購買直到手動停止"
                disabled={loading || isSpamming}
                helperText="填入總共需執行的交易次數，不填則無限執行"
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={3}>
          {tradeType === 'buy' ? (
            // 買入表單
            <>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>輸入代幣</InputLabel>
                  <Select
                    value={normalInputToken}
                    onChange={(e) => setNormalInputToken(e.target.value)}
                    label="輸入代幣"
                    disabled={loading || isSpamming}
                  >
                    <MenuItem value="BNB">BNB</MenuItem>
                    <MenuItem value="USDT">USDT</MenuItem>
                    <MenuItem value="USDC">USDC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={`輸入數量 (${normalInputToken})`}
                  type="number"
                  value={normalInputAmount}
                  onChange={(e) => setNormalInputAmount(e.target.value)}
                  placeholder="輸入要使用的代幣數量"
                  disabled={loading || isSpamming}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="目標代幣地址"
                  value={normalTokenAddress}
                  onChange={handleNormalTokenAddressChange}
                  placeholder="輸入要購買的代幣合約地址"
                  disabled={loading || isSpamming}
                  sx={{ mb: 1 }}
                />
                {normalTokenInfo && (
                  <Typography variant="body2" color="primary" sx={{ mb: 2 }}>
                    已識別：{normalTokenInfo.symbol} ({normalTokenInfo.name}) - 精度：{normalTokenInfo.decimals}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="最小獲得數量"
                  type="number"
                  value={normalMinOutputAmount}
                  onChange={(e) => setNormalMinOutputAmount(e.target.value)}
                  placeholder="輸入期望獲得的最小代幣數量"
                  disabled={loading || isSpamming}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </>
          ) : (
            // 賣出表單
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="輸入代幣地址"
                  value={normalTokenAddress}
                  onChange={handleNormalTokenAddressChange}
                  placeholder="輸入要賣出的代幣合約地址"
                  disabled={loading || isSpamming}
                  sx={{ mb: 1 }}
                />
                {normalTokenInfo && (
                  <Typography variant="body2" color="primary" sx={{ mb: 2 }}>
                    已識別：{normalTokenInfo.symbol} ({normalTokenInfo.name}) - 精度：{normalTokenInfo.decimals}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="賣出數量"
                  type="number"
                  value={normalInputAmount}
                  onChange={(e) => setNormalInputAmount(e.target.value)}
                  placeholder="輸入要賣出的代幣數量"
                  disabled={loading || isSpamming}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>輸出代幣</InputLabel>
                  <Select
                    value={normalInputToken}
                    onChange={(e) => setNormalInputToken(e.target.value)}
                    label="輸出代幣"
                    disabled={loading || isSpamming}
                  >
                    <MenuItem value="BNB">BNB</MenuItem>
                    <MenuItem value="USDT">USDT</MenuItem>
                    <MenuItem value="USDC">USDC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`最小獲得數量 (${normalInputToken})`}
                  type="number"
                  value={normalMinOutputAmount}
                  onChange={(e) => setNormalMinOutputAmount(e.target.value)}
                  placeholder="輸入期望獲得的最小代幣數量"
                  disabled={loading || isSpamming}
                  sx={{ mb: 2 }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Gas Price (Gwei)"
              type="number"
              value={gasPrice}
              onChange={(e) => setGasPrice(e.target.value)}
              disabled={loading || isSpamming}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Gas Limit"
              type="number"
              value={gasLimit}
              onChange={(e) => setGasLimit(e.target.value)}
              disabled={loading || isSpamming}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            {tradeType === 'buy' && useSpamBuy ? (
              // 燃燒購買按鈕
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                {!isSpamming ? (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={startSpamBuying}
                    disabled={loading}
                    fullWidth
                    startIcon={<LocalFireDepartmentIcon />}
                  >
                    {loading ? <CircularProgress size={24} /> : '開始燃燒購買'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={stopSpamBuying}
                    fullWidth
                    startIcon={<LocalFireDepartmentIcon />}
                  >
                    停止燃燒購買 ({spamCounter}/{spamCount || '∞'})
                  </Button>
                )}
              </Box>
            ) : (
              // 一般交易按鈕
              <Button
                variant="contained"
                color="primary"
                onClick={executeNormalTrade}
                disabled={loading || isSpamming}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : tradeType === 'buy' ? '執行購買' : '執行賣出'}
              </Button>
            )}
          </Grid>
        </Grid>
        
        {/* 燃燒購買進度顯示 */}
        {tradeType === 'buy' && useSpamBuy && isSpamming && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              燃燒購買中...
              {spamCount ? ` (${spamCounter}/${spamCount})` : ` (${spamCounter}次)`}
            </Typography>
            <LinearProgress sx={{ mt: 1, mb: 2 }} />
            <Typography variant="caption" color="text.secondary">
              每 {spamInterval} 毫秒執行一次交易
              {spamCount ? `，共 ${spamCount} 次` : '，直到手動停止'}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
// 渲染主界面
  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            Four.Meme狙擊 (BSC)
          </Typography>
          
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ mb: 2 }}
          >
            <Tab label="狙擊模式" />
            <Tab label="一般交易" />
          </Tabs>
          
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
        </CardContent>
      </Card>
      
      {tabValue === 0 ? renderSnipeMode() : renderNormalMode()}
    </Box>
  );
};

module.exports = fourmeme;
