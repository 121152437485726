const React = require('react');
const {
    Box,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Alert,
    Grid,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    Link,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Collapse,
} = require('@mui/material');

const Web3 = require('web3');

// Constants
const VIRTUAL_TOKEN = '0x0b3e328455c4059EEb9e3f84b5543F74E24e7E1b';
const VIRTUAL_ROUTER = '0xF66DeA7b3e897cD44A5a231c61B6B4423d613259';
const LAUNCH_METHOD_ID = '0x3c0b93aa';
const VIRTUAL_BUY_METHOD_ID = '0x7deb6025';
const VIRTUAL_SELL_METHOD_ID = '0x4189a68e';
const BATCH_SIZE = 5;
const TX_BATCH_SIZE = 10;
// Reduced CHECK_INTERVAL and PROCESS_INTERVAL for faster responsiveness - 可以進一步調整
const CHECK_INTERVAL = 200;
const PROCESS_INTERVAL = 100;
const LOG_CLEANUP_INTERVAL = 30000;
const MAX_RETRIES = 3;
const RETRY_DELAY = 500;

// Storage Keys
const PROCESSED_TX_KEY = 'virtualPanel_processedTx';
const LAST_BLOCK_KEY = 'virtualPanel_lastBlock';

// Debug logging (remains the same)
const DEBUG = {
    maxLogs: 1000,
    logs: [],
    logCount: 0,

    log: (...args) => {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] DEBUG:`, ...args);
        DEBUG.addLog('DEBUG', args);
    },

    error: (...args) => {
        const timestamp = new Date().toISOString();
        console.error(`[${timestamp}] ❌ ERROR:`, ...args);
        DEBUG.addLog('ERROR', args);
    },

    info: (...args) => {
        const timestamp = new Date().toISOString();
        console.info(`[${timestamp}] ℹ️ INFO:`, ...args);
        DEBUG.addLog('INFO', args);
    },

    block: (blockNumber, message) => {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] 🔲 Block ${blockNumber}: ${message}`);
        DEBUG.addLog('BLOCK', `Block ${blockNumber}: ${message}`);
    },

    tx: (hash, message) => {
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}] 📝 TX ${hash.slice(0, 10)}...${hash.slice(-8)}: ${message}`);
        DEBUG.addLog('TX', `${hash.slice(0, 10)}...${hash.slice(-8)}: ${message}`);
    },

    addLog: (type, content) => {
        DEBUG.logs.push({
            id: ++DEBUG.logCount,
            type,
            content,
            timestamp: new Date()
        });

        if (DEBUG.logs.length > DEBUG.maxLogs) {
            DEBUG.logs = DEBUG.logs.slice(-Math.floor(DEBUG.maxLogs / 2));
        }
    },

    clear: () => {
        DEBUG.logs = [];
        DEBUG.logCount = 0;
        console.clear();
    }
};

// Utils (remains the same)
const utils = {
    sleep: (ms) => new Promise(resolve => setTimeout(resolve, ms)),

    cleanupMemory: () => {
        if (global.gc) {
            global.gc();
        }
    },

    saveProcessedTx: (txHash) => {
        try {
            const processed = JSON.parse(localStorage.getItem(PROCESSED_TX_KEY) || '[]');
            processed.push(txHash);
            if (processed.length > 1000) {
                processed.splice(0, processed.length - 1000);
            }
            localStorage.setItem(PROCESSED_TX_KEY, JSON.stringify(processed));
        } catch (error) {
            DEBUG.error('Error saving processed tx:', error);
        }
    },

    isProcessedTx: (txHash) => {
        try {
            const processed = JSON.parse(localStorage.getItem(PROCESSED_TX_KEY) || '[]');
            return processed.includes(txHash);
        } catch (error) {
            DEBUG.error('Error checking processed tx:', error);
            return false;
        }
    },

    saveLastBlock: (blockNumber) => {
        try {
            localStorage.setItem(LAST_BLOCK_KEY, blockNumber.toString());
        } catch (error) {
            DEBUG.error('Error saving last block:', error);
        }
    },

    getLastBlock: () => {
        try {
            return parseInt(localStorage.getItem(LAST_BLOCK_KEY) || '0');
        } catch (error) {
            DEBUG.error('Error getting last block:', error);
            return 0;
        }
    }
};

const VirtualPanel = () => {
    const [isMonitoring, setIsMonitoring] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [operationResults, setOperationResults] = React.useState([]);
    const [showResults, setShowResults] = React.useState(false);
    const [explorerUrl, setExplorerUrl] = React.useState('');

    const [tradeType, setTradeType] = React.useState('buy');
    const [fromAddress, setFromAddress] = React.useState('');
    const [targetToken, setTargetToken] = React.useState('');
    const [inputAmount, setInputAmount] = React.useState('');
    const [gasMultiplier, setGasMultiplier] = React.useState('1.5');
    const [gasLimit, setGasLimit] = React.useState('300000');

    const [monitoringStats, setMonitoringStats] = React.useState({
        totalScanned: 0,
        matchedTx: 0,
        successfulTx: 0,
        failedTx: 0,
        startTime: null,
        currentBlock: 0,
        latestProcessedBlock: 0,
        processingSpeed: 0,
        memoryUsage: 0,
        blocksBehind: 0
    });

    const web3Ref = React.useRef(null);
    const blockCheckIntervalRef = React.useRef(null); // 用於儲存 interval 或 subscription 清理函數
    const lastProcessedBlockRef = React.useRef(null);
    const processedTxRef = React.useRef(new Set());
    const statsIntervalRef = React.useRef(null);
    const pendingTradesRef = React.useRef(new Set());
    const isProcessingRef = React.useRef(false);

    // 統計更新 (remains the same)
    const updateStats = React.useCallback(() => {
        if (!monitoringStats.startTime) return;

        const now = Date.now();
        const duration = now - monitoringStats.startTime;
        const speed = monitoringStats.totalScanned / (duration / 1000);

        setMonitoringStats(prev => ({
            ...prev,
            processingSpeed: Math.round(speed),
            memoryUsage: performance.memory ?
                Math.round(performance.memory.usedJSHeapSize / 1024 / 1024) : 0
        }));

        utils.cleanupMemory();
    }, [monitoringStats.startTime, monitoringStats.totalScanned]);

    // 清理函數 (Modified to unsubscribe WebSocket)
    const cleanup = React.useCallback(() => {
        if (blockCheckIntervalRef.current) {
            blockCheckIntervalRef.current(); // 應該是調用 WebSocket unsubscribe 函數
            blockCheckIntervalRef.current = null;
        }
        if (statsIntervalRef.current) {
            clearInterval(statsIntervalRef.current);
            statsIntervalRef.current = null;
        }
        processedTxRef.current.clear();
        pendingTradesRef.current.clear();
        lastProcessedBlockRef.current = null;
        isProcessingRef.current = false;
        utils.cleanupMemory();
    }, []);

    React.useEffect(() => {
        return () => {
            DEBUG.info('Cleaning up component...');
            cleanup();
            DEBUG.info('Cleanup complete');
        };
    }, [cleanup]);

    React.useEffect(() => {
        if (isMonitoring) {
            statsIntervalRef.current = setInterval(() => {
                updateStats();
            }, LOG_CLEANUP_INTERVAL);
        }
        return () => {
            if (statsIntervalRef.current) {
                clearInterval(statsIntervalRef.current);
                statsIntervalRef.current = null;
            }
        };
    }, [isMonitoring, updateStats]);

    React.useEffect(() => {
        const checkConnection = async () => {
            if (!window._web3Instance) return;
            try {
                const network = await window._web3Instance.eth.net.getId();
                switch (network) {
                    case 1:
                        setExplorerUrl('https://etherscan.io');
                        break;
                    case 56:
                        setExplorerUrl('https://bscscan.com');
                        break;
                    case 8453:
                        setExplorerUrl('https://basescan.org');
                        break;
                    case 42161:
                        setExplorerUrl('https://arbiscan.io');
                        break;
                    default:
                        setExplorerUrl('');
                }
            } catch (error) {
                DEBUG.error('Network check error:', error);
            }
        };

        checkConnection();
    }, []);

    // 區塊監控邏輯 (Modified to use WebSocket Subscription)
    const startBlockMonitoring = React.useCallback(() => {
        if (!window._web3Instance) return;

        let blockSubscription; // 用於儲存 subscription 物件，以便 cleanup 時取消訂閱

        const subscribeNewBlocks = async () => {
            blockSubscription = await window._web3Instance.eth.subscribe('newHeads')
            .on("data", async (blockHeader) => {
                const blockNumber = blockHeader.number;
                DEBUG.info(`[WebSocket] 新區塊頭 ${blockNumber} 接收到`);
                setMonitoringStats(prev => ({
                    ...prev,
                    currentBlock: blockNumber,
                    blocksBehind: blockNumber - (lastProcessedBlockRef.current || blockNumber)
                }));
                if (!isMonitoring || isProcessingRef.current) return; // 再次檢查 isMonitoring and isProcessing
                try {
                    const currentBlock = blockNumber;
                    const nextBlock = (lastProcessedBlockRef.current || currentBlock - 1) + 1;

                    if (nextBlock <= currentBlock) {
                        isProcessingRef.current = true;
                        DEBUG.info(`處理區塊 ${nextBlock}, 當前最新: ${currentBlock}`);

                        try {
                            await handleNewBlock(nextBlock);
                        } catch (error) {
                            DEBUG.error(`處理區塊 ${nextBlock} 失敗:`, error);
                        } finally {
                            isProcessingRef.current = false;
                        }
                    }
                } catch (error) {
                    DEBUG.error('區塊處理錯誤 (WebSocket):', error);
                    isProcessingRef.current = false;
                }
            })
            .on("connected", subscriptionId => {
                DEBUG.info(`WebSocket 訂閱成功，ID: ${subscriptionId}`);
            })
            .on("error", error => {
                DEBUG.error('WebSocket 訂閱錯誤:', error);
                setError(`WebSocket 訂閱錯誤: ${error.message}`);
                setIsMonitoring(false); // 停止監控
            });
        };

        subscribeNewBlocks(); // 啟動 WebSocket 訂閱

        // 返回 cleanup 函數，用於在停止監控時取消訂閱
        return () => {
            if (blockSubscription) {
                DEBUG.info('取消 WebSocket 區塊頭訂閱');
                blockSubscription.unsubscribe(); // 取消訂閱
            }
        };
    }, [isMonitoring]); // 依賴 isMonitoring 以控制啟動和停止

    // 處理新區塊 (Optimized Log Filtering in processLaunchTransaction) (remains the same)
    const handleNewBlock = async (blockNumber) => {
        try {
            if (!window._web3Instance || !isMonitoring) return;

            DEBUG.block(blockNumber, '開始處理區塊');

            const block = await window._web3Instance.eth.getBlock(blockNumber, true);
            if (!block || !block.transactions) {
                DEBUG.block(blockNumber, '區塊無效或無交易');
                lastProcessedBlockRef.current = blockNumber;
                return;
            }

            const launchTxs = block.transactions.filter(tx =>
                typeof tx === 'object' &&
                tx.to?.toLowerCase() === VIRTUAL_ROUTER.toLowerCase() &&
                tx.input.startsWith(LAUNCH_METHOD_ID)
            );

            if (launchTxs.length > 0) {
                DEBUG.block(blockNumber, `發現 ${launchTxs.length} 筆 Launch 交易`);
                for (const tx of launchTxs) {
                    try {
                        await processLaunchTransaction(tx, blockNumber);
                    } catch (error) {
                        DEBUG.error(`處理交易 ${tx.hash} 失敗:`, error);
                    }
                }
            }

            lastProcessedBlockRef.current = blockNumber;
            setMonitoringStats(prev => ({
                ...prev,
                latestProcessedBlock: blockNumber,
                totalScanned: prev.totalScanned + block.transactions.length
            }));

        } catch (error) {
            DEBUG.error(`處理區塊 ${blockNumber} 錯誤:`, error);
            throw error;
        }
    };

    // Launch 交易處理 (Optimized Log Filtering using filter) (remains the same)
    const processLaunchTransaction = async (tx, blockNumber) => {
        try {
            if (processedTxRef.current.has(tx.hash)) return;
            if (fromAddress && tx.from.toLowerCase() !== fromAddress.toLowerCase()) return;

            const receipt = await window._web3Instance.eth.getTransactionReceipt(tx.hash);
            if (!receipt || !receipt.logs) return;

            // Optimized log filtering using .filter() and then taking the first element
            const ownershipLogs = receipt.logs.filter(log =>
                log.topics[0] === '0x8be0079c531659141344cd1fd0e4f28419497f9722a3daafe3b41866f6b6457e'
            );
            const ownershipLog = ownershipLogs[0]; // Take the first if found

            if (!ownershipLog) return;
            const tokenAddress = ownershipLog.address;

            // 立即執行交易 (remains the same)
            if (inputAmount && !pendingTradesRef.current.has(tokenAddress)) {
                pendingTradesRef.current.add(tokenAddress);
                try {
                    const amount = window._web3Instance.utils.toWei(inputAmount, 'ether');
                    await executeTrade(amount, tokenAddress);
                } catch (error) {
                    DEBUG.error('交易執行失敗:', error);
                    setError('自動交易失敗: ' + error.message);
                } finally {
                    pendingTradesRef.current.delete(tokenAddress);
                }
            }

            setTargetToken(tokenAddress);
            setMonitoringStats(prev => ({
                ...prev,
                matchedTx: prev.matchedTx + 1
            }));

            processedTxRef.current.add(tx.hash);
            utils.saveProcessedTx(tx.hash);

        } catch (error) {
            DEBUG.error(`處理交易 ${tx.hash} 錯誤:`, error);
        }
    };

    // 交易執行 (remains the same)
    const executeTrade = async (amount, tokenAddress = null) => {
        if (!window._web3Instance || !window._userAddresses?.length) {
            throw new Error('請先連接Web3並選擇錢包');
        }

        const targetTokenAddress = tokenAddress || targetToken;
        if (!targetTokenAddress) {
            throw new Error('尚未檢測到目標代幣');
        }

        const totalWallets = window._userAddresses.length;
        const batchSize = Math.min(TX_BATCH_SIZE, 5);

        for (let i = 0; i < totalWallets; i += batchSize) {
            const walletBatch = window._userAddresses.slice(i, i + batchSize);

            const results = await Promise.allSettled(
                walletBatch.map(userAddress => (
                    tradeType === 'buy' ?
                        executeBuy(userAddress, amount, targetTokenAddress) :
                        executeSell(userAddress, amount, targetTokenAddress)
                ))
            );

            const processedResults = results.map(result => {
                if (result.status === 'fulfilled') {
                    setMonitoringStats(prev => ({
                        ...prev,
                        successfulTx: prev.successfulTx + 1
                    }));
                    return {
                        success: true,
                        ...result.value
                    };
                } else {
                    setMonitoringStats(prev => ({
                        ...prev,
                        failedTx: prev.failedTx + 1
                    }));
                    return {
                        success: false,
                        error: result.reason.message
                    };
                }
            });

            setOperationResults(prev => [...prev, ...processedResults]);

            if (i + batchSize < totalWallets) {
                await utils.sleep(100);
                utils.cleanupMemory();
            }
        }

        setShowResults(true);
    };


    // 執行買入和賣出 (remains the same)
    const executeBuy = async (userAddress, amount, targetTokenAddress) => {
        try {
            const web3 = window._web3Instance;
            const functionData = web3.eth.abi.encodeFunctionCall({
                name: 'buy',
                type: 'function',
                inputs: [
                    { type: 'uint256', name: 'amountIn' },
                    { type: 'address', name: 'tokenAddress' }
                ]
            }, [amount, targetTokenAddress]);

            // 並行獲取 Gas 參數 (remains the same)
            const [currentGasPrice, estimatedGas] = await Promise.all([
                web3.eth.getGasPrice(),
                web3.eth.estimateGas({
                    from: userAddress,
                    to: VIRTUAL_ROUTER,
                    data: functionData
                }).catch(() => gasLimit)
            ]);

            const targetGasPrice = Math.floor(
                parseInt(currentGasPrice) * parseFloat(gasMultiplier)
            ).toString();

            const tx = await web3.eth.sendTransaction({
                from: userAddress,
                to: VIRTUAL_ROUTER,
                data: functionData,
                gas: Math.min(parseInt(estimatedGas * 1.2), parseInt(gasLimit)),
                gasPrice: targetGasPrice
            });

            return {
                address: userAddress,
                hash: tx.transactionHash,
                blockNumber: tx.blockNumber,
                gasUsed: tx.gasUsed
            };

        } catch (error) {
            DEBUG.error('買入交易執行錯誤:', error);
            throw error;
        }
    };

    const executeSell = async (userAddress, amount, targetTokenAddress) => {
        try {
            const web3 = window._web3Instance;
            const functionData = web3.eth.abi.encodeFunctionCall({
                name: 'sell',
                type: 'function',
                inputs: [
                    { type: 'uint256', name: 'amountIn' },
                    { type: 'address', name: 'tokenAddress' }
                ]
            }, [amount, targetTokenAddress]);

            const [currentGasPrice, estimatedGas] = await Promise.all([
                web3.eth.getGasPrice(),
                web3.eth.estimateGas({
                    from: userAddress,
                    to: VIRTUAL_ROUTER,
                    data: functionData
                }).catch(() => gasLimit)
            ]);

            const targetGasPrice = Math.floor(
                parseInt(currentGasPrice) * parseFloat(gasMultiplier)
            ).toString();

            const tx = await web3.eth.sendTransaction({
                from: userAddress,
                to: VIRTUAL_ROUTER,
                data: functionData,
                gas: Math.min(parseInt(estimatedGas * 1.2), parseInt(gasLimit)),
                gasPrice: targetGasPrice
            });

            return {
                address: userAddress,
                hash: tx.transactionHash,
                blockNumber: tx.blockNumber,
                gasUsed: tx.gasUsed
            };

        } catch (error) {
            DEBUG.error('賣出交易執行錯誤:', error);
            throw error;
        }
    };

    // 開始監控 (Modified to start WebSocket monitoring)
    const startMonitoring = async () => {
        try {
            DEBUG.info('==================== 開始監控 (WebSocket) ====================');

            if (!window._web3Instance) throw new Error('請先連接Web3');
            if (!window._userAddresses?.length) throw new Error('請先選擇要操作的錢包');
            if (!inputAmount) throw new Error('請輸入交易數量');

            setLoading(true);
            cleanup();

            const currentBlock = await window._web3Instance.eth.getBlockNumber();
            lastProcessedBlockRef.current = currentBlock;
            web3Ref.current = window._web3Instance;

            blockCheckIntervalRef.current = startBlockMonitoring(); // 啟動 WebSocket 監控

            setMonitoringStats({
                totalScanned: 0,
                matchedTx: 0,
                successfulTx: 0,
                failedTx: 0,
                startTime: Date.now(),
                currentBlock,
                latestProcessedBlock: currentBlock,
                processingSpeed: 0,
                memoryUsage: 0,
                blocksBehind: 0
            });

            setIsMonitoring(true);
            setError('');
            setLoading(false);

        } catch (error) {
            DEBUG.error('啟動監控錯誤:', error);
            setError(error.message);
            setLoading(false);
        }
    };

    // 停止監控 (remains the same)
    const stopMonitoring = () => {
        cleanup();

        const duration = monitoringStats.startTime ?
            Math.floor((Date.now() - monitoringStats.startTime) / 1000) : 0;

        DEBUG.info('最終統計:');
        DEBUG.info(`總運行時間: ${Math.floor(duration / 3600)}時 ${Math.floor((duration % 3600) / 60)}分 ${duration % 60}秒`);
        DEBUG.info(`總掃描交易: ${monitoringStats.totalScanned}`);
        DEBUG.info(`匹配交易數: ${monitoringStats.matchedTx}`);
        DEBUG.info(`成功交易數: ${monitoringStats.successfulTx}`);
        DEBUG.info(`失敗交易數: ${monitoringStats.failedTx}`);

        setIsMonitoring(false);
    };

    // 監控統計渲染 (remains the same)
    const renderMonitoringStats = React.useMemo(() => {
        if (!isMonitoring && !monitoringStats.startTime) return null;

        const duration = monitoringStats.startTime ?
            Math.floor((Date.now() - monitoringStats.startTime) / 1000) : 0;

        const blocksBehind = monitoringStats.currentBlock - monitoringStats.latestProcessedBlock;

        return (
            <Card sx={{ mb: 2, bgcolor: 'primary.dark' }}>
                <CardContent>
                    <Typography variant="h6" color="common.white" gutterBottom>
                        監控統計
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <Typography color="common.white">
                                掃描交易: {monitoringStats.totalScanned.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography color="common.white">
                                匹配交易: {monitoringStats.matchedTx}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography color="common.white">
                                成功執行: {monitoringStats.successfulTx}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography color="common.white">
                                失敗執行: {monitoringStats.failedTx}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography color="common.white">
                                當前區塊: {monitoringStats.currentBlock.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="common.white">
                                處理區塊: {monitoringStats.latestProcessedBlock.toLocaleString()}
                                {blocksBehind > 0 &&
                                    <span style={{ color: blocksBehind > 5 ? '#ff6b6b' : '#ffd93d', marginLeft: '8px' }}>
                                        {`(落後 ${blocksBehind} 個區塊)`}
                                    </span>
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography color="common.white">
                                處理速度: {monitoringStats.processingSpeed.toLocaleString()} tx/s
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography color="common.white">
                                運行時間: {Math.floor(duration / 3600)}時 {Math.floor((duration % 3600) / 60)}分 {duration % 60}秒
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography color="common.white">
                                記憶體使用: {monitoringStats.memoryUsage}MB
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }, [isMonitoring, monitoringStats]);

    // 結果顯示渲染 (remains the same)
    const renderResults = React.useMemo(() => (
        <Collapse in={showResults}>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        操作結果
                        <Button size="small" onClick={() => setShowResults(false)} sx={{ ml: 2 }}>
                            關閉
                        </Button>
                    </Typography>
                    <List>
                        {operationResults.map((result, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`錢包: ${result.address.slice(0, 6)}...${result.address.slice(-4)}`}
                                    secondary={
                                        result.success ? (
                                            <>
                                                <Link
                                                    href={`${explorerUrl}/tx/${result.hash}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    查看交易: {result.hash.slice(0, 10)}...
                                                </Link>
                                                <br />
                                                {`區塊: ${result.blockNumber}, Gas Used: ${result.gasUsed}`}
                                            </>
                                        ) : (
                                            `失敗: ${result.error}`
                                        )
                                    }
                                    sx={{ color: result.success ? 'success.main' : 'error.main' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Collapse>
    ), [showResults, operationResults, explorerUrl]);

    // 主要渲染 (remains the same)
    return (
        <Box>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>交易類型</InputLabel>
                                <Select
                                    value={tradeType}
                                    onChange={(e) => setTradeType(e.target.value)}
                                    label="交易類型"
                                >
                                    <MenuItem value="buy">買入</MenuItem>
                                    <MenuItem value="sell">賣出</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        監控設置
                        <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                            (監控所有 Launch 方法調用)
                        </Typography>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="From地址 (合約開發者地址，選填)"
                                value={fromAddress}
                                onChange={(e) => setFromAddress(e.target.value)}
                                helperText="如果不填寫，將監控所有地址的 Launch 交易"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="目標代幣"
                                value={targetToken}
                                disabled
                                helperText="將在檢測到Launch後自動設置"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="數量"
                                type="number"
                                value={inputAmount}
                                onChange={(e) => setInputAmount(e.target.value)}
                                helperText={`${tradeType === 'buy' ? 'VIRTUAL' : '目標代幣'}的數量`}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Gas Price倍數"
                                type="number"
                                value={gasMultiplier}
                                onChange={(e) => setGasMultiplier(e.target.value)}
                                InputProps={{
                                    inputProps: { min: 1.1, max: 10, step: 0.1 }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Gas Limit"
                                type="number"
                                value={gasLimit}
                                onChange={(e) => setGasLimit(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {renderMonitoringStats}

            <Button
                variant="contained"
                fullWidth
                onClick={isMonitoring ? stopMonitoring : startMonitoring}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} /> :
                    isMonitoring ? "停止監控" : "開始監控"}
            </Button>

            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}

            {renderResults}

            {isMonitoring && <LinearProgress sx={{ mt: 2 }} />}
        </Box>
    );
};

// 導出組件 (remains the same)
module.exports = VirtualPanel;

