const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  Snackbar
} = require('@mui/material');
const Web3 = require('web3');
const {
  CONSTANTS,
  NETWORK_CONFIG,
  ABIS,
  utils
} = require('./evm');

const ContentCopyIcon = require('@mui/icons-material/ContentCopy').default;
const DeleteIcon = require('@mui/icons-material/Delete').default;

const WalletPanel = () => {
  const [privateKeys, setPrivateKeys] = React.useState('');
  const [wallets, setWallets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [balances, setBalances] = React.useState({});
  const [tokenType, setTokenType] = React.useState('NATIVE');
  const [customTokenAddress, setCustomTokenAddress] = React.useState('');
  const [selectedWallets, setSelectedWallets] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // 驗證私鑰格式
  const validatePrivateKey = (key) => {
    try {
      const cleanKey = key.startsWith('0x') ? key : `0x${key}`;
      return /^0x[0-9a-fA-F]{64}$/.test(cleanKey);
    } catch {
      return false;
    }
  };

  // 更新全局錢包狀態
  const updateGlobalWallets = (walletList) => {
    if (window._web3Instance) {
      window._web3Instance.eth.accounts.wallet.clear();
      walletList.forEach(wallet => {
        const account = window._web3Instance.eth.accounts.privateKeyToAccount(wallet.privateKey);
        window._web3Instance.eth.accounts.wallet.add(account);
      });
    }

    window._userAddresses = walletList.map(w => w.address);
    window._userAddress = walletList[0]?.address;

    localStorage.setItem('wallets', JSON.stringify(walletList));
  };

  // 關閉提示
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  // 導入錢包
  const importWallets = () => {
    setError('');
    try {
      const keys = privateKeys.trim().split('\n')
        .map(key => key.trim())
        .filter(key => key);

      const totalKeys = keys.length;
      const invalidKeys = [];
      const web3 = new Web3();
      const newWallets = [];

      keys.forEach((key, index) => {
        try {
          if (!validatePrivateKey(key)) {
            invalidKeys.push(index + 1);
            return;
          }

          const cleanKey = key.startsWith('0x') ? key : `0x${key}`;
          const account = web3.eth.accounts.privateKeyToAccount(cleanKey);
          
          if (!wallets.some(w => w.address === account.address)) {
            newWallets.push({
              address: account.address,
              privateKey: cleanKey
            });
          }
        } catch (err) {
          invalidKeys.push(index + 1);
        }
      });

      if (invalidKeys.length > 0) {
        setError(`第 ${invalidKeys.join(', ')} 行的私鑰格式無效`);
      }

      if (newWallets.length > 0) {
        const updatedWallets = [...wallets, ...newWallets];
        setWallets(updatedWallets);
        updateGlobalWallets(updatedWallets);
        setPrivateKeys('');

        setSnackbar({
          open: true,
          message: `此次操作成功: ${newWallets.length}/${totalKeys} 個錢包`,
          severity: 'success'
        });
      } else {
        setSnackbar({
          open: true,
          message: `此次操作失敗: 0/${totalKeys} 個錢包`,
          severity: 'error'
        });
      }

    } catch (err) {
      console.error('Import error:', err);
      setError('導入錢包時發生錯誤');
      setSnackbar({
        open: true,
        message: '錢包導入失敗',
        severity: 'error'
      });
    }
  };

  // 獲取代幣信息
  const getTokenInfo = async (web3, tokenAddress) => {
    try {
      const contract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      const [symbol, decimals] = await Promise.all([
        contract.methods.symbol().call(),
        contract.methods.decimals().call(),
      ]);
      return { symbol, decimals: Number(decimals) };
    } catch (error) {
      console.error('Get token info error:', error);
      return null;
    }
  };

  // 檢查餘額
  const checkBalances = async () => {
    if (wallets.length === 0) return;

    setLoading(true);
    try {
      if (!window._web3Instance) {
        throw new Error('請先連接RPC節點');
      }

      const web3 = window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const currentNetwork = Object.values(NETWORK_CONFIG).find(net => 
        net.chainId.toString() === chainId.toString()
      );

      if (!currentNetwork) {
        throw new Error('不支持的網絡');
      }

      const results = {};

      for (const wallet of wallets) {
        try {
          let balance;
          let tokenInfo;

          switch(tokenType) {
            case 'NATIVE':
              balance = await web3.eth.getBalance(wallet.address);
              results[wallet.address] = {
                type: 'NATIVE',
                symbol: currentNetwork.nativeCurrency.symbol,
                balance: web3.utils.fromWei(balance, 'ether'),
                decimals: 18
              };
              break;

            case 'USDT':
              const usdtAddress = currentNetwork.stablecoins?.USDT;
              if (!usdtAddress) throw new Error('當前網絡無USDT合約');
              
              tokenInfo = await getTokenInfo(web3, usdtAddress);
              if (!tokenInfo) throw new Error('無法獲取USDT信息');

              const usdtContract = new web3.eth.Contract(ABIS.ERC20, usdtAddress);
              balance = await usdtContract.methods.balanceOf(wallet.address).call();
              
              results[wallet.address] = {
                type: 'USDT',
                symbol: tokenInfo.symbol,
                balance: balance / Math.pow(10, tokenInfo.decimals),
                decimals: tokenInfo.decimals
              };
              break;

            case 'CUSTOM':
              if (!customTokenAddress) throw new Error('請輸入代幣合約地址');
              if (!web3.utils.isAddress(customTokenAddress)) {
                throw new Error('無效的代幣合約地址');
              }

              tokenInfo = await getTokenInfo(web3, customTokenAddress);
              if (!tokenInfo) throw new Error('無法獲取代幣信息');

              const tokenContract = new web3.eth.Contract(ABIS.ERC20, customTokenAddress);
              balance = await tokenContract.methods.balanceOf(wallet.address).call();
              
              results[wallet.address] = {
                type: 'CUSTOM',
                symbol: tokenInfo.symbol,
                balance: balance / Math.pow(10, tokenInfo.decimals),
                decimals: tokenInfo.decimals
              };
              break;
          }
        } catch (err) {
          results[wallet.address] = { error: err.message };
        }
      }

      setBalances(results);
    } catch (err) {
      console.error('Check balances error:', err);
      setError(err.message);
    }
    setLoading(false);
  };

  // 複製地址
  const copyAddress = async (address) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(address);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = address;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setSnackbar({
        open: true,
        message: '地址已复制',
        severity: 'success'
      });
    } catch (err) {
      console.error('Failed to copy:', err);
      setSnackbar({
        open: true,
        message: '复制失败',
        severity: 'error'
      });
    }
  };

  // 刪除錢包
  const removeWallet = (address) => {
    const updatedWallets = wallets.filter(w => w.address !== address);
    setWallets(updatedWallets);
    updateGlobalWallets(updatedWallets);

    setBalances(prev => {
      const newBalances = { ...prev };
      delete newBalances[address];
      return newBalances;
    });

    if (selectedWallets.includes(address)) {
      setSelectedWallets(prev => prev.filter(a => a !== address));
    }
  };

  // 切換錢包選中狀態
  const toggleWalletSelection = (address) => {
    setSelectedWallets(prev => {
      const newSelection = prev.includes(address)
        ? prev.filter(a => a !== address)
        : [...prev, address];
      window._userAddresses = newSelection;
      window._userAddress = newSelection[0] || null;
      return newSelection;
    });
  };

  // 初始化
  React.useEffect(() => {
    const savedWallets = localStorage.getItem('wallets');
    if (savedWallets) {
      try {
        const parsedWallets = JSON.parse(savedWallets);
        setWallets(parsedWallets);
        updateGlobalWallets(parsedWallets);
      } catch (err) {
        console.error('Failed to restore wallets:', err);
      }
    }
  }, []);

  return (
    <Box>
      {/* 提示消息 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* 導入錢包 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            導入錢包
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={privateKeys}
            onChange={(e) => setPrivateKeys(e.target.value)}
            placeholder="每行輸入一個私鑰"
            sx={{ mb: 2 }}
          />
          <Button 
            variant="contained" 
            onClick={importWallets}
            disabled={!privateKeys.trim()}
          >
            導入
          </Button>
        </CardContent>
      </Card>

      {/* 餘額檢查 */}
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            檢查餘額
          </Typography>

          <Select
            fullWidth
            value={tokenType}
            onChange={(e) => {
              setTokenType(e.target.value);
              setBalances({});
            }}
            sx={{ mb: 2 }}
          >
            <MenuItem value="NATIVE">Native Token</MenuItem>
            <MenuItem value="USDT">USDT</MenuItem>
            <MenuItem value="CUSTOM">自定義代幣</MenuItem>
          </Select>

          {tokenType === 'CUSTOM' && (
            <TextField
              fullWidth
              label="代幣合約地址"
              value={customTokenAddress}
              onChange={(e) => setCustomTokenAddress(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          <Button 
            variant="contained" 
            onClick={checkBalances}
            disabled={loading || wallets.length === 0 || (tokenType === 'CUSTOM' && !customTokenAddress)}
          >
            {loading ? <CircularProgress size={24} /> : '檢查餘額'}
          </Button>
        </CardContent>
      </Card>

      {/* 錯誤提示 */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* 錢包列表 */}
      {wallets.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedWallets.length === wallets.length}
                    indeterminate={selectedWallets.length > 0 && selectedWallets.length < wallets.length}
                    onChange={() => {
                      if (selectedWallets.length === wallets.length) {
                        setSelectedWallets([]);
                        window._userAddresses = [];
                        window._userAddress = null;
                      } else {
                        const allAddresses = wallets.map(w => w.address);
                        setSelectedWallets(allAddresses);
                        window._userAddresses = allAddresses;
                        window._userAddress = allAddresses[0] || null;
                      }
                    }}
                  />
                </TableCell>
                <TableCell>地址</TableCell>
                <TableCell align="right">餘額</TableCell>
                <TableCell align="right">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map((wallet) => (
                <TableRow 
                  key={wallet.address}
                  selected={selectedWallets.includes(wallet.address)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedWallets.includes(wallet.address)}
                      onChange={() => toggleWalletSelection(wallet.address)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {wallet.address.slice(0, 6)}...{wallet.address.slice(-4)}
                      <IconButton size="small" onClick={() => copyAddress(wallet.address)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {balances[wallet.address]?.error ? (
                      <Typography color="error" variant="body2">
                        {balances[wallet.address].error}
                      </Typography>
                    ) : (
                      balances[wallet.address] ? 
                        `${Number(balances[wallet.address].balance).toFixed(6)} ${balances[wallet.address].symbol}` :
                        '-'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => removeWallet(wallet.address)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

module.exports = WalletPanel;


