const React = require('react');
const {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Grid,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  ButtonGroup,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Chip
} = require('@mui/material');

const {
  Search: SearchIcon,
  SwapHoriz: SwapHorizIcon,
  Close: CloseIcon,
  Info: InfoIcon,
  Shield: ShieldIcon,
  Delete: DeleteIcon,
  Refresh: RefreshIcon,
  AccessTime: AccessTimeIcon,
  CheckCircle: CheckCircleIcon,
  Cancel: CancelIcon
} = require('@mui/icons-material');

const Web3 = require('web3');
const {
  CONSTANTS,
  NETWORK_CONFIG,
  ABIS,
  utils
} = require('./evm');

// 定義常量
const NATIVE_TOKEN = 'NATIVE';
const PAYMENT_TOKENS = {
  NATIVE: 'Native Token',
  USDT: 'USDT'
};

// 交易協議
const PROTOCOLS = {
  V2: 'v2',
  V3: 'v3'
};

// 買入模式
const BUY_MODES = {
  EXACT_IN: 'exactIn',   // 固定支付模式 
  EXACT_OUT: 'exactOut'  // 固定獲得模式
};

// UniswapV3 費率
const UNISWAP_V3_FEES = {
  '500': '0.05%',    // 低手續費 (適合穩定幣)
  '3000': '0.3%',    // 中手續費 (常用)
  '10000': '1%'      // 高手續費 (高波動性代幣)
};

// UniswapV3 部署地址
const UNISWAP_V3_DEPLOYMENT = {
  '1': {  // Ethereum
    factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    router: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    quoter: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    nonfungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'
  },
  '56': { // BSC
    factory: '0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7',
    router: '0xB971eF87ede563556b2ED4b1C0b0019111Dd85d2',
    quoter: '0x78D78E420Da98ad378D7799bE8f4AF69033EB077'
  },
  '42161': { // Arbitrum
    factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    router: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    quoter: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'
  },
  '8453': { // Base
    factory: '0x33128a8fC17869897dcE68Ed026d694621f6FDfD',
    router: '0x4752ba5DBc23f44D41AAc99B785A12307A7Da01c',
    quoter: '0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a'
  }
};

const CHAIN_REFRESH_INTERVALS = {
  1: 12000,    // Ethereum: 12秒
  56: 3000,    // BSC: 3秒
  8453: 2000,  // Base: 2秒
  42161: 2000, // Arbitrum: 2秒
  1116: 3000,  // Core: 3秒
  20201022: 3000  // PEGO: 3秒
};

// 添加防MEV的RPC配置
const ANTI_MEV_RPC_CONFIG = {
  1: [ // Ethereum
    { name: "BlockSec RPC", url: "https://eth.rpc.blocksec.com" },
    { name: "MEV Blocker RPC", url: "https://rpc.mevblocker.io" },
    { name: "Flashbots RPC", url: "https://rpc.flashbots.net/?builder=f1b.io&builder=rsync&builder=beaverbuild.org&builder=builder0x69&builder=Titan&builder=EigenPhi&builder=boba-builder&builder=Gambit+Labs&builder=payload&builder=Loki&builder=BuildAI&builder=JetBuilder&builder=tbuilder&builder=penguinbuild&builder=bobthebuilder&builder=BTCS&builder=bloXroute&builder=Blockbeelder&hint=hash" }
  ],
  56: [ // BSC
    { name: "BlockSec RPC", url: "https://bsc.rpc.blocksec.com" }
  ]
};

const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
const TEST_AMOUNT = '100000000000000'; // 0.0001 Native Token

// 錯誤常量
const ERRORS = {
  NO_WEB3: '請先連接Web3',
  NO_WALLET: '請先選擇錢包',
  NO_POOL: '請先選擇交易池',
  INVALID_AMOUNT: '無效的金額',
  INSUFFICIENT_BALANCE: '餘額不足',
  CONTRACT_ERROR: '合約調用失敗',
  TAX_CHECK_FAILED: '稅率檢測失敗',
  APPROVAL_FAILED: '授權失敗',
  NETWORK_ERROR: '網絡錯誤',
  INVALID_PATH: '無效的交易路徑',
  SLIPPAGE_TOO_HIGH: '滑點過高',
  UNSUPPORTED_PROTOCOL: '當前網絡不支持此協議',
  NO_LIQUIDITY: '沒有流動性'
};

// MaikoRouter 配置
const MAIKO_ROUTER_CONFIG = {
  '1': {
    address: '0x...',  
    name: 'Ethereum'
  },
  '56': {
    address: '0xb5935ee9b185d521916CeEA9e760eD16AE5BB0D8',
    name: 'BSC'
  },
  '8453': {
    address: '0x...',
    name: 'Base'
  },
  '42161': {
    address: '0x...',
    name: 'Arbitrum'
  },
  '1116': {
    address: '0x...',
    name: 'Core'
  },
  '20201022': {
    address: '0x...',
    name: 'PEGO'
  }
};

// 限價訂單類型
const LIMIT_ORDER_TYPES = {
  BUY: 'buy',
  SELL: 'sell'
};

// 限價訂單狀態
const LIMIT_ORDER_STATUS = {
  PENDING: 'pending',
  EXECUTED: 'executed',
  CANCELLED: 'cancelled'
};

// Limit Order Manager 配置
const LIMIT_ORDER_MANAGER_CONFIG = {
  '1': {
    address: '0x...',  
    name: 'Ethereum'
  },
  '56': {
    address: '0xc55571812f4fdcbb7cb147574ac9efdc4c66febe',
    name: 'BSC'
  },
  '8453': {
    address: '0x...',
    name: 'Base'
  },
  '42161': {
    address: '0x...',
    name: 'Arbitrum'
  },
  '1116': {
    address: '0x...',
    name: 'Core'
  },
  '20201022': {
    address: '0x...',
    name: 'PEGO'
  }
};

// Contract ABIs
const PAIR_ABI = [
  {
    constant: true,
    inputs: [],
    name: "token0",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "token1",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "getReserves",
    outputs: [
      { name: "_reserve0", type: "uint112" },
      { name: "_reserve1", type: "uint112" },
      { name: "_blockTimestampLast", type: "uint32" }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  }
];

const MAIKO_ROUTER_ABI = [
  {
    "inputs": [
      {"name": "router", "type": "address"},
      {"name": "path", "type": "address[]"},
      {"name": "isNativeInput", "type": "bool"},
      {"name": "_minOutAmount", "type": "uint256"},
      {"name": "_maxBuyTaxRate", "type": "uint256"},
      {"name": "_maxSellTaxRate", "type": "uint256"},
      {"name": "realAmount", "type": "uint256"}
    ],
    "name": "checkAndBuy",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "router", "type": "address"},
      {"name": "path", "type": "address[]"},
      {"name": "isNativeOutput", "type": "bool"},
      {"name": "_minOutAmount", "type": "uint256"},
      {"name": "_maxBuyTaxRate", "type": "uint256"},
      {"name": "_maxSellTaxRate", "type": "uint256"},
      {"name": "realAmount", "type": "uint256"}
    ],
    "name": "checkAndSell",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

// Limit Order Manager 合約 ABI
const LIMIT_ORDER_MANAGER_ABI = [
  {
    "inputs": [
      {"name": "tokenIn", "type": "address"},
      {"name": "tokenOut", "type": "address"},
      {"name": "amountIn", "type": "uint256"},
      {"name": "targetPrice", "type": "uint256"},
      {"name": "minOutAmount", "type": "uint256"},
      {"name": "deadline", "type": "uint256"}
    ],
    "name": "createLimitOrder",
    "outputs": [{"name": "orderId", "type": "uint256"}],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "orderId", "type": "uint256"}
    ],
    "name": "cancelLimitOrder",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "userAddress", "type": "address"}
    ],
    "name": "getUserOrders",
    "outputs": [{"name": "orderIds", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "orderId", "type": "uint256"}
    ],
    "name": "getOrderDetails",
    "outputs": [
      {"name": "maker", "type": "address"},
      {"name": "tokenIn", "type": "address"},
      {"name": "tokenOut", "type": "address"},
      {"name": "amountIn", "type": "uint256"},
      {"name": "targetPrice", "type": "uint256"},
      {"name": "minOutAmount", "type": "uint256"},
      {"name": "deadline", "type": "uint256"},
      {"name": "status", "type": "uint8"},
      {"name": "executedAmount", "type": "uint256"}
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "tokenIn", "type": "address"},
      {"name": "tokenOut", "type": "address"},
      {"name": "amountIn", "type": "uint256"}
    ],
    "name": "estimateAmountOut",
    "outputs": [{"name": "amountOut", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
  }
];

// 工具函數

// 獲取代幣精度
const getTokenDecimals = async (web3, tokenAddress) => {
  try {
    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    const decimals = await tokenContract.methods.decimals().call();
    return parseInt(decimals);
  } catch (error) {
    console.error('Get token decimals error:', error);
    return 18; // 默認返回18位精度
  }
};

// 格式化數值顯示
const formatAmount = (amount, decimals = 2) => {
  try {
    if (!amount || isNaN(Number(amount))) return '0.00';
    return Number(amount).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  } catch (error) {
    console.error('Format amount error:', error);
    return '0.00';
  }
};

// 轉換金額到代幣精度
const toTokenDecimals = (web3, amount, decimals) => {
  try {
    if (!amount || isNaN(Number(amount))) return '0';
    
    // 先轉換到最小單位(wei)
    const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
    // 如果精度不是18，需要調整
    if (decimals !== 18) {
      const factor = web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals));
      const amountBN = web3.utils.toBN(amountInWei);
      return amountBN.div(factor).toString();
    }
    return amountInWei;
  } catch (error) {
    console.error('Convert to token decimals error:', error);
    throw error;
  }
};

// 從代幣精度轉換回顯示金額
const fromTokenDecimals = (web3, amount, decimals) => {
  try {
    if (!amount || amount === '0') return '0';
    
    // 如果精度不是18，需要調整
    if (decimals !== 18) {
      const factor = web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals));
      const amountBN = web3.utils.toBN(amount);
      const adjustedAmount = amountBN.mul(factor).toString();
      return web3.utils.fromWei(adjustedAmount, 'ether');
    }
    return web3.utils.fromWei(amount, 'ether');
  } catch (error) {
    console.error('Convert from token decimals error:', error);
    throw error;
  }
};

// 查找當前網絡配置
const findCurrentNetwork = async (web3) => {
  try {
    const chainId = await web3.eth.getChainId();
    const chainIdStr = chainId.toString();
    const network = Object.values(NETWORK_CONFIG).find(net => 
      net.chainId.toString() === chainIdStr
    );
    if (!network) {
      console.warn(`Network config not found for chain ID ${chainIdStr}`);
    }
    return network;
  } catch (error) {
    console.error('Find network error:', error);
    return null;
  }
};

// 獲取代幣信息
const getTokenInfo = async (web3, tokenAddress) => {
  try {
    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    const [symbol, decimals, name] = await Promise.all([
      tokenContract.methods.symbol().call().catch(() => '???'),
      tokenContract.methods.decimals().call().catch(() => '18'),
      tokenContract.methods.name().call().catch(() => '')
    ]);
    return { symbol, decimals: parseInt(decimals), name };
  } catch (error) {
    console.error('Get token info error:', error);
    return { symbol: '???', decimals: 18, name: '' };
  }
};

// 创建新的Web3实例，用于防MEV RPC
const createAntiMevWeb3 = (rpcUrl) => {
  try {
    return new Web3(new Web3.providers.HttpProvider(rpcUrl));
  } catch (error) {
    console.error('Create Anti-MEV Web3 error:', error);
    throw error;
  }
};

// 檢查當前鏈是否支持UniswapV3
const isUniswapV3Supported = (chainId) => {
  return !!UNISWAP_V3_DEPLOYMENT[chainId.toString()];
};

// 為Uniswap V3編碼交易路徑
const encodePathV3 = (path, fees) => {
  if (path.length < 2 || fees.length < path.length - 1) {
    throw new Error('路徑或費率數據不完整');
  }
  
  const FEE_SIZE = 3;
  const ADDR_SIZE = 20;
  
  const encoded = new Uint8Array(path.length * ADDR_SIZE + (path.length - 1) * FEE_SIZE);
  
  // 添加第一個地址
  const firstAddr = path[0].slice(2).padStart(ADDR_SIZE * 2, '0');
  for (let i = 0; i < ADDR_SIZE; i++) {
    encoded[i] = parseInt(firstAddr.substring(i * 2, i * 2 + 2), 16);
  }
  
  // 添加剩餘的費率和地址
  for (let i = 1; i < path.length; i++) {
    // 添加費率
    const fee = parseInt(fees[i-1]);
    const feeHex = fee.toString(16).padStart(FEE_SIZE * 2, '0');
    
    for (let j = 0; j < FEE_SIZE; j++) {
      encoded[ADDR_SIZE * i + FEE_SIZE * (i - 1) + j] = 
        parseInt(feeHex.substring(j * 2, j * 2 + 2), 16);
    }
    
    // 添加地址
    const addr = path[i].slice(2).padStart(ADDR_SIZE * 2, '0');
    for (let j = 0; j < ADDR_SIZE; j++) {
      encoded[ADDR_SIZE * i + FEE_SIZE * i + j] = 
        parseInt(addr.substring(j * 2, j * 2 + 2), 16);
    }
  }
  
  return '0x' + Buffer.from(encoded).toString('hex');
};

// 獲取Uniswap V3價格信息
const getV3PriceInfo = async (web3, quoterAddress, tokenIn, tokenOut, fee, amountIn) => {
  try {
    const quoterContract = new web3.eth.Contract(ABIS.UNISWAPV3_QUOTER, quoterAddress);
    
    const amountOut = await quoterContract.methods.quoteExactInputSingle(
      tokenIn,
      tokenOut,
      fee,
      amountIn,
      0 // sqrtPriceLimitX96 (0 = no limit)
    ).call();
    
    return amountOut;
  } catch (error) {
    console.error('Get V3 price info error:', error);
    throw error;
  }
};

// 檢查Uniswap V3池子是否存在
const checkV3PoolExists = async (web3, factoryAddress, tokenA, tokenB, fee) => {
  try {
    const factoryContract = new web3.eth.Contract(ABIS.UNISWAPV3_FACTORY, factoryAddress);
    const poolAddress = await factoryContract.methods.getPool(tokenA, tokenB, fee).call();
    return poolAddress !== '0x0000000000000000000000000000000000000000';
  } catch (error) {
    console.error('Check V3 pool exists error:', error);
    return false;
  }
};

// 獲取Uniswap V3池子信息
const getV3PoolInfo = async (web3, factoryAddress, tokenA, tokenB, fee) => {
  try {
    const factoryContract = new web3.eth.Contract(ABIS.UNISWAPV3_FACTORY, factoryAddress);
    const poolAddress = await factoryContract.methods.getPool(tokenA, tokenB, fee).call();
    
    if (poolAddress === '0x0000000000000000000000000000000000000000') {
      return null;
    }
    
    const poolContract = new web3.eth.Contract(ABIS.UNISWAPV3_POOL, poolAddress);
    
    const [token0, token1, slot0, liquidity] = await Promise.all([
      poolContract.methods.token0().call(),
      poolContract.methods.token1().call(),
      poolContract.methods.slot0().call(),
      poolContract.methods.liquidity().call()
    ]);
    
    return {
      poolAddress,
      token0,
      token1,
      sqrtPriceX96: slot0.sqrtPriceX96,
      tick: slot0.tick,
      liquidity,
      fee
    };
  } catch (error) {
    console.error('Get V3 pool info error:', error);
    return null;
  }
};

// 格式化日期時間
const formatDateTime = (timestamp) => {
  try {
    const date = new Date(timestamp);
    return date.toLocaleString();
  } catch (error) {
    console.error('Format date time error:', error);
    return 'Invalid Date';
  }
};

// 格式化訂單狀態
const formatOrderStatus = (status) => {
  switch (status) {
    case LIMIT_ORDER_STATUS.PENDING:
      return { label: '待執行', color: 'warning' };
    case LIMIT_ORDER_STATUS.EXECUTED:
      return { label: '已執行', color: 'success' };
    case LIMIT_ORDER_STATUS.CANCELLED:
      return { label: '已取消', color: 'error' };
    default:
      return { label: '未知', color: 'default' };
  }
};

const TradePanel = () => {
  // 基礎狀態
  const [selectedFunction, setSelectedFunction] = React.useState('swap');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [result, setResult] = React.useState(null);
  const [operationResults, setOperationResults] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const [explorerUrl, setExplorerUrl] = React.useState('');
  const [nativeSymbol, setNativeSymbol] = React.useState('ETH');
  const [currentNetwork, setCurrentNetwork] = React.useState(null);
  const [currentChainId, setCurrentChainId] = React.useState(null);
  
  // 協議選擇狀態
  const [selectedProtocol, setSelectedProtocol] = React.useState(PROTOCOLS.V2);
  const [protocolSupported, setProtocolSupported] = React.useState({
    [PROTOCOLS.V2]: true,
    [PROTOCOLS.V3]: false
  });

  // 防MEV相關狀態
  const [useAntiMev, setUseAntiMev] = React.useState(false);
  const [selectedAntiMevRpc, setSelectedAntiMevRpc] = React.useState('');
  const [antiMevWeb3, setAntiMevWeb3] = React.useState(null);
  const [availableAntiMevRpcs, setAvailableAntiMevRpcs] = React.useState([]);

  // Gas設置
  const [gasPrice, setGasPrice] = React.useState('');
  const [gasLimit, setGasLimit] = React.useState('3000000');
  const [currentGasPrice, setCurrentGasPrice] = React.useState(null);
  const [estimatedGasFee, setEstimatedGasFee] = React.useState(null);

  // 自定義交易狀態
  const [customAddress, setCustomAddress] = React.useState('');
  const [customData, setCustomData] = React.useState('');
  const [customValue, setCustomValue] = React.useState('0');

  // 授權狀態
  const [tokenAddress, setTokenAddress] = React.useState('');
  const [spenderAddress, setSpenderAddress] = React.useState('');
  const [approveAmount, setApproveAmount] = React.useState('');
  const [showApprovalDialog, setShowApprovalDialog] = React.useState(false);
  const [approvalChecking, setApprovalChecking] = React.useState(false);
  const [currentAllowance, setCurrentAllowance] = React.useState('0');
  const [routerApprovals, setRouterApprovals] = React.useState({
    input: '0',
    output: '0'
  });
  const [contractApprovals, setContractApprovals] = React.useState({
    input: '0',
    output: '0'
  });

  // 交易相關狀態
  const [tokenContract, setTokenContract] = React.useState('');
  const [availablePools, setAvailablePools] = React.useState([]);
  const [selectedPool, setSelectedPool] = React.useState('');
  const [tradeType, setTradeType] = React.useState('buy');
  const [paymentToken, setPaymentToken] = React.useState(NATIVE_TOKEN);
  const [buyMode, setBuyMode] = React.useState(BUY_MODES.EXACT_IN);
  const [inputAmount, setInputAmount] = React.useState('');
  const [minOutput, setMinOutput] = React.useState('');
  const [desiredOutAmount, setDesiredOutAmount] = React.useState('');
  const [maxInAmount, setMaxInAmount] = React.useState('');
  const [tradePath, setTradePath] = React.useState([]);
  const [tradeTokens, setTradeTokens] = React.useState(new Map());
  
  // Uniswap V3 特有狀態
  const [selectedFeeTier, setSelectedFeeTier] = React.useState('3000');
  const [availableFeeTiers, setAvailableFeeTiers] = React.useState([]);
  const [v3Pools, setV3Pools] = React.useState([]);
  const [v3PathFees, setV3PathFees] = React.useState([]);

  // 稅率檢測相關狀態
  const [useTaxCheck, setUseTaxCheck] = React.useState(false);
  const [maxBuyTax, setMaxBuyTax] = React.useState('10');
  const [maxSellTax, setMaxSellTax] = React.useState('10');

  // 池子信息狀態
  const [poolTokens, setPoolTokens] = React.useState({
    token0: null,
    token1: null,
    reserves: null
  });
  const [poolInfo, setPoolInfo] = React.useState({
    baseTokenAmount: '0',
    tokenAmount: '0',
    baseTokenValue: '0'
  });

  // Debug 信息
  const [debugInfo, setDebugInfo] = React.useState({
    lastError: null,
    pathInfo: null,
    contractCalls: []
  });
  
  // 导致崩溃的标志，用于追踪错误
  const [errorOccurred, setErrorOccurred] = React.useState(false);

  // 限價訂單相關狀態
  const [limitOrderType, setLimitOrderType] = React.useState(LIMIT_ORDER_TYPES.BUY);
  const [limitOrderPrice, setLimitOrderPrice] = React.useState('');
  const [limitOrderAmount, setLimitOrderAmount] = React.useState('');
  const [limitOrderMinOutput, setLimitOrderMinOutput] = React.useState('');
  const [limitOrderDeadline, setLimitOrderDeadline] = React.useState('24');
  const [myLimitOrders, setMyLimitOrders] = React.useState([]);
  const [showMyOrders, setShowMyOrders] = React.useState(false);
  const [limitOrderLoading, setLimitOrderLoading] = React.useState(false);
  const [selectedLimitOrder, setSelectedLimitOrder] = React.useState(null);
  const [showLimitOrderDetails, setShowLimitOrderDetails] = React.useState(false);
// 更新當前網絡
  const updateCurrentNetwork = async (web3) => {
    if (!web3) {
      console.error('Web3 instance is required');
      return;
    }

    try {
      const chainId = await web3.eth.getChainId();
      setCurrentChainId(chainId);
      
      // 更新防MEV的RPC选项
      updateAntiMevRpcOptions(chainId);
      
      const network = await findCurrentNetwork(web3);
      
      if (network) {
        // 確保使用正確的網絡原生幣種符號
        const nativeCurrencySymbol = network.nativeCurrency?.symbol || 
          chainId === 56 ? 'BNB' :  // BSC
          chainId === 1 ? 'ETH' :   // Ethereum
          chainId === 42161 ? 'ETH' : // Arbitrum
          chainId === 8453 ? 'ETH' :  // Base
          'ETH';  // 默認

        setNativeSymbol(nativeCurrencySymbol);
        setExplorerUrl(network?.explorer?.url || '');
        
        // 檢查當前鏈是否支持 UniswapV3
        const v3Supported = isUniswapV3Supported(chainId);
        
        setProtocolSupported({
          [PROTOCOLS.V2]: true, // V2 假設所有鏈都支持
          [PROTOCOLS.V3]: v3Supported
        });
        
        // 如果當前選擇的協議在此鏈不受支持，則自動切換到支持的協議
        if (selectedProtocol === PROTOCOLS.V3 && !v3Supported) {
          setSelectedProtocol(PROTOCOLS.V2);
        }
        
        setDebugInfo(prev => ({
          ...prev,
          network: {
            chainId,
            name: network.name,
            nativeCurrency: {
              ...network.nativeCurrency,
              symbol: nativeCurrencySymbol
            },
            timestamp: new Date().toISOString(),
            v3Supported
          }
        }));

        return network;
      } else {
        throw new Error('不支持的網絡');
      }
    } catch (error) {
      console.error('Set network error:', error);
      setError(`網絡設置錯誤: ${error.message}`);
      return null;
    }
  };

  // 根据链ID更新防MEV的RPC选项
  const updateAntiMevRpcOptions = (chainId) => {
    const chainIdStr = chainId.toString();
    const antiMevRpcs = ANTI_MEV_RPC_CONFIG[chainIdStr] || [];
    
    setAvailableAntiMevRpcs(antiMevRpcs);
    
    // 如果当前链没有防MEV的RPC，禁用防MEV选项
    if (antiMevRpcs.length === 0) {
      setUseAntiMev(false);
      setSelectedAntiMevRpc('');
      setAntiMevWeb3(null);
    } else if (selectedAntiMevRpc && !antiMevRpcs.some(rpc => rpc.url === selectedAntiMevRpc)) {
      // 如果当前选择的RPC不在新链的可用RPC列表中，重置选择
      setSelectedAntiMevRpc('');
      setAntiMevWeb3(null);
    }
  };

  // 監聽鏈與協議變化
  React.useEffect(() => {
    const checkChainId = async () => {
      if (!window._web3Instance) return;
      
      try {
        const chainId = await window._web3Instance.eth.getChainId();
        setCurrentChainId(chainId);
        
        // 更新防MEV RPC選項
        updateAntiMevRpcOptions(chainId);
        
        // 檢查協議支持狀況
        const v3Supported = isUniswapV3Supported(chainId);
        
        setProtocolSupported({
          [PROTOCOLS.V2]: true,
          [PROTOCOLS.V3]: v3Supported
        });
        
        // 如果當前選擇的協議在此鏈不受支持，則自動切換到支持的協議
        if (selectedProtocol === PROTOCOLS.V3 && !v3Supported) {
          setSelectedProtocol(PROTOCOLS.V2);
        }
        
        // 這邊不要重置，否則會導致UI中的選擇消失
        // setAvailablePools([]);
        // setSelectedPool('');
        // setTradePath([]);
        // setTradeTokens(new Map());
        // setV3Pools([]);
        // setV3PathFees([]);
        
      } catch (error) {
        console.error('Check chain ID error:', error);
      }
    };
    
    checkChainId();
    
    // 設置輪詢檢查鏈ID的變化 - 降低頻率以減少干擾
    const interval = setInterval(checkChainId, 10000);
    
    return () => clearInterval(interval);
  }, [window._web3Instance, selectedProtocol]);

  // 当防MEV RPC选择变化时，创建新的Web3实例
  React.useEffect(() => {
    if (!selectedAntiMevRpc) {
      setAntiMevWeb3(null);
      return;
    }
    
    try {
      const newWeb3 = createAntiMevWeb3(selectedAntiMevRpc);
      setAntiMevWeb3(newWeb3);
    } catch (error) {
      console.error('Set anti-MEV Web3 error:', error);
      setError(`設置防夾子RPC錯誤: ${error.message}`);
      setAntiMevWeb3(null);
    }
  }, [selectedAntiMevRpc]);

  // Gas價格更新
  React.useEffect(() => {
    const updateGasPrice = async () => {
      if (!window._web3Instance) return;

      try {
        // 使用选择的Web3实例
        const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
        const gasPrice = await web3.eth.getGasPrice();
        const gasPriceInGwei = web3.utils.fromWei(gasPrice, 'gwei');
        setCurrentGasPrice(gasPriceInGwei);
        // 也自動設置到輸入欄位，但只在第一次
        if (!gasPrice) {
          setGasPrice(gasPriceInGwei);
        }
      } catch (error) {
        console.error('Get gas price error:', error);
      }
    };

    // 初始更新
    updateGasPrice();
    
    // 每3秒更新一次 - 增加間隔減少API請求次數
    const interval = setInterval(updateGasPrice, 3000);
    
    return () => clearInterval(interval);
  }, [window._web3Instance, antiMevWeb3, useAntiMev]);

  // 限價訂單狀態更新
  React.useEffect(() => {
    if (selectedFunction === 'limitOrder' && window._web3Instance && window._userAddresses) {
      fetchMyLimitOrders();
      
      // 設置定時刷新訂單 - 每30秒
      const interval = setInterval(fetchMyLimitOrders, 30000);
      
      return () => clearInterval(interval);
    }
  }, [selectedFunction, window._web3Instance, window._userAddresses, currentChainId]);

  // 協議切換處理函數
  const handleProtocolChange = (newProtocol) => {
    if (newProtocol === selectedProtocol) return;
    
    // 檢查新協議是否在當前鏈上受支持
    if (newProtocol === PROTOCOLS.V3 && !protocolSupported[PROTOCOLS.V3]) {
      setError(ERRORS.UNSUPPORTED_PROTOCOL);
      return;
    }
    
    // 重置相關狀態
    setSelectedProtocol(newProtocol);
    setAvailablePools([]);
    setSelectedPool('');
    setTradePath([]);
    setTradeTokens(new Map());
    
    // 如果切換到V3，重置V3特有狀態
    if (newProtocol === PROTOCOLS.V3) {
      setSelectedFeeTier('3000'); // 默認中等費率
      setV3Pools([]);
      setV3PathFees([]);
    }
  };
  
  // 獲取交易金額
  const getTransactionAmounts = async (web3) => {
    try {
      if (tradeType === 'buy') {
        if (buyMode === BUY_MODES.EXACT_IN) {
          if (!inputAmount || !minOutput) {
            throw new Error('請輸入支付數量和最小獲得數量');
          }

          const network = await findCurrentNetwork(web3);
          const inDecimals = paymentToken === NATIVE_TOKEN ? 
            18 : 
            await getTokenDecimals(web3, network.stablecoins.USDT);

          return {
            amountIn: toTokenDecimals(web3, inputAmount, inDecimals),
            amountOutMin: toTokenDecimals(web3, minOutput, 18)
          };
        } else {
          if (!desiredOutAmount || !maxInAmount) {
            throw new Error('請輸入目標獲得數量和最大支付數量');
          }

          const network = await findCurrentNetwork(web3);
          const inDecimals = paymentToken === NATIVE_TOKEN ? 
            18 : 
            await getTokenDecimals(web3, network.stablecoins.USDT);

          return {
            amountOut: toTokenDecimals(web3, desiredOutAmount, 18),
            amountInMax: toTokenDecimals(web3, maxInAmount, inDecimals)
          };
        }
      } else {
        if (!inputAmount || !minOutput) {
          throw new Error('請輸入賣出數量和最小獲得數量');
        }

        const network = await findCurrentNetwork(web3);
        const outDecimals = paymentToken === NATIVE_TOKEN ? 
          18 : 
          await getTokenDecimals(web3, network.stablecoins.USDT);

        return {
          amountIn: toTokenDecimals(web3, inputAmount, 18),
          amountOutMin: toTokenDecimals(web3, minOutput, outDecimals)
        };
      }
    } catch (error) {
      console.error('Get transaction amounts error:', error);
      throw error;
    }
  };
// 構建V2交易路徑
  const buildTradePathV2 = async (web3, network, pool, targetToken, tradeType, paymentToken) => {
    try {
      if (!pool || !targetToken) {
        console.error("Missing required parameters:", { pool, targetToken });
        throw new Error(ERRORS.INVALID_PATH);
      }

      const path = [];
      const tokens = new Map();
      const debugInfo = {
        poolType: null,
        pathSteps: []
      };

      // 判斷底池類型
      const isUSDTPool = pool.baseTokenAddress.toLowerCase() === network.stablecoins.USDT.toLowerCase();
      const isWrappedPool = pool.baseTokenAddress.toLowerCase() === network.contracts.wrappedNative.toLowerCase();
      debugInfo.poolType = isUSDTPool ? 'USDT Pool' : isWrappedPool ? 'Wrapped Pool' : 'Unknown Pool';

      if (tradeType === 'buy') {
        if (paymentToken === NATIVE_TOKEN) {
          // Native Token 買入情況
          path.push(network.contracts.wrappedNative);
          tokens.set(network.contracts.wrappedNative.toLowerCase(), {
            symbol: `W${network.nativeCurrency.symbol}`,
            isNative: true,
            decimals: 18
          });
          debugInfo.pathSteps.push('Native -> Wrapped');

          if (!isWrappedPool) {
            // 如果不是Wrapped池,需要先轉換到USDT
            path.push(network.stablecoins.USDT);
            const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
            tokens.set(network.stablecoins.USDT.toLowerCase(), {
              ...usdtInfo,
              isStable: true
            });
            debugInfo.pathSteps.push('Wrapped -> USDT');
          }

          path.push(targetToken);
          const targetTokenInfo = await getTokenInfo(web3, targetToken);
          tokens.set(targetToken.toLowerCase(), targetTokenInfo);
          debugInfo.pathSteps.push('-> Target Token');

        } else {
          // USDT 買入情況
          path.push(network.stablecoins.USDT);
          const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
          tokens.set(network.stablecoins.USDT.toLowerCase(), {
            ...usdtInfo,
            isStable: true
          });
          debugInfo.pathSteps.push('USDT');

          if (!isUSDTPool) {
            // 如果不是USDT池,需要先轉換到Wrapped
            path.push(network.contracts.wrappedNative);
            tokens.set(network.contracts.wrappedNative.toLowerCase(), {
              symbol: `W${network.nativeCurrency.symbol}`,
              isNative: true,
              decimals: 18
            });
            debugInfo.pathSteps.push('-> Wrapped');
          }

          path.push(targetToken);
          const targetTokenInfo = await getTokenInfo(web3, targetToken);
          tokens.set(targetToken.toLowerCase(), targetTokenInfo);
          debugInfo.pathSteps.push('-> Target Token');
        }
      } else {
          // 賣出邏輯
          path.push(targetToken);
          const targetTokenInfo = await getTokenInfo(web3, targetToken);
          tokens.set(targetToken.toLowerCase(), targetTokenInfo);
          debugInfo.pathSteps.push('Target Token');

          // 如果是 USDT 底池
          if (pool.baseToken === 'USDT') {
            // 如果目標是 USDT，直接加入 USDT
            if (paymentToken !== NATIVE_TOKEN) {
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push('-> USDT');
            } else {
              // 如果要換成 Native Token，需要經過 USDT 和 Wrapped
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push('-> USDT');

              path.push(network.contracts.wrappedNative);
              tokens.set(network.contracts.wrappedNative.toLowerCase(), {
                symbol: `W${network.nativeCurrency.symbol}`,
                isNative: true,
                decimals: 18
              });
              debugInfo.pathSteps.push('-> Wrapped -> Native');
            }
          } else if (pool.baseToken === 'WRAPPED') {
            // 如果是 Wrapped 底池
            path.push(network.contracts.wrappedNative);
            tokens.set(network.contracts.wrappedNative.toLowerCase(), {
              symbol: `W${network.nativeCurrency.symbol}`,
              isNative: true,
              decimals: 18
            });
            debugInfo.pathSteps.push('-> Wrapped');

            if (paymentToken !== NATIVE_TOKEN) {
              // 如果目標是 USDT，需要再加入 USDT
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push('-> USDT');
            }
            // 如果目標是 Native Token，則已經完成路徑
          }
        }

      // 驗證路徑 - 使用try-catch防止失敗导致整个应用崩溃
      // 修复这里的错误，将A1改为1
      try {
        const factory = new web3.eth.Contract(ABIS.FACTORY, window._selectedDex.factory);
        for (let i = 0; i < path.length - 1; i++) {
          const pair = await factory.methods.getPair(path[i], path[i + 1]).call();
          if (pair === CONSTANTS.ADDRESSES.ZERO) {
            // 正常错误流程，不会导致应用崩溃
            setError(`无效路径: ${tokens.get(path[i].toLowerCase())?.symbol || path[i].slice(0, 6)} -> ${tokens.get(path[i + 1].toLowerCase())?.symbol || path[i+1].slice(0, 6)} 之间没有流动池`);
            return null;
          }
        }
      } catch (pairCheckError) {
        console.error('Pair check error:', pairCheckError);
        // 修改错误处理方式，返回null而不是抛出异常
        setError(`验证交易路径时出错: ${pairCheckError.message}`);
        return null;
      }

      // 更新Debug信息
      setDebugInfo(prev => ({
        ...prev,
        pathInfo: debugInfo
      }));

      return { path, tokens };

    } catch (error) {
      console.error('Build trade path error:', error);
      setDebugInfo(prev => ({
        ...prev,
        lastError: {
          location: 'buildTradePathV2',
          message: error.message,
          timestamp: new Date().toISOString()
        }
      }));
      // 捕获错误以防止崩溃
      setError(`建立交易路径时出错: ${error.message}`);
      return null;
    }
  };

  // 構建V3交易路徑
  const buildTradePathV3 = async (web3, network, targetToken, tradeType, paymentToken) => {
    try {
      if (!targetToken) {
        throw new Error(ERRORS.INVALID_PATH);
      }
      
      const chainId = await web3.eth.getChainId();
      const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
      
      if (!deployment) {
        throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
      }
      
      const path = [];
      const fees = [];
      const tokens = new Map();
      const debugInfo = {
        protocol: 'UniswapV3',
        pathSteps: []
      };

      if (tradeType === 'buy') {
        if (paymentToken === NATIVE_TOKEN) {
          // Native Token 買入情況
          path.push(network.contracts.wrappedNative);
          tokens.set(network.contracts.wrappedNative.toLowerCase(), {
            symbol: `W${network.nativeCurrency.symbol}`,
            isNative: true,
            decimals: 18
          });
          debugInfo.pathSteps.push('Native -> Wrapped');
          
          // 檢查是否有直接的 Wrapped/Target 池子
          const directPoolExists = await checkV3PoolExists(
            web3, 
            deployment.factory, 
            network.contracts.wrappedNative, 
            targetToken, 
            selectedFeeTier
          );
          
          if (directPoolExists) {
            fees.push(selectedFeeTier);
            path.push(targetToken);
            const targetTokenInfo = await getTokenInfo(web3, targetToken);
            tokens.set(targetToken.toLowerCase(), targetTokenInfo);
            debugInfo.pathSteps.push(`-> Target Token (${UNISWAP_V3_FEES[selectedFeeTier]})`);
          } else {
            // 透過 USDT 中繼
            const wrappedUsdtPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.contracts.wrappedNative, 
              network.stablecoins.USDT, 
              '3000' // 中等費率通常適合穩定幣-主幣交易對
            );
            
            const usdtTargetPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.stablecoins.USDT, 
              targetToken, 
              selectedFeeTier
            );
            
            if (wrappedUsdtPoolExists && usdtTargetPoolExists) {
              fees.push('3000');
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push('-> USDT (0.3%)');
              
              fees.push(selectedFeeTier);
              path.push(targetToken);
              const targetTokenInfo = await getTokenInfo(web3, targetToken);
              tokens.set(targetToken.toLowerCase(), targetTokenInfo);
              debugInfo.pathSteps.push(`-> Target Token (${UNISWAP_V3_FEES[selectedFeeTier]})`);
            } else {
              throw new Error(ERRORS.NO_LIQUIDITY);
            }
          }
        } else {
          // USDT 買入情況
          path.push(network.stablecoins.USDT);
          const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
          tokens.set(network.stablecoins.USDT.toLowerCase(), {
            ...usdtInfo,
            isStable: true
          });
          debugInfo.pathSteps.push('USDT');
          
          // 檢查是否有直接的 USDT/Target 池子
          const directPoolExists = await checkV3PoolExists(
            web3, 
            deployment.factory, 
            network.stablecoins.USDT, 
            targetToken, 
            selectedFeeTier
          );
          
          if (directPoolExists) {
            fees.push(selectedFeeTier);
            path.push(targetToken);
            const targetTokenInfo = await getTokenInfo(web3, targetToken);
            tokens.set(targetToken.toLowerCase(), targetTokenInfo);
            debugInfo.pathSteps.push(`-> Target Token (${UNISWAP_V3_FEES[selectedFeeTier]})`);
          } else {
            // 透過 Wrapped 中繼
            const usdtWrappedPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.stablecoins.USDT, 
              network.contracts.wrappedNative, 
              '3000' // 中等費率通常適合穩定幣-主幣交易對
            );
            
            const wrappedTargetPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.contracts.wrappedNative, 
              targetToken, 
              selectedFeeTier
            );
            
            if (usdtWrappedPoolExists && wrappedTargetPoolExists) {
              fees.push('3000');
              path.push(network.contracts.wrappedNative);
              tokens.set(network.contracts.wrappedNative.toLowerCase(), {
                symbol: `W${network.nativeCurrency.symbol}`,
                isNative: true,
                decimals: 18
              });
              debugInfo.pathSteps.push('-> Wrapped (0.3%)');
              
              fees.push(selectedFeeTier);
              path.push(targetToken);
              const targetTokenInfo = await getTokenInfo(web3, targetToken);
              tokens.set(targetToken.toLowerCase(), targetTokenInfo);
              debugInfo.pathSteps.push(`-> Target Token (${UNISWAP_V3_FEES[selectedFeeTier]})`);
            } else {
              throw new Error(ERRORS.NO_LIQUIDITY);
            }
          }
        }
      } else {
        // 賣出邏輯
        path.push(targetToken);
        const targetTokenInfo = await getTokenInfo(web3, targetToken);
        tokens.set(targetToken.toLowerCase(), targetTokenInfo);
        debugInfo.pathSteps.push('Target Token');
        
        if (paymentToken === NATIVE_TOKEN) {
          // 賣出至 Native Token
          
          // 檢查是否有直接的 Target/Wrapped 池子
          const directPoolExists = await checkV3PoolExists(
            web3, 
            deployment.factory, 
            targetToken, 
            network.contracts.wrappedNative, 
            selectedFeeTier
          );
          
          if (directPoolExists) {
            fees.push(selectedFeeTier);
            path.push(network.contracts.wrappedNative);
            tokens.set(network.contracts.wrappedNative.toLowerCase(), {
              symbol: `W${network.nativeCurrency.symbol}`,
              isNative: true,
              decimals: 18
            });
            debugInfo.pathSteps.push(`-> Wrapped (${UNISWAP_V3_FEES[selectedFeeTier]})`);
          } else {
            // 透過 USDT 中繼
            const targetUsdtPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              targetToken, 
              network.stablecoins.USDT, 
              selectedFeeTier
            );
            
            const usdtWrappedPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.stablecoins.USDT, 
              network.contracts.wrappedNative, 
              '3000' // 中等費率通常適合穩定幣-主幣交易對
            );
            
            if (targetUsdtPoolExists && usdtWrappedPoolExists) {
              fees.push(selectedFeeTier);
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push(`-> USDT (${UNISWAP_V3_FEES[selectedFeeTier]})`);
              
              fees.push('3000');
              path.push(network.contracts.wrappedNative);
              tokens.set(network.contracts.wrappedNative.toLowerCase(), {
                symbol: `W${network.nativeCurrency.symbol}`,
                isNative: true,
                decimals: 18
              });
              debugInfo.pathSteps.push('-> Wrapped (0.3%)');
            } else {
              throw new Error(ERRORS.NO_LIQUIDITY);
            }
          }
        } else {
          // 賣出至 USDT
          
          // 檢查是否有直接的 Target/USDT 池子
          const directPoolExists = await checkV3PoolExists(
            web3, 
            deployment.factory, 
            targetToken, 
            network.stablecoins.USDT, 
            selectedFeeTier
          );
          
          if (directPoolExists) {
            fees.push(selectedFeeTier);
            path.push(network.stablecoins.USDT);
            const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
            tokens.set(network.stablecoins.USDT.toLowerCase(), {
              ...usdtInfo,
              isStable: true
            });
            debugInfo.pathSteps.push(`-> USDT (${UNISWAP_V3_FEES[selectedFeeTier]})`);
          } else {
            // 透過 Wrapped 中繼
            const targetWrappedPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              targetToken, 
              network.contracts.wrappedNative, 
              selectedFeeTier
            );
            
            const wrappedUsdtPoolExists = await checkV3PoolExists(
              web3, 
              deployment.factory, 
              network.contracts.wrappedNative, 
              network.stablecoins.USDT, 
              '3000' // 中等費率通常適合穩定幣-主幣交易對
            );
            
            if (targetWrappedPoolExists && wrappedUsdtPoolExists) {
              fees.push(selectedFeeTier);
              path.push(network.contracts.wrappedNative);
              tokens.set(network.contracts.wrappedNative.toLowerCase(), {
                symbol: `W${network.nativeCurrency.symbol}`,
                isNative: true,
                decimals: 18
              });
              debugInfo.pathSteps.push(`-> Wrapped (${UNISWAP_V3_FEES[selectedFeeTier]})`);
              
              fees.push('3000');
              path.push(network.stablecoins.USDT);
              const usdtInfo = await getTokenInfo(web3, network.stablecoins.USDT);
              tokens.set(network.stablecoins.USDT.toLowerCase(), {
                ...usdtInfo,
                isStable: true
              });
              debugInfo.pathSteps.push('-> USDT (0.3%)');
            } else {
              throw new Error(ERRORS.NO_LIQUIDITY);
            }
          }
        }
      }

      // 儲存路徑費率
      setV3PathFees(fees);

      // 更新Debug信息
      setDebugInfo(prev => ({
        ...prev,
        pathInfo: debugInfo
      }));

      return { path, tokens };

    } catch (error) {
      console.error('Build V3 trade path error:', error);
      setDebugInfo(prev => ({
        ...prev,
        lastError: {
          location: 'buildTradePathV3',
          message: error.message,
          timestamp: new Date().toISOString()
        }
      }));
      // 改进错误处理方式
      setError(`建立V3交易路径时出错: ${error.message}`);
      return null;
    }
  };

  // 建立交易路徑的統一接口
  const buildTradePath = async (web3, network, pool, targetToken, tradeType, paymentToken) => {
    // 使用try-catch防止未捕获的错误导致应用崩溃
    try {
      if (selectedProtocol === PROTOCOLS.V2) {
        return await buildTradePathV2(web3, network, pool, targetToken, tradeType, paymentToken);
      } else if (selectedProtocol === PROTOCOLS.V3) {
        return await buildTradePathV3(web3, network, targetToken, tradeType, paymentToken);
      } else {
        throw new Error('未知協議');
      }
    } catch (error) {
      console.error('Build trade path error:', error);
      setError(`建立交易路径失败: ${error.message}`);
      return null;
    }
  };

  // 計算最大輸入金額
  const handleMaxInput = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const userAddress = window._userAddresses[0];
      const network = await findCurrentNetwork(web3);
      
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }
      
      // 根據當前功能確定要獲取哪個代幣的最大值
      if (selectedFunction === 'swap') {
        if (tradeType === 'buy' && paymentToken === NATIVE_TOKEN) {
          const balance = await web3.eth.getBalance(userAddress);
          // 保留一些 Native Token 作為 gas
          const gasReserve = web3.utils.toWei('0.01', 'ether');
          const maxAmount = BigInt(balance) - BigInt(gasReserve);
          setInputAmount(web3.utils.fromWei(maxAmount.toString(), 'ether'));
        } else {
          const tokenToCheck = tradeType === 'sell' ? 
            tokenContract : 
            network.stablecoins.USDT;
          
          // 修改此處，避免變量名衝突
          const token = new web3.eth.Contract(ABIS.ERC20, tokenToCheck);
          const [balance, decimals] = await Promise.all([
            token.methods.balanceOf(userAddress).call(),
            token.methods.decimals().call()
          ]);
          setInputAmount(fromTokenDecimals(web3, balance, parseInt(decimals)));
        }
      } else if (selectedFunction === 'limitOrder') {
        if (limitOrderType === LIMIT_ORDER_TYPES.BUY && paymentToken === NATIVE_TOKEN) {
          const balance = await web3.eth.getBalance(userAddress);
          // 保留一些 Native Token 作為 gas
          const gasReserve = web3.utils.toWei('0.01', 'ether');
          const maxAmount = BigInt(balance) - BigInt(gasReserve);
          setLimitOrderAmount(web3.utils.fromWei(maxAmount.toString(), 'ether'));
        } else {
          const tokenToCheck = limitOrderType === LIMIT_ORDER_TYPES.SELL ? 
            tokenContract : 
            network.stablecoins.USDT;
          
          const token = new web3.eth.Contract(ABIS.ERC20, tokenToCheck);
          const [balance, decimals] = await Promise.all([
            token.methods.balanceOf(userAddress).call(),
            token.methods.decimals().call()
          ]);
          setLimitOrderAmount(fromTokenDecimals(web3, balance, parseInt(decimals)));
        }
      }
    } catch (error) {
      console.error('Get max amount error:', error);
      setError('獲取餘額失敗: ' + error.message);
    }
  };
// 檢查授權狀態
  const checkApprovals = async (tokenAddress, userAddress, type = 'input') => {
    try {
      if (!window._web3Instance || !window._selectedDex) {
        throw new Error(ERRORS.NO_WEB3);
      }

      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainId.toString()];
      
      // 獲取對應協議的路由器地址
      let routerAddress = '';
      if (selectedProtocol === PROTOCOLS.V2) {
        routerAddress = window._selectedDex.router;
      } else if (selectedProtocol === PROTOCOLS.V3) {
        const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
        if (!deployment) {
          throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
        }
        routerAddress = deployment.router;
      }

      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const routerAllowance = await tokenContract.methods
        .allowance(userAddress, routerAddress)
        .call();

      let contractAllowance = '0';
      if (maikoRouterConfig && maikoRouterConfig.address !== '0x...') {
        contractAllowance = await tokenContract.methods
          .allowance(userAddress, maikoRouterConfig.address)
          .call();
      }

      setRouterApprovals(prev => ({
        ...prev,
        [type]: routerAllowance
      }));

      setContractApprovals(prev => ({
        ...prev,
        [type]: contractAllowance
      }));

      return {
        routerApproved: BigInt(routerAllowance) > 0n,
        contractApproved: BigInt(contractAllowance) > 0n
      };
    } catch (error) {
      console.error('Check approvals error:', error);
      setError(`檢查授權狀態失敗: ${error.message}`);
      return { routerApproved: false, contractApproved: false };
    }
  };
  
  // 交易類型變更處理
  const handleTradeTypeChange = async (newType) => {
    setTradeType(newType);
    setInputAmount('');
    setMinOutput('');
    setDesiredOutAmount('');
    setMaxInAmount('');

    if (selectedProtocol === PROTOCOLS.V2 && (!selectedPool || !tokenContract || !window._web3Instance || !window._selectedDex)) {
      return;
    }

    if (selectedProtocol === PROTOCOLS.V3 && (!tokenContract || !window._web3Instance)) {
      return;
    }

    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      if (selectedProtocol === PROTOCOLS.V2) {
        const pool = availablePools.find(p => p.pairAddress === selectedPool);
        if (!pool) {
          throw new Error(ERRORS.NO_POOL);
        }

        const result = await buildTradePath(
          web3,
          network,
          pool,
          tokenContract,
          newType,
          paymentToken
        );

        if (result) {
          setTradePath(result.path);
          setTradeTokens(result.tokens);
        }
      } else if (selectedProtocol === PROTOCOLS.V3) {
        const result = await buildTradePath(
          web3,
          network,
          null, // V3不需要池子參數
          tokenContract,
          newType,
          paymentToken
        );

        if (result) {
          setTradePath(result.path);
          setTradeTokens(result.tokens);
        }
      }
    } catch (error) {
      console.error('Trade type change error:', error);
      setError(error.message);
      // 错误不影响页面状态
    }
  };

  // 支付代幣變更處理
  const handlePaymentTokenChange = async (newToken) => {
    setPaymentToken(newToken);
    setInputAmount('');
    setMinOutput('');
    setDesiredOutAmount('');
    setMaxInAmount('');
    setLimitOrderAmount('');
    setLimitOrderMinOutput('');

    if (selectedProtocol === PROTOCOLS.V2 && (!selectedPool || !tokenContract || !window._web3Instance || !window._selectedDex)) {
      return;
    }

    if (selectedProtocol === PROTOCOLS.V3 && (!tokenContract || !window._web3Instance)) {
      return;
    }

    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      // 更新 currentNetwork 狀態
      updateCurrentNetwork(web3).then(network => {
        setCurrentNetwork(network);
      }).catch(error => {
        console.error('Update network error:', error);
      });

      // 切換到 USDT 時檢查授權
      if (newToken !== NATIVE_TOKEN && window._userAddresses?.[0]) {
        try {
          await checkApprovals(network.stablecoins.USDT, window._userAddresses[0], 'input');
        } catch (approvalError) {
          console.error('Check approval error:', approvalError);
          // 不阻止流程继续
        }
      }

      if (selectedFunction === 'swap') {
        if (selectedProtocol === PROTOCOLS.V2) {
          const pool = availablePools.find(p => p.pairAddress === selectedPool);
          if (!pool) {
            throw new Error(ERRORS.NO_POOL);
          }

          const result = await buildTradePath(
            web3,
            network,
            pool,
            tokenContract,
            tradeType,
            newToken
          );

          if (result) {
            setTradePath(result.path);
            setTradeTokens(result.tokens);
          }
        } else if (selectedProtocol === PROTOCOLS.V3) {
          const result = await buildTradePath(
            web3,
            network,
            null, // V3不需要池子參數
            tokenContract,
            tradeType,
            newToken
          );

          if (result) {
            setTradePath(result.path);
            setTradeTokens(result.tokens);
          }
        }
      }
    } catch (error) {
      console.error('Payment token change error:', error);
      setError(error.message);
      setDebugInfo(prev => ({
        ...prev,
        lastError: {
          location: 'handlePaymentTokenChange',
          error: error.message,
          timestamp: new Date().toISOString()
        }
      }));
    }
  };

  // 創建限價訂單
  const createLimitOrder = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    if (!tokenContract) {
      setError('請輸入代幣合約地址');
      return;
    }

    if (!limitOrderPrice || !limitOrderAmount) {
      setError('請輸入價格和數量');
      return;
    }

    setLimitOrderLoading(true);
    setError('');

    try {
      // 使用選擇的Web3實例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const userAddress = window._userAddresses[0];
      const chainId = await web3.eth.getChainId();
      const network = await findCurrentNetwork(web3);

      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      // 獲取限價訂單管理器配置
      const limitOrderConfig = LIMIT_ORDER_MANAGER_CONFIG[chainId.toString()];
      if (!limitOrderConfig || limitOrderConfig.address === '0x...') {
        throw new Error('當前網絡不支持限價訂單');
      }

      // 創建合約實例
      const limitOrderManager = new web3.eth.Contract(
        LIMIT_ORDER_MANAGER_ABI,
        limitOrderConfig.address
      );

      // 計算目標價格
      const targetPriceInWei = web3.utils.toWei(limitOrderPrice, 'ether');
      
      // 計算截止時間
      const deadlineHours = parseInt(limitOrderDeadline) || 24; // 默認24小時
      const deadline = Math.floor(Date.now() / 1000) + (deadlineHours * 60 * 60);

      let tokenIn, tokenOut, amountIn, minOutAmount, value = '0';

      // 根據訂單類型設置參數
      if (limitOrderType === LIMIT_ORDER_TYPES.BUY) {
        tokenIn = paymentToken === NATIVE_TOKEN ? 
                CONSTANTS.ADDRESSES.ZERO : 
                network.stablecoins.USDT;
        tokenOut = tokenContract;
        
        // 計算輸入金額
        const inDecimals = paymentToken === NATIVE_TOKEN ? 18 : 
                         await getTokenDecimals(web3, network.stablecoins.USDT);
        amountIn = toTokenDecimals(web3, limitOrderAmount, inDecimals);
        
        // 計算最小輸出金額
        minOutAmount = limitOrderMinOutput ? 
                     toTokenDecimals(web3, limitOrderMinOutput, 18) : 
                     '0';
        
        // 如果使用原生代幣，設置交易值
        if (paymentToken === NATIVE_TOKEN) {
          value = amountIn;
        } else {
          // 檢查USDT授權
          const usdtContract = new web3.eth.Contract(ABIS.ERC20, network.stablecoins.USDT);
          const allowance = await usdtContract.methods.allowance(userAddress, limitOrderConfig.address).call();
          
          if (BigInt(allowance) < BigInt(amountIn)) {
            // 需要授權
            await usdtContract.methods.approve(
              limitOrderConfig.address,
              MAX_UINT256
            ).send({
              from: userAddress,
              gas: gasLimit,
              gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
            });
          }
        }
      } else {
        // 賣出訂單
        tokenIn = tokenContract;
        tokenOut = paymentToken === NATIVE_TOKEN ? 
                 CONSTANTS.ADDRESSES.ZERO : 
                 network.stablecoins.USDT;
        
        // 計算輸入金額
        const targetTokenDecimals = await getTokenDecimals(web3, tokenContract);
        amountIn = toTokenDecimals(web3, limitOrderAmount, targetTokenDecimals);
        
        // 計算最小輸出金額
        const outDecimals = paymentToken === NATIVE_TOKEN ? 18 : 
                          await getTokenDecimals(web3, network.stablecoins.USDT);
        minOutAmount = limitOrderMinOutput ? 
                     toTokenDecimals(web3, limitOrderMinOutput, outDecimals) : 
                     '0';
        
        // 檢查代幣授權
        const tokenContractInstance = new web3.eth.Contract(ABIS.ERC20, tokenContract);
        const allowance = await tokenContractInstance.methods.allowance(userAddress, limitOrderConfig.address).call();
        
        if (BigInt(allowance) < BigInt(amountIn)) {
          // 需要授權
          await tokenContractInstance.methods.approve(
            limitOrderConfig.address,
            MAX_UINT256
          ).send({
            from: userAddress,
            gas: gasLimit,
            gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
          });
        }
      }

      // 創建限價訂單
      const tx = await limitOrderManager.methods.createLimitOrder(
        tokenIn,
        tokenOut,
        amountIn,
        targetPriceInWei,
        minOutAmount,
        deadline
      ).send({
        from: userAddress,
        value: value,
        gas: gasLimit,
        gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
      });

      console.log('Limit order created:', tx);

      // 重新獲取用戶訂單
      fetchMyLimitOrders();

      setResult({
        success: true,
        message: '限價訂單創建成功',
        hash: tx.transactionHash
      });

      // 清空輸入
      setLimitOrderAmount('');
      setLimitOrderMinOutput('');
      setLimitOrderPrice('');

    } catch (error) {
      console.error('Create limit order error:', error);
      setError(error.message);
      setResult({
        success: false,
        message: error.message
      });
    } finally {
      setLimitOrderLoading(false);
    }
  };

  // 獲取用戶的限價訂單
  const fetchMyLimitOrders = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      return;
    }

    setLimitOrderLoading(true);

    try {
      // 使用選擇的Web3實例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const userAddress = window._userAddresses[0];
      const chainId = await web3.eth.getChainId();

      // 獲取限價訂單管理器配置
      const limitOrderConfig = LIMIT_ORDER_MANAGER_CONFIG[chainId.toString()];
      if (!limitOrderConfig || limitOrderConfig.address === '0x...') {
        console.warn('當前網絡不支持限價訂單');
        setMyLimitOrders([]);
        return;
      }

      // 創建合約實例
      const limitOrderManager = new web3.eth.Contract(
        LIMIT_ORDER_MANAGER_ABI,
        limitOrderConfig.address
      );

      // 獲取用戶訂單ID列表
      const orderIds = await limitOrderManager.methods.getUserOrders(userAddress).call();
      console.log('User order IDs:', orderIds);

      if (orderIds.length === 0) {
        setMyLimitOrders([]);
        return;
      }

      // 獲取每個訂單的詳細信息
      const orderPromises = orderIds.map(async (orderId) => {
        try {
          const orderDetails = await limitOrderManager.methods.getOrderDetails(orderId).call();
          
          // 獲取代幣信息
          const tokenInAddress = orderDetails.tokenIn === CONSTANTS.ADDRESSES.ZERO ? 
                               "NATIVE" : orderDetails.tokenIn;
          const tokenOutAddress = orderDetails.tokenOut === CONSTANTS.ADDRESSES.ZERO ? 
                                "NATIVE" : orderDetails.tokenOut;
          
          let tokenInInfo, tokenOutInfo;
          
          if (tokenInAddress === "NATIVE") {
            tokenInInfo = { 
              symbol: nativeSymbol, 
              decimals: 18, 
              name: nativeSymbol 
            };
          } else {
            tokenInInfo = await getTokenInfo(web3, tokenInAddress);
          }
          
          if (tokenOutAddress === "NATIVE") {
            tokenOutInfo = { 
              symbol: nativeSymbol, 
              decimals: 18, 
              name: nativeSymbol 
            };
          } else {
            tokenOutInfo = await getTokenInfo(web3, tokenOutAddress);
          }
          
          // 計算人類可讀數字
          const amountIn = fromTokenDecimals(web3, orderDetails.amountIn, parseInt(tokenInInfo.decimals));
          const minOutAmount = fromTokenDecimals(web3, orderDetails.minOutAmount, parseInt(tokenOutInfo.decimals));
          const targetPrice = web3.utils.fromWei(orderDetails.targetPrice, 'ether');
          const deadlineDate = new Date(parseInt(orderDetails.deadline) * 1000);
          
          // 狀態轉換
          const statusMap = {
            0: LIMIT_ORDER_STATUS.PENDING,
            1: LIMIT_ORDER_STATUS.EXECUTED,
            2: LIMIT_ORDER_STATUS.CANCELLED
          };
          
          const executedAmount = orderDetails.executedAmount === '0' ? 
                               '0' : 
                               fromTokenDecimals(web3, orderDetails.executedAmount, parseInt(tokenOutInfo.decimals));
          
          return {
            id: orderId,
            maker: orderDetails.maker,
            tokenIn: tokenInAddress,
            tokenOut: tokenOutAddress,
            tokenInInfo,
            tokenOutInfo,
            amountIn,
            targetPrice,
            minOutAmount,
            deadline: orderDetails.deadline,
            deadlineDate,
            status: statusMap[orderDetails.status] || LIMIT_ORDER_STATUS.PENDING,
            executedAmount,
            type: tokenOutAddress === tokenContract ? LIMIT_ORDER_TYPES.BUY : LIMIT_ORDER_TYPES.SELL
          };
        } catch (err) {
          console.error(`Error fetching order ${orderId}:`, err);
          return null;
        }
      });
      
      const orders = (await Promise.all(orderPromises)).filter(Boolean);
      orders.sort((a, b) => b.deadline - a.deadline); // 按截止時間降序排列
      
      setMyLimitOrders(orders);
      console.log('User orders:', orders);
    } catch (error) {
      console.error('Fetch limit orders error:', error);
      setMyLimitOrders([]);
    } finally {
      setLimitOrderLoading(false);
    }
  };

  // 取消限價訂單
  const cancelLimitOrder = async (orderId) => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    setLimitOrderLoading(true);
    setError('');

    try {
      // 使用選擇的Web3實例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const userAddress = window._userAddresses[0];
      const chainId = await web3.eth.getChainId();

      // 獲取限價訂單管理器配置
      const limitOrderConfig = LIMIT_ORDER_MANAGER_CONFIG[chainId.toString()];
      if (!limitOrderConfig || limitOrderConfig.address === '0x...') {
        throw new Error('當前網絡不支持限價訂單');
      }

      // 創建合約實例
      const limitOrderManager = new web3.eth.Contract(
        LIMIT_ORDER_MANAGER_ABI,
        limitOrderConfig.address
      );

      // 發送取消訂單交易
      const tx = await limitOrderManager.methods.cancelLimitOrder(orderId).send({
        from: userAddress,
        gas: gasLimit,
        gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
      });

      console.log('Order cancelled:', tx);

      // 重新獲取用戶訂單
      fetchMyLimitOrders();

      setResult({
        success: true,
        message: '訂單已成功取消',
        hash: tx.transactionHash
      });

    } catch (error) {
      console.error('Cancel limit order error:', error);
      setError(error.message);
      setResult({
        success: false,
        message: error.message
      });
    } finally {
      setLimitOrderLoading(false);
    }
  };

  // 計算限價訂單的獲得數量
  const estimateLimitOrderOutput = async (inputAmount, targetPrice, isLimitBuy) => {
    if (!window._web3Instance || !inputAmount || !targetPrice) {
      return '0';
    }

    try {
      // 使用選擇的Web3實例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const network = await findCurrentNetwork(web3);

      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      // 獲取限價訂單管理器配置
      const limitOrderConfig = LIMIT_ORDER_MANAGER_CONFIG[chainId.toString()];
      if (!limitOrderConfig || limitOrderConfig.address === '0x...') {
        throw new Error('當前網絡不支持限價訂單');
      }

      // 創建合約實例
      const limitOrderManager = new web3.eth.Contract(
        LIMIT_ORDER_MANAGER_ABI,
        limitOrderConfig.address
      );

      // 根據訂單類型設置代幣地址
      let tokenIn, tokenOut, inDecimals, outDecimals;
      
      if (isLimitBuy) {
        // 買入訂單: 支付 USDT/Native 獲得目標代幣
        tokenIn = paymentToken === NATIVE_TOKEN ? 
                CONSTANTS.ADDRESSES.ZERO : 
                network.stablecoins.USDT;
        tokenOut = tokenContract;
        
        inDecimals = paymentToken === NATIVE_TOKEN ? 18 : 
                   await getTokenDecimals(web3, network.stablecoins.USDT);
        outDecimals = 18; // 假設目標代幣精度為18
      } else {
        // 賣出訂單: 支付目標代幣獲得 USDT/Native
        tokenIn = tokenContract;
        tokenOut = paymentToken === NATIVE_TOKEN ? 
                 CONSTANTS.ADDRESSES.ZERO : 
                 network.stablecoins.USDT;
        
        inDecimals = 18; // 假設目標代幣精度為18
        outDecimals = paymentToken === NATIVE_TOKEN ? 18 : 
                    await getTokenDecimals(web3, network.stablecoins.USDT);
      }
      
      // 轉換輸入金額到代幣精度
      const amountInWei = toTokenDecimals(web3, inputAmount, inDecimals);
      
      // 使用價格預估輸出數量
      // 簡單計算: 對於買入訂單，輸出 = 輸入 / 價格; 對於賣出訂單，輸出 = 輸入 * 價格
      let estimatedOutput;
      if (isLimitBuy) {
        // 買入: 支付金額 / 價格 = 獲得代幣數量
        estimatedOutput = BigInt(amountInWei) * BigInt(10**18) / BigInt(web3.utils.toWei(targetPrice, 'ether'));
      } else {
        // 賣出: 賣出數量 * 價格 = 獲得金額
        estimatedOutput = BigInt(amountInWei) * BigInt(web3.utils.toWei(targetPrice, 'ether')) / BigInt(10**18);
      }
      
      // 考慮精度差異
      if (inDecimals !== outDecimals) {
        const adjustment = BigInt(10 ** Math.abs(inDecimals - outDecimals));
        estimatedOutput = inDecimals > outDecimals ? 
                        estimatedOutput / adjustment : 
                        estimatedOutput * adjustment;
      }
      
      // 轉換回顯示金額
      return fromTokenDecimals(web3, estimatedOutput.toString(), outDecimals);
    } catch (error) {
      console.error('Estimate limit order output error:', error);
      return '0';
    }
  };
// 池子選擇處理 - 这是导致问题的主要函数
  const handlePoolSelect = async (poolAddress) => {
    try {
      // 先设置池子地址，即使后面出错也至少UI上会显示选择
      setSelectedPool(poolAddress);
      if (!poolAddress) return;
  
      // V3協議不使用池子選擇
      if (selectedProtocol === PROTOCOLS.V3) return;
  
      // 获取web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      if (!web3 || !window._selectedDex) {
        throw new Error(ERRORS.NO_WEB3);
      }
  
      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }
  
      const pool = availablePools.find(p => p.pairAddress === poolAddress);
      if (!pool) {
        throw new Error(ERRORS.NO_POOL);
      }
  
      // 更新池子信息
      setPoolInfo({
        baseTokenAmount: pool.baseTokenDisplayAmount || '0',
        tokenAmount: pool.tokenDisplayAmount || '0',
        baseTokenValue: pool.baseTokenValue || '0'
      });
  
      // 检查授权状态时使用try-catch避免崩溃
      if (window._userAddresses?.[0]) {
        try {
          if (tradeType === 'buy') {
            if (paymentToken === NATIVE_TOKEN) {
              // Native token不需要檢查授權
              setRouterApprovals(prev => ({ ...prev, input: MAX_UINT256 }));
              setContractApprovals(prev => ({ ...prev, input: MAX_UINT256 }));
            } else {
              // 檢查USDT授權
              await checkApprovals(network.stablecoins.USDT, window._userAddresses[0], 'input');
            }
          } else {
            // 賣出時檢查合約代幣授權
            await checkApprovals(tokenContract, window._userAddresses[0], 'input');
          }
        } catch (approvalError) {
          console.error('Check approvals error:', approvalError);
          // 不阻止后续流程
        }
      }
  
      // 更新交易路徑 - 使用try-catch避免崩溃
      try {
        const pathResult = await buildTradePath(
          web3,
          network,
          pool,
          tokenContract,
          tradeType,
          paymentToken
        );
  
        if (pathResult) {
          setTradePath(pathResult.path);
          setTradeTokens(pathResult.tokens);
        }
      } catch (pathError) {
        console.error('Build path error:', pathError);
        setError(`构建交易路径失败: ${pathError.message}`);
        // 不阻止UI显示
      }
  
    } catch (error) {
      console.error('Pool select error:', error);
      setError(`选择池子失败: ${error.message}`);
      setDebugInfo(prev => ({
        ...prev,
        lastError: {
          location: 'handlePoolSelect',
          error: error.message,
          timestamp: new Date().toISOString()
        }
      }));
      // 即使出错也不会影响应用状态
    }
  };

  // 處理費率等級選擇 (僅V3)
  const handleFeeTierChange = async (newFeeTier) => {
    setSelectedFeeTier(newFeeTier);
    
    // 如果不是V3協議或沒有設置代幣合約，則退出
    if (selectedProtocol !== PROTOCOLS.V3 || !tokenContract || !window._web3Instance) {
      return;
    }
    
    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }
      
      // 重新構建交易路徑
      const result = await buildTradePath(
        web3,
        network,
        null, // V3不需要池子參數
        tokenContract,
        tradeType,
        paymentToken
      );
      
      if (result) {
        setTradePath(result.path);
        setTradeTokens(result.tokens);
      }
    } catch (error) {
      console.error('Fee tier change error:', error);
      setError(`费率等级变更失败: ${error.message}`);
      // 不影响界面状态
    }
  };

  // 處理限價訂單類型變更
  const handleLimitOrderTypeChange = (newType) => {
    setLimitOrderType(newType);
    setLimitOrderAmount('');
    setLimitOrderMinOutput('');
    // 不需要重置价格和截止时间
  };

  // 準備交易數據
  const prepareTransactionData = async (web3, chainId) => {
    try {
      if (!web3 || !chainId) {
        throw new Error(ERRORS.NO_WEB3);
      }

      const amounts = await getTransactionAmounts(web3);
      if (!amounts) {
        throw new Error(ERRORS.INVALID_AMOUNT);
      }

      // 記錄準備數據的Debug信息
      setDebugInfo(prev => ({
        ...prev,
        txPreparation: {
          amounts,
          path: tradePath,
          protocol: selectedProtocol,
          v3PathFees: selectedProtocol === PROTOCOLS.V3 ? v3PathFees : [],
          timestamp: new Date().toISOString()
        }
      }));

      if (selectedProtocol === PROTOCOLS.V2) {
        // V2協議交易
        if (useTaxCheck) {
          const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainId.toString()];
          if (!maikoRouterConfig || maikoRouterConfig.address === '0x...') {
            throw new Error('Tax check not supported on this network');
          }

          const maikoRouter = new web3.eth.Contract(
            MAIKO_ROUTER_ABI,
            maikoRouterConfig.address
          );

          if (tradeType === 'buy') {
            const isNativeInput = paymentToken === NATIVE_TOKEN;
            const txData = maikoRouter.methods.checkAndBuy(
              window._selectedDex.router,
              tradePath,
              isNativeInput,
              amounts.amountOutMin || '0',
              maxBuyTax * 100,
              maxSellTax * 100,
              amounts.amountIn || amounts.amountInMax
            ).encodeABI();

            const txValue = isNativeInput ? 
              (BigInt(TEST_AMOUNT) + BigInt(amounts.amountIn || amounts.amountInMax)).toString() :
              TEST_AMOUNT;

            return {
              to: maikoRouterConfig.address,
              data: txData,
              value: txValue
            };
          } else {
            const txData = maikoRouter.methods.checkAndSell(
              window._selectedDex.router,
              tradePath,
              paymentToken === NATIVE_TOKEN,
              amounts.amountOutMin || '0',
              maxBuyTax * 100,
              maxSellTax * 100,
              amounts.amountIn || amounts.amountInMax
            ).encodeABI();

            return {
              to: maikoRouterConfig.address,
              data: txData,
              value: TEST_AMOUNT // 賣出時只需要發送測試用的Native Token
            };
          }
        } else {
          // 普通DEX交易 (V2)
          const routerContract = new web3.eth.Contract(ABIS.ROUTER, window._selectedDex.router);
          const deadline = Math.floor(Date.now() / 1000) + 300; // 5分鐘

          let txData;
          let txValue = '0';

          if (tradeType === 'buy') {
            if (buyMode === BUY_MODES.EXACT_IN) {
              if (paymentToken === NATIVE_TOKEN) {
                txData = routerContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
                  amounts.amountOutMin,
                  tradePath,
                  window._userAddresses[0],
                  deadline
                ).encodeABI();
                txValue = amounts.amountIn;
              } else {
                txData = routerContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                  amounts.amountIn,
                  amounts.amountOutMin,
                  tradePath,
                  window._userAddresses[0],
                  deadline
                ).encodeABI();
              }
            } else {
              if (paymentToken === NATIVE_TOKEN) {
                txData = routerContract.methods.swapETHForExactTokens(
                  amounts.amountOut,
                  tradePath,
                  window._userAddresses[0],
                  deadline
                ).encodeABI();
                txValue = amounts.amountInMax;
              } else {
                txData = routerContract.methods.swapTokensForExactTokens(
                  amounts.amountOut,
                  amounts.amountInMax,
                  tradePath,
                  window._userAddresses[0],
                  deadline
                ).encodeABI();
              }
            }
          } else {
            if (paymentToken === NATIVE_TOKEN) {
              txData = routerContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
                amounts.amountIn,
                amounts.amountOutMin,
                tradePath,
                window._userAddresses[0],
                deadline
              ).encodeABI();
            } else {
              txData = routerContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amounts.amountIn,
                amounts.amountOutMin,
                tradePath,
                window._userAddresses[0],
                deadline
              ).encodeABI();
            }
          }

          return {
            to: window._selectedDex.router,
            data: txData,
            value: txValue
          };
        }
      } else if (selectedProtocol === PROTOCOLS.V3) {
        // V3協議交易
        const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
        if (!deployment) {
          throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
        }
        
        const routerContract = new web3.eth.Contract(ABIS.UNISWAPV3_ROUTER, deployment.router);
        const deadline = Math.floor(Date.now() / 1000) + 300; // 5分鐘
        
        let txData;
        let txValue = '0';
        
        // 編碼V3路徑
        const encodedPath = tradePath.length > 1 ? encodePathV3(tradePath, v3PathFees) : tradePath[0];
        
        if (tradeType === 'buy') {
          if (buyMode === BUY_MODES.EXACT_IN) {
            if (paymentToken === NATIVE_TOKEN) {
              // 使用exactInput方法，傳遞編碼的路徑
              const params = {
                path: encodedPath,
                recipient: window._userAddresses[0],
                deadline,
                amountIn: amounts.amountIn,
                amountOutMinimum: amounts.amountOutMin
              };
              
              txData = routerContract.methods.exactInput(params).encodeABI();
              txValue = amounts.amountIn;
            } else {
              // 使用exactInput方法，傳遞編碼的路徑
              const params = {
                path: encodedPath,
                recipient: window._userAddresses[0],
                deadline,
                amountIn: amounts.amountIn,
                amountOutMinimum: amounts.amountOutMin
              };
              
              txData = routerContract.methods.exactInput(params).encodeABI();
            }
          } else {
            if (paymentToken === NATIVE_TOKEN) {
              // 使用exactOutput方法，傳遞編碼的路徑
              const params = {
                path: encodedPath,
                recipient: window._userAddresses[0],
                deadline,
                amountOut: amounts.amountOut,
                amountInMaximum: amounts.amountInMax
              };
              
              txData = routerContract.methods.exactOutput(params).encodeABI();
              txValue = amounts.amountInMax;
            } else {
              // 使用exactOutput方法，傳遞編碼的路徑
              const params = {
                path: encodedPath,
                recipient: window._userAddresses[0],
                deadline,
                amountOut: amounts.amountOut,
                amountInMaximum: amounts.amountInMax
              };
              
              txData = routerContract.methods.exactOutput(params).encodeABI();
            }
          }
        } else {
          // 賣出邏輯
          if (paymentToken === NATIVE_TOKEN) {
            // 使用exactInput方法並unwrapWETH9
            const params = {
              path: encodedPath,
              recipient: deployment.router, // 發送到路由器，然後轉換為ETH
              deadline,
              amountIn: amounts.amountIn,
              amountOutMinimum: amounts.amountOutMin
            };
            
            // 使用multicall組合exactInput和unwrapWETH9操作
            const exactInputData = routerContract.methods.exactInput(params).encodeABI();
            const unwrapData = routerContract.methods.unwrapWETH9(
              amounts.amountOutMin,
              window._userAddresses[0]
            ).encodeABI();
            
            txData = routerContract.methods.multicall([exactInputData, unwrapData]).encodeABI();
          } else {
            // 簡單的代幣到代幣交換
            const params = {
              path: encodedPath,
              recipient: window._userAddresses[0],
              deadline,
              amountIn: amounts.amountIn,
              amountOutMinimum: amounts.amountOutMin
            };
            
            txData = routerContract.methods.exactInput(params).encodeABI();
          }
        }
        
        return {
          to: deployment.router,
          data: txData,
          value: txValue
        };
      } else {
        throw new Error('未知協議');
      }
    } catch (error) {
      console.error('Prepare transaction error:', error);
      setDebugInfo(prev => ({
        ...prev,
        lastError: {
          location: 'prepareTransactionData',
          error: error.message,
          timestamp: new Date().toISOString()
        }
      }));
      throw error;
    }
  };

  // 執行交易
  const executeTrade = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    if (selectedProtocol === PROTOCOLS.V2 && !selectedPool) {
      setError(ERRORS.NO_POOL);
      return;
    }

    if (!tradePath || tradePath.length === 0) {
      setError("无效的交易路径，请重新选择池子");
      return;
    }

    setLoading(true);
    setError('');

    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const userAddress = window._userAddresses[0];
      const chainId = await web3.eth.getChainId();
      const network = await findCurrentNetwork(web3);

      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      // 獲取Uniswap V3部署信息
      let v3RouterAddress = '';
      if (selectedProtocol === PROTOCOLS.V3) {
        const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
        if (!deployment) {
          throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
        }
        v3RouterAddress = deployment.router;
      }

      // 授權檢查
      if (tradeType === 'sell' || (tradeType === 'buy' && paymentToken !== NATIVE_TOKEN)) {
        const tokenToCheck = tradeType === 'sell' ? tokenContract : network.stablecoins.USDT;
        const approvals = await checkApprovals(tokenToCheck, userAddress);
        
        if (useTaxCheck && selectedProtocol === PROTOCOLS.V2) {
          if (!approvals.contractApproved) {
            setShowApprovalDialog(true);
            setLoading(false);
            return;
          }
        } else {
          if (!approvals.routerApproved) {
            setShowApprovalDialog(true);
            setLoading(false);
            return;
          }
        }
      }

      // 準備交易數據
      const txData = await prepareTransactionData(web3, chainId);
      
      // 構建完整交易對象
      const tx = {
        from: userAddress,
        to: txData.to,
        data: txData.data,
        value: txData.value,
        gas: gasLimit,
        gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
      };

      // 記錄交易Debug信息
      setDebugInfo(prev => ({
        ...prev,
        lastTransaction: {
          ...tx,
          protocol: selectedProtocol,
          useAntiMev: useAntiMev,
          antiMevRpc: selectedAntiMevRpc,
          timestamp: new Date().toISOString()
        }
      }));

      console.log('Sending transaction:', tx);
      console.log('Protocol:', selectedProtocol);
      console.log('Using Anti-MEV:', useAntiMev ? 'Yes' : 'No');
      if (useAntiMev) {
        console.log('Anti-MEV RPC:', selectedAntiMevRpc);
      }
      
      const receipt = await web3.eth.sendTransaction(tx);
      console.log('Transaction receipt:', receipt);

      // 更新結果
      setResult({
        success: true,
        message: '交易成功',
        hash: receipt.transactionHash
      });

      // 清空輸入
      if (tradeType === 'buy') {
        if (buyMode === BUY_MODES.EXACT_IN) {
          setInputAmount('');
          setMinOutput('');
        } else {
          setDesiredOutAmount('');
          setMaxInAmount('');
        }
      } else {
        setInputAmount('');
        setMinOutput('');
      }

    } catch (error) {
      console.error('Trade execution error:', error);
      setError(error.message);
      setResult({
        success: false,
        message: error.message
      });
    } finally {
      setLoading(false);
    }
  };
// V2檢查交易池和構建初始數據
  const checkPoolsV2 = async () => {
    if (!tokenContract || !window._web3Instance || !window._selectedDex) {
      setError(ERRORS.NO_WEB3);
      return;
    }

    setLoading(true);
    setError(''); // 清除之前的错误信息
    
    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      
      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }

      if (!web3.utils.isAddress(tokenContract)) {
        throw new Error('Invalid token contract address format');
      }

      const code = await web3.eth.getCode(tokenContract);
      if (code === '0x' || code === '0x0') {
        throw new Error('No contract code at address');
      }

      // 獲取目標代幣的精度和USDT精度
      const targetTokenContract = new web3.eth.Contract(ABIS.ERC20, tokenContract);
      const usdtContract = new web3.eth.Contract(ABIS.ERC20, network.stablecoins.USDT);
      
      let targetTokenDecimals, usdtDecimals;
      
      try {
        // 使用try-catch分别处理每个调用，防止一个失败导致整个流程中断
        targetTokenDecimals = await targetTokenContract.methods.decimals().call();
      } catch (error) {
        console.error('Failed to get target token decimals:', error);
        targetTokenDecimals = 18; // 默认值
      }
      
      try {
        usdtDecimals = await usdtContract.methods.decimals().call();
      } catch (error) {
        console.error('Failed to get USDT decimals:', error);
        usdtDecimals = 18; // 默认值
      }

      console.log('Token decimals:', {
        targetTokenDecimals: Number(targetTokenDecimals),
        usdtDecimals: Number(usdtDecimals),
        chainId: network.chainId
      });

      const factoryContract = new web3.eth.Contract(ABIS.FACTORY, window._selectedDex.factory);
      const pools = [];

      // 檢查 USDT 池子
      if (network.stablecoins?.USDT) {
        try {
          console.log('Checking USDT pair:', network.stablecoins.USDT);
          const usdtPair = await factoryContract.methods.getPair(
            tokenContract,
            network.stablecoins.USDT
          ).call();

          if (usdtPair && usdtPair !== CONSTANTS.ADDRESSES.ZERO) {
            try {
              const pairContract = new web3.eth.Contract(PAIR_ABI, usdtPair);
              
              let token0, token1, reserves;
              try {
                token0 = await pairContract.methods.token0().call();
                token1 = await pairContract.methods.token1().call();
                reserves = await pairContract.methods.getReserves().call();
              } catch (pairError) {
                console.error('Failed to get pair data:', pairError);
                // 继续检查其他池子，不中断流程
                throw new Error('无法获取USDT交易对数据');
              }

              let token0Info, token1Info;
              try {
                token0Info = await getTokenInfo(web3, token0);
                token1Info = await getTokenInfo(web3, token1);
              } catch (tokenInfoError) {
                console.error('Failed to get token info:', tokenInfoError);
                // 使用默认值
                token0Info = { symbol: token0.slice(0, 6) + '...', decimals: 18 };
                token1Info = { symbol: token1.slice(0, 6) + '...', decimals: 18 };
              }

              const isToken0 = tokenContract.toLowerCase() === token0.toLowerCase();
              const tokenAmount = isToken0 ? reserves._reserve0 : reserves._reserve1;
              const baseTokenAmount = isToken0 ? reserves._reserve1 : reserves._reserve0;

              // 使用實際獲取的 USDT 精度進行計算
              const baseTokenDisplayAmount = web3.utils.toBN(baseTokenAmount)
                .div(web3.utils.toBN(10).pow(web3.utils.toBN(usdtDecimals)))
                .toString();

              const tokenDisplayAmount = web3.utils.toBN(tokenAmount)
                .div(web3.utils.toBN(10).pow(web3.utils.toBN(targetTokenDecimals)))
                .toString();

              console.log('USDT Pool calculation:', {
                baseTokenAmount,
                usdtDecimals: Number(usdtDecimals),
                baseTokenDisplayAmount,
                tokenDisplayAmount
              });

              pools.push({
                baseToken: 'USDT',
                baseTokenAddress: network.stablecoins.USDT,
                pairAddress: usdtPair,
                token0,
                token1,
                token0Info,
                token1Info,
                reserve0: reserves._reserve0,
                reserve1: reserves._reserve1,
                tokenAmount,
                baseTokenAmount,
                baseTokenDisplayAmount,
                tokenDisplayAmount,
                baseTokenValue: baseTokenDisplayAmount,
                isToken0,
                tokenDecimals: Number(targetTokenDecimals)
              });
            } catch (err) {
              console.error('Failed to get USDT pair info:', err);
              // 继续流程，不中断
            }
          }
        } catch (err) {
          console.error('Failed to check USDT pair:', err);
          // 继续流程，不中断
        }
      }

      // 檢查 Wrapped Native 池子
      if (network.contracts?.wrappedNative) {
        try {
          console.log('Checking Wrapped Native pair:', network.contracts.wrappedNative);
          const wrappedPair = await factoryContract.methods.getPair(
            tokenContract,
            network.contracts.wrappedNative
          ).call();

          if (wrappedPair && wrappedPair !== CONSTANTS.ADDRESSES.ZERO) {
            try {
              const pairContract = new web3.eth.Contract(PAIR_ABI, wrappedPair);
              
              let token0, token1, reserves;
              try {
                token0 = await pairContract.methods.token0().call();
                token1 = await pairContract.methods.token1().call();
                reserves = await pairContract.methods.getReserves().call();
              } catch (pairError) {
                console.error('Failed to get wrapped pair data:', pairError);
                throw new Error('无法获取原生代币交易对数据');
              }

              let token0Info, token1Info;
              try {
                token0Info = await getTokenInfo(web3, token0);
                token1Info = await getTokenInfo(web3, token1);
              } catch (tokenInfoError) {
                console.error('Failed to get token info:', tokenInfoError);
                // 使用默认值
                token0Info = { symbol: token0.slice(0, 6) + '...', decimals: 18 };
                token1Info = { symbol: token1.slice(0, 6) + '...', decimals: 18 };
              }

              const isToken0 = tokenContract.toLowerCase() === token0.toLowerCase();
              const tokenAmount = isToken0 ? reserves._reserve0 : reserves._reserve1;
              const baseTokenAmount = isToken0 ? reserves._reserve1 : reserves._reserve0;

              // 計算 Native Token 顯示數量
              const baseTokenDisplayAmount = web3.utils.toBN(baseTokenAmount)
                .div(web3.utils.toBN(10).pow(web3.utils.toBN(18)))
                .toString();

              // 計算目標代幣顯示數量
              const tokenDisplayAmount = web3.utils.toBN(tokenAmount)
                .div(web3.utils.toBN(10).pow(web3.utils.toBN(targetTokenDecimals)))
                .toString();

              // 獲取 Native Token 的 USDT 價格
              let baseTokenValue = '0';
              try {
                const wrappedUsdtPair = await factoryContract.methods
                  .getPair(network.contracts.wrappedNative, network.stablecoins.USDT)
                  .call();
                
                if (wrappedUsdtPair && wrappedUsdtPair !== CONSTANTS.ADDRESSES.ZERO) {
                  const usdtPairContract = new web3.eth.Contract(PAIR_ABI, wrappedUsdtPair);
                  const [wrappedToken0, wrappedReserves] = await Promise.all([
                    usdtPairContract.methods.token0().call(),
                    usdtPairContract.methods.getReserves().call()
                  ]);

                  const isWrappedToken0 = network.contracts.wrappedNative.toLowerCase() === wrappedToken0.toLowerCase();
                  const usdtReserves = isWrappedToken0 ? wrappedReserves._reserve1 : wrappedReserves._reserve0;
                  const nativeReserves = isWrappedToken0 ? wrappedReserves._reserve0 : wrappedReserves._reserve1;

                  // 使用實際的 USDT 精度計算 Native Token 價格
                  const priceInUSDT = web3.utils.toBN(usdtReserves)
                    .mul(web3.utils.toBN(web3.utils.toWei('1', 'ether')))
                    .div(web3.utils.toBN(nativeReserves))
                    .div(web3.utils.toBN(10).pow(web3.utils.toBN(usdtDecimals)))
                    .toString();

                  console.log('Native Token price calculation:', {
                    usdtReserves,
                    nativeReserves,
                    usdtDecimals: Number(usdtDecimals),
                    priceInUSDT
                  });

                  // 計算總價值
                  baseTokenValue = (Number(baseTokenDisplayAmount) * Number(priceInUSDT)).toFixed(2);
                }
              } catch (priceError) {
                console.error('Failed to get native token price:', priceError);
                // 继续流程，使用默认价值
              }

              console.log('Native Token Pool calculation:', {
                baseTokenDisplayAmount,
                tokenDisplayAmount,
                baseTokenValue
              });

              pools.push({
                baseToken: 'WRAPPED',
                baseTokenAddress: network.contracts.wrappedNative,
                pairAddress: wrappedPair,
                token0,
                token1,
                token0Info,
                token1Info,
                reserve0: reserves._reserve0,
                reserve1: reserves._reserve1,
                tokenAmount,
                baseTokenAmount,
                baseTokenDisplayAmount,
                tokenDisplayAmount,
                baseTokenValue,
                isToken0,
                tokenDecimals: Number(targetTokenDecimals)
              });
            } catch (err) {
              console.error('Failed to get Wrapped pair info:', err);
              // 继续流程，不中断
            }
          }
        } catch (err) {
          console.error('Failed to check wrapped token pair:', err);
          // 继续流程，不中断
        }
      }

      if (pools.length === 0) {
        throw new Error('No available V2 trading pools found');
      }

      // 為每個池子添加原生代幣符號
      pools.forEach(pool => {
        pool.nativeSymbol = network.nativeCurrency.symbol;
      });

      // 在设置池子列表前先重置选择的池子
      setSelectedPool('');
      setAvailablePools(pools);
      console.log('Final V2 pools:', pools);

    } catch (error) {
      console.error('Check V2 pools error:', error);
      setError(`检查V2池子失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // V3檢查可用池子和費率
  const checkPoolsV3 = async () => {
    if (!tokenContract || !window._web3Instance) {
      setError(ERRORS.NO_WEB3);
      return;
    }
    
    setLoading(true);
    setError(''); // 清除之前的错误信息
    
    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const chainId = await web3.eth.getChainId();
      
      // 檢查當前鏈是否支持V3
      if (!isUniswapV3Supported(chainId)) {
        throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
      }
      
      const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
      const network = await findCurrentNetwork(web3);
      
      if (!network) {
        throw new Error(ERRORS.NETWORK_ERROR);
      }
      
      if (!web3.utils.isAddress(tokenContract)) {
        throw new Error('Invalid token contract address format');
      }
      
      const code = await web3.eth.getCode(tokenContract);
      if (code === '0x' || code === '0x0') {
        throw new Error('No contract code at address');
      }
      
      // 獲取目標代幣的基本信息
      let targetTokenInfo;
      try {
        targetTokenInfo = await getTokenInfo(web3, tokenContract);
      } catch (error) {
        console.error('Failed to get token info:', error);
        targetTokenInfo = { symbol: '???', decimals: 18, name: '' };
      }
      
      console.log('Target token info:', targetTokenInfo);
      
      // 可用的費率等級
      const feeTiers = ['500', '3000', '10000'];
      const availableFees = [];
      const v3Pools = [];
      
      // 檢查與USDT的池子
      for (const fee of feeTiers) {
        try {
          const poolExists = await checkV3PoolExists(
            web3,
            deployment.factory,
            tokenContract,
            network.stablecoins.USDT,
            fee
          );
          
          if (poolExists) {
            availableFees.push(fee);
            
            const poolInfo = await getV3PoolInfo(
              web3,
              deployment.factory,
              tokenContract,
              network.stablecoins.USDT,
              fee
            );
            
            if (poolInfo) {
              v3Pools.push({
                ...poolInfo,
                baseToken: 'USDT',
                baseTokenAddress: network.stablecoins.USDT
              });
            }
          }
        } catch (error) {
          console.error(`Failed to check V3 USDT pool with fee ${fee}:`, error);
          // 继续检查其他费率
        }
      }
      
      // 檢查與WrappedNative的池子
      for (const fee of feeTiers) {
        try {
          const poolExists = await checkV3PoolExists(
            web3,
            deployment.factory,
            tokenContract,
            network.contracts.wrappedNative,
            fee
          );
          
          if (poolExists) {
            if (!availableFees.includes(fee)) {
              availableFees.push(fee);
            }
            
            const poolInfo = await getV3PoolInfo(
              web3,
              deployment.factory,
              tokenContract,
              network.contracts.wrappedNative,
              fee
            );
            
            if (poolInfo) {
              v3Pools.push({
                ...poolInfo,
                baseToken: 'WRAPPED',
                baseTokenAddress: network.contracts.wrappedNative
              });
            }
          }
        } catch (error) {
          console.error(`Failed to check V3 Wrapped pool with fee ${fee}:`, error);
          // 继续检查其他费率
        }
      }
      
      if (availableFees.length === 0) {
        throw new Error('No V3 pools found for this token');
      }
      
      // 按費率排序
      availableFees.sort((a, b) => parseInt(a) - parseInt(b));
      
      // 設置可用的費率和池子
      setAvailableFeeTiers(availableFees);
      setV3Pools(v3Pools);
      
      // 如果當前選擇的費率不在可用列表中，選擇預設費率
      if (!availableFees.includes(selectedFeeTier)) {
        // 優先選擇中等費率 (3000)，其次是可用的第一個費率
        const defaultFee = availableFees.includes('3000') ? '3000' : availableFees[0];
        setSelectedFeeTier(defaultFee);
      }
      
      console.log('Available V3 fee tiers:', availableFees);
      console.log('V3 pools:', v3Pools);
      
      // 檢查所有相關代幣的授權狀態
      if (window._userAddresses?.[0]) {
        try {
          if (tradeType === 'buy') {
            if (paymentToken === NATIVE_TOKEN) {
              // Native token不需要檢查授權
              setRouterApprovals(prev => ({ ...prev, input: MAX_UINT256 }));
              setContractApprovals(prev => ({ ...prev, input: MAX_UINT256 }));
            } else {
              // 檢查USDT授權
              await checkApprovals(network.stablecoins.USDT, window._userAddresses[0], 'input');
            }
          } else {
            // 賣出時檢查合約代幣授權
            await checkApprovals(tokenContract, window._userAddresses[0], 'input');
          }
        } catch (approvalError) {
          console.error('Check approvals error:', approvalError);
          // 不阻止后续流程
        }
      }
      
      // 建立交易路徑
      try {
        const pathResult = await buildTradePath(
          web3,
          network,
          null, // V3不需要池子參數
          tokenContract,
          tradeType,
          paymentToken
        );
        
        if (pathResult) {
          setTradePath(pathResult.path);
          setTradeTokens(pathResult.tokens);
        }
      } catch (pathError) {
        console.error('Build path error:', pathError);
        setError(`构建交易路径失败: ${pathError.message}`);
        // 不阻止后续UI渲染
      }
      
    } catch (error) {
      console.error('Check V3 pools error:', error);
      setError(`检查V3池子失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // 統一檢查池子函數
  const checkPools = async () => {
    // 清除之前的状态
    setError('');
    setTradePath([]);
    setTradeTokens(new Map());
    
    if (selectedProtocol === PROTOCOLS.V2) {
      await checkPoolsV2();
    } else if (selectedProtocol === PROTOCOLS.V3) {
      await checkPoolsV3();
    } else {
      setError('未知協議');
    }
  };
// 授權相關函數
  const handleApprove = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    setLoading(true);
    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const amount = approveAmount ? 
        web3.utils.toWei(approveAmount, 'ether') : 
        MAX_UINT256;

      await tokenContract.methods.approve(
        spenderAddress,
        amount
      ).send({
        from: window._userAddresses[0],
        gas: gasLimit,
        gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
      });

      setResult({
        success: true,
        message: '授權成功'
      });

      // 清空輸入
      setTokenAddress('');
      setSpenderAddress('');
      setApproveAmount('');

    } catch (error) {
      console.error('Approve error:', error);
      setError(error.message);
      setResult({
        success: false,
        message: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  // 處理自定義交易
  const handleCustomTransaction = async () => {
    if (!window._web3Instance || !window._userAddresses) {
      setError(ERRORS.NO_WALLET);
      return;
    }

    setLoading(true);
    try {
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      
      const tx = {
        from: window._userAddresses[0],
        to: customAddress,
        data: customData || '0x',
        value: customValue ? web3.utils.toWei(customValue, 'ether') : '0',
        gas: gasLimit,
        gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
      };

      const receipt = await web3.eth.sendTransaction(tx);

      setResult({
        success: true,
        message: '交易成功',
        hash: receipt.transactionHash
      });

      // 清空輸入
      setCustomAddress('');
      setCustomData('');
      setCustomValue('0');

    } catch (error) {
      console.error('Custom tx error:', error);
      setError(error.message);
      setResult({
        success: false,
        message: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  // 批准Router使用代幣
  const approveRouter = async (tokenAddress, userAddress) => {
    try {
      if (!window._web3Instance) {
        throw new Error(ERRORS.NO_WEB3);
      }

      setLoading(true);
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      
      // 根據選擇的協議獲取路由器地址
      let routerAddress = '';
      
      if (selectedProtocol === PROTOCOLS.V2 && window._selectedDex) {
        routerAddress = window._selectedDex.router;
      } else if (selectedProtocol === PROTOCOLS.V3) {
        const chainId = await web3.eth.getChainId();
        const deployment = UNISWAP_V3_DEPLOYMENT[chainId.toString()];
        if (!deployment) {
          throw new Error(ERRORS.UNSUPPORTED_PROTOCOL);
        }
        routerAddress = deployment.router;
      }
      
      if (!routerAddress) {
        throw new Error('找不到路由器地址');
      }

      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const tx = await tokenContract.methods
        .approve(routerAddress, MAX_UINT256)
        .send({
          from: userAddress,
          gas: gasLimit,
          gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
        });

      await checkApprovals(tokenAddress, userAddress);
      return tx;
    } catch (err) {
      console.error('Approve router error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 批准合約使用代幣
  const approveContract = async (tokenAddress, userAddress) => {
    try {
      if (!window._web3Instance) {
        throw new Error(ERRORS.NO_WEB3);
      }

      setLoading(true);
      // 使用选择的Web3实例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainId.toString()];

      if (!maikoRouterConfig || maikoRouterConfig.address === '0x...') {
        throw new Error('当前网络不支持税率检测合约');
      }

      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const tx = await tokenContract.methods
        .approve(maikoRouterConfig.address, MAX_UINT256)
        .send({
          from: userAddress,
          gas: gasLimit,
          gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
        });

      await checkApprovals(tokenAddress, userAddress);
      return tx;
    } catch (err) {
      console.error('Approve contract error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 批准限價訂單合約使用代幣
  const approveLimitOrderContract = async (tokenAddress, userAddress) => {
    try {
      if (!window._web3Instance) {
        throw new Error(ERRORS.NO_WEB3);
      }

      setLimitOrderLoading(true);
      // 使用選擇的Web3實例
      const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
      const chainId = await web3.eth.getChainId();
      const limitOrderConfig = LIMIT_ORDER_MANAGER_CONFIG[chainId.toString()];

      if (!limitOrderConfig || limitOrderConfig.address === '0x...') {
        throw new Error('當前網絡不支持限價訂單合約');
      }

      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const tx = await tokenContract.methods
        .approve(limitOrderConfig.address, MAX_UINT256)
        .send({
          from: userAddress,
          gas: gasLimit,
          gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
        });

      return tx;
    } catch (err) {
      console.error('Approve limit order contract error:', err);
      throw err;
    } finally {
      setLimitOrderLoading(false);
    }
  };

  // 进一步修复池数据闪烁问题的Hook函数
  const usePoolRefresh = (web3, pool, chainId) => {
    // 初始化状态时直接使用pool的基础数据，避免从0开始
    const [poolData, setPoolData] = React.useState({
      baseTokenDisplayAmount: pool?.baseTokenDisplayAmount || '0',
      tokenDisplayAmount: pool?.tokenDisplayAmount || '0',
      baseTokenValue: pool?.baseTokenValue || '0',
      tokenPrice: '0.00' // 初始价格设置
    });

    // 使用ref来存储最后一次有效的数据，避免数据闪烁
    const lastValidDataRef = React.useRef({
      baseTokenDisplayAmount: pool?.baseTokenDisplayAmount || '0',
      tokenDisplayAmount: pool?.tokenDisplayAmount || '0',
      baseTokenValue: pool?.baseTokenValue || '0',
      tokenPrice: '0.00'
    });

    // 添加初始化标志，确保初始数据被正确设置
    const initializedRef = React.useRef(false);

    // 初始化函数
    const initializeData = React.useCallback(() => {
      if (pool && !initializedRef.current) {
        const initialData = {
          baseTokenDisplayAmount: pool.baseTokenDisplayAmount || '0',
          tokenDisplayAmount: pool.tokenDisplayAmount || '0',
          baseTokenValue: pool.baseTokenValue || '0',
          tokenPrice: '0.00'
        };
        
        lastValidDataRef.current = initialData;
        setPoolData(initialData);
        initializedRef.current = true;
        
        console.log("初始化池数据:", initialData);
      }
    }, [pool]);

    // 在组件挂载和pool变化时初始化数据
    React.useEffect(() => {
      initializeData();
    }, [initializeData]);

    const refreshPoolData = React.useCallback(async () => {
      if (!web3 || !pool) return;

      try {
        const pairContract = new web3.eth.Contract(PAIR_ABI, pool.pairAddress);
        let reserves;
        try {
          reserves = await pairContract.methods.getReserves().call();
        } catch (error) {
          console.error('Failed to get reserves:', error);
          return; // 如果无法获取reserves，退出刷新但保留最后的有效数据
        }

        const tokenAmount = pool.isToken0 ? reserves._reserve0 : reserves._reserve1;
        const baseTokenAmount = pool.isToken0 ? reserves._reserve1 : reserves._reserve0;

        // 計算顯示金額
        let baseTokenDisplayAmount, tokenDisplayAmount;
        try {
          baseTokenDisplayAmount = web3.utils.toBN(baseTokenAmount)
            .div(web3.utils.toBN(10).pow(web3.utils.toBN(pool.baseToken === 'WRAPPED' ? 18 : pool.token1Info.decimals)))
            .toString();

          tokenDisplayAmount = web3.utils.toBN(tokenAmount)
            .div(web3.utils.toBN(10).pow(web3.utils.toBN(pool.tokenDecimals)))
            .toString();
        } catch (error) {
          console.error('Failed to calculate display amounts:', error);
          // 使用上次有效的数据而不是设置为0
          baseTokenDisplayAmount = lastValidDataRef.current.baseTokenDisplayAmount;
          tokenDisplayAmount = lastValidDataRef.current.tokenDisplayAmount;
        }

        // 如果是Wrapped池，需要獲取Native Token價格
        let baseTokenValue = lastValidDataRef.current.baseTokenValue; // 默认使用上次有效的值
        if (pool.baseToken === 'WRAPPED') {
          try {
            const factory = new web3.eth.Contract(ABIS.FACTORY, window._selectedDex.factory);
            const network = await findCurrentNetwork(web3);
            const wrappedUsdtPair = await factory.methods
              .getPair(network.contracts.wrappedNative, network.stablecoins.USDT)
              .call();

            if (wrappedUsdtPair && wrappedUsdtPair !== CONSTANTS.ADDRESSES.ZERO) {
              const usdtPairContract = new web3.eth.Contract(PAIR_ABI, wrappedUsdtPair);
              const wrappedReserves = await usdtPairContract.methods.getReserves().call();
              const token0 = await usdtPairContract.methods.token0().call();

              const isWrappedToken0 = network.contracts.wrappedNative.toLowerCase() === token0.toLowerCase();
              const usdtReserves = isWrappedToken0 ? wrappedReserves._reserve1 : wrappedReserves._reserve0;
              const nativeReserves = isWrappedToken0 ? wrappedReserves._reserve0 : wrappedReserves._reserve1;

              // 使用安全的计算方式
              try {
                const priceInUSDT = web3.utils.toBN(usdtReserves)
                  .mul(web3.utils.toBN(web3.utils.toWei('1', 'ether')))
                  .div(web3.utils.toBN(nativeReserves))
                  .div(web3.utils.toBN(10).pow(web3.utils.toBN(pool.token1Info.decimals)))
                  .toString();

                // 检查计算结果是否有效，如果无效则使用上次有效的值
                const newBaseTokenValue = (Number(baseTokenDisplayAmount) * Number(priceInUSDT)).toFixed(2);
                if (!isNaN(newBaseTokenValue) && Number(newBaseTokenValue) > 0) {
                  baseTokenValue = newBaseTokenValue;
                }
              } catch (calcError) {
                console.error('Price calculation error:', calcError);
                // 保留上次的有效值
              }
            }
          } catch (error) {
            console.error('Failed to get native token price:', error);
            // 保留上次的有效值
          }
        } else {
          baseTokenValue = baseTokenDisplayAmount;
        }

        // 計算代幣價格 - 使用安全计算，确保显示正确价格
        let tokenPrice = lastValidDataRef.current.tokenPrice; // 默认使用上次有效的值
        try {
          if (Number(tokenDisplayAmount) > 0 && Number(baseTokenValue) > 0) {
            const calculatedPrice = Number(baseTokenValue) / Number(tokenDisplayAmount);
            if (!isNaN(calculatedPrice) && calculatedPrice > 0) {
              tokenPrice = calculatedPrice.toFixed(6);
              console.log(`计算的代币价格: ${tokenPrice} (基于 ${baseTokenValue} / ${tokenDisplayAmount})`);
            }
          }
        } catch (error) {
          console.error('Token price calculation error:', error);
          // 保留上次有效的价格
        }

        // 防止值为"0"导致闪烁
        const newData = {
          baseTokenDisplayAmount: Number(baseTokenDisplayAmount) > 0 ? baseTokenDisplayAmount : lastValidDataRef.current.baseTokenDisplayAmount,
          tokenDisplayAmount: Number(tokenDisplayAmount) > 0 ? tokenDisplayAmount : lastValidDataRef.current.tokenDisplayAmount,
          baseTokenValue: Number(baseTokenValue) > 0 ? baseTokenValue : lastValidDataRef.current.baseTokenValue,
          tokenPrice: Number(tokenPrice) > 0 ? tokenPrice : lastValidDataRef.current.tokenPrice
        };
        
        // 验证新数据的有效性
        const isValidData = 
          Number(newData.baseTokenDisplayAmount) > 0 && 
          Number(newData.tokenDisplayAmount) > 0 && 
          Number(newData.baseTokenValue) > 0;
        
        if (isValidData) {
          lastValidDataRef.current = newData;
          setPoolData(newData);
          console.log("更新池数据:", newData);
        } else {
          console.log("跳过无效数据更新:", newData);
        }

      } catch (error) {
        console.error('Pool refresh error:', error);
        // 出错时仍然使用最后一次有效的数据，不更新状态
      }
    }, [web3, pool]);

    React.useEffect(() => {
      if (!chainId || !web3 || !pool) return;

      // 初始刷新
      refreshPoolData();

      // 設置定時刷新 - 增加刷新间隔以减少API调用频率
      const interval = setInterval(
        refreshPoolData,
        CHAIN_REFRESH_INTERVALS[chainId] || 12000 // 默認12秒
      );

      return () => clearInterval(interval);
    }, [chainId, web3, pool, refreshPoolData]);

    return poolData;
  };
// 同样修复V3池数据的Hook函数
  const useV3PoolRefresh = (web3, poolAddress, tokenContract, baseTokenAddress, chainId) => {
    const [poolData, setPoolData] = React.useState({
      liquidity: '0',
      sqrtPriceX96: '0',
      tick: '0',
      tokenPrice: '0'
    });
    
    // 使用ref存储最后有效数据
    const lastValidDataRef = React.useRef({
      liquidity: '0',
      sqrtPriceX96: '0',
      tick: '0',
      tokenPrice: '0',
      isToken0: false,
      token0Symbol: '',
      token1Symbol: ''
    });
    
    // 添加初始化标志
    const initializedRef = React.useRef(false);
    
    const refreshPoolData = React.useCallback(async () => {
      if (!web3 || !poolAddress || !tokenContract || !baseTokenAddress) return;
      
      try {
        // 檢索池子信息
        const poolContract = new web3.eth.Contract(ABIS.UNISWAPV3_POOL, poolAddress);
        let slot0, liquidity, token0;
        
        try {
          // 分别try-catch每个调用，防止一个失败影响整体
          [slot0, liquidity, token0] = await Promise.all([
            poolContract.methods.slot0().call(),
            poolContract.methods.liquidity().call(),
            poolContract.methods.token0().call()
          ]);
        } catch (poolError) {
          console.error('Failed to get pool data:', poolError);
          return; // 如果无法获取基本数据，退出刷新但保留最后的有效数据
        }
        
        // 獲取代幣精度
        const tokenContractInstance = new web3.eth.Contract(ABIS.ERC20, tokenContract);
        const baseTokenContractInstance = new web3.eth.Contract(ABIS.ERC20, baseTokenAddress);
        
        let tokenDecimals, baseTokenDecimals, tokenSymbol, baseTokenSymbol;
        try {
          [tokenDecimals, baseTokenDecimals, tokenSymbol, baseTokenSymbol] = await Promise.all([
            tokenContractInstance.methods.decimals().call(),
            baseTokenContractInstance.methods.decimals().call(),
            tokenContractInstance.methods.symbol().call(),
            baseTokenContractInstance.methods.symbol().call()
          ]);
        } catch (tokenInfoError) {
          console.error('Failed to get token info:', tokenInfoError);
          // 使用默认值或上次有效值
          tokenDecimals = 18;
          baseTokenDecimals = 18;
          tokenSymbol = lastValidDataRef.current.token0Symbol || tokenContract.slice(0, 6) + '...';
          baseTokenSymbol = lastValidDataRef.current.token1Symbol || baseTokenAddress.slice(0, 6) + '...';
        }
        
        // 計算價格
        const sqrtPriceX96 = slot0.sqrtPriceX96;
        const isToken0 = tokenContract.toLowerCase() === token0.toLowerCase();
        
        // 安全计算价格
        let price = lastValidDataRef.current.tokenPrice; // 默认使用上次有效价格
        try {
          // sqrt(price) = sqrt(amount1/amount0) * 2^96
          if (isToken0) {
            // 如果目標代幣是token0，價格 = 1/price
            // price = amount1/amount0
            // price = (sqrtPriceX96/2^96)^2
            const sqrtPrice = BigInt(sqrtPriceX96);
            const q96 = BigInt(1) << BigInt(96);
            if (sqrtPrice > 0n) {
              price = ((sqrtPrice * sqrtPrice) / (q96 * q96)).toString();
              
              // 考慮精度差異
              if (Number(tokenDecimals) !== Number(baseTokenDecimals)) {
                const decimalsAdjustment = BigInt(10) ** BigInt(Math.abs(Number(baseTokenDecimals) - Number(tokenDecimals)));
                price = Number(tokenDecimals) > Number(baseTokenDecimals) 
                  ? (BigInt(price) * decimalsAdjustment).toString()
                  : (BigInt(price) / decimalsAdjustment).toString();
              }
            }
          } else {
            // 如果目標代幣是token1，價格 = price
            // price = amount0/amount1
            // price = 1/(sqrtPriceX96/2^96)^2
            const sqrtPrice = BigInt(sqrtPriceX96);
            const q96 = BigInt(1) << BigInt(96);
            
            if (sqrtPrice === BigInt(0)) {
              price = lastValidDataRef.current.tokenPrice; // 保留上次有效值
            } else {
              price = ((q96 * q96) / (sqrtPrice * sqrtPrice)).toString();
            }
            
            // 考慮精度差異
            if (Number(tokenDecimals) !== Number(baseTokenDecimals)) {
              const decimalsAdjustment = BigInt(10) ** BigInt(Math.abs(Number(baseTokenDecimals) - Number(tokenDecimals)));
              price = Number(tokenDecimals) > Number(baseTokenDecimals) 
                ? (BigInt(price) / decimalsAdjustment).toString()
                : (BigInt(price) * decimalsAdjustment).toString();
            }
          }
        } catch (priceError) {
          console.error('Price calculation error:', priceError);
          // 保留上次有效的价格
        }
        
        // 格式化為更易讀的數字
        let formattedPrice;
        try {
          formattedPrice = web3.utils.fromWei(price, 'ether');
          // 确保价格有效，否则使用上次有效价格
          if (isNaN(Number(formattedPrice)) || Number(formattedPrice) <= 0) {
            formattedPrice = lastValidDataRef.current.tokenPrice;
          }
          
          // 格式化价格为6位小数的字符串
          formattedPrice = Number(formattedPrice).toFixed(6);
          console.log(`V3池代币价格: ${formattedPrice}`);
        } catch (error) {
          console.error('Format price error:', error);
          formattedPrice = lastValidDataRef.current.tokenPrice;
        }
        
        // 构建新数据，确保不会显示无效值
        const newData = {
          liquidity: Number(liquidity) > 0 ? liquidity : lastValidDataRef.current.liquidity,
          sqrtPriceX96,
          tick: slot0.tick,
          tokenPrice: Number(formattedPrice) > 0 ? formattedPrice : lastValidDataRef.current.tokenPrice,
          isToken0,
          token0Symbol: isToken0 ? tokenSymbol : baseTokenSymbol,
          token1Symbol: isToken0 ? baseTokenSymbol : tokenSymbol
        };
        
        // 检查是否为有效数据
        const isValidData = 
          Number(newData.liquidity) > 0 && 
          slot0.tick && 
          Number(newData.tokenPrice) > 0;
        
        if (isValidData) {
          lastValidDataRef.current = newData;
          setPoolData(newData);
          initializedRef.current = true;
          console.log("更新V3池数据:", newData);
        } else {
          console.log("跳过无效V3池数据更新:", newData);
        }
        
      } catch (error) {
        console.error('V3 Pool refresh error:', error);
        // 出错时不更新状态，保持上一次的数据
      }
    }, [web3, poolAddress, tokenContract, baseTokenAddress]);
    
    React.useEffect(() => {
      if (!chainId || !web3 || !poolAddress) return;
      
      // 初始刷新
      refreshPoolData();
      
      // 設置定時刷新 - 增加刷新间隔以减少API调用频率
      const interval = setInterval(
        refreshPoolData,
        CHAIN_REFRESH_INTERVALS[chainId] || 12000 // 默認12秒
      );
      
      return () => clearInterval(interval);
    }, [chainId, web3, poolAddress, refreshPoolData]);
    
    return poolData;
  };

  // 渲染防MEV设置组件
  const renderAntiMevSettings = () => {
    if (!currentChainId) return null;
    
    // 只有在 ETH (1) 或 BSC (56) 链上显示防夹子选项
    if (currentChainId !== 1 && currentChainId !== 56) return null;
    
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <ShieldIcon sx={{ mr: 1 }} />
            防夾子設置 (ANTI-MEV)
            <Tooltip title="使用特殊的RPC保護您的交易免受MEV機器人夾子攻擊" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          
          <FormControlLabel
            control={
              <Switch
                checked={useAntiMev}
                onChange={(e) => {
                  setUseAntiMev(e.target.checked);
                  if (!e.target.checked) {
                    setSelectedAntiMevRpc('');
                    setAntiMevWeb3(null);
                  } else if (availableAntiMevRpcs.length > 0 && !selectedAntiMevRpc) {
                    // 自动选择第一个RPC
                    setSelectedAntiMevRpc(availableAntiMevRpcs[0].url);
                  }
                }}
              />
            }
            label="啟用防夾子"
          />
          
          {useAntiMev && availableAntiMevRpcs.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="anti-mev-rpc-label">選擇防夾子RPC</InputLabel>
                <Select
                  labelId="anti-mev-rpc-label"
                  value={selectedAntiMevRpc}
                  onChange={(e) => setSelectedAntiMevRpc(e.target.value)}
                  label="選擇防夾子RPC"
                >
                  {availableAntiMevRpcs.map((rpc, index) => (
                    <MenuItem key={index} value={rpc.url}>
                      {rpc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  // 渲染協議選擇選項卡
  const renderProtocolSelector = () => {
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          選擇交易協議
          <Tooltip title="選擇使用的Uniswap協議版本" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Tabs
          value={selectedProtocol}
          onChange={(e, newValue) => handleProtocolChange(newValue)}
          sx={{ mb: 1 }}
        >
          <Tab 
            value={PROTOCOLS.V2} 
            label="Uniswap V2" 
            disabled={loading}
          />
          <Tab 
            value={PROTOCOLS.V3} 
            label="Uniswap V3" 
            disabled={loading || !protocolSupported[PROTOCOLS.V3]}
          />
        </Tabs>
        {!protocolSupported[PROTOCOLS.V3] && selectedProtocol === PROTOCOLS.V2 && (
          <Alert severity="info" sx={{ mt: 1 }}>
            當前網絡不支持 Uniswap V3
          </Alert>
        )}
      </Box>
    );
  };

  // 渲染池子选择组件 (V2)
  const PoolSelector = ({ 
    availablePools, 
    selectedPool, 
    onSelect, 
    nativeSymbol,
    loading 
  }) => {
    if (availablePools.length === 0) return null;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          選擇交易池
          <Tooltip title="選擇要使用的流動性池" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Select
          fullWidth
          value={selectedPool}
          onChange={(e) => onSelect(e.target.value)}
          disabled={loading}
          sx={{ 
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }
          }}
        >
          <MenuItem value="">
            <Typography color="textSecondary">請選擇交易池</Typography>
          </MenuItem>
          {availablePools.map((pool) => (
            <MenuItem key={pool.pairAddress} value={pool.pairAddress}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '100%'
              }}>
                <Typography>
                  {pool.baseToken === 'WRAPPED' ? 
                    `Native Pool` : 
                    `${pool.baseToken} Pool`
                  }
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {pool.baseToken === 'WRAPPED' ?
                    `${formatAmount(pool.baseTokenDisplayAmount, 4)} ${nativeSymbol} ($${formatAmount(pool.baseTokenValue, 2)})` :
                    `$${formatAmount(pool.baseTokenValue, 2)}`
                  }
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  // 渲染V3費率選擇器
  const FeeTierSelector = ({
    availableFeeTiers,
    selectedFeeTier,
    onSelect,
    loading
  }) => {
    if (availableFeeTiers.length === 0) return null;
    
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          選擇費率等級
          <Tooltip title="選擇V3交易池的費率等級" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Select
          fullWidth
          value={selectedFeeTier}
          onChange={(e) => onSelect(e.target.value)}
          disabled={loading}
        >
          {availableFeeTiers.map((fee) => (
            <MenuItem key={fee} value={fee}>
              {UNISWAP_V3_FEES[fee] || `${parseInt(fee)/10000}%`}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };
// 修复后的PoolInfoDisplayV2组件
  const PoolInfoDisplayV2 = ({ selectedPool, availablePools, nativeSymbol }) => {
    const [chainId, setChainId] = React.useState(null);

    React.useEffect(() => {
      const getChainId = async () => {
        if (!window._web3Instance) return;
        try {
          const id = await window._web3Instance.eth.getChainId();
          setChainId(id);
        } catch (error) {
          console.error('Get chain ID error:', error);
        }
      };
      getChainId();
    }, []);

    if (!selectedPool) return null;

    const pool = availablePools.find(p => p.pairAddress === selectedPool);
    if (!pool) return null;

    // 使用选择的Web3实例
    const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
    const poolData = usePoolRefresh(web3, pool, chainId);

    // 添加调试输出
    console.log("PoolInfoDisplayV2 - Current pool data:", poolData);

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            V2 流動池資訊
            <Tooltip title="顯示當前選擇的交易池資訊，包括流動性和價值" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  基礎代幣數量
                </Typography>
                <Typography variant="h6">
                  {formatAmount(poolData.baseTokenDisplayAmount || pool.baseTokenDisplayAmount, 4)} {pool.baseToken === 'WRAPPED' ? pool.nativeSymbol : 'USDT'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  合約代幣數量
                </Typography>
                <Typography variant="h6">
                  {formatAmount(poolData.tokenDisplayAmount || pool.tokenDisplayAmount, 4)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  基礎代幣價值
                </Typography>
                <Typography variant="h6">
                  {pool.baseToken === 'WRAPPED' ? 
                    `${formatAmount(poolData.baseTokenDisplayAmount || pool.baseTokenDisplayAmount, 4)} ${pool.nativeSymbol} ($${formatAmount(poolData.baseTokenValue || pool.baseTokenValue, 2)})` :
                    `$${formatAmount(poolData.baseTokenValue || pool.baseTokenValue, 2)}`
                  }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  目標代幣價格
                </Typography>
                <Typography variant="h6">
                  ${poolData.tokenPrice && Number(poolData.tokenPrice) > 0 ? formatAmount(poolData.tokenPrice, 6) : '0.000000'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 同样修复V3池信息显示组件
  const PoolInfoDisplayV3 = ({ v3Pools, tokenContract, selectedFeeTier, nativeSymbol }) => {
    const [chainId, setChainId] = React.useState(null);
    
    React.useEffect(() => {
      const getChainId = async () => {
        if (!window._web3Instance) return;
        try {
          const id = await window._web3Instance.eth.getChainId();
          setChainId(id);
        } catch (error) {
          console.error('Get chain ID error:', error);
        }
      };
      getChainId();
    }, []);
    
    // 找到選定費率的池子
    const selectedPools = v3Pools.filter(p => p.fee.toString() === selectedFeeTier);
    if (selectedPools.length === 0) return null;
    
    // 優先選擇USDT池，其次是Wrapped池
    const usdtPool = selectedPools.find(p => p.baseToken === 'USDT');
    const wrappedPool = selectedPools.find(p => p.baseToken === 'WRAPPED');
    const pool = usdtPool || wrappedPool;
    
    if (!pool) return null;
    
    // 使用選擇的Web3實例
    const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
    const poolData = useV3PoolRefresh(web3, pool.poolAddress, tokenContract, pool.baseTokenAddress, chainId);
    
    // 添加调试输出
    console.log("PoolInfoDisplayV3 - Current pool data:", poolData);
    
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            V3 流動池資訊 ({UNISWAP_V3_FEES[selectedFeeTier]})
            <Tooltip title="顯示當前選擇的V3交易池資訊" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  流動性
                </Typography>
                <Typography variant="h6">
                  {formatAmount(poolData.liquidity || pool.liquidity || '0', 0)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  當前价格刻度
                </Typography>
                <Typography variant="h6">
                  {poolData.tick || pool.tick || '0'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: 'background.default',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  目標代幣價格
                </Typography>
                <Typography variant="h6">
                  ${poolData.tokenPrice && Number(poolData.tokenPrice) > 0 ? formatAmount(poolData.tokenPrice, 6) : '0.000000'}
                </Typography>
                {poolData.isToken0 !== undefined && poolData.token0Symbol && poolData.token1Symbol && (
                  <Typography variant="caption" color="textSecondary">
                    {poolData.isToken0 ? 
                      `1 ${poolData.token0Symbol} = ${formatAmount(poolData.tokenPrice, 6)} ${poolData.token1Symbol}` :
                      `1 ${poolData.token1Symbol} = ${formatAmount(poolData.tokenPrice, 6)} ${poolData.token0Symbol}`
                    }
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 渲染授权状态显示组件
  const ApprovalStatus = ({ 
    web3, 
    type,
    routerApprovals,
    contractApprovals,
    currentNetwork,
    label 
  }) => {
    if (!web3) return null;

    const chainId = currentNetwork?.chainId?.toString();
    const maikoConfig = MAIKO_ROUTER_CONFIG[chainId];

    // 获取正确的授权数值
    const routerApproval = type === 'input' ? routerApprovals.input : routerApprovals.output;
    const contractApproval = type === 'input' ? contractApprovals.input : contractApprovals.output;

    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          {label}授权状态
          <Tooltip title="显示目标代币授权状态" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: 1,
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Router授权数量:</span>
            <span>{Number(web3.utils.fromWei(routerApproval || '0', 'ether')).toLocaleString()}</span>
          </Typography>
          {maikoConfig ? (
            maikoConfig.address !== '0x...' ? (
              <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>检测合約授权数量:</span>
                <span>{Number(web3.utils.fromWei(contractApproval || '0', 'ether')).toLocaleString()}</span>
              </Typography>
            ) : (
              <Typography variant="body2" color="warning.main">
                检测合約授权数量: 未部署
              </Typography>
            )
          ) : null}
        </Box>
      </Box>
    );
  };

  // 渲染交易路径显示组件
  const TradePathDisplay = ({ path, tokens, v3PathFees = [], protocol }) => {
    if (!path || path.length === 0) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          交易路径 ({protocol === PROTOCOLS.V3 ? 'V3' : 'V2'})
          <Tooltip title="显示代币交换的完整路径，包括中间代币" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
          bgcolor: 'background.paper',
          p: 2,
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider'
        }}>
          {path.map((address, index) => {
            const token = tokens.get(address.toLowerCase());
            const symbol = token?.symbol || address.slice(0, 6);
            const fee = index < v3PathFees.length ? v3PathFees[index] : null;
            
            return (
              <React.Fragment key={address}>
                <Box 
                  sx={{
                    bgcolor: token?.isNative ? 'warning.light' : 
                           token?.isStable ? 'success.light' : 
                           'primary.light',
                    color: 'common.white',
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Typography variant="body2">
                    {symbol}
                  </Typography>
                  {token?.isNative && (
                    <Tooltip title="原生代币">
                      <Box component="span" sx={{ opacity: 0.8 }}>🔷</Box>
                    </Tooltip>
                  )}
                  {token?.isStable && (
                    <Tooltip title="稳定币">
                      <Box component="span" sx={{ opacity: 0.8 }}>💰</Box>
                    </Tooltip>
                  )}
                </Box>
                {index < path.length - 1 && (
                  <>
                    {fee && protocol === PROTOCOLS.V3 && (
                      <Tooltip title={`費率: ${UNISWAP_V3_FEES[fee] || fee}`}>
                        <Box sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>
                          {UNISWAP_V3_FEES[fee]?.replace('%', '') || (parseInt(fee)/10000)}%
                        </Box>
                      </Tooltip>
                    )}
                    <SwapHorizIcon color="action" />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    );
  };

  // 渲染Gas设置
  const renderGasSettings = () => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Gas Price (Gwei)"
              type="number"
              value={gasPrice}
              onChange={(e) => setGasPrice(e.target.value)}
              InputProps={{
                inputProps: { min: 0 }
              }}
              helperText={currentGasPrice ? `当前: ${currentGasPrice} Gwei` : 'Loading...'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Gas Limit"
              type="number"
              value={gasLimit}
              onChange={(e) => setGasLimit(e.target.value)}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="textSecondary">
              预估最大Gas Fee: {
                gasPrice && gasLimit ? 
                `${(Number(gasPrice) * Number(gasLimit) / 1e9).toFixed(6)} ${nativeSymbol}` : 
                'Please input Gas Price and Gas Limit'
              }
            </Typography>
            {debugInfo?.lastError && (
              <Typography variant="caption" color="error">
                Last Error: {debugInfo.lastError.message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
// 渲染交易设置组件
  const renderTradeSettings = () => (
    <Box>
      <RadioGroup
        value={tradeType}
        onChange={(e) => handleTradeTypeChange(e.target.value)}
        sx={{ mb: 2 }}
      >
        <FormControlLabel value="buy" control={<Radio />} label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            买入
            <Tooltip title="从流动池中购买代币" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        } />
        <FormControlLabel value="sell" control={<Radio />} label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            卖出
            <Tooltip title="将代币卖出到流动池" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        } />
      </RadioGroup>

      {tradeType === 'buy' && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            买入模式
            <Tooltip title="选择买入方式" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <RadioGroup
            value={buyMode}
            onChange={(e) => setBuyMode(e.target.value)}
          >
            <FormControlLabel 
              value={BUY_MODES.EXACT_IN} 
              control={<Radio />} 
              label="固定支付模式（输入固定数量A代币，得到最少B代币）" 
            />
            <FormControlLabel 
              value={BUY_MODES.EXACT_OUT} 
              control={<Radio />} 
              label="固定获得模式（要得到指定数量B代币，最多付出A代币）" 
            />
          </RadioGroup>
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          支付代币
          <Tooltip title="选择用于交易的代币类型" placement="right">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>
        <Select
          fullWidth
          value={paymentToken}
          onChange={(e) => handlePaymentTokenChange(e.target.value)}
        >
          {Object.entries(PAYMENT_TOKENS).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {key === NATIVE_TOKEN ? `${label} (${nativeSymbol})` : label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {useTaxCheck && selectedProtocol === PROTOCOLS.V2 && (
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
              税率设置
              <Tooltip title="设置最大可接受的买入和卖出税率" placement="right">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="最大买入税率 (%)"
                  type="number"
                  value={maxBuyTax}
                  onChange={(e) => setMaxBuyTax(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0, max: 100 }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="最大卖出税率 (%)"
                  type="number"
                  value={maxSellTax}
                  onChange={(e) => setMaxSellTax(e.target.value)}
                  InputProps={{
                    inputProps: { min: 0, max: 100 }
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      
      {selectedProtocol === PROTOCOLS.V2 && (
        <FormControlLabel
          control={
            <Switch
              checked={useTaxCheck}
              onChange={async (e) => {
                const newValue = e.target.checked;
                setUseTaxCheck(false);

                try {
                  const chainId = await window._web3Instance.eth.getChainId();
                  const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainId.toString()];

                  if (newValue && (!maikoRouterConfig || !maikoRouterConfig.address || maikoRouterConfig.address === '0x...')) {
                    setError('当前网络不支持税率检测');
                    return;
                  }

                  setUseTaxCheck(newValue);
                } catch (err) {
                  setError('检查网络支持状态时出错');
                  console.error(err);
                }
              }}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              税率检测 (仅V2)
              <Tooltip title="启用后会在交易前检测代币的买入卖出税率" placement="right">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          }
          sx={{ mb: 2, display: 'block' }}
        />
      )}
    </Box>
  );

  // 渲染交易金额输入组件
  const renderTradeAmountInputs = () => {
    const renderMaxButton = () => (
      <Button
        variant="outlined"
        size="small"
        onClick={handleMaxInput}
        disabled={loading}
        sx={{ ml: 1 }}
      >
        最大
      </Button>
    );

    if (tradeType === 'buy') {
      if (buyMode === BUY_MODES.EXACT_IN) {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <TextField
                  fullWidth
                  label={`支付数量 (${paymentToken === NATIVE_TOKEN ? nativeSymbol : 'USDT'})`}
                  type="number"
                  value={inputAmount}
                  onChange={(e) => setInputAmount(e.target.value)}
                  disabled={loading}
                  InputProps={{
                    inputProps: { min: 0, step: "any" },
                    endAdornment: !loading && renderMaxButton()
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="最小获得数量"
                type="number"
                value={minOutput}
                onChange={(e) => setMinOutput(e.target.value)}
                disabled={loading}
                InputProps={{
                  inputProps: { min: 0, step: "any" }
                }}
              />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="目标获得数量"
                type="number"
                value={desiredOutAmount}
                onChange={(e) => setDesiredOutAmount(e.target.value)}
                disabled={loading}
                InputProps={{
                  inputProps: { min: 0, step: "any" }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <TextField
                  fullWidth
                  label={`最大支付数量 (${paymentToken === NATIVE_TOKEN ? nativeSymbol : 'USDT'})`}
                  type="number"
                  value={maxInAmount}
                  onChange={(e) => setMaxInAmount(e.target.value)}
                  disabled={loading}
                  InputProps={{
                    inputProps: { min: 0, step: "any" },
                    endAdornment: !loading && renderMaxButton()
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        );
      }
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <TextField
                fullWidth
                label="卖出数量"
                type="number"
                value={inputAmount}
                onChange={(e) => setInputAmount(e.target.value)}
                disabled={loading}
                InputProps={{
                  inputProps: { min: 0, step: "any" },
                  endAdornment: !loading && renderMaxButton()
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={`最小获得数量 (${paymentToken === NATIVE_TOKEN ? nativeSymbol : 'USDT'})`}
              type="number"
              value={minOutput}
              onChange={(e) => setMinOutput(e.target.value)}
              disabled={loading}
              InputProps={{
                inputProps: { min: 0, step: "any" }
              }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  // 渲染限價訂單表單
  const renderLimitOrderForm = () => {
    return (
      <Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="代幣合約地址"
            value={tokenContract}
            onChange={(e) => {
              setTokenContract(e.target.value);
            }}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            訂單類型
            <Tooltip title="選擇訂單類型：買入或賣出" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <RadioGroup
            value={limitOrderType}
            onChange={(e) => handleLimitOrderTypeChange(e.target.value)}
            sx={{ mb: 2 }}
          >
            <FormControlLabel value={LIMIT_ORDER_TYPES.BUY} control={<Radio />} label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                限價買入
                <Tooltip title="當價格達到或低於設定價格時買入代幣" placement="right">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            } />
            <FormControlLabel value={LIMIT_ORDER_TYPES.SELL} control={<Radio />} label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                限價賣出
                <Tooltip title="當價格達到或高於設定價格時賣出代幣" placement="right">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            } />
          </RadioGroup>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            支付代幣
            <Tooltip title="選擇用於交易的代幣類型" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Select
            fullWidth
            value={paymentToken}
            onChange={(e) => handlePaymentTokenChange(e.target.value)}
          >
            {Object.entries(PAYMENT_TOKENS).map(([key, label]) => (
              <MenuItem key={key} value={key}>
                {key === NATIVE_TOKEN ? `${label} (${nativeSymbol})` : label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <TextField
                fullWidth
                label={limitOrderType === LIMIT_ORDER_TYPES.BUY 
                  ? `支付數量 (${paymentToken === NATIVE_TOKEN ? nativeSymbol : 'USDT'})` 
                  : '賣出數量'}
                type="number"
                value={limitOrderAmount}
                onChange={(e) => {
                  setLimitOrderAmount(e.target.value);
                  // 估算輸出數量
                  estimateLimitOrderOutput(
                    e.target.value, 
                    limitOrderPrice, 
                    limitOrderType === LIMIT_ORDER_TYPES.BUY
                  ).then(estimated => {
                    if (Number(estimated) > 0) {
                      setLimitOrderMinOutput(estimated);
                    }
                  });
                }}
                disabled={limitOrderLoading}
                InputProps={{
                  inputProps: { min: 0, step: "any" },
                  endAdornment: !limitOrderLoading && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleMaxInput}
                      disabled={limitOrderLoading}
                      sx={{ ml: 1 }}
                    >
                      最大
                    </Button>
                  )
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="目標價格 (USD)"
              type="number"
              value={limitOrderPrice}
              onChange={(e) => {
                setLimitOrderPrice(e.target.value);
                // 估算輸出數量
                estimateLimitOrderOutput(
                  limitOrderAmount, 
                  e.target.value, 
                  limitOrderType === LIMIT_ORDER_TYPES.BUY
                ).then(estimated => {
                  if (Number(estimated) > 0) {
                    setLimitOrderMinOutput(estimated);
                  }
                });
              }}
              disabled={limitOrderLoading}
              InputProps={{
                inputProps: { min: 0, step: "any" }
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={limitOrderType === LIMIT_ORDER_TYPES.BUY 
                ? '預計獲得數量' 
                : `預計獲得數量 (${paymentToken === NATIVE_TOKEN ? nativeSymbol : 'USDT'})`}
              type="number"
              value={limitOrderMinOutput}
              onChange={(e) => setLimitOrderMinOutput(e.target.value)}
              disabled={limitOrderLoading}
              InputProps={{
                inputProps: { min: 0, step: "any" }
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            訂單有效期 (小時)
          </Typography>
          <Select
            fullWidth
            value={limitOrderDeadline}
            onChange={(e) => setLimitOrderDeadline(e.target.value)}
            disabled={limitOrderLoading}
          >
            <MenuItem value="1">1小時</MenuItem>
            <MenuItem value="12">12小時</MenuItem>
            <MenuItem value="24">24小時</MenuItem>
            <MenuItem value="48">48小時</MenuItem>
            <MenuItem value="72">3天</MenuItem>
            <MenuItem value="168">7天</MenuItem>
            <MenuItem value="720">30天</MenuItem>
          </Select>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              disabled={limitOrderLoading || !tokenContract || !limitOrderAmount || !limitOrderPrice}
              onClick={createLimitOrder}
              sx={{ mt: 2, mb: 2 }}
            >
              {limitOrderLoading ? <CircularProgress size={24} /> : '創建限價訂單'}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setShowMyOrders(true);
                fetchMyLimitOrders();
              }}
              sx={{ mt: 2, mb: 2 }}
            >
              查看我的訂單
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // 渲染限價訂單列表
  const renderLimitOrderList = () => {
    if (!showMyOrders) return null;

    return (
      <Box sx={{ mt: 3, mb: 2 }}>
        <Card>
          <CardContent>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2
            }}>
              <Typography variant="h6">
                我的限價訂單
              </Typography>
              <Box>
                <IconButton 
                  color="primary" 
                  onClick={fetchMyLimitOrders} 
                  disabled={limitOrderLoading}
                  sx={{ mr: 1 }}
                >
                  <RefreshIcon />
                </IconButton>
                <IconButton 
                  onClick={() => setShowMyOrders(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            {limitOrderLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : myLimitOrders.length === 0 ? (
              <Box sx={{ 
                p: 3, 
                textAlign: 'center',
                bgcolor: 'background.default',
                borderRadius: 1 
              }}>
                <Typography>沒有找到限價訂單</Typography>
              </Box>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>類型</TableCell>
                      <TableCell>價格 (USD)</TableCell>
                      <TableCell>數量</TableCell>
                      <TableCell>狀態</TableCell>
                      <TableCell>截止時間</TableCell>
                      <TableCell>操作</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myLimitOrders.map((order) => {
                      const status = formatOrderStatus(order.status);
                      return (
                        <TableRow key={order.id}>
                          <TableCell>
                            <Chip 
                              label={order.type === LIMIT_ORDER_TYPES.BUY ? '買入' : '賣出'} 
                              color={order.type === LIMIT_ORDER_TYPES.BUY ? 'success' : 'error'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>${formatAmount(order.targetPrice, 6)}</TableCell>
                          <TableCell>
                            {formatAmount(order.amountIn, 4)} {order.tokenInInfo.symbol}
                          </TableCell>
                          <TableCell>
                            <Chip 
                              label={status.label} 
                              color={status.color}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title={formatDateTime(order.deadlineDate)}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
                                <Typography variant="body2">
                                  {new Date(order.deadlineDate).toLocaleDateString()}
                                </Typography>
                              </Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  setSelectedLimitOrder(order);
                                  setShowLimitOrderDetails(true);
                                }}
                              >
                                <InfoIcon fontSize="small" />
                              </IconButton>
                              {order.status === LIMIT_ORDER_STATUS.PENDING && (
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => cancelLimitOrder(order.id)}
                                  disabled={limitOrderLoading}
                                >
                                  <CancelIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };
// 渲染限價訂單詳情對話框
  const renderLimitOrderDetailsDialog = () => {
    if (!selectedLimitOrder) return null;
    
    const status = formatOrderStatus(selectedLimitOrder.status);
    const deadlineDate = new Date(selectedLimitOrder.deadlineDate);
    const isExpired = deadlineDate < new Date();
    
    return (
      <Dialog 
        open={showLimitOrderDetails} 
        onClose={() => setShowLimitOrderDetails(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            限價訂單詳情
            <IconButton onClick={() => setShowLimitOrderDetails(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">訂單類型</Typography>
                <Chip 
                  label={selectedLimitOrder.type === LIMIT_ORDER_TYPES.BUY ? '買入' : '賣出'} 
                  color={selectedLimitOrder.type === LIMIT_ORDER_TYPES.BUY ? 'success' : 'error'}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">狀態</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip 
                    label={status.label} 
                    color={status.color}
                  />
                  {isExpired && selectedLimitOrder.status === LIMIT_ORDER_STATUS.PENDING && (
                    <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                      (已過期)
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">輸入代幣</Typography>
                <Typography>
                  {formatAmount(selectedLimitOrder.amountIn, 6)} {selectedLimitOrder.tokenInInfo.symbol}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">目標價格</Typography>
                <Typography>${formatAmount(selectedLimitOrder.targetPrice, 6)} USD</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">最小輸出數量</Typography>
                <Typography>
                  {formatAmount(selectedLimitOrder.minOutAmount, 6)} {selectedLimitOrder.tokenOutInfo.symbol}
                </Typography>
              </Box>
            </Grid>
            {selectedLimitOrder.status === LIMIT_ORDER_STATUS.EXECUTED && (
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="textSecondary">實際獲得數量</Typography>
                  <Typography>
                    {formatAmount(selectedLimitOrder.executedAmount, 6)} {selectedLimitOrder.tokenOutInfo.symbol}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">創建時間</Typography>
                <Typography>{formatDateTime(selectedLimitOrder.deadlineDate - (parseInt(selectedLimitOrder.deadline) * 1000))}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" color="textSecondary">過期時間</Typography>
                <Typography>{formatDateTime(selectedLimitOrder.deadlineDate)}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {selectedLimitOrder.status === LIMIT_ORDER_STATUS.PENDING && (
            <Button 
              color="error" 
              onClick={() => {
                cancelLimitOrder(selectedLimitOrder.id);
                setShowLimitOrderDetails(false);
              }}
              disabled={limitOrderLoading}
            >
              取消訂單
            </Button>
          )}
          <Button onClick={() => setShowLimitOrderDetails(false)}>關閉</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // 渲染授權界面
  const renderApprove = () => (
    <Box>
      <TextField
        fullWidth
        label="代币合约地址"
        value={tokenAddress}
        onChange={(e) => setTokenAddress(e.target.value)}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="授权地址"
        value={spenderAddress}
        onChange={(e) => setSpenderAddress(e.target.value)}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="授权数量 (留空表示最大值)"
        type="number"
        value={approveAmount}
        onChange={(e) => setApproveAmount(e.target.value)}
        InputProps={{
          inputProps: { min: 0, step: "any" }
        }}
        sx={{ mb: 2 }}
      />

      <Button
        variant="contained"
        fullWidth
        disabled={loading || !tokenAddress || !spenderAddress}
        onClick={handleApprove}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        {loading ? '授权处理中...' : '执行授权'}
      </Button>
    </Box>
  );

  // 渲染自定義交易界面
  const renderCustomTransaction = () => (
    <Box>
      <TextField
        fullWidth
        label="目标地址"
        value={customAddress}
        onChange={(e) => setCustomAddress(e.target.value)}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label="交易数据 (hex)"
        value={customData}
        onChange={(e) => setCustomData(e.target.value)}
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />

      <TextField
        fullWidth
        label={`${nativeSymbol} 数量`}
        type="number"
        value={customValue}
        onChange={(e) => setCustomValue(e.target.value)}
        InputProps={{
          inputProps: { min: 0, step: "any" }
        }}
        sx={{ mb: 2 }}
      />

      <Button
        variant="contained"
        fullWidth
        disabled={loading || !customAddress}
        onClick={handleCustomTransaction}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        {loading ? '交易处理中...' : '执行交易'}
      </Button>
    </Box>
  );

  // 渲染交易面板
  const renderSwapPanel = () => (
    <Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="代币合约地址"
          value={tokenContract}
          onChange={(e) => {
            setTokenContract(e.target.value);
            // 不立即清除池子列表，只有在检查按钮点击后才清除
          }}
        />
      </Box>

      {/* 協議選擇 */}
      {renderProtocolSelector()}

      <Button
        variant="contained"
        onClick={checkPools}
        disabled={!tokenContract || (selectedProtocol === PROTOCOLS.V2 && !window._selectedDex) || loading}
        fullWidth
        sx={{ mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : '检查交易池'}
      </Button>

      {selectedProtocol === PROTOCOLS.V2 && availablePools.length > 0 && (
        <>
          <PoolSelector
            availablePools={availablePools}
            selectedPool={selectedPool}
            onSelect={handlePoolSelect}
            nativeSymbol={nativeSymbol}
            loading={loading}
          />

          {selectedPool && (
            <>
              <PoolInfoDisplayV2 
                selectedPool={selectedPool}
                availablePools={availablePools}
                nativeSymbol={nativeSymbol}
              />
            </>
          )}
        </>
      )}
      
      {selectedProtocol === PROTOCOLS.V3 && availableFeeTiers.length > 0 && (
        <>
          <FeeTierSelector
            availableFeeTiers={availableFeeTiers}
            selectedFeeTier={selectedFeeTier}
            onSelect={handleFeeTierChange}
            loading={loading}
          />
          
          <PoolInfoDisplayV3
            v3Pools={v3Pools}
            tokenContract={tokenContract}
            selectedFeeTier={selectedFeeTier}
            nativeSymbol={nativeSymbol}
          />
        </>
      )}

      {routerApprovals && contractApprovals && (
        <>
          {tradeType === 'buy' && paymentToken !== NATIVE_TOKEN && (
            <ApprovalStatus
              web3={useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance}
              type="input"
              routerApprovals={routerApprovals}
              contractApprovals={contractApprovals}
              currentNetwork={currentNetwork}
              label="USDT"
            />
          )}
          {tradeType === 'sell' && (
            <ApprovalStatus
              web3={useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance}
              type="input"
              routerApprovals={routerApprovals}
              contractApprovals={contractApprovals}
              currentNetwork={currentNetwork}
              label="代币"
            />
          )}
        </>
      )}

      <TradePathDisplay 
        path={tradePath} 
        tokens={tradeTokens} 
        v3PathFees={v3PathFees} 
        protocol={selectedProtocol}
      />

      {renderTradeSettings()}

      {renderTradeAmountInputs()}

      <Button
        variant="contained"
        fullWidth
        disabled={loading || 
          (selectedProtocol === PROTOCOLS.V2 && !selectedPool) || 
          !tradePath || tradePath.length === 0}
        onClick={executeTrade}
        sx={{ mt: 2, mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : '执行交易'}
      </Button>
    </Box>
  );
  
  // 渲染限價訂單面板
  const renderLimitOrderPanel = () => (
    <Box>
      {renderLimitOrderForm()}
      {renderLimitOrderList()}
      {renderLimitOrderDetailsDialog()}
    </Box>
  );

  // 主渲染函數
  return (
    <Box>
      {renderGasSettings()}
      
      {/* 防夹子设置（只在ETH和BSC链上显示） */}
      {renderAntiMevSettings()}
      
      <Box sx={{ mb: 2 }}>
        <Select
          fullWidth
          value={selectedFunction}
          onChange={(e) => setSelectedFunction(e.target.value)}
        >
          <MenuItem value="swap">代币交换</MenuItem>
          <MenuItem value="limitOrder">限价订单</MenuItem>
          <MenuItem value="approve">代币授权</MenuItem>
          <MenuItem value="customTx">自定义交易</MenuItem>
        </Select>
      </Box>

      {selectedFunction === 'swap' && renderSwapPanel()}
      {selectedFunction === 'limitOrder' && renderLimitOrderPanel()}
      {selectedFunction === 'approve' && renderApprove()}
      {selectedFunction === 'customTx' && renderCustomTransaction()}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {result && (
        <Alert severity={result.success ? "success" : "error"} sx={{ mt: 2 }}>
          {result.message}
          {result.hash && (
            <Box sx={{ mt: 1 }}>
              <Link 
                href={`${explorerUrl}/tx/${result.hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                查看交易详情
              </Link>
            </Box>
          )}
        </Alert>
      )}

      {loading && <LinearProgress sx={{ mt: 2, mb: 1 }} />}

      <Dialog 
        open={showApprovalDialog} 
        onClose={() => setShowApprovalDialog(false)}
      >
        <DialogTitle>需要授权</DialogTitle>
        <DialogContent>
          <Typography>
            在进行交易前，需要先授权{useTaxCheck && selectedProtocol === PROTOCOLS.V2 ? '合約' : '路由器'}使用您的代币。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowApprovalDialog(false)}>
            取消
          </Button>
          <Button 
            variant="contained" 
            onClick={async () => {
              try {
                const web3 = useAntiMev && antiMevWeb3 ? antiMevWeb3 : window._web3Instance;
                const network = await findCurrentNetwork(web3);
                if (!network) {
                  throw new Error('网络未连接');
                }

                const tokenToApprove = tradeType === 'sell' ? 
                  tokenContract : 
                  network.stablecoins.USDT;

                if (useTaxCheck && selectedProtocol === PROTOCOLS.V2) {
                  await approveContract(tokenToApprove, window._userAddresses[0]);
                } else {
                  await approveRouter(tokenToApprove, window._userAddresses[0]);
                }
                setShowApprovalDialog(false);
              } catch (error) {
                console.error('Approval error:', error);
                setError(error.message);
              }
            }}
          >
            授权
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

module.exports = TradePanel;

