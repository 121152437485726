const React = require('react');
const { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  TextField, 
  Button, 
  Alert,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  InputAdornment
} = require('@mui/material');

const { Info: InfoIcon } = require('@mui/icons-material');
const Web3 = require('web3');
const {
  CONSTANTS,
  NETWORK_CONFIG,
  ABIS,
  utils
} = require('./evm');

// 常量定義
const TEST_AMOUNT = '100000000000000'; // 0.0001 Native Token
const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

// 支持同區塊搶跑的鏈
const SAME_BLOCK_FRONTRUN_CHAINS = [
  '1',   // ETH
  '56',  // BSC
  '1116', // Core
  '20201022' // PEGO
];

// L2 鏈列表 (不支持內存池訪問的鏈)
const L2_CHAINS = [
  '8453',  // Base
  '42161'  // Arbitrum
];

// 交易模式定義
const TRADE_MODES = {
  FIXED_INPUT: 'fixedInput',   // 固定支付模式
  FIXED_OUTPUT: 'fixedOutput'  // 固定獲得模式
};

// 賣出模式定義
const SELL_MODES = {
  FIXED: 'fixed',         // 固定數量
  PERCENTAGE: 'percentage' // 百分比數量
};

// 交易路徑類型定義
const TRADE_PATH_TYPES = {
  DIRECT: 'direct',           // 直接交易路徑
  VIA_STABLE: 'via_stable',   // 通過穩定幣交易
  CUSTOM: 'custom'            // 自定義路徑
};

// 新增 UniswapV3 费率选项
const UNISWAPV3_FEE_LEVELS = {
  '100': '0.01%',
  '500': '0.05%',
  '3000': '0.3%',
  '10000': '1%'
};

// 默认 UniswapV3 费率
const DEFAULT_UNISWAPV3_FEE = '3000';

// 协议类型定义
const PROTOCOL_TYPES = {
  V2: 'v2',
  V3: 'v3'
};

// 新增 Anti-MEV RPC 配置
const ANTI_MEV_RPC_CONFIG = {
  '1': [ // ETH
    {
      url: 'https://eth.rpc.blocksec.com',
      name: 'Blocksec'
    },
    {
      url: 'https://rpc.mevblocker.io',
      name: 'MEV Blocker'
    },
    {
      url: 'https://rpc.flashbots.net/?builder=f1b.io&builder=rsync&builder=beaverbuild.org&builder=builder0x69&builder=Titan&builder=EigenPhi&builder=boba-builder&builder=Gambit+Labs&builder=payload&builder=Loki&builder=BuildAI&builder=JetBuilder&builder=tbuilder&builder=penguinbuild&builder=bobthebuilder&builder=BTCS&builder=bloXroute&builder=Blockbeelder&hint=hash',
      name: 'Flashbots'
    }
  ],
  '56': [ // BSC
    {
      url: 'https://bsc.rpc.blocksec.com',
      name: 'Blocksec'
    }
  ]
};
// MaikoRouter 配置
const MAIKO_ROUTER_CONFIG = {
  '1': {
    address: '0x...',  
    name: 'Ethereum'
  },
  '56': {
    address: '0xbe2a1De274d8c573D8C8edF62bd9BcdDc5b64eCf',  
    name: 'BSC'
  },
  '8453': {
    address: '0x...',  
    name: 'Base'
  },
  '42161': {
    address: '0x...',  
    name: 'Arbitrum'
  },
  '1116': {
    address: '0x...',  
    name: 'Core'
  },
  '20201022': {
    address: '0x...',  
    name: 'PEGO'
  }
};

// Router Function Signatures
const ROUTER_FUNCTIONS = {
  // V2 函数
  swapExactTokensForTokensSupportingFeeOnTransferTokens: '0x5c11d795',
  swapExactETHForTokensSupportingFeeOnTransferTokens: '0xb6f9de95',
  swapExactTokensForETHSupportingFeeOnTransferTokens: '0x791ac947',
  swapTokensForExactTokens: '0x8803dbee',
  swapETHForExactTokens: '0xfb3bdb41',
  swapTokensForExactETH: '0x4a25d94a',
  addLiquidity: '0xe8e33700',
  addLiquidityETH: '0xf305d719',
  // V3 函数
  exactInput: '0xc04b8d59',
  exactInputSingle: '0x414bf389',
  exactOutput: '0xf28c0498',
  exactOutputSingle: '0xdb3e2198'
};

// MaikoRouter ABI
const MAIKO_ROUTER_ABI = [
  {
    "inputs": [
      {"name": "router", "type": "address"},
      {"name": "path", "type": "address[]"},
      {"name": "isNativeInput", "type": "bool"},
      {"name": "_minOutAmount", "type": "uint256"},
      {"name": "_maxBuyTaxRate", "type": "uint256"},
      {"name": "_maxSellTaxRate", "type": "uint256"},
      {"name": "realAmount", "type": "uint256"}
    ],
    "name": "checkAndBuy",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {"name": "router", "type": "address"},
      {"name": "path", "type": "address[]"},
      {"name": "isNativeOutput", "type": "bool"},
      {"name": "_minOutAmount", "type": "uint256"},
      {"name": "_maxBuyTaxRate", "type": "uint256"},
      {"name": "_maxSellTaxRate", "type": "uint256"},
      {"name": "realAmount", "type": "uint256"}
    ],
    "name": "checkAndSell",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

// [Auto Sniper] 区块杀检测配置
const BLOCK_DEATH_PATTERNS = {
  VARIABLES: [
    /(startBlock|deadBlock|blacklistBlock|protectBlock|initialBlock)/i,
    /(endBlock|enableBlock|disableBlock|killBlock)/i
  ],
  COMPARISONS: [
    /block\.number\s*(>=|<=|>|<)\s*(\w+)/g,
    /(\w+)\s*(>=|<=|>|<)\s*block\.number/g
  ],
  FUNCTIONS: [
    /function\s+setDeadBlock\(uint256\)/,
    /function\s+setStartBlock\(uint256\)/,
    /function\s+enableProtection\(uint256\)/
  ]
};
// Tax Check Support
const isTaxCheckSupported = (chainId) => {
  if (!chainId) return false;
  const config = MAIKO_ROUTER_CONFIG[chainId];
  if (!config) return false;
  return config.address && config.address !== '0x...' && config.address !== '0x0000000000000000000000000000000000000000';
};

// 新增 Anti-MEV Support 函數
const isAntiMevSupported = (chainId) => {
  return chainId && ANTI_MEV_RPC_CONFIG[chainId] !== undefined;
};

// 检查DEX是否为V3
const isDexV3 = (dex) => {
  return dex && dex.type && dex.type.includes('UniswapV3');
};

// 基礎工具函數
const getTokenDecimals = async (tokenAddress, web3) => {
  try {
    const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    const decimals = await token.methods.decimals().call();
    return parseInt(decimals);
  } catch (error) {
    console.error('Get token decimals error:', error);
    return 18;
  }
};

const getTokenInfo = async (web3, tokenAddress) => {
  try {
    const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    const [symbol, decimals, name] = await Promise.all([
      token.methods.symbol().call(),
      token.methods.decimals().call(),
      token.methods.name().call().catch(() => '')
    ]);
    return { symbol, decimals: parseInt(decimals), name };
  } catch (error) {
    console.error('Get token info error:', error);
    return { symbol: '???', decimals: 18, name: '' };
  }
};

const getPool = async (token0, token1, web3, selectedDex) => {
  try {
    if (isDexV3(selectedDex)) {
      const factory = new web3.eth.Contract(ABIS.UNISWAPV3_FACTORY, selectedDex.factory);
      // 使用默认费率查询V3池
      return await factory.methods.getPool(token0, token1, DEFAULT_UNISWAPV3_FEE).call();
    } else {
      const factory = new web3.eth.Contract(ABIS.FACTORY, selectedDex.factory);
      return await factory.methods.getPair(token0, token1).call();
    }
  } catch (error) {
    console.error('Get pool error:', error);
    return '0x0000000000000000000000000000000000000000';
  }
};

// 获取V3池
const getV3Pool = async (token0, token1, fee, web3, factoryAddress) => {
  try {
    const factory = new web3.eth.Contract(ABIS.UNISWAPV3_FACTORY, factoryAddress);
    return await factory.methods.getPool(token0, token1, fee).call();
  } catch (error) {
    console.error('Get V3 pool error:', error);
    return '0x0000000000000000000000000000000000000000';
  }
};

const findCurrentNetwork = async (web3) => {
  try {
    const chainId = await web3.eth.getChainId();
    const chainIdStr = chainId.toString();
    const network = Object.values(NETWORK_CONFIG).find(net => 
      net.chainId.toString() === chainIdStr
    );
    if (!network) {
      console.warn(`Network config not found for chain ID ${chainIdStr}`);
    }
    return network;
  } catch (error) {
    console.error('Find current network error:', error);
    return null;
  }
};

const getTokenBalance = async (tokenAddress, userAddress, web3) => {
  try {
    const token = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    const balance = await token.methods.balanceOf(userAddress).call();
    return balance;
  } catch (error) {
    console.error('Get token balance error:', error);
    return '0';
  }
};

const parseAddressFromLog = (log) => {
  try {
    const addresses = [];
    for (const topic of log.topics) {
      if (topic.length === 66) {
        const potentialAddress = '0x' + topic.slice(26);
        if (Web3.utils.isAddress(potentialAddress)) {
          addresses.push(potentialAddress.toLowerCase());
        }
      }
    }
    return addresses;
  } catch (error) {
    console.error('Parse address from log error:', error);
    return [];
  }
};
// 格式化數值顯示
const formatAmount = (amount, decimals = 2) => {
  try {
    return Number(amount).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  } catch (error) {
    console.error('Format amount error:', error);
    return '0';
  }
};

// 轉換金額到代幣精度
const toTokenDecimals = (web3, amount, decimals) => {
  try {
    const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
    if (decimals !== 18) {
      const factor = web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals));
      const amountBN = web3.utils.toBN(amountInWei);
      return amountBN.div(factor).toString();
    }
    return amountInWei;
  } catch (error) {
    console.error('Convert to token decimals error:', error);
    throw error;
  }
};

// 從代幣精度轉換回顯示金額
const fromTokenDecimals = (web3, amount, decimals) => {
  try {
    if (decimals !== 18) {
      const factor = web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals));
      const amountBN = web3.utils.toBN(amount);
      const adjustedAmount = amountBN.mul(factor).toString();
      return web3.utils.fromWei(adjustedAmount, 'ether');
    }
    return web3.utils.fromWei(amount, 'ether');
  } catch (error) {
    console.error('Convert from token decimals error:', error);
    throw error;
  }
};

// 构建 UniswapV3 路径编码
const encodeV3Path = (path, fees) => {
  if (path.length < 2 || fees.length !== path.length - 1) {
    throw new Error('Invalid path or fees length');
  }

  let encoded = '0x';
  for (let i = 0; i < path.length; i++) {
    encoded += path[i].slice(2).toLowerCase();
    if (i < path.length - 1) {
      const fee = parseInt(fees[i]).toString(16).padStart(6, '0');
      encoded += fee;
    }
  }
  return encoded;
};
// 構建交易路徑
const buildTradePath = async (web3, network, targetToken, tradeType, inputToken, selectedDex, pathType, protocolType, feeTier = DEFAULT_UNISWAPV3_FEE) => {
  try {
    if (!targetToken) {
      throw new Error('無效的目標代幣');
    }

    const path = [];
    const tokens = new Map();
    const fees = []; // 用于V3路径

    // 獲取輸入代幣地址
    const inputTokenAddress = inputToken === 'native' ? 
      selectedDex.weth : 
      network.stablecoins.USDT;

    // 根據路徑類型構建
    if (pathType === TRADE_PATH_TYPES.DIRECT) {
      if (tradeType === 'buy') {
        path.push(inputTokenAddress, targetToken);
        if (protocolType === PROTOCOL_TYPES.V3) {
          fees.push(feeTier);
        }
      } else {
        path.push(targetToken, inputTokenAddress);
        if (protocolType === PROTOCOL_TYPES.V3) {
          fees.push(feeTier);
        }
      }
    } else if (pathType === TRADE_PATH_TYPES.VIA_STABLE) {
      if (tradeType === 'buy') {
        if (inputToken === 'native') {
          path.push(selectedDex.weth, network.stablecoins.USDT, targetToken);
          if (protocolType === PROTOCOL_TYPES.V3) {
            fees.push(feeTier, feeTier);
          }
        } else {
          path.push(network.stablecoins.USDT, selectedDex.weth, targetToken);
          if (protocolType === PROTOCOL_TYPES.V3) {
            fees.push(feeTier, feeTier);
          }
        }
      } else {
        if (inputToken === 'native') {
          path.push(targetToken, network.stablecoins.USDT, selectedDex.weth);
          if (protocolType === PROTOCOL_TYPES.V3) {
            fees.push(feeTier, feeTier);
          }
        } else {
          path.push(targetToken, selectedDex.weth, network.stablecoins.USDT);
          if (protocolType === PROTOCOL_TYPES.V3) {
            fees.push(feeTier, feeTier);
          }
        }
      }
    }

    // 驗證路徑並收集代幣信息
    if (protocolType === PROTOCOL_TYPES.V2) {
      const factory = new web3.eth.Contract(ABIS.FACTORY, selectedDex.factory);
      for (let i = 0; i < path.length; i++) {
        const tokenAddress = path[i].toLowerCase();
        if (!tokens.has(tokenAddress)) {
          // 收集代幣信息
          let tokenInfo;
          if (tokenAddress === selectedDex.weth.toLowerCase()) {
            tokenInfo = {
              symbol: `W${network.nativeCurrency.symbol}`,
              decimals: 18,
              isNative: true
            };
          } else if (tokenAddress === network.stablecoins.USDT.toLowerCase()) {
            tokenInfo = await getTokenInfo(web3, tokenAddress);
            tokenInfo.isStable = true;
          } else {
            tokenInfo = await getTokenInfo(web3, tokenAddress);
          }
          tokens.set(tokenAddress, tokenInfo);
        }

        // 檢查池子是否存在
        if (i < path.length - 1) {
          const pair = await factory.methods.getPair(path[i], path[i + 1]).call();
          if (pair === CONSTANTS.ADDRESSES.ZERO) {
            throw new Error(`無效的交易路徑: ${tokens.get(path[i].toLowerCase())?.symbol} -> ${tokens.get(path[i + 1].toLowerCase())?.symbol} 之間沒有流動性池`);
          }
        }
      }
    } else {
      // V3 路径验证
      const factory = new web3.eth.Contract(ABIS.UNISWAPV3_FACTORY, selectedDex.factory);
      for (let i = 0; i < path.length; i++) {
        const tokenAddress = path[i].toLowerCase();
        if (!tokens.has(tokenAddress)) {
          // 收集代幣信息
          let tokenInfo;
          if (tokenAddress === selectedDex.weth.toLowerCase()) {
            tokenInfo = {
              symbol: `W${network.nativeCurrency.symbol}`,
              decimals: 18,
              isNative: true
            };
          } else if (tokenAddress === network.stablecoins.USDT.toLowerCase()) {
            tokenInfo = await getTokenInfo(web3, tokenAddress);
            tokenInfo.isStable = true;
          } else {
            tokenInfo = await getTokenInfo(web3, tokenAddress);
          }
          tokens.set(tokenAddress, tokenInfo);
        }

        // 檢查V3池子是否存在
        if (i < path.length - 1) {
          const pool = await factory.methods.getPool(path[i], path[i + 1], fees[i]).call();
          if (pool === CONSTANTS.ADDRESSES.ZERO) {
            throw new Error(`無效的V3交易路徑: ${tokens.get(path[i].toLowerCase())?.symbol} -> ${tokens.get(path[i + 1].toLowerCase())?.symbol} 之間沒有流動性池`);
          }
        }
      }
    }

    return { 
      path, 
      tokens, 
      fees,
      encodedPath: protocolType === PROTOCOL_TYPES.V3 ? encodeV3Path(path, fees) : null
    };
  } catch (error) {
    console.error('Build trade path error:', error);
    throw error;
  }
};

// 分析流動池信息
const analyzeLiquidityPool = async (poolAddress, web3, selectedDex) => {
  if (!poolAddress || poolAddress === CONSTANTS.ADDRESSES.ZERO) {
    throw new Error('無效的池子地址');
  }

  try {
    if (isDexV3(selectedDex)) {
      // V3池子分析
      const poolContract = new web3.eth.Contract(ABIS.UNISWAPV3_POOL, poolAddress);
      const [token0, token1, slot0, liquidity] = await Promise.all([
        poolContract.methods.token0().call(),
        poolContract.methods.token1().call(),
        poolContract.methods.slot0().call(),
        poolContract.methods.liquidity().call()
      ]);

      const [token0Info, token1Info] = await Promise.all([
        getTokenInfo(web3, token0),
        getTokenInfo(web3, token1)
      ]);

      return {
        token0,
        token1,
        token0Info,
        token1Info,
        sqrtPriceX96: slot0.sqrtPriceX96,
        tick: slot0.tick,
        liquidity,
        isV3: true
      };
    } else {
      // V2池子分析
      const pairContract = new web3.eth.Contract(ABIS.PAIR, poolAddress);
      const [token0, token1, reserves] = await Promise.all([
        pairContract.methods.token0().call(),
        pairContract.methods.token1().call(),
        pairContract.methods.getReserves().call()
      ]);

      const [token0Info, token1Info] = await Promise.all([
        getTokenInfo(web3, token0),
        getTokenInfo(web3, token1)
      ]);

      return {
        token0,
        token1,
        token0Info,
        token1Info,
        reserves: {
          token0: reserves._reserve0,
          token1: reserves._reserve1
        },
        isV3: false
      };
    }
  } catch (error) {
    console.error('Analyze liquidity pool error:', error);
    throw error;
  }
};
// 計算交易所需的金額
const calculateTradeAmount = async (web3, inputAmount, outputAmount, inputDecimals, outputDecimals, mode) => {
  try {
    if (mode === TRADE_MODES.FIXED_INPUT) {
      return {
        amountIn: toTokenDecimals(web3, inputAmount, inputDecimals),
        amountOutMin: toTokenDecimals(web3, outputAmount, outputDecimals)
      };
    } else {
      return {
        amountOut: toTokenDecimals(web3, outputAmount, outputDecimals),
        amountInMax: toTokenDecimals(web3, inputAmount, inputDecimals)
      };
    }
  } catch (error) {
    console.error('Calculate trade amount error:', error);
    throw error;
  }
};

// 計算百分比賣出數量
const calculateSellAmount = async (tokenAddress, userAddress, web3, sellPercentage) => {
  try {
    if (!web3 || !tokenAddress || !userAddress) {
      throw new Error('缺少參數');
    }
    
    const balance = await getTokenBalance(tokenAddress, userAddress, web3);
    if (balance === '0') {
      return '0';
    }
    
    const decimals = await getTokenDecimals(tokenAddress, web3);
    const balanceInEther = fromTokenDecimals(web3, balance, decimals);
    
    // 計算要賣出的百分比金額
    const percentage = parseFloat(sellPercentage) / 100;
    const sellAmount = parseFloat(balanceInEther) * percentage;
    
    return sellAmount.toString();
  } catch (error) {
    console.error('Calculate sell amount error:', error);
    throw error;
  }
};

// 檢查地址是否涉及該交易（用於防針對模式）
const checkAddressInvolvement = (tx, receipt, ourAddresses) => {
  try {
    // 檢查交易直接涉及到我們的地址
    if (ourAddresses.includes(tx.from.toLowerCase()) || 
        ourAddresses.includes(tx.to.toLowerCase())) {
      return true;
    }
    
    // 檢查日誌中是否涉及我們的地址
    if (receipt && receipt.logs) {
      for (const log of receipt.logs) {
        if (log.topics && log.topics.length > 0) {
          const addresses = parseAddressFromLog(log);
          for (const address of addresses) {
            if (ourAddresses.includes(address)) {
              return true;
            }
          }
        }
      }
    }
    
    return false;
  } catch (error) {
    console.error('Check address involvement error:', error);
    return false;
  }
};

// 檢查授權狀態
const checkApprovals = async (tokenAddress, userAddress, type = 'input') => {
  try {
    if (!window._web3Instance || !window._selectedDex) {
      throw new Error('請先連接Web3');
    }

    const web3 = window._web3Instance;
    const chainId = await web3.eth.getChainId();
    const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainId.toString()];
    const routerAddress = window._selectedDex.router;

    const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
    
    const routerAllowance = await tokenContract.methods
      .allowance(userAddress, routerAddress)
      .call();

    let contractAllowance = '0';
    if (maikoRouterConfig && maikoRouterConfig.address !== '0x...') {
      contractAllowance = await tokenContract.methods
        .allowance(userAddress, maikoRouterConfig.address)
        .call();
    }

    return {
      routerApproved: BigInt(routerAllowance) > 0n,
      contractApproved: BigInt(contractAllowance) > 0n,
      routerAllowance,
      contractAllowance
    };
  } catch (error) {
    console.error('Check approvals error:', error);
    throw error;
  }
};
// 構建 UniswapV2 買入交易
const constructBuyTx = async (
  web3,
  router,
  selectedDex,
  path,
  amount,
  outputAmountOrMaxInput,
  userAddress,
  isNativeInput,
  buyMode = TRADE_MODES.FIXED_INPUT
) => {
  let value = '0';
  let data;

  const deadline = Math.floor(Date.now() / 1000) + 300;

  if (buyMode === TRADE_MODES.FIXED_INPUT) {
    if (isNativeInput) {
      value = amount;
      data = router.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
        outputAmountOrMaxInput,
        path,
        userAddress,
        deadline
      ).encodeABI();
    } else {
      data = router.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        amount,
        outputAmountOrMaxInput,
        path,
        userAddress,
        deadline
      ).encodeABI();
    }
  } else {
    if (isNativeInput) {
      data = router.methods.swapETHForExactTokens(
        amount,
        path,
        userAddress,
        deadline
      ).encodeABI();
      value = outputAmountOrMaxInput;
    } else {
      data = router.methods.swapTokensForExactTokens(
        amount,
        outputAmountOrMaxInput,
        path,
        userAddress,
        deadline
      ).encodeABI();
    }
  }

  return {
    from: userAddress,
    to: selectedDex.router,
    value,
    data
  };
};

// 構建 UniswapV3 買入交易
const constructV3BuyTx = async (
  web3,
  router,
  selectedDex,
  pathInfo,
  amount,
  outputAmountOrMaxInput,
  userAddress,
  isNativeInput,
  buyMode = TRADE_MODES.FIXED_INPUT
) => {
  let value = '0';
  let data;

  const deadline = Math.floor(Date.now() / 1000) + 300;
  const { path, encodedPath } = pathInfo;

  if (path.length === 2) {
    // 单路径交易使用exactInputSingle/exactOutputSingle
    if (buyMode === TRADE_MODES.FIXED_INPUT) {
      const params = {
        tokenIn: path[0],
        tokenOut: path[1],
        fee: pathInfo.fees[0], 
        recipient: userAddress,
        deadline,
        amountIn: amount,
        amountOutMinimum: outputAmountOrMaxInput,
        sqrtPriceLimitX96: '0' // 无价格限制
      };

      if (isNativeInput) {
        value = amount;
        data = router.methods.exactInputSingle(params).encodeABI();
      } else {
        data = router.methods.exactInputSingle(params).encodeABI();
      }
    } else {
      const params = {
        tokenIn: path[0],
        tokenOut: path[1],
        fee: pathInfo.fees[0], 
        recipient: userAddress,
        deadline,
        amountOut: amount,
        amountInMaximum: outputAmountOrMaxInput,
        sqrtPriceLimitX96: '0' // 无价格限制
      };

      if (isNativeInput) {
        value = outputAmountOrMaxInput;
        data = router.methods.exactOutputSingle(params).encodeABI();
      } else {
        data = router.methods.exactOutputSingle(params).encodeABI();
      }
    }
  } else {
    // 多路径交易使用exactInput/exactOutput
    if (buyMode === TRADE_MODES.FIXED_INPUT) {
      const params = {
        path: encodedPath,
        recipient: userAddress,
        deadline,
        amountIn: amount, 
        amountOutMinimum: outputAmountOrMaxInput
      };

      if (isNativeInput) {
        value = amount;
        data = router.methods.exactInput(params).encodeABI();
      } else {
        data = router.methods.exactInput(params).encodeABI();
      }
    } else {
      const params = {
        path: encodedPath,
        recipient: userAddress,
        deadline,
        amountOut: amount,
        amountInMaximum: outputAmountOrMaxInput
      };

      if (isNativeInput) {
        value = outputAmountOrMaxInput;
        data = router.methods.exactOutput(params).encodeABI();
      } else {
        data = router.methods.exactOutput(params).encodeABI();
      }
    }
  }

  // 处理原生代币
  if (isNativeInput) {
    // UniswapV3 Router 的 multicall 功能
    // 包装 ETH->WETH 和交易操作
    const unwrapWETH = router.methods.unwrapWETH9(0, userAddress).encodeABI();
    const multicall = router.methods.multicall([data, unwrapWETH]).encodeABI();
    data = multicall;
  }

  return {
    from: userAddress,
    to: selectedDex.router,
    value,
    data
  };
};

// 構建帶稅率檢查的買入交易
const constructBuyTxWithTaxCheck = async (
  web3,
  maikoRouterAddress,
  routerAddress,
  path,
  amount,
  outputAmountOrMaxInput,
  maxBuyTaxBP,
  maxSellTaxBP,
  userAddress,
  isNativeInput,
  buyMode = TRADE_MODES.FIXED_INPUT
) => {
  const maikoContract = new web3.eth.Contract(MAIKO_ROUTER_ABI, maikoRouterAddress);

  const data = maikoContract.methods.checkAndBuy(
    routerAddress,
    path,
    isNativeInput,
    outputAmountOrMaxInput,
    maxBuyTaxBP,
    maxSellTaxBP,
    amount
  ).encodeABI();

  const txValue = isNativeInput ? 
    (buyMode === TRADE_MODES.FIXED_INPUT ? 
      (BigInt(TEST_AMOUNT) + BigInt(amount)).toString() : 
      (BigInt(TEST_AMOUNT) + BigInt(outputAmountOrMaxInput)).toString()
    ) : 
    TEST_AMOUNT;

  return {
    from: userAddress,
    to: maikoRouterAddress,
    value: txValue,
    data
  };
};

// 構建 UniswapV2 賣出交易
const constructSellTx = async (
  web3,
  router,
  selectedDex,
  path,
  amount,
  minOutputAmount,
  userAddress
) => {
  const deadline = Math.floor(Date.now() / 1000) + 300;
  
  const data = path[path.length - 1].toLowerCase() === selectedDex.weth.toLowerCase() ?
    router.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
      amount,
      minOutputAmount,
      path,
      userAddress,
      deadline
    ).encodeABI() :
    router.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
      amount,
      minOutputAmount,
      path,
      userAddress,
      deadline
    ).encodeABI();

  return {
    from: userAddress,
    to: selectedDex.router,
    value: '0',
    data
  };
};

// 構建 UniswapV3 賣出交易
const constructV3SellTx = async (
  web3,
  router,
  selectedDex,
  pathInfo,
  amount,
  minOutputAmount,
  userAddress
) => {
  const deadline = Math.floor(Date.now() / 1000) + 300;
  const { path, encodedPath } = pathInfo;
  const isNativeOutput = path[path.length - 1].toLowerCase() === selectedDex.weth.toLowerCase();
  let data;

  if (path.length === 2) {
    // 单路径交易
    const params = {
      tokenIn: path[0],
      tokenOut: path[1],
      fee: pathInfo.fees[0],
      recipient: isNativeOutput ? selectedDex.router : userAddress, // 如果输出是ETH，先发送到路由器
      deadline,
      amountIn: amount,
      amountOutMinimum: minOutputAmount,
      sqrtPriceLimitX96: '0' // 无价格限制
    };

    data = router.methods.exactInputSingle(params).encodeABI();
  } else {
    // 多路径交易
    const params = {
      path: encodedPath,
      recipient: isNativeOutput ? selectedDex.router : userAddress,
      deadline,
      amountIn: amount,
      amountOutMinimum: minOutputAmount
    };

    data = router.methods.exactInput(params).encodeABI();
  }

  // 如果输出是原生代币，需要添加unwrapWETH9调用
  if (isNativeOutput) {
    const unwrapWETH = router.methods.unwrapWETH9(minOutputAmount, userAddress).encodeABI();
    const multicall = router.methods.multicall([data, unwrapWETH]).encodeABI();
    data = multicall;
  }

  return {
    from: userAddress,
    to: selectedDex.router,
    value: '0',
    data
  };
};

// 構建帶稅率檢查的賣出交易
const constructSellTxWithTaxCheck = async (
  web3,
  maikoRouterAddress,
  routerAddress,
  path,
  amount,
  minOutputAmount,
  maxBuyTaxBP,
  maxSellTaxBP,
  userAddress
) => {
  const maikoContract = new web3.eth.Contract(MAIKO_ROUTER_ABI, maikoRouterAddress);
  const isNativeOutput = path[path.length - 1].toLowerCase() === window._selectedDex.weth.toLowerCase();

  const data = maikoContract.methods.checkAndSell(
    routerAddress,
    path,
    isNativeOutput,
    minOutputAmount,
    maxBuyTaxBP,
    maxSellTaxBP,
    amount
  ).encodeABI();

  return {
    from: userAddress,
    to: maikoRouterAddress,
    value: TEST_AMOUNT,
    data
  };
};
// Web3 WebSocket 連接管理
class Web3WebSocketManager {
  constructor() {
    this.web3 = null;
    this.wsProvider = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectInterval = 1000;
    this.subscriptions = {
      pendingTx: null,
      newBlocks: null
    };
    this.callbacks = {
      pendingTx: null,
      newBlocks: null,
      error: null
    };
  }

  connect(wsUrl) {
    return new Promise((resolve, reject) => {
      try {
        if (this.wsProvider) {
          this.disconnect();
        }

        this.wsProvider = new Web3.providers.WebsocketProvider(wsUrl);
        this.web3 = new Web3(this.wsProvider);

        // 設置連接事件處理器
        this.wsProvider.on('connect', () => {
          console.log('WebSocket連接已建立');
          this.reconnectAttempts = 0;
          resolve(this.web3);
        });

        this.wsProvider.on('error', (error) => {
          console.error('WebSocket連接錯誤:', error);
          if (this.callbacks.error) {
            this.callbacks.error(error);
          }
          reject(error);
        });

        this.wsProvider.on('end', () => {
          console.log('WebSocket連接已關閉，嘗試重連...');
          this.reconnect();
        });
      } catch (error) {
        console.error('WebSocket連接初始化錯誤:', error);
        reject(error);
      }
    });
  }

  reconnect() {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error('達到最大重連嘗試次數，放棄重連');
      if (this.callbacks.error) {
        this.callbacks.error(new Error('WebSocket重連失敗'));
      }
      return;
    }

    setTimeout(async () => {
      this.reconnectAttempts++;
      console.log(`嘗試重連 (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
      
      try {
        await this.connect(this.wsProvider.connection.url);
        console.log('重新連接成功，重新訂閱...');
        
        // 重新訂閱
        this.resubscribe();
      } catch (error) {
        console.error('重連失敗:', error);
        this.reconnect();
      }
    }, this.reconnectInterval * Math.pow(2, this.reconnectAttempts));
  }

  resubscribe() {
    // 重新訂閱待處理交易
    if (this.callbacks.pendingTx) {
      this.subscribePendingTransactions(this.callbacks.pendingTx);
    }
    
    // 重新訂閱新區塊
    if (this.callbacks.newBlocks) {
      this.subscribeNewBlockHeaders(this.callbacks.newBlocks);
    }
  }

  disconnect() {
    if (this.subscriptions.pendingTx) {
      this.subscriptions.pendingTx.unsubscribe();
      this.subscriptions.pendingTx = null;
    }
    
    if (this.subscriptions.newBlocks) {
      this.subscriptions.newBlocks.unsubscribe();
      this.subscriptions.newBlocks = null;
    }
    
    if (this.wsProvider && this.wsProvider.connection) {
      this.wsProvider.disconnect();
    }
    
    this.wsProvider = null;
    this.web3 = null;
  }

  subscribePendingTransactions(callback) {
    if (!this.web3) {
      throw new Error('WebSocket未連接');
    }
    
    this.callbacks.pendingTx = callback;
    
    this.subscriptions.pendingTx = this.web3.eth.subscribe('pendingTransactions')
      .on('data', (txHash) => {
        if (callback) {
          callback(txHash);
        }
      })
      .on('error', (error) => {
        console.error('待處理交易訂閱錯誤:', error);
        if (this.callbacks.error) {
          this.callbacks.error(error);
        }
      });
    
    return this.subscriptions.pendingTx;
  }

  subscribeNewBlockHeaders(callback) {
    if (!this.web3) {
      throw new Error('WebSocket未連接');
    }
    
    this.callbacks.newBlocks = callback;
    
    this.subscriptions.newBlocks = this.web3.eth.subscribe('newBlockHeaders')
      .on('data', (blockHeader) => {
        if (callback) {
          callback(blockHeader);
        }
      })
      .on('error', (error) => {
        console.error('新區塊訂閱錯誤:', error);
        if (this.callbacks.error) {
          this.callbacks.error(error);
        }
      });
    
    return this.subscriptions.newBlocks;
  }

  async getTransaction(txHash) {
    if (!this.web3) {
      throw new Error('WebSocket未連接');
    }
    
    return await this.web3.eth.getTransaction(txHash);
  }

  async getTransactionReceipt(txHash) {
    if (!this.web3) {
      throw new Error('WebSocket未連接');
    }
    
    return await this.web3.eth.getTransactionReceipt(txHash);
  }

  async getCode(address) {
    if (!this.web3) {
      throw new Error('WebSocket未連接');
    }
    
    return await this.web3.eth.getCode(address);
  }

  getWeb3Instance() {
    return this.web3;
  }
}
// [Auto Sniper] 自動狙擊邏輯
class AutoSniper {
  constructor(web3Manager) {
    this.web3Manager = web3Manager;
    this.targetContract = null;
    this.contractAnalysis = {};
    this.safeBlocks = [];
    this.currentBlock = 0;
    this.enabled = false;
    this.analyzedContracts = new Set();
    this.executionCallback = null;
  }

  setTargetContract(contractAddress) {
    this.targetContract = contractAddress.toLowerCase();
  }

  async analyzeContract(contractAddress) {
    try {
      if (this.analyzedContracts.has(contractAddress.toLowerCase())) {
        return this.contractAnalysis[contractAddress.toLowerCase()];
      }

      const code = await this.web3Manager.getCode(contractAddress);
      const analysis = this.analyzeBlockProtection(code);
      
      const contractInfo = {
        address: contractAddress.toLowerCase(),
        riskLevel: analysis.riskLevel,
        patterns: analysis.patterns,
        firstSeenBlock: this.currentBlock,
        safeBlockWindow: null
      };
      
      this.contractAnalysis[contractAddress.toLowerCase()] = contractInfo;
      this.analyzedContracts.add(contractAddress.toLowerCase());
      
      // 如果找到區塊保護機制，計算安全交易窗口
      if (analysis.patterns.length > 0) {
        this.calculateSafeBlockWindow(contractInfo);
      }
      
      return contractInfo;
    } catch (error) {
      console.error('合約分析錯誤:', error);
      return null;
    }
  }

  analyzeBlockProtection(contractCode) {
    const findings = [];
    
    // 檢測危險變量
    BLOCK_DEATH_PATTERNS.VARIABLES.forEach(pattern => {
      const matches = contractCode.match(pattern);
      if (matches) findings.push(`發現區塊控制變量: ${matches[0]}`);
    });

    // 檢測比較操作
    BLOCK_DEATH_PATTERNS.COMPARISONS.forEach(pattern => {
      const matches = [...contractCode.matchAll(pattern)];
      matches.forEach(match => {
        findings.push(`發現區塊比較: ${match[0]}`);
      });
    });

    // 檢測設置函數
    BLOCK_DEATH_PATTERNS.FUNCTIONS.forEach(pattern => {
      if (pattern.test(contractCode)) {
        findings.push(`發現區塊設置函數: ${pattern.toString()}`);
      }
    });

    return {
      riskLevel: findings.length > 0 ? 'high' : 'low',
      patterns: findings
    };
  }

  calculateSafeBlockWindow(contractInfo) {
    // 這裡實現安全窗口計算邏輯
    // 一般情況下，合約從創建到啟用保護機制會有一段時間
    // 我們可以估算這個時間，提供一個安全窗口
    
    // 估算啟用保護的區塊數
    const estimatedProtectionBlocks = 10; // 保守估計10個區塊
    
    const safeWindow = {
      contract: contractInfo.address,
      start: contractInfo.firstSeenBlock,
      end: contractInfo.firstSeenBlock + estimatedProtectionBlocks
    };
    
    this.safeBlocks.push(safeWindow);
    contractInfo.safeBlockWindow = safeWindow;
    
    return safeWindow;
  }

  isWithinSafeWindow(contractAddress) {
    const address = contractAddress.toLowerCase();
    const safeWindow = this.safeBlocks.find(w => w.contract === address);
    
    if (!safeWindow) return true; // 沒有識別到安全窗口，假設安全
    
    return this.currentBlock >= safeWindow.start && this.currentBlock <= safeWindow.end;
  }

  setExecutionCallback(callback) {
    this.executionCallback = callback;
  }

  handleNewBlock(blockHeader) {
    this.currentBlock = blockHeader.number;
    
    // 檢查是否在目標合約的安全窗口內，如果是則觸發執行
    if (this.enabled && this.targetContract && this.isWithinSafeWindow(this.targetContract)) {
      console.log(`當前區塊 ${this.currentBlock} 在安全交易窗口內，執行交易`);
      
      if (this.executionCallback) {
        this.executionCallback(this.targetContract);
      }
    }
  }

  handleNewContract(txHash, contractAddress) {
    this.analyzeContract(contractAddress);
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }
}
const MempoolPanel = () => {
  // Tab 狀態
  const [currentTab, setCurrentTab] = React.useState(0);
  const [protocolTab, setProtocolTab] = React.useState(PROTOCOL_TYPES.V2); // 新增协议切换Tab

  // 交易類型狀態
  const [tradeType, setTradeType] = React.useState('buy');
  const [inputToken, setInputToken] = React.useState('native');
  const [buyMode, setBuyMode] = React.useState(TRADE_MODES.FIXED_INPUT);
  const [sellMode, setSellMode] = React.useState(SELL_MODES.FIXED);
  const [sellPercentage, setSellPercentage] = React.useState('100');
  const [antiTargetMode, setAntiTargetMode] = React.useState(false);

  // 基礎狀態
  const [isMonitoring, setIsMonitoring] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const [operationResults, setOperationResults] = React.useState([]);
  const [showResults, setShowResults] = React.useState(false);
  const [result, setResult] = React.useState(null);
  const [explorerUrl, setExplorerUrl] = React.useState('');

  // V3 特有状态
  const [feeTier, setFeeTier] = React.useState(DEFAULT_UNISWAPV3_FEE);

  // 授權狀態
  const [routerApprovals, setRouterApprovals] = React.useState({
    input: '0',
    output: '0'
  });
  const [contractApprovals, setContractApprovals] = React.useState({
    input: '0',
    output: '0'
  });
  const [showApprovalDialog, setShowApprovalDialog] = React.useState(false);
  const [approvalTokenAddress, setApprovalTokenAddress] = React.useState('');
  const [approvalUserAddress, setApprovalUserAddress] = React.useState('');

  // 鏈支持狀態
  const [chainSupport, setChainSupport] = React.useState({
    currentChain: null,
    isSameBlockSupported: false,
    isL2Chain: false,
    canUseTaxCheck: false,
    canUseAntiMev: false,  // 防夾子功能支持狀態
    hasV3Support: false    // 是否支持V3
  });

  // Gas設置
  const [gasMultiplier, setGasMultiplier] = React.useState('1.5');
  const [gasPrice, setGasPrice] = React.useState('');
  const [gasLimit, setGasLimit] = React.useState('300000');
  const [skipBlocks, setSkipBlocks] = React.useState('0');
  const [currentGasPrice, setCurrentGasPrice] = React.useState(null);

  // 監控目標設置
  const [fromAddress, setFromAddress] = React.useState('');
  const [toAddress, setToAddress] = React.useState('');
  const [methodIds, setMethodIds] = React.useState('');

  // 加池監控專用設置
  const [minWrappedAmount, setMinWrappedAmount] = React.useState('');
  const [minUsdtAmount, setMinUsdtAmount] = React.useState('');

  // 交易設置
  const [inputAmount, setInputAmount] = React.useState('');
  const [minOutputAmount, setMinOutputAmount] = React.useState('');
  const [outputAmount, setOutputAmount] = React.useState('');
  const [maxInputAmount, setMaxInputAmount] = React.useState('');

  // 稅率檢測設置
  const [useTaxCheck, setUseTaxCheck] = React.useState(false);
  const [maxBuyTax, setMaxBuyTax] = React.useState('10');
  const [maxSellTax, setMaxSellTax] = React.useState('10');
  const [maikoRouter, setMaikoRouter] = React.useState(null);

  // 防夾子(Anti-MEV)設置
  const [useAntiMev, setUseAntiMev] = React.useState(false);
  const [selectedAntiMevRpc, setSelectedAntiMevRpc] = React.useState('');
  const [availableAntiMevRpcs, setAvailableAntiMevRpcs] = React.useState([]);

  // 交易路徑設置
  const [pathType, setPathType] = React.useState(TRADE_PATH_TYPES.DIRECT);
  const [tradePath, setTradePath] = React.useState([]);
  const [tradeTokens, setTradeTokens] = React.useState(new Map());
  const [tradeFees, setTradeFees] = React.useState([]); // 添加V3费率
  const [encodedPath, setEncodedPath] = React.useState(null); // 添加V3编码路径

  // [Auto Sniper] 自動狙擊狀態
  const [autoSnipeConfig, setAutoSnipeConfig] = React.useState({
    enabled: false,
    contractAnalysis: {},
    safeBlocks: [],
    currentBlock: 0
  });

  // Web3 相關
  const web3ManagerRef = React.useRef(null);
  const autoSniperRef = React.useRef(null);
  const pendingTxRef = React.useRef(new Set());
  const targetTokenBalancesRef = React.useRef(new Map());
  const processedBlocksRef = React.useRef(new Set());
  const lastProcessedBlockRef = React.useRef(0);
  const antiMevWeb3Ref = React.useRef(null); // 防夾子Web3實例

  // 監控統計
  const [monitoringStats, setMonitoringStats] = React.useState({
    totalScanned: 0,
    matchedTx: 0,
    successfulTx: 0,
    failedTx: 0,
    startTime: null
  });
// 初始化 Web3 相關服務
  React.useEffect(() => {
    // 初始化 Web3 WebSocket 管理器
    if (!web3ManagerRef.current) {
      web3ManagerRef.current = new Web3WebSocketManager();
    }
    
    // 初始化自動狙擊器
    if (!autoSniperRef.current) {
      autoSniperRef.current = new AutoSniper(web3ManagerRef.current);
    }
    
    // 組件卸載時清理連接
    return () => {
      if (web3ManagerRef.current) {
        web3ManagerRef.current.disconnect();
      }
    };
  }, []);

  // 監聽 RPC 和鏈的變化，更新支持的特性
  React.useEffect(() => {
    const checkChainSupport = async () => {
      if (!window._web3Instance) return;

      try {
        const chainId = await window._web3Instance.eth.getChainId();
        const chainIdString = chainId.toString();
        
        const isSameBlockSupported = SAME_BLOCK_FRONTRUN_CHAINS.includes(chainIdString);
        const isL2Chain = L2_CHAINS.includes(chainIdString);
        const canUseTaxCheck = isTaxCheckSupported(chainIdString);
        const canUseAntiMev = isAntiMevSupported(chainIdString); // 檢查是否支持防夾子功能
        
        // 检查是否有V3 DEX
        const hasV3Support = Boolean(window._selectedDex && isDexV3(window._selectedDex));

        setChainSupport({
          currentChain: chainIdString,
          isSameBlockSupported,
          isL2Chain,
          canUseTaxCheck,
          canUseAntiMev,
          hasV3Support
        });

        // 如果选择的DEX改变，检查协议类型
        if (window._selectedDex) {
          const isV3 = isDexV3(window._selectedDex);
          setProtocolTab(isV3 ? PROTOCOL_TYPES.V3 : PROTOCOL_TYPES.V2);
        }

        // 如果是支持防夾子的鏈，設置可用的RPC列表
        if (canUseAntiMev) {
          setAvailableAntiMevRpcs(ANTI_MEV_RPC_CONFIG[chainIdString] || []);
          // 如果有可用的防夾子RPC，默認選擇第一個
          if (ANTI_MEV_RPC_CONFIG[chainIdString]?.length > 0) {
            setSelectedAntiMevRpc(ANTI_MEV_RPC_CONFIG[chainIdString][0].url);
          }
        } else {
          setAvailableAntiMevRpcs([]);
          setSelectedAntiMevRpc('');
          setUseAntiMev(false); // 自動關閉防夾子功能
        }

        const network = await findCurrentNetwork(window._web3Instance);
        if (network?.explorer?.url) {
          setExplorerUrl(network.explorer.url);
        }

        const maikoRouterConfig = MAIKO_ROUTER_CONFIG[chainIdString];
        if (maikoRouterConfig && maikoRouterConfig.address !== '0x...') {
          setMaikoRouter(maikoRouterConfig);
        } else {
          setMaikoRouter(null);
          if (useTaxCheck) {
            setUseTaxCheck(false);
            setError('當前網絡不支持稅率檢測，已自動關閉');
          }
        }

      } catch (error) {
        console.error('Failed to check chain support:', error);
      }
    };

    checkChainSupport();
    
    // 降低檢查頻率，10秒一次
    const interval = setInterval(checkChainSupport, 10000);
    return () => clearInterval(interval);
  }, [useTaxCheck]);

  // Gas價格更新
  React.useEffect(() => {
    const updateGasPrice = async () => {
      if (!window._web3Instance) return;

      try {
        const web3 = window._web3Instance;
        const gasPrice = await web3.eth.getGasPrice();
        const gasPriceInGwei = web3.utils.fromWei(gasPrice, 'gwei');
        setCurrentGasPrice(gasPriceInGwei);
        if (!gasPrice) {
          setGasPrice(gasPriceInGwei);
        }
      } catch (error) {
        console.error('Get gas price error:', error);
      }
    };

    updateGasPrice();
    
    // 降低更新頻率，5秒一次
    const interval = setInterval(updateGasPrice, 5000);
    return () => clearInterval(interval);
  }, []);
// 更新結果列表
  const updateResults = (results) => {
    setOperationResults(prev => [...prev, ...results]);
    setShowResults(true);
    
    const failures = results.filter(r => !r.success);
    if (failures.length > 0) {
      setError(`${failures.length}個錢包操作失敗`);
    }

    setResult({
      success: failures.length === 0,
      message: `完成 ${results.length - failures.length}/${results.length} 個錢包的操作`
    });

    setMonitoringStats(prev => ({
      ...prev,
      successfulTx: prev.successfulTx + (results.length - failures.length),
      failedTx: prev.failedTx + failures.length
    }));
  };

  // 清理餘額緩存
  const clearBalanceCache = () => {
    targetTokenBalancesRef.current.clear();
  };

  // 處理訂閱錯誤
  const handleSubscriptionError = (error) => {
    console.error('Subscription error:', error);
    setError('監控訂閱錯誤: ' + error.message);
    stopMonitoring();
  };

  // 授權相關函數
  const approveRouter = async (tokenAddress, userAddress) => {
    try {
      if (!window._web3Instance || !window._selectedDex) {
        throw new Error('請先連接Web3');
      }

      setLoading(true);
      const web3 = window._web3Instance;
      const routerAddress = window._selectedDex.router;

      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const tx = await tokenContract.methods
        .approve(routerAddress, MAX_UINT256)
        .send({
          from: userAddress,
          gas: gasLimit,
          gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
        });

      const approval = await checkApprovals(tokenAddress, userAddress);
      setRouterApprovals(prev => ({
        ...prev,
        input: approval.routerAllowance
      }));

      return tx;
    } catch (err) {
      console.error('Approve router error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const approveContract = async (tokenAddress, userAddress) => {
    try {
      if (!window._web3Instance || !maikoRouter) {
        throw new Error('請先連接Web3');
      }

      setLoading(true);
      const web3 = window._web3Instance;
      const tokenContract = new web3.eth.Contract(ABIS.ERC20, tokenAddress);
      
      const tx = await tokenContract.methods
        .approve(maikoRouter.address, MAX_UINT256)
        .send({
          from: userAddress,
          gas: gasLimit,
          gasPrice: gasPrice ? web3.utils.toWei(gasPrice, 'gwei') : await web3.eth.getGasPrice()
        });

      const approval = await checkApprovals(tokenAddress, userAddress);
      setContractApprovals(prev => ({
        ...prev,
        input: approval.contractAllowance
      }));

      return tx;
    } catch (err) {
      console.error('Approve contract error:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // 初始化防夾子(Anti-MEV) Web3實例
  const initAntiMevWeb3 = async (rpcUrl) => {
    try {
      if (!rpcUrl) {
        antiMevWeb3Ref.current = null;
        return null;
      }
      
      // 創建新的HTTP提供者
      const provider = new Web3.providers.HttpProvider(rpcUrl);
      const web3Instance = new Web3(provider);
      
      // 測試連接
      await web3Instance.eth.getBlockNumber();
      
      antiMevWeb3Ref.current = web3Instance;
      console.log('Anti-MEV RPC連接成功:', rpcUrl);
      
      return web3Instance;
    } catch (error) {
      console.error('初始化Anti-MEV Web3失敗:', error);
      setError(`連接防夾子RPC失敗: ${error.message}`);
      antiMevWeb3Ref.current = null;
      return null;
    }
  };
// 檢查交易條件
  const checkTransactionConditions = async (tx, receipt = null) => {
    try {
      const web3 = window._web3Instance;
      let conditionsMet = true;

      // 檢查是否為防針對模式
      if (tradeType === 'sell' && antiTargetMode) {
        if (tx.to?.toLowerCase() === toAddress.toLowerCase()) {
          const ourAddresses = window._userAddresses.map(addr => addr.toLowerCase());
          const txReceipt = receipt || await web3.eth.getTransactionReceipt(tx.hash);
          if (!txReceipt) return false;
          
          const involvedInTx = checkAddressInvolvement(tx, txReceipt, ourAddresses);
          return involvedInTx;
        }
        return false;
      }

      // 普通模式檢查
      if (fromAddress && tx.from?.toLowerCase() !== fromAddress.toLowerCase()) {
        return false;
      }

      if (toAddress && tx.to?.toLowerCase() !== toAddress.toLowerCase()) {
        return false;
      }

      if (methodIds) {
        const methodIdList = methodIds.split(',').map(id => id.trim());
        if (!methodIdList.some(methodId => tx.input.startsWith(methodId))) {
          return false;
        }
      }

      if (minWrappedAmount && tx.value) {
        const amountWei = web3.utils.toBN(tx.value);
        const minAmountWei = web3.utils.toWei(minWrappedAmount, 'ether');
        if (amountWei.lt(web3.utils.toBN(minAmountWei))) {
          return false;
        }
      }

      if (minUsdtAmount && tx.input.length >= 138) {
        const data = tx.input.slice(10);
        const amountHex = '0x' + data.slice(0, 64);
        const amountWei = web3.utils.toBN(amountHex);
        const minAmountWei = web3.utils.toWei(minUsdtAmount, 'ether');
        if (amountWei.lt(web3.utils.toBN(minAmountWei))) {
          return false;
        }
      }

      return conditionsMet;

    } catch (error) {
      console.error('Check conditions error:', error);
      return false;
    }
  };

  // 執行交易
  const executeTrade = async (triggerTx, targetGasPrice) => {
    try {
      const web3 = window._web3Instance;
      if (!web3 || !window._selectedDex || !window._userAddresses?.length) {
        throw new Error('未連接RPC或未選擇DEX或錢包');
      }

      // 清理餘額緩存
      clearBalanceCache();

      const network = await findCurrentNetwork(web3);
      if (!network) {
        throw new Error('無法識別當前網絡');
      }

      // 獲取交易路徑
      let pathResult = await buildTradePath(
        web3,
        network,
        toAddress, // 目標合約地址
        tradeType,
        inputToken,
        window._selectedDex,
        pathType,
        protocolTab, // 使用当前选择的协议
        feeTier    // 传递V3费率
      );

      if (!pathResult) {
        throw new Error('無法構建交易路徑');
      }

      const { path, fees, encodedPath } = pathResult;
      setTradePath(path);
      setTradeTokens(pathResult.tokens);
      setTradeFees(fees || []);
      setEncodedPath(encodedPath);

      // 使用防夾子(Anti-MEV) RPC進行交易(如果啟用)
      const useAntiMevForTx = useAntiMev && selectedAntiMevRpc && chainSupport.canUseAntiMev;
      
      // 初始化Anti-MEV Web3實例(如果需要)
      if (useAntiMevForTx && !antiMevWeb3Ref.current) {
        await initAntiMevWeb3(selectedAntiMevRpc);
        if (!antiMevWeb3Ref.current) {
          throw new Error('無法初始化防夾子RPC連接');
        }
      }

      // 選擇要使用的Web3實例
      const txWeb3 = useAntiMevForTx ? antiMevWeb3Ref.current : web3;
      
      if (!txWeb3) {
        throw new Error('無法獲取有效的Web3實例');
      }

      const results = [];

      for (const userAddress of window._userAddresses) {
        try {
          // 檢查授權
          if (tradeType === 'sell' || (tradeType === 'buy' && inputToken !== 'native')) {
            const tokenToCheck = tradeType === 'sell' ? 
              toAddress : 
              network.stablecoins.USDT;

            const approvals = await checkApprovals(tokenToCheck, userAddress);
            
            if (useTaxCheck) {
              if (!approvals.contractApproved) {
                await approveContract(tokenToCheck, userAddress);
              }
            } else {
              if (!approvals.routerApproved) {
                await approveRouter(tokenToCheck, userAddress);
              }
            }
          }

          let tx;
          if (useTaxCheck) {
            const maxBuyTaxBP = parseFloat(maxBuyTax) * 100;
            const maxSellTaxBP = parseFloat(maxSellTax) * 100;

            if (tradeType === 'buy') {
              const amount = buyMode === TRADE_MODES.FIXED_INPUT ?
                txWeb3.utils.toWei(inputAmount, 'ether') :
                txWeb3.utils.toWei(outputAmount, 'ether');
              
              const outputAmountOrMaxInput = buyMode === TRADE_MODES.FIXED_INPUT ?
                txWeb3.utils.toWei(minOutputAmount, 'ether') :
                txWeb3.utils.toWei(maxInputAmount, 'ether');

              tx = await constructBuyTxWithTaxCheck(
                txWeb3,
                maikoRouter.address,
                window._selectedDex.router,
                path,
                amount,
                outputAmountOrMaxInput,
                maxBuyTaxBP,
                maxSellTaxBP,
                userAddress,
                inputToken === 'native',
                buyMode
              );
            } else {
              // 計算賣出數量
              let sellAmount;
              if (sellMode === SELL_MODES.FIXED) {
                sellAmount = txWeb3.utils.toWei(inputAmount, 'ether');
              } else {
                // 百分比賣出
                const amountStr = await calculateSellAmount(
                  toAddress, 
                  userAddress, 
                  txWeb3, 
                  sellPercentage
                );
                sellAmount = txWeb3.utils.toWei(amountStr, 'ether');
              }

              tx = await constructSellTxWithTaxCheck(
                txWeb3,
                maikoRouter.address,
                window._selectedDex.router,
                path,
                sellAmount,
                txWeb3.utils.toWei(minOutputAmount, 'ether'),
                maxBuyTaxBP,
                maxSellTaxBP,
                userAddress
              );
            }
          } else {
            // 根据协议类型选择不同的交易构建函数
            if (protocolTab === PROTOCOL_TYPES.V3) {
              const router = new txWeb3.eth.Contract(ABIS.UNISWAPV3_ROUTER, window._selectedDex.router);
              
              if (tradeType === 'buy') {
                const amount = buyMode === TRADE_MODES.FIXED_INPUT ?
                  txWeb3.utils.toWei(inputAmount, 'ether') :
                  txWeb3.utils.toWei(outputAmount, 'ether');
                
                const outputAmountOrMaxInput = buyMode === TRADE_MODES.FIXED_INPUT ?
                  txWeb3.utils.toWei(minOutputAmount, 'ether') :
                  txWeb3.utils.toWei(maxInputAmount, 'ether');
  
                tx = await constructV3BuyTx(
                  txWeb3,
                  router,
                  window._selectedDex,
                  pathResult,
                  amount,
                  outputAmountOrMaxInput,
                  userAddress,
                  inputToken === 'native',
                  buyMode
                );
              } else {
                // 计算卖出数量
                let sellAmount;
                if (sellMode === SELL_MODES.FIXED) {
                  sellAmount = txWeb3.utils.toWei(inputAmount, 'ether');
                } else {
                  // 百分比卖出
                  const amountStr = await calculateSellAmount(
                    toAddress, 
                    userAddress, 
                    txWeb3, 
                    sellPercentage
                  );
                  sellAmount = txWeb3.utils.toWei(amountStr, 'ether');
                }
  
                tx = await constructV3SellTx(
                  txWeb3,
                  router,
                  window._selectedDex,
                  pathResult,
                  sellAmount,
                  txWeb3.utils.toWei(minOutputAmount, 'ether'),
                  userAddress
                );
              }
            } else {
              // V2 交易构建逻辑
              const router = new txWeb3.eth.Contract(ABIS.ROUTER, window._selectedDex.router);
  
              if (tradeType === 'buy') {
                const amount = buyMode === TRADE_MODES.FIXED_INPUT ?
                  txWeb3.utils.toWei(inputAmount, 'ether') :
                  txWeb3.utils.toWei(outputAmount, 'ether');
                
                const outputAmountOrMaxInput = buyMode === TRADE_MODES.FIXED_INPUT ?
                  txWeb3.utils.toWei(minOutputAmount, 'ether') :
                  txWeb3.utils.toWei(maxInputAmount, 'ether');
  
                tx = await constructBuyTx(
                  txWeb3,
                  router,
                  window._selectedDex,
                  path,
                  amount,
                  outputAmountOrMaxInput,
                  userAddress,
                  inputToken === 'native',
                  buyMode
                );
              } else {
                // 计算卖出数量
                let sellAmount;
                if (sellMode === SELL_MODES.FIXED) {
                  sellAmount = txWeb3.utils.toWei(inputAmount, 'ether');
                } else {
                  // 百分比卖出
                  const amountStr = await calculateSellAmount(
                    toAddress, 
                    userAddress, 
                    txWeb3, 
                    sellPercentage
                  );
                  sellAmount = txWeb3.utils.toWei(amountStr, 'ether');
                }
  
                tx = await constructSellTx(
                  txWeb3,
                  router,
                  window._selectedDex,
                  path,
                  sellAmount,
                  txWeb3.utils.toWei(minOutputAmount, 'ether'),
                  userAddress
                );
              }
            }
          }

          // 設置gas
          tx.gas = gasLimit;
          tx.gasPrice = targetGasPrice;

          // 如果使用防夾子RPC，則記錄到控制台
          if (useAntiMevForTx) {
            console.log(`使用防夾子RPC進行交易: ${selectedAntiMevRpc}`);
          }

          const receipt = await txWeb3.eth.sendTransaction(tx);
          
          results.push({
            address: userAddress,
            success: true,
            hash: receipt.transactionHash
          });

        } catch (error) {
          console.error('Transaction failed:', error);
          results.push({
            address: userAddress,
            success: false,
            error: error.message
          });
        }
      }

      updateResults(results);

    } catch (error) {
      console.error('Execute trade error:', error);
      setError(error.message);
    }
  };
// 處理待處理交易
  const handlePendingTransaction = async (txHash) => {
    try {
      if (pendingTxRef.current.has(txHash)) return;
      
      // 記錄已處理的交易以避免重複處理
      pendingTxRef.current.add(txHash);
      
      // 設置限制處理的交易數量以避免內存問題
      if (pendingTxRef.current.size > 10000) {
        pendingTxRef.current.clear();
      }

      setMonitoringStats(prev => ({
        ...prev,
        totalScanned: prev.totalScanned + 1
      }));

      const tx = await web3ManagerRef.current.getTransaction(txHash);
      if (!tx) return;

      let receipt = null;
      if (tradeType === 'sell' && antiTargetMode) {
        receipt = await web3ManagerRef.current.getTransactionReceipt(txHash);
        if (!receipt) return;
      }

      const matchesConditions = await checkTransactionConditions(tx, receipt);
      if (!matchesConditions) return;

      setMonitoringStats(prev => ({
        ...prev,
        matchedTx: prev.matchedTx + 1
      }));

      // 計算gas價格
      const gasPrice = Math.floor(
        parseInt(tx.gasPrice) * parseFloat(gasMultiplier)
      ).toString();

      await executeTrade(tx, gasPrice);

    } catch (error) {
      console.error('Handle pending transaction error:', error);
    }
  };

  // 處理新區塊
  const handleNewBlock = async (blockHeader) => {
    try {
      const blockNumber = blockHeader.number;
      
      // 更新當前區塊號
      setAutoSnipeConfig(prev => ({
        ...prev,
        currentBlock: blockNumber
      }));
      
      // 檢查是否已處理過此區塊
      if (processedBlocksRef.current.has(blockNumber)) {
        return;
      }
      
      // 記錄已處理的區塊
      processedBlocksRef.current.add(blockNumber);
      
      // 限制已處理區塊的數量以避免內存問題
      if (processedBlocksRef.current.size > 100) {
        processedBlocksRef.current.clear();
        processedBlocksRef.current.add(blockNumber);
      }
      
      // 檢查是否需要跳過區塊
      const skipBlockCount = parseInt(skipBlocks);
      if (skipBlockCount > 0) {
        const lastBlock = lastProcessedBlockRef.current;
        if (lastBlock > 0 && blockNumber - lastBlock <= skipBlockCount) {
          console.log(`跳過區塊 ${blockNumber}，需要間隔 ${skipBlockCount} 個區塊`);
          return;
        }
      }
      
      lastProcessedBlockRef.current = blockNumber;
      
      // 自動狙擊邏輯
      if (currentTab === 1 && autoSnipeConfig.enabled) {
        autoSniperRef.current.handleNewBlock(blockHeader);
      }
    } catch (error) {
      console.error('Handle new block error:', error);
    }
  };

  // 開始區塊監控
  const startBlockMonitoring = () => {
    if (!web3ManagerRef.current) return;
    
    try {
      web3ManagerRef.current.subscribeNewBlockHeaders(handleNewBlock);
    } catch (error) {
      console.error('Start block monitoring error:', error);
      setError('區塊監控啟動失敗: ' + error.message);
    }
  };

  // 處理合約創建
  const handleContractCreation = async (tx) => {
    try {
      if (!tx || !autoSnipeConfig.enabled) return;
      
      const receipt = await web3ManagerRef.current.getTransactionReceipt(tx.hash);
      if (!receipt || !receipt.contractAddress) return;
      
      const contractInfo = await autoSniperRef.current.analyzeContract(receipt.contractAddress);
      
      if (contractInfo) {
        setAutoSnipeConfig(prev => ({
          ...prev,
          contractAnalysis: {
            ...prev.contractAnalysis,
            [receipt.contractAddress.toLowerCase()]: contractInfo
          }
        }));
      }
    } catch (error) {
      console.error('Handle contract creation error:', error);
    }
  };

  // 自動狙擊執行交易
  const executeAutoSniperTrade = async (targetContract) => {
    try {
      // 使用相同的交易執行邏輯，但目標合約是自動識別的
      const gas = await window._web3Instance.eth.getGasPrice();
      const gasPriceBoost = Math.floor(
        parseInt(gas) * parseFloat(gasMultiplier)
      ).toString();
      
      await executeTrade(null, gasPriceBoost);
    } catch (error) {
      console.error('Auto sniper trade execution error:', error);
      setError('自動狙擊交易執行失敗: ' + error.message);
    }
  };
// 開始监控
  const startMonitoring = async () => {
    try {
      if (currentTab === 1) { // 自动狙擊模式
        if (!window._web3Instance || !window._selectedDex) {
          throw new Error('請先連接RPC並選擇DEX');
        }

        if (!window._userAddresses?.length) {
          throw new Error('請先選擇要操作的錢包');
        }

        if (!toAddress) {
          throw new Error('請輸入目標合約地址');
        }

        // 如果啟用了防夾子功能，初始化防夾子Web3實例
        if (useAntiMev && !antiMevWeb3Ref.current) {
          const antiMevWeb3 = await initAntiMevWeb3(selectedAntiMevRpc);
          if (!antiMevWeb3) {
            throw new Error('無法連接防夾子RPC，請檢查設置');
          }
        }

        // 提前檢查授權
        setLoading(true);
        const web3 = window._web3Instance;
        const network = await findCurrentNetwork(web3);
        
        if (!network) {
          throw new Error('無法識別當前網絡');
        }

        // 檢查所有錢包的授權狀態
        for (const userAddress of window._userAddresses) {
          if (tradeType === 'sell' || (tradeType === 'buy' && inputToken !== 'native')) {
            const tokenToCheck = tradeType === 'sell' ? 
              toAddress : 
              network.stablecoins.USDT;

            const approvals = await checkApprovals(tokenToCheck, userAddress);
            
            if (useTaxCheck) {
              if (!approvals.contractApproved) {
                setApprovalTokenAddress(tokenToCheck);
                setApprovalUserAddress(userAddress);
                setShowApprovalDialog(true);
                setLoading(false);
                return;
              }
            } else {
              if (!approvals.routerApproved) {
                setApprovalTokenAddress(tokenToCheck);
                setApprovalUserAddress(userAddress);
                setShowApprovalDialog(true);
                setLoading(false);
                return;
              }
            }
          }
        }

        clearBalanceCache();

        // 連接WebSocket
        await web3ManagerRef.current.connect(window._rpcUrl);
        
        // 設置自動狙擊目標和回調
        autoSniperRef.current.setTargetContract(toAddress);
        autoSniperRef.current.setExecutionCallback(executeAutoSniperTrade);
        autoSniperRef.current.enable();

        // 訂閱新區塊和待處理交易
        web3ManagerRef.current.subscribeNewBlockHeaders(handleNewBlock);
        web3ManagerRef.current.subscribePendingTransactions((txHash) => {
          web3ManagerRef.current.getTransaction(txHash).then(tx => {
            if (tx && tx.to === null) {
              handleContractCreation(tx);
            }
          });
        });

        // 重置統計
        setMonitoringStats({
          totalScanned: 0,
          matchedTx: 0,
          successfulTx: 0,
          failedTx: 0,
          startTime: Date.now()
        });

        setAutoSnipeConfig(prev => ({
          ...prev,
          enabled: true
        }));
        
        setIsMonitoring(true);
        setError('');
        setLoading(false);

      } else { // 原有手动狙擊模式
        if (!window._web3Instance || !window._selectedDex) {
          throw new Error('請先連接RPC並選擇DEX');
        }

        if (!window._userAddresses?.length) {
          throw new Error('請先選擇要操作的錢包');
        }

        if (!toAddress) {
          throw new Error('請輸入目標合約地址');
        }

        // 检查当前DEX的协议类型
        if (isDexV3(window._selectedDex) && protocolTab !== PROTOCOL_TYPES.V3) {
          setProtocolTab(PROTOCOL_TYPES.V3);
          console.log('当前DEX为V3协议，已自动切换到V3模式');
        } else if (!isDexV3(window._selectedDex) && protocolTab !== PROTOCOL_TYPES.V2) {
          setProtocolTab(PROTOCOL_TYPES.V2);
          console.log('当前DEX为V2协议，已自动切换到V2模式');
        }

        // 如果啟用了防夾子功能，初始化防夾子Web3實例
        if (useAntiMev && !antiMevWeb3Ref.current) {
          const antiMevWeb3 = await initAntiMevWeb3(selectedAntiMevRpc);
          if (!antiMevWeb3) {
            throw new Error('無法連接防夾子RPC，請檢查設置');
          }
        }

        // 提前檢查授權
        setLoading(true);
        const web3 = window._web3Instance;
        const network = await findCurrentNetwork(web3);
        
        if (!network) {
          throw new Error('無法識別當前網絡');
        }

        // 檢查所有錢包的授權狀態
        for (const userAddress of window._userAddresses) {
          if (tradeType === 'sell' || (tradeType === 'buy' && inputToken !== 'native')) {
            const tokenToCheck = tradeType === 'sell' ? 
              toAddress : 
              network.stablecoins.USDT;

            const approvals = await checkApprovals(tokenToCheck, userAddress);
            
            if (useTaxCheck) {
              if (!approvals.contractApproved) {
                setApprovalTokenAddress(tokenToCheck);
                setApprovalUserAddress(userAddress);
                setShowApprovalDialog(true);
                setLoading(false);
                return;
              }
            } else {
              if (!approvals.routerApproved) {
                setApprovalTokenAddress(tokenToCheck);
                setApprovalUserAddress(userAddress);
                setShowApprovalDialog(true);
                setLoading(false);
                return;
              }
            }
          }
        }

        clearBalanceCache();

        // 連接WebSocket
        await web3ManagerRef.current.connect(window._rpcUrl);

        // 重置統計
        setMonitoringStats({
          totalScanned: 0,
          matchedTx: 0,
          successfulTx: 0,
          failedTx: 0,
          startTime: Date.now()
        });

        // 啟動監控
        if (!chainSupport.isL2Chain) {
          web3ManagerRef.current.subscribePendingTransactions(handlePendingTransaction);
        }

        startBlockMonitoring();

        setIsMonitoring(true);
        setError('');
        setLoading(false);
      }
    } catch (err) {
      console.error('Start monitoring error:', err);
      setError('啟動監控失敗: ' + err.message);
      setLoading(false);
    }
  };

  // 停止監控
  const stopMonitoring = () => {
    if (web3ManagerRef.current) {
      web3ManagerRef.current.disconnect();
    }
    
    // 斷開防夾子RPC連接
    antiMevWeb3Ref.current = null;
    
    setIsMonitoring(false);
    
    if (currentTab === 1) {
      setAutoSnipeConfig(prev => ({
        ...prev,
        enabled: false
      }));
      
      if (autoSniperRef.current) {
        autoSniperRef.current.disable();
      }
    }
    
    clearBalanceCache();
    pendingTxRef.current.clear();
    processedBlocksRef.current.clear();
    lastProcessedBlockRef.current = 0;
  };
// 渲染監控統計
  const renderMonitoringStats = () => {
    if (!isMonitoring && !monitoringStats.startTime) return null;

    const duration = monitoringStats.startTime ? 
      Math.floor((Date.now() - monitoringStats.startTime) / 1000) : 0;

    return (
      <Card sx={{ mb: 2, bgcolor: 'primary.dark' }}>
        <CardContent>
          <Typography variant="h6" color="common.white" gutterBottom>
            監控統計
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                掃描交易: {monitoringStats.totalScanned}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                匹配交易: {monitoringStats.matchedTx}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                成功執行: {monitoringStats.successfulTx}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="common.white">
                失敗執行: {monitoringStats.failedTx}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="common.white">
                運行時間: {Math.floor(duration / 3600)}時 {Math.floor((duration % 3600) / 60)}分 {duration % 60}秒
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 渲染鏈支持狀態提示
  const renderChainSupportAlert = () => {
    if (!chainSupport.currentChain) {
      return (
        <Alert severity="warning" sx={{ mb: 2 }}>
          請先連接 RPC 節點
        </Alert>
      );
    }

    if (chainSupport.isL2Chain) {
      return (
        <Alert severity="error" sx={{ mb: 2 }}>
          此鏈只可1區塊賣出
        </Alert>
      );
    }

    if (chainSupport.isSameBlockSupported) {
      return (
        <Alert severity="success" sx={{ mb: 2 }}>
          此鏈正常可0區塊 除非隱私交易
        </Alert>
      );
    }

    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        不支持的鏈或無法確定支持狀態
      </Alert>
    );
  };

  // 渲染結果列表
  const renderResults = () => (
    <Collapse in={showResults}>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            操作結果
            <Button 
              size="small" 
              onClick={() => setShowResults(false)}
              sx={{ ml: 2 }}
            >
              關閉
            </Button>
          </Typography>
          <List>
            {operationResults.map((result, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={`錢包: ${result.address.slice(0, 6)}...${result.address.slice(-4)}`}
                    secondary={
                      result.success ? (
                        <Link 
                          href={`${explorerUrl}/tx/${result.hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          查看交易: {result.hash.slice(0, 10)}...
                        </Link>
                      ) : (
                        `失敗: ${result.error}`
                      )
                    }
                    sx={{
                      color: result.success ? 'success.main' : 'error.main'
                    }}
                  />
                </ListItem>
                {index < operationResults.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Collapse>
  );

  // 渲染交易路徑顯示
  const renderTradePath = () => {
    if (!tradePath.length) return null;

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            交易路徑
            <Tooltip title="顯示代幣交換的完整路徑" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1
          }}>
            {tradePath.map((address, index) => {
              const token = tradeTokens.get(address.toLowerCase());
              return (
                <React.Fragment key={address}>
                  <Box sx={{
                    bgcolor: token?.isNative ? 'warning.light' : 
                           token?.isStable ? 'success.light' : 
                           'primary.light',
                    color: 'common.white',
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Typography>{token?.symbol || address.slice(0, 6)}</Typography>
                    {token?.isNative && (
                      <Tooltip title="原生代幣">
                        <Box component="span">🔷</Box>
                      </Tooltip>
                    )}
                    {token?.isStable && (
                      <Tooltip title="穩定幣">
                        <Box component="span">💰</Box>
                      </Tooltip>
                    )}
                  </Box>
                  {index < tradePath.length - 1 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography color="text.secondary">→</Typography>
                      {protocolTab === PROTOCOL_TYPES.V3 && tradeFees && tradeFees[index] && (
                        <Tooltip title={`費率: ${UNISWAPV3_FEE_LEVELS[tradeFees[index]]}`}>
                          <Box component="span" sx={{ 
                            bgcolor: 'secondary.light', 
                            color: 'white', 
                            px: 1, 
                            py: 0.5, 
                            borderRadius: 1,
                            fontSize: '0.75rem'
                          }}>
                            {UNISWAPV3_FEE_LEVELS[tradeFees[index]]}
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
          {encodedPath && protocolTab === PROTOCOL_TYPES.V3 && (
            <Typography variant="caption" sx={{ mt: 1, display: 'block', color: 'text.secondary' }}>
              编码路径: {encodedPath.slice(0, 32)}...
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  // 渲染賣出模式設置
  const renderSellModeSettings = () => {
    if (tradeType !== 'sell') return null;

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            賣出模式設置
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>賣出模式</InputLabel>
                <Select
                  value={sellMode}
                  onChange={(e) => setSellMode(e.target.value)}
                  label="賣出模式"
                >
                  <MenuItem value={SELL_MODES.FIXED}>固定數量</MenuItem>
                  <MenuItem value={SELL_MODES.PERCENTAGE}>百分比數量</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={antiTargetMode}
                    onChange={(e) => setAntiTargetMode(e.target.checked)}
                  />
                }
                label={
                  <Box>
                    <Typography component="span">防針對模式</Typography>
                    <Typography 
                      component="span" 
                      color="text.secondary" 
                      sx={{ ml: 1, fontSize: '0.875rem' }}
                    >
                      (當目標合約對已導入錢包執行操作時觸發賣出)
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 渲染V3特定设置面板
  const renderV3SettingsPanel = () => {
    if (protocolTab !== PROTOCOL_TYPES.V3) return null;

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            UniswapV3 設置
            <Tooltip title="設置UniswapV3特定參數" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>費率</InputLabel>
                <Select
                  value={feeTier}
                  onChange={(e) => setFeeTier(e.target.value)}
                  label="費率"
                >
                  {Object.entries(UNISWAPV3_FEE_LEVELS).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                  交易時使用的費率等級，不同費率對應不同的池子
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // 渲染防夾子(Anti-MEV)設置面板
  const renderAntiMevPanel = () => {
    // 僅在支持防夾子的鏈上顯示
    if (!chainSupport.canUseAntiMev) return null;

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            防夾子(Anti-MEV)設置
            <Tooltip title="使用專門的RPC服務來防止交易被夾子攻擊" placement="right">
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useAntiMev}
                    onChange={(e) => setUseAntiMev(e.target.checked)}
                  />
                }
                label="啟用防夾子保護"
              />
            </Grid>
            
            {useAntiMev && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>選擇防夾子RPC</InputLabel>
                  <Select
                    value={selectedAntiMevRpc}
                    onChange={(e) => setSelectedAntiMevRpc(e.target.value)}
                    label="選擇防夾子RPC"
                  >
                    {availableAntiMevRpcs.map((rpc) => (
                      <MenuItem key={rpc.url} value={rpc.url}>
                        {rpc.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                    注意: 防夾子僅適用於交易執行，監控仍使用主RPC
                  </Typography>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };
// [Auto Sniper] 自動狙擊控制面板
  const renderAutoSniperPanel = () => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          自動狙擊設置
          <Tooltip title="自動檢測合約的區塊限制並選擇安全交易窗口">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="目標合約地址"
              value={toAddress}
              onChange={(e) => setToAddress(e.target.value)}
              helperText="輸入要狙擊的合約地址"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>輸入代幣</InputLabel>
              <Select
                value={inputToken}
                onChange={(e) => setInputToken(e.target.value)}
              >
                <MenuItem value="native">原生代幣</MenuItem>
                <MenuItem value="usdt">USDT</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="買入金額"
              type="number"
              value={inputAmount}
              onChange={(e) => setInputAmount(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="最小獲得數量"
              type="number"
              value={minOutputAmount}
              onChange={(e) => setMinOutputAmount(e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  // 渲染自動狙擊合約分析面板
  const renderContractAnalysisPanel = () => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          合約安全分析
        </Typography>
        {Object.values(autoSnipeConfig.contractAnalysis).length > 0 ? (
          Object.values(autoSnipeConfig.contractAnalysis).map(contract => (
            <Box key={contract.address} sx={{ mb: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 2 }}>
              <Typography variant="subtitle1">
                {contract.address.slice(0, 8)}...{contract.address.slice(-6)} - 風險等級: 
                <span style={{ 
                  color: contract.riskLevel === 'high' ? '#d32f2f' : '#2e7d32',
                  fontWeight: 'bold',
                  marginLeft: '4px'
                }}>
                  {contract.riskLevel}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                首次出現區塊: {contract.firstSeenBlock}
              </Typography>
              {contract.safeBlockWindow && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  安全交易窗口: 區塊 {contract.safeBlockWindow.start} 到 {contract.safeBlockWindow.end}
                  {autoSnipeConfig.currentBlock > 0 && (
                    <span style={{ 
                      color: autoSnipeConfig.currentBlock >= contract.safeBlockWindow.start && 
                             autoSnipeConfig.currentBlock <= contract.safeBlockWindow.end ? 
                             '#2e7d32' : '#d32f2f',
                      fontWeight: 'bold',
                      marginLeft: '4px'
                    }}>
                      (當前: {autoSnipeConfig.currentBlock})
                    </span>
                  )}
                </Typography>
              )}
              {contract.patterns && contract.patterns.length > 0 && (
                <>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    檢測到的保護機制:
                  </Typography>
                  <ul style={{ marginTop: '4px', paddingLeft: '20px' }}>
                    {contract.patterns.map((pattern, idx) => (
                      <li key={idx} style={{ color: '#666' }}>
                        {pattern}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            尚未檢測到合約，開始監控後將自動分析。
          </Typography>
        )}
      </CardContent>
    </Card>
  );
// 主渲染
  return (
    <Box>
      {/* 授權對話框 */}
      <Dialog 
        open={showApprovalDialog} 
        onClose={() => setShowApprovalDialog(false)}
      >
        <DialogTitle>需要授權</DialogTitle>
        <DialogContent>
          <Typography>
            在進行交易前，需要先授權{useTaxCheck ? '合約' : '路由器'}使用您的代幣。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowApprovalDialog(false)}>
            取消
          </Button>
          <Button 
            variant="contained" 
            onClick={async () => {
              try {
                if (useTaxCheck) {
                  await approveContract(approvalTokenAddress, approvalUserAddress);
                } else {
                  await approveRouter(approvalTokenAddress, approvalUserAddress);
                }
                setShowApprovalDialog(false);
                // 完成授權後自動重新啟動監控
                startMonitoring();
              } catch (error) {
                console.error('Approval error:', error);
                setError(error.message);
              }
            }}
          >
            授權
          </Button>
        </DialogActions>
      </Dialog>

      <Tabs 
        value={currentTab} 
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{ mb: 2 }}
      >
        <Tab label="一般狙擊" />
        <Tab label="自動狙擊" />
      </Tabs>

      {/* 协议切换标签 */}
      <Tabs
        value={protocolTab}
        onChange={(e, newValue) => setProtocolTab(newValue)}
        sx={{ mb: 2 }}
      >
        <Tab label="UniswapV2" value={PROTOCOL_TYPES.V2} />
        <Tab 
          label="UniswapV3" 
          value={PROTOCOL_TYPES.V3} 
          disabled={!chainSupport.hasV3Support && !isDexV3(window._selectedDex)}
        />
      </Tabs>

      {currentTab === 0 ? (
        <Box>
          {renderChainSupportAlert()}
          {renderMonitoringStats()}
          {renderTradePath()}
          
          {/* 交易類型選擇 */}
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>交易類型</InputLabel>
                    <Select
                      value={tradeType}
                      onChange={(e) => {
                        setTradeType(e.target.value);
                        if (e.target.value === 'buy') {
                          setAntiTargetMode(false);
                        }
                      }}
                      label="交易類型"
                    >
                      <MenuItem value="buy">買入</MenuItem>
                      <MenuItem value="sell">賣出</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {tradeType === 'buy' ? '買入貨幣' : '賣出貨幣'}
                    </InputLabel>
                    <Select
                      value={inputToken}
                      onChange={(e) => setInputToken(e.target.value)}
                      label={tradeType === 'buy' ? '買入貨幣' : '賣出貨幣'}
                    >
                      <MenuItem value="native">原生代幣</MenuItem>
                      <MenuItem value="usdt">USDT</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {tradeType === 'buy' && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>買入模式</InputLabel>
                      <Select
                        value={buyMode}
                        onChange={(e) => setBuyMode(e.target.value)}
                        label="買入模式"
                      >
                        <MenuItem value={TRADE_MODES.FIXED_INPUT}>
                          固定支付模式（支付固定數量）
                        </MenuItem>
                        <MenuItem value={TRADE_MODES.FIXED_OUTPUT}>
                          固定獲得模式（獲得固定數量）
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>

          {renderSellModeSettings()}
          
          {/* V3 特定设置 */}
          {renderV3SettingsPanel()}
          
          {/* 防夾子設置 */}
          {renderAntiMevPanel()}

          {/* 監控設置 */}
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                監控設置
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Gas Price倍數 (1.1~10)"
                    type="number"
                    value={gasMultiplier}
                    onChange={(e) => setGasMultiplier(e.target.value)}
                    InputProps={{
                      inputProps: { min: 1.1, max: 10, step: 0.1 }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="跳過區塊數"
                    type="number"
                    value={skipBlocks}
                    onChange={(e) => setSkipBlocks(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Gas Limit"
                    type="number"
                    value={gasLimit}
                    onChange={(e) => setGasLimit(e.target.value)}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* 監控目標設置 */}
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                監控目標設置
              </Typography>
              <Grid container spacing={2}>
                {!antiTargetMode && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="監控From地址 (選填)"
                      value={fromAddress}
                      onChange={(e) => setFromAddress(e.target.value)}
                    />
                  </Grid>
                )}
                
                <Grid item xs={12} md={antiTargetMode ? 12 : 6}>
                  <TextField
                    fullWidth
                    label={antiTargetMode ? "目標合約地址" : "監控To地址"}
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                  />
                </Grid>

                {!antiTargetMode && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Method ID列表 (以逗號分隔)"
                      value={methodIds}
                      onChange={(e) => setMethodIds(e.target.value)}
                      placeholder="例如: 0x00000000,0x11111111"
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>

          {/* 交易設置 */}
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                交易設置
              </Typography>
              <Grid container spacing={2}>
                {tradeType === 'buy' ? (
                  buyMode === TRADE_MODES.FIXED_INPUT ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="支付數量"
                          type="number"
                          value={inputAmount}
                          onChange={(e) => setInputAmount(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0, step: "any" }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="最小獲得數量"
                          type="number"
                          value={minOutputAmount}
                          onChange={(e) => setMinOutputAmount(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0, step: "any" }
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="目標獲得數量"
                          type="number"
                          value={outputAmount}
                          onChange={(e) => setOutputAmount(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0, step: "any" }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="最大支付數量"
                          type="number"
                          value={maxInputAmount}
                          onChange={(e) => setMaxInputAmount(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0, step: "any" }
                          }}
                        />
                      </Grid>
                    </>
                  )
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      {sellMode === SELL_MODES.FIXED ? (
                        <TextField
                          fullWidth
                          label="賣出數量"
                          type="number"
                          value={inputAmount}
                          onChange={(e) => setInputAmount(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0, step: "any" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  size="small"
                                  onClick={async () => {
                                    try {
                                      if (!window._web3Instance || !window._userAddresses?.length) {
                                        throw new Error('請先連接錢包');
                                      }
                                      const web3 = window._web3Instance;
                                      const balance = await getTokenBalance(toAddress, window._userAddresses[0], web3);
                                      const decimals = await getTokenDecimals(toAddress, web3);
                                      const maxAmount = fromTokenDecimals(web3, balance, decimals);
                                      setInputAmount(maxAmount);
                                    } catch (error) {
                                      console.error('獲取最大數量失敗:', error);
                                      setError('獲取最大數量失敗: ' + error.message);
                                    }
                                  }}
                                >
                                  MAX
                                </Button>
                              </InputAdornment>
                            )
                          }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label="賣出百分比 (%)"
                          type="number"
                          value={sellPercentage}
                          onChange={(e) => setSellPercentage(e.target.value)}
                          InputProps={{
                            inputProps: { min: 0.1, max: 100, step: 0.1 }
                          }}
                          helperText="將賣出持倉的指定百分比"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="最小獲得數量"
                        type="number"
                        value={minOutputAmount}
                        onChange={(e) => setMinOutputAmount(e.target.value)}
                        InputProps={{
                          inputProps: { min: 0, step: "any" }
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {/* 稅率檢測設置 */}
              <FormControlLabel
                control={
                  <Switch
                    checked={useTaxCheck}
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      if (newValue && !chainSupport.canUseTaxCheck) {
                        setError('當前網絡尚未部署稅率檢測合約，無法開啟此功能');
                        return;
                      }
                      setUseTaxCheck(newValue);
                    }}
                    disabled={!chainSupport.canUseTaxCheck}
                  />
                }
                label={
                  <Box>
                    <Typography component="span">稅率檢測</Typography>
                    {!chainSupport.canUseTaxCheck && (
                      <Typography 
                        component="span" 
                        color="text.secondary" 
                        sx={{ ml: 1, fontSize: '0.875rem' }}
                      >
                        (當前網絡未支援)
                      </Typography>
                    )}
                  </Box>
                }
                sx={{ mt: 2, display: 'block' }}
              />

              {useTaxCheck && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="最大買入稅率 (%)"
                      type="number"
                      value={maxBuyTax}
                      onChange={(e) => setMaxBuyTax(e.target.value)}
                      InputProps={{
                        inputProps: { min: 0, max: 100 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="最大賣出稅率 (%)"
                      type="number"
                      value={maxSellTax}
                      onChange={(e) => setMaxSellTax(e.target.value)}
                      InputProps={{
                        inputProps: { min: 0, max: 100 }
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>

          {/* 監控控制 */}
          <Button
            variant="contained"
            fullWidth
            onClick={isMonitoring ? stopMonitoring : startMonitoring}
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 
             isMonitoring ? "停止監控" : "開始監控"}
          </Button>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {/* 結果列表 */}
          {renderResults()}

          {/* 監控中提示 */}
          {isMonitoring && (
            <LinearProgress sx={{ mt: 2, mb: 1 }} />
          )}
        </Box>
      ) : (
        <Box>
          {renderAutoSniperPanel()}
          {renderV3SettingsPanel()}
          {renderAntiMevPanel()}
          {renderContractAnalysisPanel()}
          
          <Button 
            variant="contained" 
            fullWidth
            onClick={isMonitoring ? stopMonitoring : startMonitoring}
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 
             isMonitoring ? "停止智能監控" : "開始智能監控"}
          </Button>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

// 導出組件
module.exports = MempoolPanel;
